/**
 * Reusable dialog component
 */
import React from 'react'
import PropTypes from 'prop-types'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { PersonAddSharp } from '@material-ui/icons'
import {
    Button,
    Dialog as Modal,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    useMediaQuery,
    Box
} from '@material-ui/core';
import ProgressButton from '../Common/ProgressButton';

const useStyles = makeStyles(theme => ({

    dialogActions: {
        padding: '0 24px 24px'
    },

    dialogContent: {
        padding: '0 24px',
    },

    backDrop: {
        backdropFilter: "blur(1px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    },

    button: {
        textTransform: "none"
    }
}))

const Dialog = React.forwardRef((props, ref) => {
    const classes = useStyles()
    const theme = useTheme();
    const {
        open,
        onClose,
        onAccept,
        header,
        dialogContentText,
        onCloseText,
        onAcceptText,
        maxWidth,
        divider,
        disableAccept,
        isDestructive,
        isWaiting,
        disableCancel,
        scroll,
        ...others
    } = props

    function getButtonProps() {
        return { size: "small" }
    }

    return (
        <Modal
            maxWidth="md"
            BackdropProps={{
                classes: {
                    root: classes.backDrop,
                },
            }}
            open={open}
            {...others}
            scroll={scroll}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title" disableBackdropClick={true}>
            {header}
            <DialogContent ref={ref} dividers={divider} className={classes.dialogContent}>
                {dialogContentText && <DialogContentText>
                    {dialogContentText}
                </DialogContentText>}
                {/* Render children here which were wrapped inside this component*/}
                {props.children}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {onClose && !isDestructive && <Button
                    color="primary"
                    {...getButtonProps()}
                    onClick={onClose}
                    className={classes.button}
                    disabled={disableCancel}
                    variant="outlined"
                    >
                    {onCloseText}
                </Button>}
                {onAccept &&
                    <ProgressButton
                        isWaiting={isWaiting}
                        autoFocus
                        color={isDestructive ? "secondary" : "primary"}
                        size="small"
                        variant="contained"
                        disabled={disableAccept}
                        onClick={onAccept}>
                        {onAcceptText}
                    </ProgressButton>
                }
                {isDestructive && <Button
                    color="primary"
                    {...getButtonProps()}
                    className={classes.button}
                    onClick={onClose}
                    variant="outlined"
                    >
                    {onCloseText}
                </Button>}
            </DialogActions>
        </Modal>
    )
})

Dialog.defaultProps = {
    onCloseText: 'Cancel',
    onAcceptText: 'Save',
    maxWidth: 'sm',
    divider: true,
    disableAccept: false,
    isDestructive: false,
    isWaiting: false,
    scroll: "body"
}

Dialog.propTypes = {
    open: PropTypes.bool,
    onAcceptText: PropTypes.any,
    onCloseText: PropTypes.any,
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    divider: PropTypes.bool,
    header: PropTypes.object.isRequired,
    isDestructive: PropTypes.bool,
    isWaiting: PropTypes.bool,
    scroll: PropTypes.string
}

export default Dialog
