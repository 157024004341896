/* Action Types */
const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const TOGGLE_ONBOARDING_DIALOG_VISIBILITY = 'TOGGLE_ONBOARDING_DIALOG_VISIBILITY'
const TOGGLE_INTENT_DIALOG_VISIBILITY = 'TOGGLE_INTENT_DIALOG_VISIBILITY'
const TOGGLE_VERIFY_EMAIL_VISIBILITY = 'TOGGLE_VERIFY_EMAIL_VISIBILITY'
const TOGGLE_TEAMBUILD_DIALOG_VISIBILITY = 'TOGGLE_TEAMBUILD_DIALOG_VISIBILITY'
const SET_ALL_USERS_OPTIONS_LIST = 'SET_ALL_USERS_OPTIONS_LIST'
const SET_USER_PROFILE_DETAILS = 'SET_USER_PROFILE_DETAILS'
const SET_LOCATION_SETTINGS = 'SET_LOCATION_SETTINGS'
const SET_ORG_SETTINGS = 'SET_ORG_SETTINGS'
const SET_NETWORK_SETTINGS = "SET_NETWORK_SETTINGS"
const SET_LICENSE_DETAILS = "SET_LICENSE_DETAILS"
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER'
export const HIDE_APP_DRAWER = 'HIDE_APP_DRAWER'
export const SHOW_APP_DRAWER = 'SHOW_APP_DRAWER'
export const COUNTRY_CODE = 'COUNTRY_CODE'
export const SET_SHOW_BANNER = 'SET_SHOW_BANNER'

export default {
    SHOW_SNACKBAR,
    TOGGLE_ONBOARDING_DIALOG_VISIBILITY,
    SET_ALL_USERS_OPTIONS_LIST,
    SET_USER_PROFILE_DETAILS,
    TOGGLE_INTENT_DIALOG_VISIBILITY,
    TOGGLE_TEAMBUILD_DIALOG_VISIBILITY,
    TOGGLE_APP_DRAWER,
    HIDE_APP_DRAWER,
    TOGGLE_VERIFY_EMAIL_VISIBILITY,
    SHOW_APP_DRAWER,
    COUNTRY_CODE,
    SET_ORG_SETTINGS,
    SET_LOCATION_SETTINGS,
    SET_NETWORK_SETTINGS,
    SET_LICENSE_DETAILS
}