import React, { useEffect, useContext, useState } from 'react'
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context';
// Context consumer
import useComponentMountedHook from '../Hooks/useComponentMountedHook';
import { useAxios, useRORAxios } from '../core/useAxios';
import UserApis from '../components/Users/UserApis';
import GlobalStateContext from '../Context/GlobalStateContext';
import GlobalStateMutations from '../Context/GlobalStateMutations';
import loadable from '@loadable/component'
import SettingApis from './Settings/SettingApis';
import usePermissions from '../Hooks/usePermissions';
import { isEmptyObject } from 'jquery';

const UserPortal = loadable(() => import('./UserPortal'), {
    fallback: "",
});

const ResellerPortal = loadable(() => import('./ResellerPortal'), {
    fallback: "",
});

const SwitchPortal = (props) => {
    const { oidcUser, logout, events } = useReactOidc();
    const [state, dispatch] = useContext(GlobalStateContext)
    const [isWaiting, setIsWaiting] = useState(true)
    const permissions = usePermissions();

    const axiosInstance = useAxios(
        process.env.REACT_APP_KC_BROKER_URL,
        oidcUser.access_token
    );

    const axiosRORInstance = useRORAxios(
        process.env.REACT_APP_IM_HTTP_API_ROR_URL,
        oidcUser.access_token
    );

    const { isComponentMounted } = useComponentMountedHook(axiosInstance)

    useEffect(() => {
        if (isComponentMounted) {
            UserApis.getUserProfile(axiosInstance).then(({ data }) => {
                // Set user details in global state
                dispatch({
                    type: GlobalStateMutations.SET_USER_PROFILE_DETAILS,
                    payload: data
                })
            }).finally(() => {
                setIsWaiting(false)
            })
            SettingApis.fetchAllSettings(axiosRORInstance).then(({ data }) => {
                dispatch({
                    type: GlobalStateMutations.SET_NETWORK_SETTINGS,
                    payload: data.settings
                })
            })
        }
    }, [isComponentMounted]);

    if (isEmptyObject(permissions) || isWaiting) return null
    return (
        <>
            {permissions.isReseller ? <ResellerPortal /> : <UserPortal />}
        </>
    )
}



export default withOidcSecure(SwitchPortal);
