import React, { createContext, useReducer, useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect } from 'react';
import GlobalStateMutations, { TOGGLE_APP_DRAWER, HIDE_APP_DRAWER, SHOW_APP_DRAWER, COUNTRY_CODE, SET_SHOW_BANNER } from './GlobalStateMutations';

const position = {
    vertical: 'bottom',
    horizontal: 'right',
}

/* Define a context and a reducer for updating the context */
const GlobalStateContext = createContext();

const initialState = {
    snack: {
        isVisible: false,
        message: '',
        variant: 'success',
    },
    isOnboargingDialogVisible: false, // weather onboarding dialog is visible or not
    allUsersList: [],
    userProfileDetails: {}, // User details
    isDrawerVisible: true,
    orgSettings: {},
    locationSettings: {},
    networkSettings:{},
    licenseDetails:{},
    showBanner: { visible: false, text: "" }
};

const globalStateReducer = (state, action) => {
    switch (action.type) {
        case GlobalStateMutations.SHOW_SNACKBAR:
            return {
                ...state,
                snack: { ...action.payload },
            };
        case GlobalStateMutations.TOGGLE_ONBOARDING_DIALOG_VISIBILITY:
            return {
                ...state,
                isOnboargingDialogVisible: action.payload,
            };
        case GlobalStateMutations.TOGGLE_INTENT_DIALOG_VISIBILITY:
            return {
                ...state,
                isIntentDialogVisible: action.payload,
            };
        case GlobalStateMutations.TOGGLE_VERIFY_EMAIL_VISIBILITY:
            return {
                ...state,
                isVerifyEmailDialogVisible: action.payload,
            };
        case GlobalStateMutations.TOGGLE_TEAMBUILD_DIALOG_VISIBILITY:
            return {
                ...state,
                isTeamBuildDialogVisible: action.payload,
            };
        case GlobalStateMutations.SET_ALL_USERS_OPTIONS_LIST:
            return {
                ...state,
                allUsersList: action.payload,
            };
        case GlobalStateMutations.SET_USER_PROFILE_DETAILS:
            return {
                ...state,
                userProfileDetails: action.payload,
            };
        case GlobalStateMutations.SET_ORG_SETTINGS:
            return {
                ...state,
                orgSettings: action.payload,
            };
        case GlobalStateMutations.SET_NETWORK_SETTINGS:
            return {
                ...state,
                networkSettings: action.payload,
            };
        case GlobalStateMutations.SET_LICENSE_DETAILS:
                return {
                    ...state,
                    licenseDetails: action.payload,
                };
        case GlobalStateMutations.SET_LOCATION_SETTINGS:
            return {
                ...state,
                locationSettings: action.payload,
            };
        case TOGGLE_APP_DRAWER:
            return {
                ...state,
                isDrawerVisible: !state.isDrawerVisible,
            };
        case SET_SHOW_BANNER:
            return {
                ...state,
                showBanner: action.payload,
            };
        case SHOW_APP_DRAWER:
            return {
                ...state,
                isDrawerVisible: true,
            };
        case HIDE_APP_DRAWER:
            return {
                ...state,
                isDrawerVisible: false,
            };
        case COUNTRY_CODE:
            return {
                ...state,
                contryCode: action.payload,
            };
        default:
            return state;
    }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        globalStateReducer,
        initialState
    );

    const {
        isVisible,
        message,
        variant
    } = state.snack

    function closeErrorSnack() {
        dispatch({
            type: GlobalStateMutations.SHOW_SNACKBAR,
            payload: {
                isVisible: false,
                message: '',
                variant: 'success',
            }
        });
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    return (
        <GlobalStateContext.Provider value={[state, dispatch]}>
            {children}
            {/* We will have this snackbar below app componenet to be reused
            It will be alive till app mounted state i.e. all the time */}
            <Snackbar autoHideDuration={6000} anchorOrigin={position} open={isVisible} onClose={closeErrorSnack}>
                <Alert onClose={closeErrorSnack} severity={variant}>
                    {message}
                </Alert>
            </Snackbar>
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateContext