import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'
import {
    Button,
    IconButton,
    Box,
    Popover,
} from '@material-ui/core'
import { WarningRounded, CloseRounded, MailOutlineRounded } from '@material-ui/icons'
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context';
import usePermissions from '../Hooks/usePermissions';
import { verifyEmail } from "./Users/UserApis"
import { useAxios } from '../core/useAxios'
import GlobalStateContext from '../Context/GlobalStateContext';
import DangerTriangle from "../assets/images/Icons/DangerTriangle.svg"
const event = new CustomEvent('AccessTokenRenewError', { detail: {} });

const useStyles = makeStyles(theme => ({
    warning: {
        color: "yellow",
        marginRight: 20
    },

    popover_class: {
        width: "300px",
        padding: "15px 10px 10px 20px",
        background: "#161616",
        boxShadow: "22px -18px 22px rgba(24, 39, 75, 0.12), 21px -11px 64px rgba(24, 39, 75, 0.12)",
        borderRadius: 10,
        color: "#ffffff",
        whiteSpace: "pre-line"
    },

    cancle: {
        position: "relative",
        top: -8,
        right: -15
    },


    email: {
        border: "0.5px solid #F82D2D",
        boxSizing: "border-box",
        borderRadius: 5,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "10px 14px 8px 18px",
    },

    width30: {
        width: 30,
        display: "flex"
    },

    text: {
        marginLeft: 7,
        fontSize: 11,
        fontWeight: 600,
        color: "#EE3431"
    },

    position: {
        position: "absolute",
        display: "none",
        top: "70px !important",
        background: "#161616",
        overflow: "hidden",
        borderRadius: 10,
    },

    long: {
        left: "201px !important",
        '&::before': {
            content: '""',
            position: "fixed",
            transform: "rotate( 224deg )",
            top: "90px",
            border: "7px solid #161616",
            left: "194px",
        }
    },

    short: {
        left: "72px !important",
        '&::before': {
            content: '""',
            position: "fixed",
            transform: "rotate( 224deg )",
            top: "90px",
            border: "7px solid #161616",
            left: "65px",
        }
    },

    parent: {
        '&:hover #email': {
            display: "block"
        }
    }
}));


const VerifyEmail = ({ setCanWarningRender, canWarningRender }) => {
    const classes = useStyles()
    const { oidcUser } = useReactOidc();
    const [state, dispatch] = useContext(GlobalStateContext);
    const permissions = usePermissions()

    // useEffect(() => {
    //     if (permissions.showLicenses) {
    //         setCanWarningRender(true)
    //     }
    // }, [permissions])

    useEffect(() => {
        if (permissions.showLicenses && Object.keys(state.userProfileDetails).length) {
            setCanWarningRender(!state.userProfileDetails.email_verified)
        }
    }, [state, permissions])

    const axiosInstance = useAxios(
        process.env.REACT_APP_KC_BROKER_URL,
        oidcUser.access_token
    );

    const onSend = () => {
        verifyEmail(axiosInstance).then(() => {
            toggleSnackbar(true, "Verification email has been send on your registered email id")
        })
    }

    function toggleSnackbar(isVisible, message, variant = "success") {
        dispatch({
            type: "SHOW_SNACKBAR",
            payload: {
                isVisible: isVisible,
                message: message,
                variant: variant,
            },
        });
    }

    return (<div className={classes.parent}>
        {
            canWarningRender && <>
                <Box
                    className={clsx(classes.email, { [classes.width30]: !state.isDrawerVisible })}
                    title={state.isDrawerVisible ? "" : "Confirm Email Now"}>
                    {state.isDrawerVisible && <span className={classes.text}>Confirm Email Now</span>}
                    <IconButton style={{ padding: "2px" }}>
                        {state.isDrawerVisible ?
                            <MailOutlineRounded fontSize="small" color="secondary" /> :
                            <img src={DangerTriangle} />}
                    </IconButton>
                </Box>
                <div
                    id="email"
                    className={clsx(classes.position, state.isDrawerVisible ? classes.long : classes.short)}
                >
                    <Box className={classes.popover_class} >
                        You have not confirmed your email. Please check your email and follow the instructions to confirm the account. <br /> Note : Do check your Spam folder as well.
                        <Box display="flex" justifyContent="flex-end" onClick={onSend} mt="5px">
                            <Button color="primary" size="small">Resend Email</Button>
                        </Box>
                    </Box>
                </div>
            </>
        }
    </div>
    )
}

export default withOidcSecure(VerifyEmail)
