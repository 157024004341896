import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx'
import {
    Link,
    Drawer,
    IconButton,
    ListItem,
    ListItemIcon,
    List,
    Tooltip,
    Icon,
    SvgIcon,
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import {
    KeyboardArrowRightRounded,
    KeyboardArrowLeftRounded,
} from '@material-ui/icons';
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context';
import { useContext } from 'react';
import usePermissions from '../Hooks/usePermissions';
import loadable from '@loadable/component'
import LogoWithName from '.././assets/images/Logo/nt_logo_blue.svg'
import Logo from '.././assets/images/Logo/nt_logomark_blue.svg'
import { ReactComponent as BillingIcon } from '../assets/images/Icons/Billing.svg'
import { ReactComponent as ChatIcon } from '../assets/images/Icons/Chat.svg'
import { ReactComponent as DeepDiveIcon } from '../assets/images/Icons/DeepDive.svg'
import { ReactComponent as ChannelIcon } from '../assets/images/Icons/ic-channel.svg'
import { ReactComponent as AdminIcon } from '../assets/images/Icons/contacts.svg'
import { ReactComponent as SettingIcon } from '../assets/images/Icons/Setting.svg'
import { ReactComponent as TeamCommsIcon } from "../assets/images/Icons/TeamComms.svg"
import { ReactComponent as LocationIcon } from "../assets/images/Icons/Location.svg"
import { ReactComponent as IntegrationIcon } from "../assets/images/Icons/integrate.svg"
import { ReactComponent as Onboarding } from "../assets/images/Icons/Onboarding.svg"
import { ReactComponent as DownloadApk } from "../assets/images/Icons/download-apk.svg"
import GlobalStateContext from '../Context/GlobalStateContext';
import { TOGGLE_APP_DRAWER } from '../Context/GlobalStateMutations';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import UserNav from './UserNav';
import VerifyEmail from './VerifyEmail';
import Help from './Help';
import WebPTT from "./WebPTT/WebPTT"
import { isEmptyObject } from 'jquery';

const AppDrawerSupport = loadable(() => import('./AppDrawerSupport'), {
    fallback: "",
});

const getHeight = (isDrawerVisible, canWarningRender, isTrailLicense) => {
    return (isDrawerVisible ?
        canWarningRender ? 'calc(100% - 240px)' : 'calc(100% - 192px)' :
        canWarningRender ?
            isTrailLicense ? 'calc(100% - 280px)' : 'calc(100% - 240px)' :
            isTrailLicense ? 'calc(100% - 232px)' : 'calc(100% - 192px)')
}

const useStyles = makeStyles(theme => ({
    drawer: {
        width: ({ isDrawerVisible }) => isDrawerVisible ? 196 : 68,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: ({ isDrawerVisible }) => isDrawerVisible ? 196 : 68,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },

    paperDrawer: {
        overflow: 'visible !important',
        width: ({ isDrawerVisible }) => isDrawerVisible ? 196 : 68,
        borderRight: 0,
        'box-shadow': '0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px rgb(0 0 0 / 12%)'
    },

    appDrawerButton: {
        zIndex: 3,
        border: '3px solid #fff',
        margin: '10px 10px 0',
        position: 'absolute',
        right: -26,
        background: '#fff',
        '&:hover': {
            background: '#d1ceff'
        },
        boxShadow: '0 2px 5px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12)'
    },

    buttonRoot: {
        padding: 2
    },

    listItemIcon: {
        color: '#000000',
        minWidth: 0,
    },

    listGutter: {
        padding: "11px 13px 11px 10px",
    },

    listRoot: {
        // flexDirection: 'row-reverse',
        textDecoration: 'none',
        textTransform: 'none',
        borderRadius: 10,
        marginTop: 10,
        height: 42,
        '&:hover': {
            background: '#EFEFEF',
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& .MuiSvgIcon-root': {
            fontSize: 36
        }
    },

    isActive: {
        background: '#EFEFEF',
        borderRadius: 10,
        "& div, path": {
            color: theme.palette.primary.main,
            fill: `${theme.palette.primary.main} !important`
        },
    },

    billingIcon: {
        height: 36
    },

    integrationIcon: {
        height: 32
    },

    linkLabel: {
        fontSize: 14,
        paddingTop: 4,
        paddingLeft: 18,
        fontWeight: 600,
        color: '#161616'
    },

    list: {
        overflow: 'auto',
        // direction: "rtl",
        margin: "0 0 0 13px",
        paddingRight: "13px",
        height: ({ isDrawerVisible, canWarningRender, isTrailLicense }) => getHeight(isDrawerVisible, canWarningRender, isTrailLicense)
    },

    activeIcon: {
        color: "#1c5bff"
    },

    icon: {
        position: "absolute",
        top: 45,
        right: -12,
        background: "#161616",
        color: "#ffffff",
        zIndex: 1000,
        '&:hover': {
            background: '#161616',
        },
    },

    logo: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#fff',
        textDecoration: 'none',
        padding: 14,
        marginLeft: 2
    },

    help: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        // margin: "0px 22px",
        borderTop: "2px solid #EFEFEF",
        paddingTop: "10px",
        position: "absolute",
        bottom: 67,
        width: "100%"
    },

    bg: {
        background: "#53565B",
        borderRadius: 10,
        width: 42,
        height: 42,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "110%",
        color: "#FFFFFF"
    },

    customColorSvg: {
        '& path': {
            fill: ({ isDrawerVisible }) => isDrawerVisible ? '#6D6D6D' : '#010101',
        },
    },

    listItemPTTIcon: {
        color: '#000000',
        minWidth: 0,
        paddingTop: 17
    },

    onBoarding: {
        marginLeft: 2,
        marginTop: 2,
        fontSize: "25px"
    },

    newMessage: {
        top: "14px"
    },

    online: {
        fontSize: 28,
        marginBottom: 24,
        position: "absolute",
        left: 33
    },

    redIcon: {
        color: "red"
    },

    greenIcon: {
        color: "green"
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#161616',
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 600,
        marginLeft: 20,
        borderRadius: 6
    },
}))(Tooltip);

const AppDrawer = (props) => {
    const { oidcUser } = useReactOidc();
    const { globalUnreadMessageCount, pttConnection } = props
    const [state, dispatch] = useContext(GlobalStateContext)
    const permissions = usePermissions()
    const [showBilling, setShowBilling] = useState(false)
    //used for condtional style change in navigation list
    const [canWarningRender, setCanWarningRender] = useState(false)
    const classes = useStyles({
        isDrawerVisible: state.isDrawerVisible,
        canWarningRender: canWarningRender,
        isTrailLicense: state.licenseDetails?.is_trial_license
    })
    const notificationRef = useRef(null)

    useEffect(() => {
        if (globalUnreadMessageCount > 0 && notificationRef.current) {
            notificationRef.current.classList.remove('hidden');
        } else {
            notificationRef.current.classList.add('hidden');

        }
    }, [globalUnreadMessageCount])

    useEffect(() => {
        const setting = state.orgSettings
        if (!isEmptyObject(setting)) {
            const resellerSettings = setting.reseller_settings
            if (resellerSettings && resellerSettings.is_lead) {
                setShowBilling(resellerSettings.show_billing_to_customer)
            } else {
                setShowBilling(true)
            }
        }
    }, [state])

    const CustomLink = (props) => {
        return (
            <NavLink
                {...props}
                activeClassName={classes.isActive}
            >{props.children}</NavLink>
        )
    }

    const AdapterLink = React.forwardRef((props, ref) => <CustomLink innerRef={ref} {...props} />);

    const RouteItem = ({ title, id, icon, to }) =>
        <HtmlTooltip title={state.isDrawerVisible ? "" : title} placement="right">
            <ListItem id={id} component={AdapterLink} to={to} classes={{ gutters: classes.listGutter, root: classes.listRoot }}>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>{icon}</ListItemIcon>
                {to == "/messenger" && <Tooltip title={pttConnection.connected ? "PTT Connected" : "PTT Disconnected"} placement="top">
                    <span className={clsx(classes.online, pttConnection.connected ? classes.greenIcon : classes.redIcon)}>&#9679;</span>
                </Tooltip>}
                {state.isDrawerVisible && <div className={classes.linkLabel}>{title}</div>}
            </ListItem>
        </HtmlTooltip>

    const toggleDrawer = (params) => {
        dispatch({ type: TOGGLE_APP_DRAWER })
    }

    const CustomeIcon = ({ icon, subClass }) => {
        return <SvgIcon className={clsx(classes.customColorSvg, subClass)} component={icon} style={{ fontSize: 24 }} />
    }

    return (
        <>
            <Drawer
                variant="persistent"
                anchor="left"
                className={classes.drawer}
                open={true}
                classes={{
                    paper: clsx(classes.paperDrawer)
                }}>
                <div className={clsx(classes.alignCenter, classes.logo)}><img src={state.isDrawerVisible ? LogoWithName : Logo} alt="logo" height="36px" /></div>
                <VerifyEmail canWarningRender={canWarningRender} setCanWarningRender={setCanWarningRender} />
                <IconButton onClick={toggleDrawer} className={classes.icon} size="small">
                    {state.isDrawerVisible ? <KeyboardArrowLeftRounded fontSize="small" /> : <KeyboardArrowRightRounded fontSize="small" />}
                </IconButton>
                <List disablePadding className={clsx(classes.list, "scroll-drawer")}>
                    {state.licenseDetails?.is_trial_license && permissions.showUsers && <RouteItem title="Start Guide" id="start-guide" icon={<CustomeIcon icon={Onboarding} subClass={classes.onBoarding} />} to="/start-guide" />}
                    <div
                        id="eva-global-unread-count"
                        ref={notificationRef}
                        className={clsx("hidden", { ["new-msg"]: !permissions.showChat || !state.licenseDetails?.is_trial_license })}
                        style={{ zIndex: 1 }}
                    >1+</div>
                    <RouteItem title="PTT & Chat" id="chat-link" icon={<CustomeIcon icon={ChatIcon} subClass={classes.greenColor} />} to="/messenger" />
                    {permissions.showDirectory &&
                        <RouteItem title="Users & Teams" id="directory-link" icon={<CustomeIcon icon={TeamCommsIcon} />} to="/directory" />
                    }
                    {permissions.showChannels &&
                        <RouteItem title="Channels" id="team-comms-link" icon={<CustomeIcon icon={ChannelIcon} />} to="/channels" />
                    }
                    {permissions.showDeepDive &&
                        <RouteItem title="DeepDive" id="deepdive-link" icon={<CustomeIcon icon={DeepDiveIcon} />} to="/deepdive" />
                    }
                    {permissions.showLocations &&
                        <RouteItem title="Location" id="location-link" icon={<CustomeIcon icon={LocationIcon} />} to="/location" />
                    }
                    {permissions.showIntegration &&
                        <RouteItem title="Integrations" id="integrations=link" icon={<CustomeIcon icon={IntegrationIcon} />} to="/integrations" />
                    }
                    {permissions.showSettings &&
                        <RouteItem title="Settings" id="setting-link" icon={<CustomeIcon icon={SettingIcon} />} to="/settings" />
                    }
                    {permissions.showRoles &&
                        <RouteItem title="Admins & Roles" id="roles-link" icon={<CustomeIcon icon={AdminIcon} />} to="/roles" />
                    }
                    {permissions.showLicenses && showBilling &&
                        <RouteItem title="Billing" id="license-management-link" icon={<CustomeIcon icon={BillingIcon} />} to="/license-management" />
                    }
                    {permissions.showChat && state?.networkSettings?.org_code &&
                        <RouteItem title="Download App" id="apk-link" icon={<CustomeIcon icon={DownloadApk} />} to="/apk" />
                    }
                </List>
                {permissions.showUsers && !permissions.hasOwnProperty("UsersReadonly") && <Help />}
                <UserNav />
                <WebPTT />
            </Drawer>
            <AppDrawerSupport />
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        globalUnreadMessageCount: state.reducerChatUI.globalUnreadMessageCount,
        pttConnection: state.reducerChatUI.pttConnection,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withOidcSecure(AppDrawer));
