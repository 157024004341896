export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const START_IMAGE_ASSET_DOWNLOADING = 'START_IMAGE_ASSET_DOWNLOADING'
export const STOP_IMAGE_ASSET_DOWNLOADING = 'STOP_IMAGE_ASSET_DOWNLOADING'
export const SET_SCHEDULED_MESSAGE_LIST_OPEN = 'SET_SCHEDULED_MESSAGE_LIST_OPEN'
export const CLOSE_SCHEDULED_MESSAGE_LIST = 'CLOSE_SCHEDULED_MESSAGE_LIST'
export const SHOW_MESSAGE_PREVIEW = 'SHOW_MESSAGE_PREVIEW'
export const HIDE_MESSAGE_PREVIEW = 'HIDE_MESSAGE_PREVIEW'
export const SHOW_MESSAGE_INFO = 'SHOW_MESSAGE_INFO'
export const HIDE_MESSAGE_INFO = 'HIDE_MESSAGE_INFO'
export const SHOW_GROUP_INFO_DRAWER = 'SHOW_GROUP_INFO_DRAWER'
export const HIDE_GROUP_INFO_DRAWER = 'HIDE_GROUP_INFO_DRAWER'
export const SET_SIDEBAR_TAB_INDEX = 'SET_SIDEBAR_TAB_INDEX'
export const SET_IS_MESSAGE_QUOTED = 'SET_IS_MESSAGE_QUOTED';
export const CANCEL_QUOTE_MESSAGE = 'CANCEL_QUOTE_MESSAGE';
export const HIDE_MESSAGE_REPLIES = 'HIDE_MESSAGE_REPLIES';
export const SHOW_MESSAGE_REPLIES = 'SHOW_MESSAGE_REPLIES';
export const SET_CHAT_MODE = 'SET_CHAT_MODE'
export const SET_PTT_CONNECTION = 'SET_PTT_CONNECTION'
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const SET_GLOBAL_UNREAD_MESSAGE_COUNT = 'SET_GLOBAL_UNREAD_MESSAGE_COUNT';
export const ENABLE_BROADCAST_REPLY = 'ENABLE_BROADCAST_REPLY';
export const DISABLE_BROADCAST_REPLY = 'DISABLE_BROADCAST_REPLY';
export const DISMISS_CONFIRM_DELETE_MESSAGE = 'DISMISS_CONFIRM_DELETE_MESSAGE';
export const SET_CONFIRM_DELETE_MESSAGE = 'SET_CONFIRM_DELETE_MESSAGE';
export const SET_CALL_STATUS = 'SET_CALL_STATUS';
export const SHOW_CALL_SCREEN = 'SHOW_CALL_SCREEN';
export const HIDE_CALL_SCREEN = 'HIDE_CALL_SCREEN';
export const SET_ACTIVE_CHAT_LIST_PRESENT = 'SET_ACTIVE_CHAT_LIST_PRESENT';
export const STOP_PLAYBACK = 'STOP_PLAYBACK';
export const SET_PLAYING_ID = 'SET_PLAYING_ID';