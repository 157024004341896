/**
 * Proto Command Builder object, this contains all the information that is
 * needed for the proto buff.
 */

class Notifications {
    constructor() {
        // default settings
        this.permissionAccess = false;
        this.evaChatNotification = null;
        this.settings = {
            type: "info",
            allow_dismiss: true,
            label: "Cancel",
            className: "btn-xs btn-inverse",
            placement: {
                from: "top",
                align: "right",
            },
            delay: 4000,
            animate: {
                enter: "chat-notification animated fadeIn",
                exit: "chat-notification animated fadeOut",
            },
            offset: {
                x: 20,
                y: 85,
            },
            z_index: 1085,
        };
        this.twemoji = window.twemoji
        this.$ = window.$
        this.Notify = window.Notify

        this.evaNotificationPermissionGranted = () => {
            this.permissionAccess = true;
            console.log("Permission has been granted by the user");
        }
    
        this.evaNotificationPermissionDenied = () =>  {
            // var that = this
            this.permissionAccess = false;
            console.warn("Permission has been denied by the user");
        }

        this.chatMsgNotificationTemplate = '<div data-notify="container" class="col-xs-8 col-sm-3 alert alert-{0}" role="alert">' +
            '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">&times;</button>' +
            '<div class="clearfix">' +
            '  <div class="pull-left m-r-10">' +
            '    <span data-notify="icon"></span>' +
            '  </div>' +
            '  <div class="pull-left msg-details">' +
            '    <div data-notify="title" class="title">{1}</div>' +
            '    <div data-notify="message" class="message">{2}</div>' +
            '  </div>' +
            '</div>' +
            '<div class="progress" data-notify="progressbar">' +
            '  <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">' +
            '  </div>' +
            '</div>' +
            '<a onclick="{event.preventDefault();3}" target="{4}" data-notify="url"></a>' +
            '</div>';
    }

    notifyNewChatMessage(from, message, contact, defaultNotificationImage, goToMessenger) {
        // var formattedMessage = this.twemoji.parse(message);
        // var options = { template: this.chatMsgNotificationTemplate };
        // var notificationSettings = this.$.extend(true, {}, this.settings, options);
        // this.$.notify(
        //     {
        //         title: from,
        //         message: formattedMessage,
        //         icon: "zmdi zmdi-comment-alt-text zmdi-hc-2x",
        //         template: this.chatMsgNotificationTemplate,
        //         url: `javascript: ${goToMessenger()}`,
        //         target: "_self",
        //     },
        //     notificationSettings
        // );

        //play sound
        // this.$("#chatAudio")[0].play();

        if (this.permissionAccess) {
            this.evaBrowserNotification(from, message, contact, defaultNotificationImage, 10);
        }
    }

    closeAllNotifications() {
        this.$.notifyClose();
        if (this.evaChatNotification) {
            this.evaChatNotification.close();
        }
    }

    getBrowserNotificationPermission() {
        //browser notification permission checking
        if (!this.Notify.needsPermission) {
            this.permissionAccess = true;
        } else if (this.Notify.isSupported()) {
            this.Notify.requestPermission(this.evaNotificationPermissionGranted, this.evaNotificationPermissionDenied);
        }
    }

    notifyNewCallMessage(from, toUuid, defaultNotificationImage, callId) {
        var incomingCall = "Incoming Call";
        var name = from.displayName || from.name;
        this.$("#call-ringtone")[0].play().then(function() {
            console.log('Audio started unlocked!')
        }).catch(function(){
            console.log('Audio started locked')
        })

        if (this.permissionAccess) {
            this.evaBrowserNotification(name, incomingCall, from, defaultNotificationImage, 30);
        }
    }
    
    evaBrowserNotification(from, message, contact, defaultNotificationImage, timeout) {
        var avatarThumbUrl;
        if (contact && contact.avatarThumbUrl) {
            avatarThumbUrl = contact.avatarThumbUrl;
        } else {
            avatarThumbUrl = defaultNotificationImage;
        }
        this.evaChatNotification = new window.Notify(from, {
            body: message,
            icon: avatarThumbUrl,
            tag: "My unique id",
            notifyShow: this.evaShowNotification,
            notifyClose: this.evaCloseNotification,
            notifyClick: this.evaClickNotification,
            notifyError: this.evaErrorNotification,
            timeout: timeout,
            closeOnClick: true,
        });
        this.evaChatNotification.show();
    }

    evaShowNotification() {
        console.log("notification is shown!");
    }

    evaCloseNotification() {
        console.log("notification is closed!");
    }

    evaClickNotification() {
        window.focus();
        console.log("notification was clicked!");
    }

    evaErrorNotification() {
        console.error("Error showing notification. You may need to request permission.");
    }

}

export default Notifications;
