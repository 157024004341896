import React, { useContext, memo, } from "react";
import { DialogContentText } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { connect } from 'react-redux';
import {  dismissDeleteMessage } from '../containers/ChatUI/actions'
import Dialog from "../../Users/Dialog";
import ChatManagerStore from "../Store/ChatManagerStore";

const DeleteMessageDialog = (props) => {
    const { 
        selectedChatOwnerInfo,
        selectedmessage,
        dismissDeleteMessage,
        confirmDeleteMessage,
        one_o_one_chat,
        group_chat
     } = props;
    const { chatManagerRef } = useContext(ChatManagerStore);
    const deleteMessageDialog = () => ({
        open: confirmDeleteMessage,
        onClose: dismissDeleteMessage,
        onAccept: makeDeleteRequest,
        divider: false,
        onAcceptText: "Delete",
        isDestructive: true,
        header: (
            <Box p="26px" fontWeight="600">
                Are you sure?
            </Box>
        ),
    });

    const DeleteMessageDialogBody = () => (
        <DialogContentText>
            You are about to delete this message. Do you want to proceed?
        </DialogContentText>
    );

    const makeDeleteRequest = () => {
        let activeChatUuid = selectedChatOwnerInfo.toUUID;
        var chatObj
        if (chatManagerRef.current.isGroup(activeChatUuid)) {
            chatObj = group_chat[activeChatUuid];
        } else {
            chatObj = one_o_one_chat[activeChatUuid];
        }
        var msgFileId;
        if (selectedmessage.msg_id) {
            chatObj.deleteMsg(selectedmessage.msg_id, msgFileId);
        } else {
        }
        // FIXME: dismiss after successful delete from server
        dismissDeleteMessage()
    }

    return (
        <>
            {confirmDeleteMessage && <Dialog
                {...deleteMessageDialog()}>
                <DeleteMessageDialogBody />
            </Dialog>}
        </>
    );
}

const mapStateToProps = function(state) {
    return {
        one_o_one_chat: state.reducerChat.one_o_one_chat,
        selectedChatOwnerInfo: state.reducerChat.selectedChatOwnerInfo,
        selectedmessage: state.reducerChat.selectedmessage,
        group_chat: state.reducerChat.group_chat,
        one_o_one_chat: state.reducerChat.one_o_one_chat,
        confirmDeleteMessage: state.reducerChatUI.confirmDeleteMessage
    }
}

const mapDispatchToProps = {
    dismissDeleteMessage
}

function areEqual(prevProps, nextProps) {
   return (prevProps === nextProps)
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DeleteMessageDialog, areEqual));
