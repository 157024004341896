import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ThreeDotsWhite from '../../assets/images/three-dots.svg';
/**
 * Renders loading svg or button text
 */
const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        position: 'relative',
    },

    button: {
        padding: 6,
        textTransform:"none"
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    noCursorAllowed: {
        cursor: 'not-allowed',
    },

    loadingImg: {
        height: 15,
        padding: 3,
    },
}));

const ProgressButton = props => {
    const classes = useStyles();
    const { isWaiting, disabled, onClick, styles, variant, color, size } = props;

    return (
        <div className={classes.buttonWrapper}>
            <Button
                variant={variant}
                color={color}
                disabled={disabled}
                size={size}
                className={clsx(styles, classes.button, {
                    [classes.noCursorAllowed]: isWaiting,
                })}
                onClick={onClick}>
                {isWaiting ? (
                    <img src={ThreeDotsWhite} alt="loading" className={classes.loadingImg} />
                ) : (
                    props.children
                )}
            </Button>
        </div>
    );
};

ProgressButton.defaultProps = {
    variant: 'text',
    color: 'primary',
    size: 'small'
};

ProgressButton.propTypes = {
    variant: PropTypes.any,
    color: PropTypes.any,
    size: PropTypes.any
};

export default ProgressButton;
