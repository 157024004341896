import React, { createContext, useReducer, useEffect } from 'react';
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context';
/* Defines a context and a reducer for updating the context ie Store for our app*/

const AuthZContext = createContext();

const SET_PERMISSIONS = 'SET_PERMISSIONS'

const initialState = {
    permissions: {} // Roles/Permissions to be decoded from JWT
};

const authZReducer = (state, action) => {
    switch (action.type) {
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: { ...action.payload },
            };
        default:
            return state;
    }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const AuthZProvider = ({ children }) => {
    const { oidcUser } = useReactOidc();
    
    const [state, dispatch] = useReducer(
        authZReducer,
        initialState
    );

    useEffect(() => {
        // Decode JWT
        decodeJWT(oidcUser.access_token)
    }, [])

    function decodeJWT(jwt) {
        let jwtData = jwt.split('.')[1]
        let decodedJwtJsonData = window.atob(jwtData)
        let decodedJwtData = JSON.parse(decodedJwtJsonData)
        // let isAdmin = decodedJwtData.admin
        // console.log('jwtData: ' + jwtData)
        // console.log('decodedJwtJsonData: ', decodedJwtJsonData)
        // console.log('Is admin: ' + isAdmin)
        setPermissions(decodedJwtData.resource_access)
    }

    // TODO: check if finding can be replaced with destructuring in object
    const { } = state.permissions

    function setPermissions(permissions) {
        dispatch({
            type: 'SET_PERMISSIONS',
            payload: {
                permissions
            }
        });
    }

    return (
        <AuthZContext.Provider value={[state, dispatch]}>
            {children}
        </AuthZContext.Provider>
    );
};

export default AuthZContext