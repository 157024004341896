import React, { createContext, useReducer, useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect } from 'react';
import { find, map, remove, cloneDeep } from 'lodash'
import GlobalStateMutations from './GlobalStateMutations';
import FeedsStateMutations from './FeedsStateMutations';

/* Define a context and a reducer for updating the context */
const FeedsStateContext = createContext();

const initialState = {
    postsResponse: {},
    currentPostTitle: '',
    currentPostBody: '',
    currentSelectedPost: {}, // Selected post to perform operations
    postsList: {},
    selectedAttachment: {}, // Currently selected/processing attachment
    attachmentsPendingForUpload: [], // Attachments which are pending to be uploaded
    attachmentsToDelete: [], // Attachments pending to be deleted
    pendingAttachmentFiles: [],
    feeds: [], // Holds feeds response
    isUploading: false, // Boolean weather attachment uploading is in progress or not
    videoAttachment: {}
};

const feedsStateReducer = (state, action) => {
    switch (action.type) {
        case FeedsStateMutations.SET_FEEDS_RESPONSE:
            return {
                ...state,
                feeds: action.payload ,
            };
        case FeedsStateMutations.SET_POST_RESPONSE:
            return {
                ...state,
                postsResponse: action.payload ,
            };
        case FeedsStateMutations.UPDATE_POST_RESPONSE:
        return {
            ...state,
            postsResponse: {
                ...state.postsResponse,
                posts: [
                    ...state.postsResponse.posts, ...action.payload
                ]
            },
        };
        case FeedsStateMutations.SET_POST_LIST:
                return {
                    ...state,
                    postsList: getKeyValuePair(action.payload)
                }
        case FeedsStateMutations.UPDATE_POST_LIST:
                return {
                    ...state,
                    postsList: {...state.postsList, ...getKeyValuePairForPosts(action.payload)}
                }
        
        case FeedsStateMutations.UPDATE_POST:
            let uuid = action.payload.uuid
            let file = action.payload.file
            let post = find(state.postsResponse.posts, (o) => o.uuid === uuid)
            let updatedPosts = state.postsResponse.posts.map((i) => {
                if (i.uuid === uuid) {
                    i.attachments = [
                        ...i.attachments, ...file
                    ]
                }
                return i
            })
            let updatedPost = { 
                ...post,
                attachments: [...post.attachments, ...[ ...post.attachments, ...file]]
            }
            return {
                ...state,
                postsResponse: updatedPosts
            }
        case FeedsStateMutations.EDIT_POST_TITLE:
            return {
                ...state,
                currentPostTitle: action.payload
            }
        case FeedsStateMutations.EDIT_POST_BODY:
            return {
                ...state,
                currentPostBody: action.payload
            }
        case FeedsStateMutations.SET_SELECTED_POST:
            return {
                ...state,
                currentSelectedPost: action.payload
            }
        case FeedsStateMutations.SET_SELECTED_ATTACHMENT:
            return {
                ...state,
                selectedAttachment: action.payload
            }
        case FeedsStateMutations.SET_ATTACHMENTS_PENDING_FOR_UPLOAD:
            return {
                ...state,
                attachmentsPendingForUpload: [...state.attachmentsPendingForUpload, ...action.payload]
            }
            case FeedsStateMutations.CLEAR_PENDING_ATTACHMENTS:
                return {
                    ...state,
                    attachmentsPendingForUpload: []
                }
            case FeedsStateMutations.SET_ATTACHMENTS_TO_DELETE:
                return {
                    ...state,
                    attachmentsToDelete: [...state.attachmentsToDelete, ...[action.payload]]
                }
            case FeedsStateMutations.CLEAR_ATTACHMENTS_TO_DELETE:
                return {
                    ...state,
                    attachmentsToDelete: []
                }
            case FeedsStateMutations.SET_PENDING_ATTACHMENT_FILES:
                return {
                    ...state,
                    pendingAttachmentFiles: [...state.pendingAttachmentFiles, ...action.payload]
                }
            case FeedsStateMutations.REMOVE_FROM_PENDING_ATTACHMENT_FILES:
                let updatedAttachments = cloneDeep(state.pendingAttachmentFiles)
                remove(updatedAttachments, (i) => i.uuid === action.payload)
                return {
                    ...state,
                    pendingAttachmentFiles: updatedAttachments
                }
            case FeedsStateMutations.CLEAR_PENDING_ATTACHMENT_FILES:
                return {
                    ...state,
                    pendingAttachmentFiles: []
                }
            case FeedsStateMutations.SET_UPLOAD_PROGRESS:
                return {
                    ...state,
                    isUploading: action.payload
                }
            case FeedsStateMutations.SET_VIDEO_ATTACHMENT:
                return {
                    ...state,
                    videoAttachment: {...state.videoAttachment, ...getKeyValuePairForVideoAttachment(action.payload)}
                }

        default:
            return state;
    }
};

// Action helpers
const getKeyValuePair = (payload) => {
    let keyValueArray = {}
    payload.posts.forEach((i) => {
        keyValueArray[i.uuid] = i;
    }) 
    return keyValueArray
}

const getKeyValuePairForVideoAttachment = (payload) => {
        return {[payload.uuid]: payload.videoBlob}
}

const getKeyValuePairForPosts = (payload) => {
    let keyValueArray = {}
    payload.forEach((i) => {
        keyValueArray[i.uuid] = i;
    }) 
    return keyValueArray
}


/* Export a component to provide the context to its children. This is used in our _app.js file */

export const FeedsStateProvider = ({ children }) => {
    const [feedsState, feedsDispatch] = useReducer(
        feedsStateReducer,
        initialState
    );

    return (
        <FeedsStateContext.Provider value={[feedsState, feedsDispatch]}>
            {children}
        </FeedsStateContext.Provider>
    );
};

export default FeedsStateContext