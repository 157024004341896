import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Tooltip, Tab, Tabs, Chip } from '@material-ui/core'
import UserLogsContext from '../../Context/UserLogs'
import Dialog from "./Dialog";
import { PersonAddSharp } from "@material-ui/icons";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({

    header: {
        padding: 26,
        display: 'flex',
        alignItems: 'center',
    },

    dFlex: {
        display: "flex",
        alignItems: "center",
    },

    headerTitle: {
        marginLeft: 13,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
    },

    ellipsis: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    emailCell: {
        flex: 3,
        color: '#5E5E5E'
    },

    tableCell: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '15px',
        padding: '15px 24px'
    },

    listItem: {
        display: 'flex',
        boxShadow: '0px 0.73665px 1.21645px rgba(0, 0, 0, 0.0363503), 0px 1.75597px 3.33987px rgba(0, 0, 0, 0.0536497), 0px 6px 27px rgba(0, 0, 0, 0.09)',
        marginTop: 5,
        marginBottom: 10,
        alignItems: 'center'
    },

    w100: {
        width: '100%'
    },

    sucessCell: {
        color: '#00AD9D'
    },

    errorCell: {
        color: '#DC2E10'
    },

    margin20: {
        marginTop: 20,
        marginRight: 20
    },

    tableHead: {
        marginBottom: 10
    },

    tab: {
        position: "sticky",
        top: 0,
        backgroundColor: "#fff"
    }

}));


const UserLogsDialog = (props) => {
    const { close } = props;
    const classes = useStyles();
    const [state, dispatch] = useContext(UserLogsContext)
    const [tabIndex, setTabIndex] = useState(0)
    const [successStatus, setSuccessStatus] = useState([])
    const [existStatus, setExistStatus] = useState([])
    const [existItems, setExistItems] = useState(9)
    const [successItems, setSuccessItems] = useState(9)
    const heads = ['Email', 'Status']
    const {
        successfullyCreatedUsers,
        existingUsers,
        failedUsers,
        isDialogVisible
    } = state

    const DialogData = () => ({
        open: isDialogVisible,
        header: getHeader(),
        dialogContentText: "",
        onAccept: close,
        divider: false,
        onAcceptText: 'Done',
    });

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const { ref: succesRef, inView: successInView } = useInView({
        threshold: 0,
    });

    const { ref: existRef, inView: existInView } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        setExistStatus(existingUsers.slice(0, existItems))
        setSuccessStatus(successfullyCreatedUsers.slice(0, successItems))
    }, [state])

    useEffect(() => {
        setExistStatus(existStatus.concat(existingUsers.slice(existItems, existItems + 10)))
        setExistItems(existItems + 10)
    }, [existInView])

    useEffect(() => {
        setExistStatus(successStatus.concat(successStatus.slice(successItems, successItems + 10)))
        setSuccessItems(successItems + 10)
    }, [successInView])

    function getHeader() {
        return (
            <>
                <Box className={classes.header}>
                    <Box className={classes.dFlex}>
                        <PersonAddSharp />
                        <Box className={classes.headerTitle}>User Logs</Box>
                    </Box>
                </Box>
            </>
        );
    }

    const SuccessfullUserContainer = () => (<>
        <LogTableHeader />
        {successStatus.map((email, index) => (
            <LogItem isError={false}
                key={index}
                isHeading={false} email={email} status="Successfully Added" />
        ))}
        {(successStatus.length < successfullyCreatedUsers.length) && <div ref={succesRef} style={{ height: "20px" }}></div>}
    </>
    )

    const ExistingUserContainer = () => (<>
        <LogTableHeader />
        {existStatus.map((email, index) => (
            <LogItem isError={true}
                key={index}
                isHeading={false} email={email} status="User Already Exists" />
        ))}
        {(existStatus.length < existingUsers.length) && <div ref={existRef} style={{ height: "20px" }}></div>}
    </>
    )

    const FailedUserContainer = () => (<>
        <LogTableHeader />
        {Object.keys(failedUsers).map((email, index) => (
            <LogItem isError={true}
                key={index}
                isHeading={false} email={email} status={failedUsers[email]} />
        ))}
    </>
    )

    const LogTableHeader = () => (
        <LogItem isError={false}
            isHeading={true} email="Email" status="Status" />
    )

    const LogItem = (props) => {
        const { isHeading, isError, email, status } = props
        return (
            <Box className={clsx(classes.listItem, { [classes.w100]: !isHeading }, { [classes.tableHead]: isHeading })}>
                <Grid item md={6} sm={6} xs={6}>
                    <Box className={clsx(classes.tableCell, classes.emailCell)}>
                        <Tooltip title={email} aria-label={email}>
                            <Box className={clsx({ [classes.ellipsis]: !isHeading })}>{email}</Box>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <Box className={clsx(classes.tableCell, { [classes.errorCell]: isError }, { [classes.sucessCell]: !isError && !isHeading })}>
                        <Tooltip title={status} aria-label={status}>
                            <Box className={clsx({ [classes.ellipsis]: !isHeading })}>{status}</Box>
                        </Tooltip>
                    </Box>
                </Grid>
            </Box>
        )
    }

    function getDialogBody() {
        return (
            <Box >
                <Tabs
                    className={classes.tab}
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon tabs example">
                    <Tab label={<>SUCCESSFULL <span>{successfullyCreatedUsers.length}</span></>} />
                    <Tab label={<>FAILED <span>{Object.keys(failedUsers).length}</span> </>} />
                    <Tab label={<>EXISTING <span>{existingUsers.length}</span> </>} />
                </Tabs>
                {tabIndex === 0 && <SuccessfullUserContainer />}
                {tabIndex === 1 && <FailedUserContainer />}
                {tabIndex === 2 && <ExistingUserContainer />}
            </Box>

        )
    }

    return (
        <Dialog {...DialogData()}>{getDialogBody()}</Dialog>
    )
}

export default UserLogsDialog