import * as types from './types';

const startLoader = () => {
    return (dispatch) => {
        dispatch({ type: types.START_LOADER });
    };
};

const stopLoader = () => {
    return (dispatch) => {
        dispatch({ type: types.STOP_LOADER });
    };
};

const startImageAssetDownloading = () => {
    return (dispatch) => {
        dispatch({ type: types.START_IMAGE_ASSET_DOWNLOADING });
    };
};

const stopImageAssetDownloading = () => {
    return (dispatch) => {
        dispatch({ type: types.STOP_IMAGE_ASSET_DOWNLOADING });
    };
};

const openScheduledMessageList = () => {
    return (dispatch) => {
        dispatch({ type: types.SET_SCHEDULED_MESSAGE_LIST_OPEN });
    };
};

const closeScheduledMessageList = () => {
    return (dispatch) => {
        dispatch({ type: types.CLOSE_SCHEDULED_MESSAGE_LIST });
    };
};

const showMessagePreview = () => {
    return (dispatch) => {
        dispatch({ type: types.SHOW_MESSAGE_PREVIEW });
    };
};
const hideMessagePreview = () => {
    return (dispatch) => {
        dispatch({ type: types.HIDE_MESSAGE_PREVIEW });
    };
};

const showMessageInfo = () => {
    return (dispatch) => {
        dispatch({ type: types.SHOW_MESSAGE_INFO });
    };
};

const showMessageReplies = () => {
    return (dispatch) => {
        dispatch({ type: types.SHOW_MESSAGE_REPLIES });
    };
};

const hideMessageReplies = () => {
    return (dispatch) => {
        dispatch({ type: types.HIDE_MESSAGE_REPLIES });
    };
};

const closeDrawer = () => {
    return (dispatch) => {
        dispatch({ type: types.CLOSE_DRAWER });
    };
};

const hideMessageInfo = () => {
    return (dispatch) => {
        dispatch({ type: types.HIDE_MESSAGE_INFO });
    };
};

const showGroupInfoDrawer = () => {
    return (dispatch) => {
        dispatch({ type: types.SHOW_GROUP_INFO_DRAWER });
    };
};
const hideGroupInfoDrawer = () => {
    return (dispatch) => {
        dispatch({ type: types.HIDE_GROUP_INFO_DRAWER });
    };
};

const setSidebarTabIndex = (index) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SIDEBAR_TAB_INDEX, index: index });
    };
};

const setMessageIsQuoted = () => {
    return (dispatch) => {
        dispatch({ type: types.SET_IS_MESSAGE_QUOTED});
    };
}

const cancelMessageQuote = () => {
    return (dispatch) => {
        dispatch({ type: types.CANCEL_QUOTE_MESSAGE});
    };
}

const setGlobalUnreadMessageCount = count => {
    return (dispatch) => {
        dispatch({ type: types.SET_GLOBAL_UNREAD_MESSAGE_COUNT, payload: { count: count }});
    };
}

const enableBroadcastReply = count => {
    return (dispatch) => {
        dispatch({ type: types.ENABLE_BROADCAST_REPLY});
    };
}

const disableBroadcastReply = count => {
    return (dispatch) => {
        dispatch({ type: types.DISABLE_BROADCAST_REPLY });
    };
}

const setConfirmDeleteMessage = count => {
    return (dispatch) => {
        dispatch({ type: types.SET_CONFIRM_DELETE_MESSAGE });
    };
}

const dismissDeleteMessage = count => {
    return (dispatch) => {
        dispatch({ type: types.DISMISS_CONFIRM_DELETE_MESSAGE });
    };
}

const setCallStatus = status => {
    return (dispatch) => {
        dispatch({ type: types.SET_CALL_STATUS, payload: status });
    };
}

const setPttConnection = status => {
    return (dispatch) => {
        dispatch({ type: types.SET_PTT_CONNECTION, payload: status });
    };
}

const showCallScreen = () => {
    return (dispatch) => {
        dispatch({ type: types.SHOW_CALL_SCREEN });
    };
}

const hideCallScreen = () => {
    return (dispatch) => {
        dispatch({ type: types.HIDE_CALL_SCREEN });
    };
}

const setActiveChatListPresent = (status) => {
    return (dispatch) => {
        dispatch({ type: types.SET_ACTIVE_CHAT_LIST_PRESENT, payload: status });
    };
}

const setPlaybackId = (id) => {
    return (dispatch) => {
        dispatch({ type: types.SET_PLAYING_ID, payload: id});
    };
}

const stopPlayback = (id) => {
    return (dispatch) => {
        dispatch({ type: types.SET_PLAYING_ID, payload: 0});
    };
}

export {
    startLoader,
    stopLoader,
    startImageAssetDownloading,
    stopImageAssetDownloading,
    openScheduledMessageList,
    closeScheduledMessageList,
    showMessagePreview,
    hideMessagePreview,
    showMessageInfo,
    hideMessageInfo,
    showGroupInfoDrawer,
    hideGroupInfoDrawer,
    setSidebarTabIndex,
    setMessageIsQuoted,
    cancelMessageQuote,
    showMessageReplies,
    hideMessageReplies,
    closeDrawer,
    setGlobalUnreadMessageCount,
    enableBroadcastReply,
    disableBroadcastReply,
    setConfirmDeleteMessage,
    dismissDeleteMessage,
    setCallStatus,
    showCallScreen,
    hideCallScreen,
    setActiveChatListPresent,
    stopPlayback,
    setPlaybackId,
    setPttConnection,
    // getProducts,
    // incrementProductQuantity,
    // decrementProductQuantity,
    // getProductDetails,
    // decrementProductQuantityFromDetail,
    // incrementProductQuantityFromDetail
}