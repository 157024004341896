import React, { useContext, useEffect, useState } from "react";
// import { Router } from '@reach/router'
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { withOidcSecure } from '@axa-fr/react-oidc-context';
import GlobalStateContext from "../Context/GlobalStateContext";
import { useLocation, withRouter } from "react-router";
import SetHistory from '../core/SetHistory'
import { setGlobalUnreadMessageCount } from '../components/Messanger/containers/ChatUI/actions'
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    content: {
        position: "relative",
        flexGrow: 1,
        padding: "21px 24px 0px",
        "-webkit-box-ordinal-group": 3,
        "-ms-flex-order": 2,
        order: 2,
        '-webkit-box-flex': 1,
        '-ms-flex': 1,
        // height: '100%',
        '& div[tabindex="-1"]': {
            height: "100%",
        },
        // marginLeft: drawerWidth,
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: ({ drawerWidth }) => drawerWidth,
    },

    appBarShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: ({ drawerWidth }) => drawerWidth,
    },

    rootRouter: {
        flexGrow: 1,
    },
}));


const MainContent = (props) => {
    const { setGlobalUnreadMessageCount, globalUnreadMessageCount } = props
    const [state, dispatch] = useContext(GlobalStateContext)
    const [activeLink, setActiveLink] = React.useState('/')
    const classes = useStyles({ drawerWidth: state.isDrawerVisible ? 196 : 68 })
    useEffect(() => {
        // When messenger is launched, hide global unread notification
        if (activeLink === '/messenger' && globalUnreadMessageCount > 0) setGlobalUnreadMessageCount(0)
    }, [activeLink, globalUnreadMessageCount])
    return (
        <main
            role="main"
            className={clsx(classes.content, {
                [classes.appBarShift]: state.isDrawerVisible,
            })}>
            {props.children}
            <SetHistory setActiveLink={setActiveLink} />
        </main>
    );
};

const mapStateToProps = function (state) {
    return {
        globalUnreadMessageCount: state.reducerChatUI.globalUnreadMessageCount,
    }
}

const mapDispatchToProps = {
    setGlobalUnreadMessageCount
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
