const Messenger = 'messenger'
const Directory = 'directory'
const Channels = 'channels'
const Settings = 'settings'
const ChannelsView = 'channels-view'
const Location = 'location'
const Home = '/welcome'
const ChannelDetailView = 'channels-view'
const LicenseManagement = 'license-management'
const Purchase = 'purchase'
const FeedsView = 'feeds-view'
const MyProfile = 'my-profile'
const DeepDive = 'deepdive'
const Integrations = 'integrations'
const ChangeLog = 'changelog'
const StartGuide="start-guide"
const Roles="roles"
export default {
    Messenger,
    Settings,
    ChannelsView,
    Home,
    ChannelDetailView,
    LicenseManagement,
    FeedsView,
    MyProfile,
    ChangeLog,
    Directory,
    Channels,
    Location,
    DeepDive,
    Integrations,
    StartGuide,
    Roles
}