import ProtoCommandBuilder from "./ProtoCommandBuilder";

class ScheduledMessage {

    constructor(chatUiManagerObj, commandBuilderObj, sendMessage) {
        this.chatUiManagerObj = chatUiManagerObj
        this.commandBuilderObj = commandBuilderObj
        this.sendSocketMessage = sendMessage
        this.nextPage = 1
        this.scheduleMessageList = {}

        this.isBroadcastScheduleOpen = false;

        this.MobilockChatClient = window.MobilockChatClient
        this.scheduledFrequencies = { 1: "Once", 2: "Every Hour", 3: "Daily", 4: "On Every Weekday", 5: "Weekly", 6: "Monthly" }
    }


    sendCancelSchedledMessage(messageUuid) {
        var scheduleMessageCommand = this.commandBuilderObj.getCancelScheduleMessageCommand(messageUuid);
        this.sendSocketMessage(scheduleMessageCommand);
    }

    sendGetScheduledConversationMessages(toUuid, page) {
        var scheduleMessageCommand = this.commandBuilderObj.getConversationScheduledMessagesCommand(toUuid, page);
        this.sendSocketMessage(scheduleMessageCommand);
    }

    getNewScheduleRequestCommand(toUuid, uuid, message, iv, scheduleObj) {
        var startAt = this.getLongValue(scheduleObj.startAt);
        var endAt = this.getLongValue(scheduleObj.endAt)
        return this.commandBuilderObj.getScheduleMessageCommand(toUuid, uuid, message, iv, startAt, endAt, scheduleObj.frequency, scheduleObj.replyAllowed);
    }

    getEditScheduleRequestCommand(uuid, message, iv, scheduleObj) {
        var startAt = this.getLongValue(scheduleObj.startAt);
        var endAt = this.getLongValue(scheduleObj.endAt);
        return this.commandBuilderObj.getEditScheduleMessageCommand(uuid, message.message, message.iv, startAt, endAt, scheduleObj.frequency);

    }

    checkMessageExistOnUI(scheduledMessage) {
        // if($(".scheduled-msgs-area .chat [data-sc-msg-uuid=" + scheduledMessage.uuid + "]").length > 0) {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    // handleConversationScheduledMessages(response) {
    //     var chatManagerObj = this.MobilockChatClient.chatManagerObj;
    //     var chatUiManagerObj = this.chatUiManagerObj;
    //     var chatContainer = $('.scheduled-msgs-area');
    //     if(chatManagerObj.isGroup(response.withUuid)) {
    //         var chatObj = chatManagerObj.getGroupChat(response.withUuid);
    //         var group = chatManagerObj.getGroup(response.withUuid);
    //         if(!group.subscribed) {
    //             return;
    //         }
    //         var subscriberList = chatManagerObj.subscriberList;
    //         var group = chatManagerObj.getGroup(response.withUuid)
    //     } else {
    //         var chatObj = chatManagerObj.getChat(response.withUuid);
    //     }

    //     var previousTopElement = chatContainer.children('.chat-msg-e').first();


    //     if(!response.scheduleMessages) {
    //         $('#chat_container .right .all-scheduled-messages').hide();
    //         $('#chat_container .right .empty-scheduled-msg').show();
    //     } else {
    //         $('#chat_container .right .all-scheduled-messages').show();
    //         $('#chat_container .right .empty-scheduled-msg').hide();
    //     }

    //     _.each(response.scheduleMessages, function (scheduledMessage) {
    //         if (this.MobilockChatClient.scheduleMessageUiManagerObj.checkMessageExistOnUI(scheduledMessage)) {
    //             return;
    //         }

    //         this.MobilockChatClient.scheduleMessageUiManagerObj.scheduleMessageList[scheduledMessage.uuid] = scheduledMessage;
    //         var nextScheduledAt = moment(scheduledMessage.nextScheduledAt).format("DD-MM-YYYY HH:mm");
    //         var endAt = moment(scheduledMessage.scheduleEndAt).format("DD-MM-YYYY");
    //         var contactName = "You";

    //         if (this.MobilockChatClient.chatMetaData.selfUUID != scheduledMessage.fromUuid) {
    //             contactName = chatManagerObj.getContact(scheduledMessage.fromUuid).name;
    //         }

    //         var formattedMessage  = this.chatUiManagerObj.formatMessageForUi(chatObj.decryptMessage(scheduledMessage.message, scheduledMessage.iv), scheduledMessage.isBroadcast);
    //         chatContainer.prepend(scheduledMessageTemplate({
    //             chat_msg: formattedMessage,
    //             next_scheduled_at: nextScheduledAt,
    //             end_at: scheduledFrequencies[scheduledMessage.frequency] == "Once" ? "NA" : endAt,
    //             msg_uuid: scheduledMessage.uuid,
    //             broadcast_bubble: scheduledMessage.isBroadcast ? "broadcast-bubble" : "",
    //             scheduled_by: contactName,
    //             frequency: scheduledFrequencies[scheduledMessage.frequency]
    //         }));

    //         chatContainer.find('.dropdown-toggle').dropdown();
    //     });

    //     if (previousTopElement.length > 0) {
    //         var scrollTopPrev = previousTopElement.offset().top;
    //         setTimeout(function () {
    //             $('#chat_container .right .all-scheduled-messages')[0].scrollTop = scrollTopPrev;
    //         }, 300);
    //     } else if(this.MobilockChatClient.scheduleMessageUiManagerObj.nextPage == 1){
    //         $('#chat_container .right .all-scheduled-messages')[0].scrollTop = $('#chat_container .right .all-scheduled-messages')[0].scrollHeight;
    //     }
    //     this.MobilockChatClient.scheduleMessageUiManagerObj.nextPage = response.nextPage;
    // }

    // validateScheduleForm() {
    //     var nextScheduledToCompare = moment($("#start-date-input").data().date, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
    //     if(moment(nextScheduledToCompare).isBefore(moment())) {
    //         this.chatUiManagerObj.errorMessage('Start schedule time should be greater than current time','danger');
    //         return false;
    //     }

    //     var scheduleEndToCompare = moment($("#end-date-input").data().date, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
    //     if(moment(scheduleEndToCompare).isBefore(moment())) {
    //         this.chatUiManagerObj.errorMessage('End schedule time should be greater than current time','danger');
    //         return false;
    //     }


    //     if(moment(scheduleEndToCompare).isBefore(moment(nextScheduledToCompare))) {
    //         this.chatUiManagerObj.errorMessage('End schedule time should be greater than start schedule time','danger');
    //         return false;
    //     }

    //     return true;

    // }


    getLongValue(localDateTime) {
        if (localDateTime) {
            return window.moment.utc(window.moment(localDateTime, 'DD-MM-YYYY HH:mm').toDate()).unix() * 1000;
        }

        return 0;
    }

    displayEndDate(frequency) {
        if (this.scheduledFrequencies[frequency] == "Once") {
            // $('.right .schedule-form #end-time-accordion').hide();
        } else {
            // $("#chat_container .right #schedule_form #end-time-accordion").show();
            // $("#chat_container .right #schedule_form .start-collapse").collapse("show");
        }
    }
}

export default ScheduledMessage