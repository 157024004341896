import React, { useState, useEffect, useContext } from "react";
import "./App.css";

import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import AppRouter from "./Routes/AppRouter";
import { ThemeProvider } from "@material-ui/styles";
import ThemeConfig from "./theme/Typography";
import { GlobalStateProvider } from "./Context/GlobalStateContext";
import { I18nProvider } from "@lingui/react";
import { UserLogsProvider } from "./Context/UserLogs"
import { FeedsStateProvider } from "./Context/FeedsStateContext";
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()
// auth
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },

    loader: {
        alignSelf: "center",
        textAlign: "center",
        margin: "auto",
    },

    progressContainer: {
        height: "100%",
        display: "flex",
    },
}));

const theme = createMuiTheme(ThemeConfig);

const App = () => {
    const classes = useStyles(theme);

    /**
     * Wrapper for providers
     * @param {*} props children
     */
    const Providers = (props) => {
        return (
            <>
                <I18nProvider>
                    <GlobalStateProvider>
                        <FeedsStateProvider>
                            <QueryClientProvider client={queryClient}>
                                {props.children}
                            </QueryClientProvider>
                        </FeedsStateProvider>
                    </GlobalStateProvider>
                </I18nProvider>
            </>
        );
    };

    return (
        <>
            <Providers>
                <div className={classes.root}>
                    <ThemeProvider theme={theme}>
                        <UserLogsProvider>
                            <AppRouter />
                        </UserLogsProvider>
                    </ThemeProvider>
                </div>
            </Providers>
        </>
    );
};

export default App;
