import * as types from './types';
import { remove, find, filter, map, indexOf as lodashIndexOf, isEmpty } from 'lodash'
const ZERO = 0

const initialState = {
    selectedChatOwnerInfo: {
        name: '',
        email: '',
        toUUID: null
    },
    groupList: {},
    groupListCopy: {}, // for search reset operation
    contactList: {},
    activeChatList: {},
    one_o_one_chat: {},
    group_chat: {},
    subscriberList: {},
    messageUuids: {},
    imagesWithUuids: {},
    audioSourceWithUuids: {},
    broadcastList: {},
    currentPttTarget: {
        connected: {
            name: '',
            email: '',
            toUUID: null
        },
        listeningChannels:[],
        shoutId: null,
        isConnected: false,
    },
    receivingPttTarget: {
        isReceiving: false,
        uuid: ""
    },
    pttSettings: {},
    messageToEdit: null,
    conversationList: [],
    draftMessegeList: {},

    copyEnabled: false,
    editEnabled: false,
    replyEnabled: false,
    deleteEnabled: false,
    maxFileSize: 29360128, // Check if default

    selectedmessage: null,

    pendingFileUpload: [],
    fileToUpload: null, // TODO: rename to image 
    _fileToUpload: null, // for files
    imageForPreview: null,
    selectedSchedulerData: { // Data being nested, we an object for holding selected scheduler data
        isOpen: false,
        uuid: null, // uuid will be there while editing and null while creating new
    },
    scheduledMessages: [],
    scheduleData: {},
    searchQuery: '',
    pendingMessages: [],
    contactsSearchResult: {},
    groupsSearchResult: {},
    broadcastsSearchResult: {},
    activeUnreadCount: {}, // active chat unread count
    failedMessages: [], // failed message ids
    contactListCopy: {},
    contactsNextPage: 1,
    groupsNextPage: 1
}

const reducer = (state = initialState, action) => {
    var activeChatUuid = state.selectedChatOwnerInfo.toUUID
    var _isGroup = state.groupList[activeChatUuid]
    var _chat = state.one_o_one_chat[activeChatUuid]
    var _activeChat = _isGroup ? state.group_chat[activeChatUuid] : state.one_o_one_chat[activeChatUuid]

    switch (action.type) {
        case types.SET_CURRENT_CONVERSATION_OWNER_DETAILS:
            return {
                ...state,
                selectedChatOwnerInfo: { ...action.payload }
            };
        case types.SET_GROUPS_LIST:
            return {
                ...state,
                groupList: { ...state.groupList, ...action.payload },
                groupListCopy: { ...state.groupList, ...action.payload }
            };
        case types.RESET_GROUP_UNREAD_MESSAGE_COUNT:
            let groupList = state.groupList[activeChatUuid]
            let _resetActiveListGroups = state.activeChatList[activeChatUuid]
            // bail out if contact dont have unread msgs
            if (!groupList || !groupList.unreadMessageCount) return { ...state }
            if (!_resetActiveListGroups || !_resetActiveListGroups.unreadMessageCount) return { ...state }
            groupList = { ...groupList, ...{ unreadMessageCount: ZERO } }
            _resetActiveListGroups = { ..._resetActiveListGroups, ...{ unreadMessageCount: ZERO } }
            return {
                ...state,
                groupList: { ...state.groupList, [activeChatUuid]: groupList },
                activeChatList: { ...state.activeChatList, [activeChatUuid]: _resetActiveListGroups }
            }
        case types.SET_GROUP_UNREAD_MESSAGE_COUNT:
            if (!action.payload) return { ...state }
            if (activeChatUuid == action.payload.uuid) return { ...state }
            let _group = state.groupList[action.payload.uuid]
            let _activeListGroup = state.activeChatList[action.payload.uuid]
            _group = { ..._group, ...{ unreadMessageCount: _group.unreadMessageCount ? _group.unreadMessageCount + 1 : 1 } }
            _activeListGroup = { ..._activeListGroup, ...{ unreadMessageCount: _activeListGroup.unreadMessageCount ? _activeListGroup.unreadMessageCount + 1 : 1 } }
            return {
                ...state,
                groupList: { ...state.groupList, [action.payload.uuid]: _group },
                activeChatList: { ...state.activeChatList, [action.payload.uuid]: _activeListGroup }
            }
        case types.SET_CONTACT_UNREAD_COUNT:
            if (activeChatUuid === action.payload.identifier) return { ...state } // bail out if chat is open
            if (!action.payload) return { ...state } // bail out if chat is open
            let _contact = state.contactList[action.payload.identifier]
            let _activeListContact = state.activeChatList[action.payload.identifier]
            if (!_activeListContact) return { ...state }
            if (!_contact) contact = {} // FIXME: when unreadMessageCount key is not present 
            _contact = { ..._contact, ...{ unreadMessageCount: _contact.unreadMessageCount ? _contact.unreadMessageCount + 1 : 1 } }
            _activeListContact = { ..._activeListContact, ...{ unreadMessageCount: _activeListContact.unreadMessageCount ? _activeListContact.unreadMessageCount + 1 : 1 } }
            return {
                ...state,
                contactList: { ...state.contactList, [action.payload.identifier]: _contact },
                activeChatList: { ...state.activeChatList, [action.payload.identifier]: _activeListContact }
            }
        case types.RESET_CONTACT_UNREAD_COUNT:
            let contact = state.contactList[activeChatUuid]
            // bail out if contact dont have unread msgs
            if (!contact || !contact.unreadMessageCount) return { ...state }
            contact = { ...contact, ...{ unreadMessageCount: ZERO } }
            return {
                ...state,
                contactList: { ...state.contactList, [activeChatUuid]: contact },
            }
        case types.RESET_ACTIVE_CONTACT_UNREAD_COUNT:
            let _resetActiveListContact = state.activeChatList[activeChatUuid]
            // bail out if contact dont have unread msgs
            if (!_resetActiveListContact || !_resetActiveListContact.unreadMessageCount) return { ...state }
            _resetActiveListContact = { ..._resetActiveListContact, ...{ unreadMessageCount: ZERO } }
            return {
                ...state,
                activeChatList: { ...state.activeChatList, [activeChatUuid]: _resetActiveListContact },
                // groupList: { ...state.groupList, [activeChatUuid]: _resetActiveListContact},
                // FIXME: check if we can get rid of resetting group, contact and active list count separately
                contactList: _isGroup ? { ...state.contactList } : { ...state.contactList, [activeChatUuid]: _resetActiveListContact },
                groupList: _isGroup ? { ...state.groupList, [activeChatUuid]: _resetActiveListContact } : { ...state.groupList }
            }
        case types.SET_CONTACT_LIST:
            return {
                ...state,
                contactList: { ...state.contactList, ...action.payload },
                contactListCopy: { ...action.payload },
            };
        case types.REMOVE_CONTACT:
            var contact_uuid = action.payload.uuid;
            delete state.contactList[contact_uuid];
            delete state.activeChatList[contact_uuid]
            return {
                ...state,
                contactList: state.contactList,
                activeChatList: state.activeChatList
            };
        case types.UPDATE_CONTACT_LIST:
            let updatedContacts = action.payload
            return {
                ...state,
                contactList: { ...state.contactList, ...updatedContacts },
                activeChatList: { ...state.activeChatList, ...updatedContacts } // FIXME: Check before adding to active list, this causes chat to move to active list
            };
        case types.SET_CONTACTS_SEARCH_RESULT:
            return {
                ...state,
                contactList: (!isEmpty(action.payload) && !state.searchQuery) ? { ...state.contactList, ...action.payload } : state.searchQuery ? { ...action.payload } : { ...state.contactListCopy },
                contactListCopy: (!isEmpty(action.payload) && !state.searchQuery) ? { ...state.contactListCopy, ...action.payload } : { ...state.contactListCopy }
            };
        case types.SET_GROUPS_SEARCH_RESULT:
            return {
                ...state,
                groupList: (!isEmpty(action.payload) && !state.searchQuery) ? { ...state.groupList, ...action.payload } : state.searchQuery ? { ...action.payload } : { ...state.groupListCopy },
                groupListCopy: (!isEmpty(action.payload) && !state.searchQuery) ? { ...state.groupListCopy, ...action.payload } : { ...state.groupListCopy }
            };
        case types.SET_BROADCASTS_SEARCH_RESULT:
            return {
                ...state,
                broadcastsSearchResult: { ...action.payload },
            };
        case types.SET_ACTIVE_CHAT_LIST:
            return {
                ...state,
                activeChatList: { ...action.payload },
            };

        case types.SET_ONE_O_ONE_CHAT_MESSAGES:
            return {
                ...state,
                one_o_one_chat: { ...state.one_o_one_chat, ...action.payload }
            };
        case types.UPDATE_MESSAGE_HISTORY:
            let _updateHistoryIdentifier = action.payload.identifier
            var _updateHistoryisDynamicGroup = state.groupList[_updateHistoryIdentifier]
            var _updateHistoryDynamicChat = _updateHistoryisDynamicGroup ? state.group_chat[_updateHistoryIdentifier] : state.one_o_one_chat[_updateHistoryIdentifier]
            if (!_updateHistoryDynamicChat) return { ...state }
            _updateHistoryDynamicChat.messages = _updateHistoryDynamicChat.messages.concat(action.payload.data)
            return {
                ...state,
                one_o_one_chat: _updateHistoryisDynamicGroup ? { ...state.one_o_one_chat } : { ...state.one_o_one_chat, [_updateHistoryIdentifier]: _updateHistoryDynamicChat },
                group_chat: _updateHistoryisDynamicGroup ? { ...state.group_chat, [_updateHistoryIdentifier]: _updateHistoryDynamicChat } : { ...state.group_chat }
            };
        case types.MESSAGE_RECEIVED:
            let identifier = action.payload.identifier // update the message list of existing conversation
            var isDynamicGroup = state.groupList[identifier]
            var dynamicChat = isDynamicGroup ? state.group_chat[identifier] : state.one_o_one_chat[identifier]
            if (!dynamicChat) return { ...state }
            dynamicChat.messages.unshift(action.payload.message)
            return {
                ...state,
                one_o_one_chat: isDynamicGroup ? { ...state.one_o_one_chat } : { ...state.one_o_one_chat, [identifier]: dynamicChat },
                group_chat: isDynamicGroup ? { ...state.group_chat, [identifier]: dynamicChat } : { ...state.group_chat }
            };
        case types.SET_NEW_SENT_MESSAGE:
            // This is where we dont have message id
            // add newly sent message in last 
            if (!_activeChat) return { ...state }
            _activeChat.messages.unshift(action.payload)
            return {
                ...state,
                one_o_one_chat: { ...state.one_o_one_chat, [activeChatUuid]: _activeChat },
            }
        case types.UPDATE_NEW_SENT_MESSAGE:
            if (!_activeChat) return { ...state }
            // As previous sent message didnt had message id, we identify it with messageUuid property
            let _updateIdentifier = action.payload.uuid || action.payload.messageUuid
            for (const key in _activeChat.messages) {
                if (_activeChat.messages[key].messageUuid == _updateIdentifier) {
                    _activeChat.messages[key] = { ...action.payload, ..._activeChat.messages[key] }
                }
            }

            return {
                ...state,
                one_o_one_chat: _isGroup ? { ...state.one_o_one_chat } : { ...state.one_o_one_chat, [activeChatUuid]: _activeChat },
                group_chat: _isGroup ? { ...state.group_chat, [activeChatUuid]: _activeChat } : { ...state.group_chat },
            }
        case types.SET_INCOMMING_ONE_O_ONE_CHAT_MESSAGES:
            // let mm = state.one_o_one_chat
            return {
                ...state,
                one_o_one_chat: { ...action.payload }
            };
        case types.SET_GROUP_CHAT_MESSAGES:
            return {
                ...state,
                group_chat: { ...state.group_chat, ...action.payload }
            };
        case types.HANDLE_DELETE_MESSAGE:
            let _deleteIsDynamicGroup = state.groupList[action.payload.identifier]
            let _deleteDynamicChat = _deleteIsDynamicGroup ? state.group_chat[action.payload.identifier] : state.one_o_one_chat[action.payload.identifier]
            remove(_deleteDynamicChat.messages, (message) => message.messageId == action.payload.data.messageId)
            return {
                ...state,
                one_o_one_chat: _deleteIsDynamicGroup ? { ...state.one_o_one_chat } : { ...state.one_o_one_chat, [action.payload.identifier]: _deleteDynamicChat },
                group_chat: _deleteIsDynamicGroup ? { ...state.group_chat, [action.payload.identifier]: _deleteDynamicChat } : { ...state.group_chat }
            }
        case types.SET_IMAGE_SOURCE:
            let _imagesWithUuids = { ...state.imagesWithUuids }
            _imagesWithUuids[action.payload.uuid] = action.payload.src
            return {
                ...state,
                imagesWithUuids: _imagesWithUuids
            }
        case types.SET_AUDIO_SOURCE:
            let _audioSourceWithUuids = { ...state.audioSourceWithUuids }
            _audioSourceWithUuids[action.payload.id] = action.payload.src
            return {
                ...state,
                audioSourceWithUuids: _audioSourceWithUuids
            }
        case types.UPDATE_EDITED_MESSAGE:
            let _editMessageIdentifier = action.payload.identifier // update the message list of existing conversation
            var _editIsDynamicGroup = state.groupList[_editMessageIdentifier]
            var _editDynamicChat = _editIsDynamicGroup ? state.group_chat[_editMessageIdentifier] : state.one_o_one_chat[_editMessageIdentifier]
            _editDynamicChat.messages.map((i) => {
                if (i.messageId === action.payload.messageId) {
                    i.iv = action.payload.messageParams.iv
                    i.message = action.payload.messageParams.message
                    i.edited = true
                }
                return i
            })
            return {
                ...state,
                one_o_one_chat: _editIsDynamicGroup ? { ...state.one_o_one_chat } : { ...state.one_o_one_chat, [_editMessageIdentifier]: _editDynamicChat },
                group_chat: _editIsDynamicGroup ? { ...state.group_chat, [_editMessageIdentifier]: _editDynamicChat } : { ...state.group_chat },
                messageToEdit: null
            };
        case types.SELECT_MESSAGE_TO_EDIT:
            return {
                ...state,
                messageToEdit: action.payload
            };
        case types.CLEAR_MESSAGE_TO_EDIT:
            return {
                ...state,
                messageToEdit: null
            };
        case types.SET_CONVERSATION_LIST:
            /**
             * Bail out if payload is empty
             * to maintain the scroll position after fetching history
             */
            if (!action.payload.length) return { ...state }
            return {
                ...state,
                conversationList: action.payload
            };
        case types.CLEAR_CONVERSATION_LIST:
            return {
                ...state,
                conversationList: []
            };
        case types.REMOVE_GROUP:
            // chatUiMgrRef.current.removeGroup(action.payload);
            var group_uuid = action.group.uuid;
            // _removeGroup(group)
            delete state.groupList[group_uuid];
            // delete state.chats.group[group_uuid];
            delete state.group_chat[group_uuid]

            // chatUiMgrRef.current.updateNoGroupAvailableMessage();
            // chatUiMgrRef.current.handleCurrentChatScreen(group.uuid);
            return {
                ...state,
                groupList: state.groupList
            };
        case types.SET_SUBSCRIBER_LIST:
            return {
                ...state,
                subscriberList: { ...state.subscriberList, ...action.payload }
            };
        case types.SET_MASSAGE_UUID_LIST:
            return {
                ...state,
                messageUuids: { ...action.uuids },
            };
        case types.SET_BROADCAST_LIST:
            return {
                ...state,
                broadcastList: { ...action.broadcastList },
            };
        case types.TOGGLE_COPY_ENABLED:
            return {
                ...state,
                copyEnabled: action.isEnabled
            };
        case types.TOGGLE_EDIT_ENABLED:
            return {
                ...state,
                editEnabled: action.isEnabled
            };
        case types.TOGGLE_REPLY_ENABLED:
            return {
                ...state,
                replyEnabled: action.isEnabled
            };
        case types.TOGGLE_DELETE_ENABLED:
            return {
                ...state,
                deleteEnabled: action.isEnabled
            };
        case types.SET_MAX_FILE_SIZE:
            return {
                ...state,
                maxFileSize: action.size
            };
        case types.SET_SELECTED_MESSAGE:
            return {
                ...state,
                selectedmessage: action.message
            };
        case types.SET_CURRENT_PTT_TARGET:
            return {
                ...state,
                currentPttTarget: action.payload
            };
        case types.SET_RECEIVING_PTT_TARGET:
            return {
                ...state,
                receivingPttTarget: action.payload
            };
        case types.SET_PTT_SETTINGS:
            return {
                ...state,
                pttSettings: action.payload
            };
        case types.SET_DRAFT_MESSAGE:
            return {
                ...state,
                draftMessegeList: { ...state.draftMessegeList, [state.selectedChatOwnerInfo.toUUID]: action.message }
            }
        case types.CLEAR_DRAFT_MESSAGE:
            return {
                ...state,
                draftMessegeList: { ...state.draftMessegeList, [state.selectedChatOwnerInfo.toUUID]: "" }
            }
        case types.SET_PENDING_FILE_UPLOAD_LIST:
            return {
                ...state,
                pendingFileUpload: [...action.file]
            };
        case types.CLEAR_PENDING_FILE_UPLOAD_LIST:
            if (state.pendingFileUpload.length == 0) return { ...state }

            let _pendingMessageFiles = state.pendingMessages
            remove(_pendingMessageFiles, (message) => message.messageUuid == action.messageUuid)
            return {
                ...state,
                pendingFileUpload: _pendingMessageFiles
            };
        case types.SET_IMAGE_FOR_UPLOAD:
            // TODO: We can leverage from draft message for image caption
            return {
                ...state,
                fileToUpload: action.image,
            };
        case types.CLEAR_UPLOADED_IMAGE:
            return {
                ...state,
                fileToUpload: null
            };
        case types.SET_FILE_FOR_UPLOAD:
            return {
                ...state,
                _fileToUpload: action.image
            };
        case types.CLEAR_FILE_FOR_UPLOAD:
            return {
                ...state,
                _fileToUpload: null
            };
        case types.SET_IMAGE_FOR_PREVIEW:
            return {
                ...state,
                imageForPreview: action.image
            };
        case types.CLEAR_IMAGE_FOR_PREVIEW:
            return {
                ...state,
                imageForPreview: null
            };
        case types.SET_SELECTED_SCHEDULER_DATA:
            return {
                ...state,
                selectedSchedulerData: { ...state.selectedSchedulerData, ...action.payload }
            };
        case types.SET_SCHEDULED_MESSAGE:
            return {
                ...state,
                scheduledMessages: [...action.message]
            };
        case types.CLEAR_SCHEDULED_MESSAGE:
            return {
                ...state,
                scheduledMessages: []
            };
        case types.SET_SCHEDULE_DATA:
            return {
                ...state,
                scheduleData: { ...state.scheduleData, ...action.payload }
            }
        case types.SET_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: action.searchString
            };
        case types.CANCEL_EDIT_MESSAGE:
            return {
                ...state,
                messageToEdit: null
            };
        case types.UPDATE_GROUPS_LIST:

            // let updatedGroups = state.groupList
            // for (const key in updatedGroups) { if (key == action.group.uuid) updatedGroups[key] = action.group }
            let updatedGroups = action.group
            return {
                ...state,
                groupList: { ...state.groupList, ...updatedGroups },
                activeChatList: { ...state.activeChatList, ...updatedGroups }  // FIXME: Check before adding to active list, this causes chat to move to active list
            };
        case types.UPDATE_REMOVED_GROUP_LIST:
            return {
                ...state,
                groupList: action.group,
                activeChatList: action.group
            };
        case types.SET_PENDING_MESSAGE:
            return {
                ...state,
                pendingMessages: [...state.pendingMessages, action.payload]
            };
        case types.CLEAR_PENDING_MESSAGE:
            let _pendingMessages = state.pendingMessages
            remove(_pendingMessages, (message) => message.uuid == action.messageUuid)
            return {
                ...state,
                pendingMessages: _pendingMessages
            };
        case types.SET_ACTIVE_UNREAD_COUNT:
            if (activeChatUuid !== action.identifier) return { ...state } // bail out if this is not active chat
            let activeUnreadConversation = state.activeUnreadCount[action.identifier]
            return {
                ...state,
                activeUnreadCount: { ...state.activeUnreadCount, [action.identifier]: activeUnreadConversation ? activeUnreadConversation + 1 : 1 }
            }
        case types.CLEAR_ACTIVE_UNREAD_COUNT:
            return {
                ...state,
                activeUnreadCount: { ...state.activeUnreadCount, [activeChatUuid]: 0 }
            }
        case types.HANDLE_REMOVED_ACTIVE_CHAT:
            return {
                ...state,
                // conversationList: [],
                selectedChatOwnerInfo: {
                    name: '',
                    email: '',
                    toUUID: null
                }
            };
        case types.MARK_MESSAGE_FAILED:
            let failedPending = state.pendingFileUpload.find((i) => (i.uuid == action.payload || i.messageUuid == action.payload))
            if (failedPending) failedPending = { ...failedPending, ...{ failed: true } }
            return {
                ...state,
                pendingFileUpload: [failedPending]
            };
        case types.SET_CONTACTS_NEXT_PAGE:
            // search result will set page to 0
            if (action.payload == 0 && state.searchQuery) return { ...state }
            return {
                ...state,
                contactsNextPage: Number(action.payload)
            };
        case types.SET_GROUPS_NEXT_PAGE:
            // TODO: Server needs tp send page 0 when pagination ends
            if (action.payload == 0 && state.searchQuery) return { ...state }
            return {
                ...state,
                groupsNextPage: Number(action.payload)
            };
        default:
            return state;
    }
}

export default reducer;