import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
// This hook shares the token with Eva Messenger using custom event
const event = new CustomEvent('fetchToken', { detail: { } });

const useShareToken = ({oidcUser, events}) => {
        // const location = useLocation()
        // const history = useHistory()
        // const { profile } = oidcUser;
    
        // Share initial token
        // useEffect(() => {
        //     if (oidcUser) {
        //         event.detail["oidcUser"] = oidcUser;
        //         event.detail["chatHost"] = process.env.REACT_APP_EVA_SOCKET_URL;
        //         // Dispatch the event.
        //         console.log("dispatchEvent from line 57")
        //         document.dispatchEvent(event);
        //     }
        // }, [oidcUser]);

        useEffect(()=> {
            events.addSilentRenewError(function(data) {
                if(data.message === "invalid_grant") {
                    window.location.reload();
                };
            })  
            events.addUserLoaded(function(data) {
                if (window.location.pathname === '/') {
                    window.location.reload()
                }
            })  
        }, [])
}

export default useShareToken