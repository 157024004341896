console.log("REACT_APP_EVA_HTTP_URL", process.env.REACT_APP_EVA_HTTP_URL);
const configuration = {
    client_id: "im-client",
    redirect_uri: process.env.REACT_APP_EVA_HTTP_URL + "authentication/callback",
    response_type: "code",
    scope: "openid id-management license-management",
    authority: process.env.REACT_APP_IDENTITY_URL + "auth/realms/sf-users/",
    silent_redirect_uri: process.env.REACT_APP_EVA_HTTP_URL + "authentication/silent_callback",
    automaticSilentRenew: true,
    loadUserInfo: false,
    accessTokenExpiringNotificationTime: 10,
    post_logout_redirect_uri: process.env.REACT_APP_HOMEPAGE_URL, 
    metadata: {
        issuer: process.env.REACT_APP_IDENTITY_URL + "auth/realms/sf-users",
        jwks_uri: process.env.REACT_APP_IDENTITY_URL + "auth/realms/sf-users/protocol/openid-connect/certs",
        authorization_endpoint: process.env.REACT_APP_IDENTITY_URL + "auth/realms/sf-users/protocol/openid-connect/auth",
        token_endpoint: process.env.REACT_APP_IDENTITY_URL + "auth/realms/sf-users/protocol/openid-connect/token",
        end_session_endpoint: process.env.REACT_APP_IDENTITY_URL + "auth/realms/sf-users/protocol/openid-connect/logout",
        introspection_endpoint: process.env.REACT_APP_IDENTITY_URL + "auth/realms/sf-users/protocol/openid-connect/token/introspect",
        check_session_iframe: true
    }
    // response_mode: "form_post"
};

export default configuration;
