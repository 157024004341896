import React, { useState, useEffect } from 'react'
/**
 * @returns {Boolean} - weather user is online of offline
 */
const useNetwork = () => {
    const [isOnline, setIsOnline] = useState(false)

    useEffect(() => {
        window.addEventListener('online', function() {
            setIsOnline(true)
        });
    }, [])

    useEffect(() => {
        window.addEventListener('offline', function() {
            setIsOnline(false)
        });
    }, [])

    return { isOnline }
    
}

export default useNetwork