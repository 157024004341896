import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import ChatManagerStore from "../Messanger/Store/ChatManagerStore";
import { connect } from 'react-redux';
import { setPttConnection } from "../Messanger/containers/ChatUI/actions"
import { makeStyles } from "@material-ui/core/styles";
import useComponentMountedHook from '../../Hooks/useComponentMountedHook';
import { useRORAxios } from "../../core/useAxios";
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { setPttSettings } from "../Messanger/containers/Chat/actions"
import { isEmptyObject } from "jquery";
import PttActions from "./Actions"
import PTTRecording from "./PTTRecording";
import GlobalStateContext from '../../Context/GlobalStateContext';
import { setCurrentPttTarget, setReceivingPttTarget } from "../Messanger/containers/Chat/actions"
const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: "40px",
        height: 60,
        width: 40,
    },

    message: {
        borderRadius: "50px",
        background: "#FCAE1E",
        display: "flex",
        height: 60,
        alignItems: "center",
        paddingRight: 10
    },

    icon: {
        marginLeft: "10px"
    }
}))

const initDragState = {
    diffX: 0,
    diffY: 0,
    dragging: false,
    styles: {}
}
const App = (props) => {
    const {
        selectedChatOwnerInfo,
        setPttConnection,
        currentPttTarget,
        pttSettings,
        setCurrentPttTarget,
        setReceivingPttTarget,
        setPttSettings,
        groupList,
        pttConnection,
    } = props
    const classes = useStyles();
    const { oidcUser } = useReactOidc();
    const { chatUiMgrRef, chatManagerRef, callObjRef } = useContext(ChatManagerStore);
    const [data, setData] = useState({ type: "-1", user_name: "" })
    const [state, dispatch] = useContext(GlobalStateContext)
    const [iframeLoaded, setiframeLoaded] = useState(false)
    const [ischannelsConnected, setIsChannelsConnected] = useState(false)

    const axiosInstance = useRORAxios(
        process.env.REACT_APP_IM_HTTP_API_ROR_URL,
        oidcUser.access_token
    );

    const { isComponentMounted } = useComponentMountedHook(axiosInstance)

    useEffect(() => {
        if (isComponentMounted && iframeLoaded) {
            connectPtt()
        } else {
            setPttConnection({ ...pttConnection, message: `` })
        }
    }, [isComponentMounted, iframeLoaded])

    useEffect(() => {
        setPttSettings(state.networkSettings)
    }, [state])

    const isInWorkingHours = (setting) => {
        let clockIn = setting.schedule?.clock_in;
        let clockOut = setting.schedule?.clock_out;
        if (setting.schedule && setting.ptt_settings.enforce_ptt_during_schedule) {
            const inTime = parseInt(clockIn.split(":")[0].replace(" ", "")) * 60 + parseInt(clockIn.split(":")[1].replace(" ", "")) // minutes
            const outTime = parseInt(clockOut.split(":")[0].replace(" ", "")) * 60 + parseInt(clockOut.split(":")[1].replace(" ", ""))
            var now = new Date();
            var currentTime = now.getHours() * 60 + now.getMinutes(); // Minutes since Midnight
            if (((outTime > inTime) && (currentTime > inTime && currentTime < outTime)) || ((outTime < inTime) && (currentTime < inTime && currentTime > outTime))) {
                return true
            } else {
                setPttConnection({ ...pttConnection, message: `Push To Talk has been disabled as you are outside work hours.it will enable again at ${clockIn}` })
                return false
            }
        } else {
            return true;
        }

    }

    const connectPtt = () => {
        if (pttSettings.ptt_settings?.user_details && isInWorkingHours(pttSettings)) {
            PttActions.connectPtt({ adress: pttSettings.ptt_settings.host + "/connect", userName: pttSettings.ptt_settings.user_details.user_name, password: pttSettings.ptt_settings.user_details.auth_token })
        }
    }

    useEffect(() => {
        if (pttConnection.connected && !isEmptyObject(groupList)) {
            listenChannels()
        }
    }, [groupList, pttConnection])

    const listenChannels = () => {
        if (!ischannelsConnected) {
            let chanelIds = []
            Object.keys(groupList).map((key) => {
                if (groupList[key].pttEnabled) {
                    chanelIds.push(groupList[key].pttChannelId)
                }
            })
            if (chanelIds.length > 0) {
                PttActions.listenChannels(chanelIds)
                setCurrentPttTarget({ ...currentPttTarget, listeningChannels: chanelIds })
            }
            setIsChannelsConnected(true)
        }
    }

    const getPttMessage = () => {
        return {
            "sent_at": new Date().getTime(),
            "type": -1,
            "user_id": oidcUser.profile.sub,
            "user_name": oidcUser.profile.name,
            "target_user_id": selectedChatOwnerInfo.toUUID.replaceAll("-", "")
        }
    }

    const stopServices = () => {
        if(pttConnection.pttSending) PttActions.stopPtt(getPttMessage())
        setPttConnection({ ...pttConnection, pttSending: false })
        chatManagerRef.current.endPtt();
        if (callObjRef.current.callId) chatManagerRef.current.endCall();
    }

    useEffect(() => {
        if (data.type != -1) {
            if (data.type == 3) stopServices(); //if SOS PTT
            chatManagerRef.current.setPttStatus("Incoming PTT...");
            chatManagerRef.current.initializePTTInComingScreen(data);
            setPttConnection({ ...pttConnection, pttReceiving: true })
        } else {
            chatManagerRef.current.endPtt();
            setPttConnection({ ...pttConnection, pttReceiving: false })
        }
    }, [data])

    const setPttReceivingDetails = (value) => {
        let flag = value.type == -1 ? false : true
        setReceivingPttTarget({ isReceiving: flag, uuid: value.user_id })
    }

    useEffect(() => {
        window.onmessage = function (e) {
            if (e.data && e.data.message) {
                switch (e.data.message) {
                    case "start_connection":
                        setiframeLoaded(true)
                        break;
                    case "sending_ptt_start":
                        //TODO
                        break;
                    case "sending_ptt_stop":
                        //TODO
                        break;
                    case "receiving_message":
                        setData(JSON.parse(e.data.data))
                        setPttReceivingDetails(JSON.parse(e.data.data))
                        break;
                    case "ptt_connection":
                        console.log("pttt connection", e.data.value);
                        setPttConnection({ ...pttConnection, connected: e.data.value })
                        break;
                    case "user_connected":
                        console.log("is user conected", e.data.value)
                        setCurrentPttTarget({ ...currentPttTarget, isConnected: e.data.value })
                        break;
                }
            }
        };
    }, [])

    return (<PTTRecording isPttOn={pttConnection.pttSending} />);
};

const mapStateToProps = function (state) {
    return {
        selectedChatOwnerInfo: state.reducerChat.selectedChatOwnerInfo,
        currentPttTarget: state.reducerChat.currentPttTarget,
        groupList: state.reducerChat.groupList,
        pttSettings: state.reducerChat.pttSettings,
        pttConnection: state.reducerChatUI.pttConnection,
    }
}

const mapDispatchToProps = {
    setPttConnection,
    setPttSettings,
    setCurrentPttTarget,
    setReceivingPttTarget
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
