import { withOidcSecure } from '@axa-fr/react-oidc-context'
import RouteNames from "../Routes/RouteNames"
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from "react"
import usePermissions from "./usePermissions"
import Directory from '../components/Directory/Directory'

import loadable from '@loadable/component'
import LoadingProgress from "../components/Misc/LoadingProgress"

// Will use code splitting provided by loadable
const Home = loadable(() => import('../components/Home/Home'), {
    fallback: <LoadingProgress />,
});

const Messenger = loadable(() => import('../components/Messanger/Messenger'), {
    fallback: <LoadingProgress />,
});
// NOTE: Being a root/home, we will not lazy load this component. That is done inside the component.
// const Directory = loadable(() => import('../components/Directory/Directory'), {
//     fallback: <LoadingProgress />,
// });

const ChannelManagement = loadable(() => import('../components/ChannelManagement/Channels'), {
    fallback: <LoadingProgress />,
});

const StartGuide = loadable(() => import('../components/StartGuide/StartGuide'), {
    fallback: <LoadingProgress />,
});

const SettingsManagement = loadable(() => import('../components/Settings/SettingsManagement'), {
    fallback: <LoadingProgress />,
});

const Integrations = loadable(() => import('../components/Integrations/Integrations'), {
    fallback: <LoadingProgress />,
});

const ChannelDetailView = loadable(() => import('../components/Channels/ChannelDetailView'), {
    fallback: <LoadingProgress />,
});

const Location = loadable(() => import('../components/Location/LocationManagement'), {
    fallback: <LoadingProgress />,
});

const DeepDive = loadable(() => import('../components/DeepDive/DeepDive'), {
    fallback: <LoadingProgress />,
});

const LicensePlans = loadable(() => import('../components/LicenseManagement/LicensePlans'), {
    fallback: <LoadingProgress />,
});

const LicenseManagement = loadable(() => import('../components/LicenseManagement/LicenseManagement'), {
    fallback: <LoadingProgress />,
});

const PaymentComplete = loadable(() => import('../components/LicenseManagement/PaymentComplete'), {
    fallback: <LoadingProgress />,
});

const LicenseDetails = loadable(() => import('../components/LicenseManagement/LicenseDetails'), {
    fallback: <LoadingProgress />,
});

const TransactionDetails = loadable(() => import('../components/LicenseManagement/TransactionDetails'), {
    fallback: <LoadingProgress />,
});

const MyProfile = loadable(() => import('../components/Users/MyProfile'), {
    fallback: <LoadingProgress />,
});

const ChangeLog = loadable(() => import('../Routes/ChangeLog'), {
    fallback: <LoadingProgress />,
})

const RolesManagement = loadable(() => import('../components/Roles/RolesManagement'), {
    fallback: <LoadingProgress />,
})

const AppManagement = loadable(() => import('../components/Apps/AppManagement'), {
    fallback: <LoadingProgress />,
})


const useRedirect = () => {

    const [routes, setRoutes] = useState([])
    const permissions = usePermissions()

    const [isComponentMounted, setIsComponentMounted] = useState(false)

    useEffect(() => {
        if (!isEmpty(permissions) && !isComponentMounted) setIsComponentMounted(true)
    }, [permissions])

    useEffect(() => {
        // Only create/update routes initially, not again 
        // Which conflicts with protected routes resulting in ummounting and mounting then=m again
        if (isComponentMounted) setRoutes(dynamicRoutes)
    }, [isComponentMounted]);

    const dynamicRoutes = [
        {
            id: 1,
            name: RouteNames.Messenger,
            component: withOidcSecure(Messenger),
            path: '/messenger',
            default: false,
            exact: true,
            redirect: false
        },

        {
            id: 2,
            Name: RouteNames.Settings,
            component: withOidcSecure(SettingsManagement),
            path: '/settings',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showSettings) || false
        },

        {
            id: 3,
            Name: RouteNames.Home,
            component: withOidcSecure(Home),
            path: '/welcome',
            default: false,
            exact: true,
            redirect: false
        },

        {
            id: 4,
            Name: RouteNames.ChannelDetailView,
            component: withOidcSecure(ChannelDetailView),
            path: '/channels-view/:channelId/edit',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showChannels) || false
        },

        {
            id: 5,
            Name: RouteNames.LicenseManagement,
            component: withOidcSecure(LicenseManagement),
            path: '/license-management',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showLicenses) || false
        },

        {
            id: 6,
            Name: RouteNames.LicenseManagement,
            component: withOidcSecure(LicensePlans),
            path: '/license-plans',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showLicenses) || false
        },

        {
            id: 7,
            Name: RouteNames.Purchase,
            component: withOidcSecure(PaymentComplete),
            path: '/payment-complete/:refId',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showLicenses) || false
        },

        {
            id: 8,
            Name: RouteNames.Purchase,
            component: withOidcSecure(LicenseDetails),
            path: '/license/:licenseNo',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showLicenses) || false
        },

        {
            id: 9,
            Name: RouteNames.Purchase,
            component: withOidcSecure(TransactionDetails),
            path: '/transactions/:orderRefId',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showLicenses) || false
        },

        {
            id: 10,
            Name: RouteNames.MyProfile,
            component: withOidcSecure(MyProfile),
            path: '/my-profile',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showChat) || false
        },

        {
            id: 11,
            Name: RouteNames.Directory,
            component: withOidcSecure(Directory),
            path: '/directory',
            default: true,
            exact: false,
            redirect: (!isEmpty(permissions) && !permissions.showDirectory) || false
        },

        {
            id: 12,
            Name: RouteNames.channels,
            component: withOidcSecure(ChannelManagement),
            path: '/channels',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showChannels) || false
        },

        {
            id: 13,
            Name: RouteNames.LicenseManagement,
            component: withOidcSecure(Location),
            path: '/location',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showLocations) || false
        },
        {
            id: 14,
            Name: RouteNames.ChangeLog,
            component: withOidcSecure(ChangeLog),
            path: '/changelogs',
            default: false,
            exact: true,
            redirect: (process.env.REACT_APP_IS_PRODUCTION === "true")
        },
        {
            id: 15,
            Name: RouteNames.LicenseManagement,
            component: withOidcSecure(Location),
            path: `/location/:userId`,
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showLocations) || false
        },
        {
            id: 16,
            Name: RouteNames.DeepDive,
            component: withOidcSecure(DeepDive),
            path: '/deepdive',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showDeepDive) || false
        },
        {
            id: 17,
            Name: RouteNames.ClientApis,
            component: withOidcSecure(Integrations),
            path: '/integrations',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showIntegration) || false
        },
        {
            id: 18,
            Name: RouteNames.StartGuide,
            component: withOidcSecure(StartGuide),
            path: '/start-guide',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showUsers) || false
        },

        {
            id: 19,
            Name: RouteNames.Roles,
            component: withOidcSecure(RolesManagement),
            path: '/roles',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showRoles) || false
        },
        {
            id: 20,
            Name: RouteNames.Roles,
            component: withOidcSecure(AppManagement),
            path: '/apk',
            default: false,
            exact: true,
            redirect: (!isEmpty(permissions) && !permissions.showRoles) || false
        },
    ]

    return { routes }
}

export default useRedirect
