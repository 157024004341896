/**
 * A mixin that provides common functionality
 * which can be used in places where we make XHR requests.
 */

import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import GlobalStateContext from '../Context/GlobalStateContext'
import GlobalStateMutations from '../Context/GlobalStateMutations'
import Messeges from '../Constants/Messeges'
import useNetwork from '../Hooks/useNetwork'

export const useAxios = (baseURL, keycloak) => {

    const [state, dispatch] = useContext(GlobalStateContext)

    const [axiosInstance, setAxiosInstance] = useState({})

    const { isOnline } = useNetwork()

    useEffect(() => {
        let url = baseURL
        let networkSettings = state.networkSettings
        if (networkSettings.endpoints) {
            if (baseURL === process.env.REACT_APP_KC_BROKER_URL) {
                url = networkSettings.endpoints.accounts_url
            } else {
                url = networkSettings.endpoints.api_url
            }
        }
        const instance = axios.create({
            baseURL: url,
            headers: {
                Authorization: keycloak ? `Bearer ${keycloak}` : undefined,
                Accept: 'application/json'
            }
        })

        setAxiosInstance({ instance })

        instance.interceptors.response.use(function (response) {
            // using data sent with request i.e toast message
            // FIXME: this only works if request has no params present
            // let { data } = response.config
            // if (data) toggleSnackbar(true, data)
            // TODO: global level success response depending on type of request
            // Do something with response data
            return response;
        }, function (error) {
            // Global error toast
            let defaultErrorMessage = 'Something went wrong!';
            // let defaultErrorMessage = !isOnline ? Messeges.OFFLINE_MESSEGE : 'Something went wrong!';
            let errorMessage = error.response ? (error.response.data ? error.response.data.error_message : defaultErrorMessage) : defaultErrorMessage;
            toggleSnackbar(true, errorMessage, 'error');
            // Do something with response error
            return Promise.reject(error);
        });

        function toggleSnackbar(isVisible, message, variant = "success") {
            dispatch({
                type: GlobalStateMutations.SHOW_SNACKBAR,
                payload: {
                    isVisible: isVisible,
                    message: message,
                    variant: variant,
                }
            });
        }

        return () => {
            setAxiosInstance({})
        }
    }, [keycloak])

    return axiosInstance.instance
}

export const useAxiosWithoutHandling = (baseURL, keycloak) => {
    const [axiosInstance, setAxiosInstance] = useState({})
    const [state, dispatch] = useContext(GlobalStateContext)

    useEffect(() => {
        let url = baseURL
        let networkSettings = state.networkSettings
        if (networkSettings.endpoints) {
            if (baseURL === process.env.REACT_APP_KC_BROKER_URL) {
                url = networkSettings.endpoints.accounts_url
            } else {
                url = networkSettings.endpoints.api_url
            }
        }
        const instance = axios.create({
            baseURL: url,
            headers: {
                Authorization: keycloak ? `Bearer ${keycloak}` : undefined
            }
        })

        setAxiosInstance({ instance })

        return () => {
            setAxiosInstance({})
        }
    }, [keycloak])

    return axiosInstance.instance
}

export const useRORAxios = (baseURL, keycloak) => {

    const [state, dispatch] = useContext(GlobalStateContext)

    const [axiosInstance, setAxiosInstance] = useState({})

    const { isOnline } = useNetwork()

    useEffect(() => {
        let url = baseURL
        let networkSettings = state.networkSettings
        if (networkSettings.endpoints) {
            if (baseURL === process.env.REACT_APP_KC_BROKER_URL) {
                url = networkSettings.endpoints.accounts_url
            } else {
                url = networkSettings.endpoints.api_url
            }
        }
        const instance = axios.create({
            baseURL: url,
            headers: {
                Authorization: keycloak ? `Bearer ${keycloak}` : undefined,
                Accept: 'application/json'
            }
        })

        setAxiosInstance({ instance })

        instance.interceptors.response.use(function (response) {
            // using data sent with request i.e toast message
            // FIXME: this only works if request has no params present
            // let { data } = response.config
            // if (data) toggleSnackbar(true, data)
            // TODO: global level success response depending on type of request
            // Do something with response data
            return response;
        }, function (error) {
            // Global error toast
            let defaultErrorMessage = 'Something went wrong!';
            // let defaultErrorMessage = !isOnline ? Messeges.OFFLINE_MESSEGE : 'Something went wrong!';
            let errorMessage = (error?.response?.data?.errors) ? error.response.data.errors : (error?.response?.data?.error) ? error?.response?.data?.error : defaultErrorMessage;
            if (error.response && error.response.data && error.response.status == 422) {
                if (typeof (errorMessage) == "object") {
                    errorMessage = JSON.stringify(errorMessage)
                } else if (errorMessage) {
                    errorMessage = errorMessage.includes(":") ? errorMessage.split(":")[1] : errorMessage;
                }
            } else {
                errorMessage = defaultErrorMessage
            }
            toggleSnackbar(true, errorMessage, 'error');
            // Do something with response error
            return Promise.reject(error);
        });

        function toggleSnackbar(isVisible, message, variant = "success") {
            dispatch({
                type: GlobalStateMutations.SHOW_SNACKBAR,
                payload: {
                    isVisible: isVisible,
                    message: message,
                    variant: variant,
                }
            });
        }

        return () => {
            setAxiosInstance({})
        }
    }, [keycloak])

    return axiosInstance.instance
}
