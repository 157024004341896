import React, { useEffect, useState, useRef, useContext } from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx'
import {
    Typography,
    IconButton,
    Popover,
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
    SvgIcon,
    ListItemText
} from '@material-ui/core';
import HelpIcon from '../assets/images/Icons/Help.svg'
import FaceAgent from "../assets/images/Icons/face-agent.svg"
import GlobalStateContext from '../Context/GlobalStateContext';
import MenuVertical from "../assets/images/Icons/MenuVertical.svg"
import { ReactComponent as Onboarding } from "../assets/images/Icons/Onboarding.svg"
import Support from "../assets/images/Icons/Support.svg"
import usePermissions from '../Hooks/usePermissions';
import {
    StarsRounded
} from '@material-ui/icons';


const useStyles = makeStyles(theme => ({

    help: {
        // margin: "0px 22px",
        borderTop: "2px solid #EFEFEF",
        paddingTop: "10px",
        position: "absolute",
        bottom: 67,
        width: "100%",
        padding: "0px 10px",
    },

    containerMargin: {
        // marginLeft: "15px"
    },

    bg: {
        background: "#53565B",
        borderRadius: 10,
        width: 42,
        height: 42,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "110%",
        color: "#FFFFFF",
        cursor: "pointer"
    },

    bgRed: {
        background: "#f0132d !important",
        fontSize: 10
    },

    iconMargin: {
        margin: "0px 6px"

    },

    flexv: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },

    flexh: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around"
    },

    more: {
        marginTop: 17,
        cursor: "pointer",
        width: "100px",
        display: "flex",
        justifyContent: "center"
    },

    listIcon: {
        marginRight: 10,
        minWidth: 0,
    },

    position: {
        position: "absolute",
        bottom: "-30px !important",
        overflow: "hidden",
        left: "72px !important",
        background: "#161616",
        borderRadius: 10,
        top: "auto !important",
        display: "none",
    },

    text: {
        color: "#ffffff",
        fontWeight: 600,
        fontSize: 14
    },

    hover: {
        '&:hover #profile': {
            display: "block"
        }
    },

    customWhiteSvg: {
        fontSize: 24,
        '& path': {
            fill: '#FFFFFF',
        },
    },

    customGreySvg: {
        fontSize: 24,
        '& path': {
            fill: '#6D6D6D',
        },
    },

    pl4: {
        paddingLeft: "4px"
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#161616',
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 6
    },
}))(Tooltip);

function ListItemLink(props) {
    return <ListItem button component="button" {...props} />;
}


const Help = () => {
    const classes = useStyles()
    const [state, dispatch] = useContext(GlobalStateContext)
    const history = useHistory()
    const [remaningDays, setRemaningDays] = useState()
    const permissions = usePermissions()

    useEffect(() => {
        if (Object.keys(state.licenseDetails).length) {
            let expDate = new Date(state.licenseDetails.next_renewal_date).getTime() - new Date().getTime()
            var Difference_In_Days = Math.round(expDate / (1000 * 3600 * 24));
            setRemaningDays(Difference_In_Days - 1)
        }

    }, [state])

    const CustomeWhiteIcon = ({ icon }) => {
        return <SvgIcon
            className={classes.customWhiteSvg}
            component={icon} />
        // style={{ fontSize: 24 }} />
    }

    const CustomeGreyIcon = ({ icon }) => {
        return <SvgIcon
            className={classes.customGreySvg}
            component={icon}
            style={{ fontSize: 24 }} />
    }

    const startGuide = () => history.push("/start-guide")

    const goToLicense = () => history.push("/license-management")

    const getTooltipText = () => {
        if (remaningDays == 0)
            return 'Your trial license expiring today'
        else if (remaningDays < 0)
            return 'Your trial license expired'
        else
            return `${remaningDays} Days Trial Remaining`
    }

    return <div  >
        <Box
            className={clsx(classes.help, state.isDrawerVisible ? classes.flexh : classes.flexv, { [classes.containerMargin]: state.isDrawerVisible })}>
            {permissions.showLicenses && state.licenseDetails?.is_trial_license &&
                <HtmlTooltip title={getTooltipText()} placement="top">
                    {remaningDays >= 0 ?
                        <div className={clsx(classes.bg, { [classes.iconMargin]: state.isDrawerVisible })} onClick={goToLicense}>
                            <span >{remaningDays}</span>
                            <span >days</span>
                        </div> :
                        <div className={clsx(classes.bg, { [classes.iconMargin]: state.isDrawerVisible }, classes.bgRed)} onClick={goToLicense}>
                            <span >Expired</span>
                        </div>}
                </HtmlTooltip>}
            {state.isDrawerVisible ?
                <>
                    {permissions.showUsers && <HtmlTooltip title="Start Guide" placement="top">
                        <div className={clsx(classes.bg, classes.iconMargin, classes.pl4)} onClick={startGuide}>
                            <CustomeWhiteIcon icon={Onboarding} />
                        </div>
                    </HtmlTooltip>}
                    <HtmlTooltip title="Contact Support Expert" placement="top">
                        <div id="intercom" className={clsx(classes.bg, classes.iconMargin)}>
                            <img src={FaceAgent} />
                        </div>
                    </HtmlTooltip>
                </> :
                <div className={clsx(classes.more, classes.hover)} >
                    <img src={MenuVertical} />
                    <div
                        id="profile"
                        className={clsx(classes.position)}
                    >
                        <Box className={classes.root} >
                            <List component="nav" className={classes.nav} aria-label="main account logout">
                                {permissions.showUsers && <ListItemLink className={classes.button} onClick={startGuide}>
                                    <ListItemIcon className={classes.listIcon}>
                                        <CustomeGreyIcon icon={Onboarding} />
                                    </ListItemIcon>
                                    <div className={classes.text}>Start Guide</div>
                                </ListItemLink>}
                                <ListItemLink className={classes.button} id="intercom">
                                    <ListItemIcon className={classes.listIcon}>
                                        <img src={Support} />
                                    </ListItemIcon>
                                    <div className={classes.text}>NuovoTeam Support</div>
                                </ListItemLink>
                            </List>
                        </Box>
                    </div>
                </div>}
        </Box>
    </div>
}
export default Help;
