export const SET_CURRENT_CONVERSATION_OWNER_DETAILS = 'SET_CURRENT_CONVERSATION_OWNER_DETAILS';
export const SET_GROUPS_LIST = 'SET_GROUPS_LIST';
export const UPDATE_GROUPS_LIST = 'UPDATE_GROUPS_LIST';
export const UPDATE_REMOVED_GROUP_LIST = 'UPDATE_REMOVED_GROUP_LIST';
export const SET_SUBSCRIBER_LIST = 'SET_SUBSCRIBER_LIST';
export const SET_MASSAGE_UUID_LIST = 'SET_MASSAGE_UUID_LIST';
export const SET_BROADCAST_LIST = 'SET_BROADCAST_LIST';
export const RESET_GROUP_UNREAD_MESSAGE_COUNT = 'RESET_GROUP_UNREAD_MESSAGE_COUNT';
export const SET_GROUP_UNREAD_MESSAGE_COUNT = 'SET_GROUP_UNREAD_MESSAGE_COUNT';
export const SET_CONTACT_UNREAD_COUNT = 'SET_CONTACT_UNREAD_COUNT';
export const SET_ACTIVE_UNREAD_COUNT = 'SET_ACTIVE_UNREAD_COUNT';
export const CLEAR_ACTIVE_UNREAD_COUNT = 'CLEAR_ACTIVE_UNREAD_COUNT';
export const RESET_CONTACT_UNREAD_COUNT = 'RESET_CONTACT_UNREAD_COUNT';
export const RESET_ACTIVE_CONTACT_UNREAD_COUNT = 'RESET_ACTIVE_CONTACT_UNREAD_COUNT';
export const SET_CONTACT_LIST = 'SET_CONTACT_LIST';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const SET_CONTACTS_NEXT_PAGE = 'SET_CONTACTS_NEXT_PAGE';
export const SET_GROUPS_NEXT_PAGE = 'SET_GROUPS_NEXT_PAGE';
export const UPDATE_CONTACT_LIST = 'UPDATE_CONTACT_LIST';
export const SET_CONTACTS_SEARCH_RESULT = 'SET_CONTACTS_SEARCH_RESULT';
export const SET_GROUPS_SEARCH_RESULT = 'SET_GROUPS_SEARCH_RESULT';
export const SET_BROADCASTS_SEARCH_RESULT = 'SET_BROADCASTS_SEARCH_RESULT';
export const SET_ONE_O_ONE_CHAT_MESSAGES = 'SET_ONE_O_ONE_CHAT_MESSAGES';
export const UPDATE_MESSAGE_HISTORY = 'UPDATE_MESSAGE_HISTORY';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const CLEAR_MESSAGE_TO_EDIT = 'CLEAR_MESSAGE_TO_EDIT';
export const SET_CURRENT_PTT_TARGET = 'SET_CURRENT_PTT_TARGET';
export const SET_RECEIVING_PTT_TARGET="SET_RECEIVING_PTT_TARGET";
export const SET_PTT_SETTINGS="SET_PTT_SETTINGS";
export const SET_NEW_SENT_MESSAGE = 'SET_NEW_SENT_MESSAGE';
export const UPDATE_NEW_SENT_MESSAGE = 'UPDATE_NEW_SENT_MESSAGE';
export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS';
export const SET_PENDING_MESSAGE = 'SET_PENDING_MESSAGE';
export const CLEAR_PENDING_MESSAGE = 'CLEAR_PENDING_MESSAGE';
export const MARK_MESSAGE_FAILED = 'MARK_MESSAGE_FAILED';

export const SET_INCOMMING_ONE_O_ONE_CHAT_MESSAGES = 'SET_INCOMMING_ONE_O_ONE_CHAT_MESSAGES';
export const SET_GROUP_CHAT_MESSAGES = 'SET_GROUP_CHAT_MESSAGES';
export const DELETE_MESSAGE = 'DELETE_MESSAGE'; // for deleting message from UI
export const HANDLE_DELETE_MESSAGE = 'HANDLE_DELETE_MESSAGE'; // for delete message requests from server
export const SET_IMAGE_SOURCE = 'SET_IMAGE_SOURCE';
export const SET_AUDIO_SOURCE = 'SET_AUDIO_SOURCE';
export const UPDATE_EDITED_MESSAGE = 'UPDATE_EDITED_MESSAGE';
export const SELECT_MESSAGE_TO_EDIT = 'SELECT_MESSAGE_TO_EDIT';
export const SET_CONVERSATION_LIST = 'SET_CONVERSATION_LIST';
export const SET_ACTIVE_CHAT_LIST = 'SET_ACTIVE_CHAT_LIST';
export const CLEAR_CONVERSATION_LIST = 'CLEAR_CONVERSATION_LIST';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const HANDLE_REMOVED_ACTIVE_CHAT = 'HANDLE_REMOVED_ACTIVE_CHAT';
export const SET_DRAFT_MESSAGE = 'SET_DRAFT_MESSAGE';
export const SET_PENDING_FILE_UPLOAD_LIST = 'SET_PENDING_FILE_UPLOAD_LIST';
export const CLEAR_PENDING_FILE_UPLOAD_LIST = 'CLEAR_PENDING_FILE_UPLOAD_LIST';
export const SET_IMAGE_FOR_UPLOAD = 'SET_IMAGE_FOR_UPLOAD';
export const CLEAR_UPLOADED_IMAGE = 'CLEAR_UPLOADED_IMAGE';
export const CLEAR_FILE_FOR_UPLOAD = 'CLEAR_FILE_FOR_UPLOAD';
export const SET_FILE_FOR_UPLOAD = 'SET_FILE_FOR_UPLOAD';
export const SET_IMAGE_FOR_PREVIEW = 'SET_IMAGE_FOR_PREVIEW';
export const CLEAR_IMAGE_FOR_PREVIEW = 'CLEAR_IMAGE_FOR_PREVIEW';
export const SET_SELECTED_SCHEDULER_DATA = 'SET_SELECTED_SCHEDULER_DATA';
export const SET_SCHEDULED_MESSAGE = 'SET_SCHEDULED_MESSAGE';
export const CLEAR_SCHEDULED_MESSAGE = 'CLEAR_SCHEDULED_MESSAGE';
export const SET_SCHEDULE_DATA = 'SET_SCHEDULE_DATA';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const HANDLE_DELETE_MESSAGE_REQUEST = 'HANDLE_DELETE_MESSAGE_REQUEST';

export const SET_SELECTED_MESSAGE = 'SET_SELECTED_MESSAGE';

export const TOGGLE_COPY_ENABLED = 'TOGGLE_COPY_ENABLED';
export const TOGGLE_EDIT_ENABLED = 'TOGGLE_EDIT_ENABLED';
export const TOGGLE_REPLY_ENABLED = 'TOGGLE_REPLY_ENABLED';
export const TOGGLE_DELETE_ENABLED = 'TOGGLE_DELETE_ENABLED';
export const SET_MAX_FILE_SIZE = 'SET_MAX_FILE_SIZE';

export const CANCEL_EDIT_MESSAGE = 'CANCEL_EDIT_MESSAGE';
export const CLEAR_DRAFT_MESSAGE = 'CLEAR_DRAFT_MESSAGE';

