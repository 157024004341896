/**
 * Proto Command Builder object, this contains all the information that is 
 * needed for the proto buff.
 */
class AttachmentManager {

    constructor(chatUiManagerObj, chatMetaData) {

        this.selfImageMessageTemplate = window._.template(window.$('#self_image_msg_template').html());
        this.fromImageMessageTemplate = window._.template(window.$('#from_image_msg_template').html());
        this.selfFileMessageTemplate = window._.template(window.$('#self_file_msg_template').html());
        this.fromFileMessageTemplate = window._.template(window.$('#from_file_msg_template').html());
        this.chatUiManagerObj = chatUiManagerObj

        this.maxFileSizeInBytes =  52428800;
        this.compressImageFormats = ['image/jpeg', 'image/jpg'];

        this.chatMetaData = chatMetaData
    }

            attachEvents() {
                if (this.chatUiManagerObj == undefined) {
                    this.chatUiManagerObj = this.chatUiManagerObj;
                }
                var fileToSend;

                // window.$("#chat_container").on('click', '.view-full-image .preview', function(){
                //     var chatObj = this.chatUiManagerObj.getActiveChatObj();
                //     var messageId = window.$(this).closest('.chat-msg-e').data('msg-id');
                //     // Image open status not sending to server as not required for Images. - As per requirement.
                //     // chatObj.markMessageOpen(messageId);
                //     var imageUrl = window.$(this).attr('src');

                //     var message = window._.find(chatObj.messages, function(eachMessage) {
                //         return eachMessage.messageId == messageId;
                //     });
                //     var caption;
                //     if (message) {
                //         caption = this.chatUiManagerObj.formatMessageForUi(chatObj.decryptMessage(message.message, message.iv), message.isBroadcast);
                //     } else {
                //         caption = window.$(this).closest('.image-msg').find('.msg')[0].innerText;
                //     }
                //     displayPreview(imageUrl, false, caption);
                    
                // });

                // window.$("#chat_container").on('click', '.view-full-image .download', function(){
                //     var chatObj = this.chatUiManagerObj.getActiveChatObj();
                //     var messageId = window.$(this).closest('.chat-msg-e').data('msg-id');
                //     var messageObj = window._.find(chatObj.messages, function(message) {
                //         return message.messageId == messageId;
                //     });
                //     window.$(this).hide();
                //     // STORE: why this function was called like this, revisit
                //     // window.MobilockChatClient.attachmentUiManagerObj.downloadImage(messageObj, chatObj);
                //     this.downloadImage(messageObj, chatObj);
                // });

                // window.$("#chat_container").on('click', '.chat-msg-e .retry', function() {
                //     if(window.Offline.state == "down") {
                //         this.chatUiManagerObj.errorMessage('Please connect to the network to send the attachment', 'danger');
                //         return;
                //     }
                //     console.log("retry called");
                //     var chatObj = this.chatUiManagerObj.getActiveChatObj();
                //     var fileUuid = window.$(this).closest('.chat-msg-e').data('msg-uuid');
                //     chatObj.retryUpload(fileUuid);
                //     window.$(this).removeClass('retry');
                //     window.$(this).addClass('sending');
                // });

                // window.$("#chat_container").on('click', '#close-eva-preview', function(){
                //     console.log('close-eva-preview called');
                //     var previewContainer = window.$(this).closest('.preview-container');
                //     window.$(previewContainer).find('#caption-container').text('').hide();
                //     window.$(previewContainer).find('.caption_msg').val("");
                //     window.$(previewContainer).find('#edit-container').hide();
                //     window.$(previewContainer).hide();
                //     if (fileToSend) {
                //         window.$('#chat_container').find('#image-input-field').val('');
                //         fileToSend = null;
                //     }
                // });

                // window.$("#chat_container").on('click', '.caption-btn.caption', function(){
                //     var caption = window.$.trim(window.$('.caption_msg').val());
                //     sendMediaImageMessage(fileToSend, caption);
                //     setTimeout(function() {
                //         window.$("#close-eva-preview").trigger('click');
                //     }, 100);
                // });

                // window.$(".img-btn").on('click', function(){
                //     if(window.Offline.state == "down") {
                //         this.chatUiManagerObj.errorMessage('Internet connection is required to send the attachment.', 'danger');
                //         return;
                //     }
                //     window.$("#image-input-field").attr('accept', 'image/*');
                //     window.$("#image-input-field").click();
                // });

                // window.$(".file-btn").on('click', function(){
                //     if(window.Offline.state == "down") {
                //         this.chatUiManagerObj.errorMessage('Internet connection is required to send the attachment.', 'danger');
                //         return;
                //     }
                //     window.$("#file-input-field").attr('accept', '');
                //     window.$("#file-input-field").click();
                // });

                // window.$('#image-input-field').on('change', function(e){
                //     
                //     var files = e.target.files;
                //     window._.each(files, function(originalFile){
                //         var extension = getFileExtension(originalFile.name);
                //         var contentType = originalFile.type 
                //         if(!contentType) {
                //             contentType = window.MobilockChatClient.getFileMimeType(extension);
                //         }

                //         if(!extension || !contentType) {
                //             if (!extension) {
                //                 displayExtensionError('image');
                //             } else {
                //                 displayFileFormatError('image');
                //             }
                //         } else if (this.maxFileSizeInBytes >= originalFile.size) {
                //             var mediaType = getMediaType(contentType);
                //             if(mediaType == "MEDIA_IMAGE") {
                //                 var file = new File([originalFile], 
                //                     formatFileName("IMG-", extension),
                //                     {type: contentType}
                //                 );
                //                 fileToSend = file;
                //                 var caption = '';
                //                 if(this.chatUiManagerObj.messageToReply) {
                //                     var chatObj = this.chatUiManagerObj.getActiveChatObj();
                //                     var group = chatObj.getGroup();
                //                     if(group && group.groupType == window.MobilockChatClient.chatManagerObj.broadCast) {
                //                         caption = window.MobilockChatClient.chatManagerObj.quill.root.innerText.trim();
                //                     } else {
                //                         caption = window.$.trim(window.$('#user_chat_msg').val());
                //                     }
                //                 }
                //                 displayPreview(URL.createObjectURL(fileToSend), true, caption);
                //             } else if (mediaType == "FILE"){
                //                 var file = new File([originalFile], 
                //                     formatFileName("FILE-", extension),
                //                     {type: contentType}
                //                 );
                //                 sendFileMessage(file, originalFile.name);
                //             }
                //         } else {
                //             displaySizeError('media image');
                //         }
                //     })
                // });

                

                // window.$('#file-input-field').on('change', function(e){
                //     
                //     var files = e.target.files;
                //     window._.each(files, function(originalFile){
                //         var extension = getFileExtension(originalFile.name);
                //         var contentType = originalFile.type;
                //         if (!contentType) {
                //             contentType = window.MobilockChatClient.getFileMimeType(extension);
                //         }
                //         if(!extension || !contentType) {
                //             if(!extension) {
                //                 displayExtensionError('file');
                //             } else {
                //                 displayFileFormatError('file');
                //             }
                //         } else if (this.maxFileSizeInBytes >= originalFile.size) {
                //             var file = new File([originalFile], 
                //                 formatFileName("FILE-", extension),
                //                 {type: contentType}
                //             );
                //             sendFileMessage(file, originalFile.name);
                //         } else {
                //             displaySizeError('file');
                //         }
                //     })
                // });

                

                // window.$("#chat_container").on('click', '.message-action-btn.download-file', function(){
                //     var chatObj = this.chatUiManagerObj.getActiveChatObj();
                //     var messageId = window.$(this).closest('.chat-msg-e').data('msg-id');
                //     // File download status not sending to server as not required for now. - As per requirement.
                //     // chatObj.markMessageOpen(messageId);
                //     var downloadActionBtn = window.$(this);
                //     downloadActionBtn.removeClass('download-file');
                //     var message = window._.find(chatObj.messages, function(eachMessage) {
                //         return eachMessage.messageId == messageId;
                //     });
                //     var downloadLink = window.$(this).siblings('a.download-link')[0];
                //     if (!downloadLink.href) {
                //         downloadActionBtn.addClass('sending');
                //         // window.MobilockChatClient.attachmentUiManagerObj.downloadFile(message, chatObjthis.attachmentUiManagerObj.downloadFile(message, chatObj)
                //     } else {
                //         downloadActionBtn.addClass('download-file');
                //         downloadLink.click();
                //     }
                // });

                
            }

            displayExtensionError(type) {
                // window.$('#chat_container').find('#image-input-field').val('');
                // window.$('#chat_container').find('#file-input-field').val('');
                this.chatUiManagerObj.errorMessage('The ' + type + " that you have selected doesn't have valid extension. Unable to send file.",'danger');
            }

            displayFileFormatError(type) {
                // window.$('#chat_container').find('#image-input-field').val('');
                // window.$('#chat_container').find('#file-input-field').val('');
                this.chatUiManagerObj.errorMessage('The ' + type + " type is not valid. Unable to send file.",'danger');
            }

            displaySizeError(type) {
                // window.$('#chat_container').find('#image-input-field').val('');
                // window.$('#chat_container').find('#file-input-field').val('');
                // this.chatUiManagerObj.errorMessage('The ' + type + ' that you have selected is larger than ' + window.MobilockChatClient.attachmentUiManagerObj.bytesToSize(this.maxFileSizeInBytes) + '. Unable to send file','danger');
                this.chatUiManagerObj.errorMessage('The ' + type + ' that you have selected is larger than ' + this.bytesToSize(this.maxFileSizeInBytes) + '. Unable to send file','danger');
            }

            displayPreview(imageUrl, isEdit, caption) {
                // var previewContainer = window.$('.preview-container');
                // var imagePreview = previewContainer.find('.image-preview');
                // var img = previewContainer.find('img').attr('src', imageUrl);
                // var editContainer = previewContainer.find('.edit-container');

                // var chatContainerHeight = window.$('.chat-container').innerHeight();
                // if(isEdit) {
                //     imagePreview.css('height', chatContainerHeight - 100);
                //     previewContainer.find('img').css('max-height', chatContainerHeight - 100);
                //     editContainer.show();
                //     setTimeout(function() {
                //         editContainer.find('.caption_msg').focus();
                //         editContainer.find('.caption_msg').val(caption);
                //     }, 10);
                //     imagePreview.show();
                // } else {
                //     if (caption != undefined && caption != "") {
                //         previewContainer.find('#caption-container').html(caption).show();
                //         // previewContainer.find('#caption-container').show();
                //     }
                //     imagePreview.show();
                //     editContainer.hide();
                // }

                // previewContainer.css('height', chatContainerHeight);
                // previewContainer.show();
                // window.$(".image-preview").fadeIn('slow');
                
            }

            getMediaType(mimeType) {
                if(mimeType.includes("image/")) {
                    return "MEDIA_IMAGE";
                } else {
                    return "FILE";
                }
            }

            sendMediaImageMessage(file, caption) {
                if(window.Offline.state == "down") {
                    this.chatUiManagerObj.errorMessage('Internet connection is required to send the attachment.','danger');
                    return;
                }
                var chatObj = this.chatUiManagerObj.getActiveChatObj();
                if(this.compressImageFormats.includes(file.type)) {
                    new window.Compressor(file, {
                        quality: 0.6, // Recommended image compression quality
                        success(result) {
                            chatObj.sendFileUpload(result, "MEDIA_IMAGE", caption, '', this.chatUiManagerObj.messageToReply);
                        },
                        error(err) {
                            console.log(err.message);
                        }
                    })
                } else {
                    chatObj.sendFileUpload(file, "MEDIA_IMAGE", caption, '', this.chatUiManagerObj.messageToReply);
                }
            }

            sendFileMessage(file, originalFileName) {
                
                var chatObj = this.chatUiManagerObj.getActiveChatObj();
                chatObj.sendFileUpload(file, "FILE", '', originalFileName, this.chatUiManagerObj.messageToReply);
                // window.$('#chat_container').find('#image-input-field').val('');
                // window.$('#chat_container').find('#file-input-field').val('');
            }

            getFileExtension(fileName) {
                var splittedName = fileName.split('.')
                if (splittedName.length > 1) {
                    return splittedName.pop();
                } else {
                    return "";
                }
            }

            formatFileName(type, extension) {
                var formattedName = type + window.MobilockChatClient.chatManagerObj.getUuid();
                if (extension) {
                    formattedName = formattedName + "." + extension;
                }
                return formattedName;
            }

            onFileInputChange(files) {
                    
                    var fileToSend;
                    var that = this
                    window._.each(files, function(originalFile){
                        var extension = that.getFileExtension(originalFile.name);
                        var contentType = originalFile.type 
                        if(!contentType) {
                            contentType = window.MobilockChatClient.getFileMimeType(extension);
                        }

                        if(!extension || !contentType) {
                            if (!extension) {
                                that.displayExtensionError('image');
                            } else {
                                that.displayFileFormatError('image');
                            }
                        } else if (that.maxFileSizeInBytes >= originalFile.size) {
                            var mediaType = that.getMediaType(contentType);
                            if(mediaType == "MEDIA_IMAGE") {
                                var file = new File([originalFile], 
                                    that.formatFileName("IMG-", extension),
                                    {type: contentType}
                                );
                                fileToSend = file;
                                var caption = '';
                                if(this.chatUiManagerObj.messageToReply) {
                                    var chatObj = this.chatUiManagerObj.getActiveChatObj();
                                    var group = chatObj.getGroup();
                                    if(group && group.groupType == window.MobilockChatClient.chatManagerObj.broadCast) {
                                        caption = window.MobilockChatClient.chatManagerObj.quill.root.innerText.trim();
                                    } else {
                                        caption = window.$.trim(window.$('#user_chat_msg').val());
                                    }
                                }
                                that.displayPreview(URL.createObjectURL(fileToSend), true, caption);
                            } else if (mediaType == "FILE"){
                                var file = new File([originalFile], 
                                    that.formatFileName("FILE-", extension),
                                    {type: contentType}
                                );
                                that.sendFileMessage(file, originalFile.name);
                            }
                        } else {
                            that.displaySizeError('media image');
                        }
                    })
            }

            setMaxFileSize(maxFileSize) {
                this.maxFileSizeInBytes = maxFileSize;
            }

            bytesToSize (bytes) {
               var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
               if (bytes == 0) return '0 Byte';
               var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
               return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
            }

            showImageMessage(messageObj, fromUuid, container, msgTimeUi, chatObj, sender, position, deleteEnabled, replyEnabled){
                
                var from = fromUuid || messageObj.fromUuid;
                var decryptedMessage;
                if(messageObj.decryptedText) {
                    decryptedMessage = this.chatUiManagerObj.formatMessageForUi(messageObj.decryptedText);
                } else {
                    decryptedMessage = this.chatUiManagerObj.formatMessageForUi(chatObj.decryptMessage(messageObj.message, messageObj.iv));
                }

                if(decryptedMessage) {
                    decryptedMessage = window.$.parseHTML(decryptedMessage)[0].data;
                }

                if (messageObj.isReply) {
                    var parentSender = window.MobilockChatClient.chatManagerObj.getUserDisplayName(messageObj.parentSenderUuid);
                    var parentMessage = this.chatUiManagerObj.formatMessageForUi(chatObj.decryptMessage(messageObj.parentMessage, messageObj.iv), messageObj.isBroadcast);
                    var parentSentAt  = this.chatUiManagerObj.formatMsgFullDayTime(messageObj.parentSentAt);
                }

                // if(this.chatMetaData.selfUUID == from ) {
                //     if(position == 'after') {
                //         container.after(this.selfImageMessageTemplate({
                //             msg_uuid: '',
                //             msg_id: messageObj.messageId,
                //             msg_status: chatObj.messageStatus(messageObj),
                //             msg_time: msgTimeUi,
                //             msg_file_id: messageObj.fileId,
                //             chat_msg: decryptedMessage,
                //             forwarded: messageObj.forwarded,
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             delete_enabled: deleteEnabled ? "" : 'hidden',
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             parent_message: parentMessage,
                //             info_enabled: !(messageObj.isReply && messageObj.isBroadcast) ? "" : 'hidden',
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));
                //     } else {
                //         container.append(this.selfImageMessageTemplate({
                //             msg_uuid: messageObj.messageUuid,
                //             msg_id: messageObj.messageId,
                //             msg_status: chatObj.messageStatus(messageObj),
                //             msg_time: msgTimeUi,
                //             msg_file_id: messageObj.fileId,
                //             chat_msg: decryptedMessage,
                //             forwarded: messageObj.forwarded,
                //             info_enabled: !(messageObj.isReply && messageObj.isBroadcast) ? "" : 'hidden',
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             delete_enabled: deleteEnabled ? "" : 'hidden',
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             parent_message: parentMessage,
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));
                //     }
                // } else {
                //     if(position == 'after') {
                //         container.after(this.fromImageMessageTemplate({
                //             msg_from: sender,
                //             msg_uuid: '',
                //             msg_id: messageObj.messageId,
                //             msg_time: msgTimeUi,
                //             chat_msg: decryptedMessage,
                //             forwarded: messageObj.forwarded,
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             parent_message: parentMessage,
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));

                //     } else {
                //         container.append(this.fromImageMessageTemplate({
                //             msg_from: sender,
                //             msg_uuid: messageObj.messageUuid,
                //             msg_id: messageObj.messageId,
                //             msg_time: msgTimeUi,
                //             chat_msg: decryptedMessage,
                //             forwarded: messageObj.forwarded,
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             parent_message: parentMessage,
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));
                //     }
                // }

                if (messageObj.file) {
                    var _this = this;
                    setTimeout(function() {
                        _this.assignImageURL(messageObj, messageObj.file, chatObj.withUuid);
                    }, 1);
                } else {
                    this.downloadImage(messageObj, chatObj);
                }
                container.find('.dropdown-toggle').dropdown();
            }

            showFileMessage(messageObj, fromUuid, container, msgTimeUi, chatObj, sender, position, deleteEnabled, replyEnabled){
                
                var from = fromUuid || messageObj.fromUuid;

                var fileSize = messageObj.size || messageObj.chatMessageExtraInfo.fileSize;
                var originalFileName = messageObj.originalFileName || messageObj.chatMessageExtraInfo.originalFileName;
                originalFileName = window.htmlEncode(originalFileName);
                if (messageObj.isReply) {
                    var parentSender = window.MobilockChatClient.chatManagerObj.getUserDisplayName(messageObj.parentSenderUuid);
                    var parentMessage = this.chatUiManagerObj.formatMessageForUi(chatObj.decryptMessage(messageObj.parentMessage, messageObj.iv), messageObj.isBroadcast);
                    var parentSentAt  = this.chatUiManagerObj.formatMsgFullDayTime(messageObj.parentSentAt);
                }

                // if(this.chatMetaData.selfUUID == from ) {
                //     if(position == 'after') {
                //         container.after(this.selfFileMessageTemplate({
                //             msg_uuid: '',
                //             msg_id: messageObj.messageId,
                //             msg_status: chatObj.messageStatus(messageObj),
                //             msg_time: msgTimeUi,
                //             msg_file_id: messageObj.fileId,
                //             original_name: originalFileName,
                //             size: this.chatUiManagerObj.formatBytes(fileSize,1),
                //             type: getExtensionFromName(originalFileName),
                //             forwarded: messageObj.forwarded,
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             delete_enabled: deleteEnabled ? "" : 'hidden',
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             info_enabled: !(messageObj.isReply && messageObj.isBroadcast) ? "" : 'hidden',
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             parent_message: parentMessage,
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));
                //     } else {
                //         container.append(this.selfFileMessageTemplate({
                //             msg_uuid: messageObj.messageUuid,
                //             msg_id: messageObj.messageId,
                //             msg_status: chatObj.messageStatus(messageObj),
                //             msg_time: msgTimeUi,
                //             info_enabled: !(messageObj.isReply && messageObj.isBroadcast) ? "" : 'hidden',
                //             msg_file_id: messageObj.fileId,
                //             original_name: originalFileName,
                //             size: this.chatUiManagerObj.formatBytes(fileSize,1),
                //             type: getExtensionFromName(originalFileName),
                //             forwarded: messageObj.forwarded,
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             delete_enabled: deleteEnabled ? "" : 'hidden',
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             parent_message: parentMessage,
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));
                //     }
                // } else {
                //     if(position == 'after') {
                //         container.after(this.fromFileMessageTemplate({
                //             msg_from : sender,
                //             msg_uuid: '',
                //             msg_id: messageObj.messageId,
                //             msg_time: msgTimeUi,
                //             original_name: originalFileName,
                //             size: this.chatUiManagerObj.formatBytes(fileSize,1),
                //             type: getExtensionFromName(originalFileName),
                //             forwarded: messageObj.forwarded,
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             parent_message: parentMessage,
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));

                //     } else {
                //         container.append(this.fromFileMessageTemplate({
                //             msg_from : sender,
                //             msg_uuid: messageObj.messageUuid,
                //             msg_id: messageObj.messageId,
                //             msg_time: msgTimeUi,
                //             original_name: originalFileName,
                //             size: this.chatUiManagerObj.formatBytes(fileSize,1),
                //             type: getExtensionFromName(originalFileName),
                //             forwarded: messageObj.forwarded,
                //             broadcast_bubble: messageObj.isBroadcast ? "broadcast-bubble" : "",
                //             is_reply: messageObj.isReply ? "reply-to-text" : "",
                //             reply_enabled: replyEnabled ? "" : 'hidden',
                //             parent_message: parentMessage,
                //             parent_message_sender: parentSender,
                //             parent_sent_at: parentSentAt,
                //         }));
                //     }
                // }

                if (messageObj.file) {
                    var messageContainer;
                    if(messageObj.messageId) {
                        messageContainer = container.find('.chat-msg-e[data-msg-id="' + messageObj.messageId + '"]');
                    } else {
                        messageContainer = container.find('.chat-msg-e[data-msg-uuid="' + messageObj.messageUuid + '"]');
                    }
                    //STORE: comented
                    // var downloadLink = messageContainer.find('a.download-link')[0];
                    // downloadLink.href = URL.createObjectURL(messageObj.file);
                    // messageContainer.find('.message-action-btn').removeClass('download-file').addClass('sending');
                }
                container.find('.dropdown-toggle').dropdown();

                function getExtensionFromName(name) {
                    return name.split(".").pop();
                }
            }

            async downloadImage (message, chatObj) {
                let response = await window.EncryptionUtils.downloadFileAsBlob(message.chatMessageExtraInfo.downloadUrl);
                if (response.error) {
                    this.chatUiManagerObj.errorMessage(response.errorMessage,'danger');
                    // this.showDownload(message, chatObj.withUuid);
                    return;
                }
                let fileReader = new FileReader();
                var _this = this
                fileReader.onload = async function(e) {
                    try {
                        let decrypted = await window.EncryptionUtils.decryptFileContent(e.target.result, 
                            chatObj.encryptionKey, 
                            message.iv, 
                            message.chatMessageExtraInfo.downloadUrl, 
                            response.type);
                        _this.assignImageURL(message, decrypted, chatObj.withUuid);
                    } catch(e) {
                        console.log("exception", e.message);
                        return;
                    }
                }
                fileReader.readAsArrayBuffer(response.file);
            }

            async downloadFile(message, chatObj) {
                let response = await window.EncryptionUtils.downloadFileAsBlob(message.chatMessageExtraInfo.downloadUrl);
                if (response.error) {
                    this.chatUiManagerObj.errorMessage(response.errorMessage,'danger');
                    // this.showFileDownload(message, chatObj.withUuid);
                    return;
                }
                let fileReader = new FileReader();
                var _this = this
                fileReader.onload = async function(e) {
                    try {
                        let decrypted = await window.EncryptionUtils.decryptFileContent(e.target.result,
                            chatObj.encryptionKey,
                            message.iv,
                            message.chatMessageExtraInfo.downloadUrl,
                            response.type);
                        _this.assignFileURL(message, decrypted, chatObj.withUuid);
                                                                
                    } catch(e) {
                        console.log("exception", e.message);
                        return;
                    }                                 
                }
                fileReader.readAsArrayBuffer(response.file);    
            }

            showFileDownload(messageObj, withUuid) {
                var container = window.$('#all_chats .chat[data-chat="' + withUuid + '"]');
                var messageContainer;
                // if(messageObj.messageId) {
                //     messageContainer = container.find('.chat-msg-e[data-msg-id="' + messageObj.messageId + '"]');
                // } else {
                //     messageContainer = container.find('.chat-msg-e[data-msg-uuid="' + messageObj.messageUuid + '"]');
                // }
                // messageContainer.find('.message-action-btn').addClass('download-file').removeClass('sending');
            }

            showDownload(messageObj, withUuid) {
                // var container = window.$('#all_chats .chat[data-chat="' + withUuid + '"]');
                // var messageContainer;
                // if(messageObj.messageId) {
                //     messageContainer = container.find('.chat-msg-e[data-msg-id="' + messageObj.messageId + '"]');
                // } else {
                //     messageContainer = container.find('.chat-msg-e[data-msg-uuid="' + messageObj.messageUuid + '"]');
                // }
                // messageContainer.find('.placeholder').hide();
                // messageContainer.find('.download').show();
            }

            assignImageURL(messageObj, file, withUuid) {
                
                // var container = window.$('#all_chats .chat[data-chat="' + withUuid + '"]');
                // var messageContainer;
                // if(messageObj.messageId) {
                //     messageContainer = container.find('.chat-msg-e[data-msg-id="' + messageObj.messageId + '"]');
                // } else {
                //     messageContainer = container.find('.chat-msg-e[data-msg-uuid="' + messageObj.messageUuid + '"]');
                // }
                // var img = messageContainer.find('img.placeholder');
                // var url = URL.createObjectURL(file);
                // img.parent().removeClass('placeholder-img');
                // img.addClass('preview');
                // img.attr('src', url);
                // img.show();
                // window.$(img).parent().attr('href', url);
            }

            assignFileURL(messageObj, file, withUuid) {
                // var container = window.$('#all_chats .chat[data-chat="' + withUuid + '"]');
                // var messageContainer;
                // if(messageObj.messageId) {
                //     messageContainer = container.find('.chat-msg-e[data-msg-id="' + messageObj.messageId + '"]');
                // } else {
                //     messageContainer = container.find('.chat-msg-e[data-msg-uuid="' + messageObj.messageUuid + '"]');
                // }
                // var downloadLink = messageContainer.find('a.download-link')[0];
                // downloadLink.href = URL.createObjectURL(file);
                // setTimeout(function() {
                //     downloadLink.click();
                // }, 10);
                // messageContainer.find('.message-action-btn').removeClass('sending').addClass('download-file');
            }
}

export default AttachmentManager
