export const getContacts = async (axiosInstance, params) => await axiosInstance.get(`ot/api/v1/contacts.json?${params}`)
export const getContactDetails = async (axiosInstance, params) => await axiosInstance.get(`ot/api/v1/contacts/details.json?${params}`)
export const getChannels = async (axiosInstance, params) => await axiosInstance.get(`ot/api/v1/im-channels.json?${params}`)
export const getChannelDetails = async (axiosInstance, params) => await axiosInstance.get(`ot/api/v1/im-channels/details.json?${params}`)
export const validateContact = async (axiosInstance, params) => await axiosInstance.get(`/ot/api/v1/contacts/validate_contact?${params}`)

export default {
    getContacts,
    getContactDetails,
    getChannels,
    getChannelDetails,
    validateContact
}