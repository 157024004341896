import React, { useState, useEffect } from 'react'
/** This hook is to handle api calls to be called only once when comonent mounts
 * to avaid calling apis when axios instance updates ie token refreshed
 */
const useComponentMountedHook = (axiosInstance) => {
    const [isComponentMounted, setIsComponentMounted] = useState(false)

    useEffect(() => {
        if (!isComponentMounted) setIsComponentMounted(true)
    }, [axiosInstance])
    return { isComponentMounted }
}

export default useComponentMountedHook