import React from 'react'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
/**
 * Stores current page/path name into state
 * @param {*} location 
 * @param {Function} setActiveLink - stores currently active link in state
 */

const SetHistory = ({ setActiveLink, location }) => {
  useEffect(() => {
    // We can also access state passed with route here.
    setActiveLink(location.pathname)
  }, [location.key])

  return null
}

SetHistory.propTypes = { setActiveLink: PropTypes.func.isRequired }

export default withRouter(SetHistory)