import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    bodyClassName: {
        '& button': {
            alignSelf: 'center'
        },
    },

    progressClassName: {
        background: theme.palette.primary.main
    },

    body: {
        padding: 0,
        display: 'flex',
        margin: 0,
    },

    toastBody: {
        background: '#2196f3 !important',
        color: '#fff',
        borderRadius: 3,
        padding: 0,
        paddingRight: 10
    },
    
}));


const ToastContainerWrapper = (params) => {
    const classes = useStyles()
    return <ToastContainer hideProgressBar bodyClassName={classes.body} toastClassName={classes.toastBody} className={classes.bodyClassName} progressClassName={classes.progressClassName} />
        
}

export default ToastContainerWrapper
