import React, { memo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { hideCallScreen } from '../containers/ChatUI/actions'
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const CallScreen = (props) => {
    const { selectedChatOwnerInfo, isCallScreenVisible, hideCallScreen } = props

    return (
        <div>
            <Dialog open={isCallScreenVisible} onClose={hideCallScreen} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
                <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                    Subscribe
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates occasionally.{selectedChatOwnerInfo.email}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={hideCallScreen} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={hideCallScreen} color="primary">
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = function(state) {
    return {
        selectedChatOwnerInfo: state.reducerChat.selectedChatOwnerInfo,
        isCallScreenVisible: state.reducerChatUI.isCallScreenVisible,
    }
}

const mapDispatchToProps = {
    hideCallScreen
}

function areEqual(prevProps, nextProps) {
    return prevProps.isCallScreenVisible === nextProps.isCallScreenVisible
} 

export default connect(mapStateToProps, mapDispatchToProps)(memo(CallScreen, areEqual));
