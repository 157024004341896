import * as types from './types';

const initialState = {
    isChatHistoryLoading: false,
    pttConnection: { connected: false, pttSending: false, pttReceiving: false, message: "" },
    isImageAttachmentDownloading: false,
    isScheduleViererOpen: false, // weather schedule message view is open
    isMessagePreviewVisible: false,
    isMessageInfoOpen: false,  // weather message info view is open or not
    isChatInfoSelected: false,  // weather chat info option is selected
    selectedTabIndex: 0,
    isQuotingmessage: false,
    isMessageRepliesOpen: false,
    globalUnreadMessageCount: 0,
    isBroadcastReplyEnabled: false,
    confirmDeleteMessage: false,
    callStatus: "IDLE", // call status
    isCallScreenVisible: false,
    noActiveChatListPresent: true,
    playingId: 0 // currently playing message id
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.START_LOADER:
            return {
                ...state,
                isChatHistoryLoading: true
            }

        case types.STOP_LOADER:
            return {
                ...state,
                isChatHistoryLoading: false
            }

        case types.START_IMAGE_ASSET_DOWNLOADING:
            return {
                ...state,
                isImageAttachmentDownloading: true
            }
        case types.STOP_IMAGE_ASSET_DOWNLOADING:
            return {
                ...state,
                isImageAttachmentDownloading: false
            }
        case types.SET_SCHEDULED_MESSAGE_LIST_OPEN:
            return {
                ...state,
                isScheduleViererOpen: true
            }
        case types.CLOSE_SCHEDULED_MESSAGE_LIST:
            return {
                ...state,
                isScheduleViererOpen: false
            }
        case types.SHOW_MESSAGE_PREVIEW:
            return {
                ...state,
                isMessagePreviewVisible: true
            }
        case types.HIDE_MESSAGE_PREVIEW:
            return {
                ...state,
                isMessagePreviewVisible: false
            }
        case types.SHOW_MESSAGE_INFO:
            return {
                ...state,
                isMessageInfoOpen: true,
                isChatInfoSelected: false,
                isMessageRepliesOpen: false
            };
        case types.HIDE_MESSAGE_INFO:
            return {
                ...state,
                isMessageInfoOpen: false,
                isChatInfoSelected: false,
                isMessageRepliesOpen: false
            }
        case types.SHOW_MESSAGE_REPLIES:
            return {
                ...state,
                isMessageRepliesOpen: true,
                isChatInfoSelected: false,
                isMessageInfoOpen: false
            };
        case types.HIDE_MESSAGE_REPLIES:
            return {
                ...state,
                isMessageRepliesOpen: false,
                isChatInfoSelected: false,
                isMessageInfoOpen: false
            }
        case types.SHOW_GROUP_INFO_DRAWER:
            return {
                ...state,
                isChatInfoSelected: true,
                isMessageInfoOpen: false,
                isMessageRepliesOpen: false
            }
        case types.CLOSE_DRAWER:
            return {
                ...state,
                isMessageRepliesOpen: false,
                isChatInfoSelected: false,
                isMessageInfoOpen: false
            }
        case types.HIDE_GROUP_INFO_DRAWER:
            return {
                ...state,
                isMessageRepliesOpen: false,
                isChatInfoSelected: false,
                isMessageInfoOpen: false
            }
        case types.SET_SIDEBAR_TAB_INDEX:
            return {
                ...state,
                selectedTabIndex: action.index
            };
        case types.SET_IS_MESSAGE_QUOTED:
            return {
                ...state,
                isQuotingmessage: true
            };
        case types.CANCEL_QUOTE_MESSAGE:
            return {
                ...state,
                isQuotingmessage: false
            };
        case types.SET_GLOBAL_UNREAD_MESSAGE_COUNT:
            return {
                ...state,
                globalUnreadMessageCount: action.payload.count
            };
        case types.ENABLE_BROADCAST_REPLY:
            return {
                ...state,
                isBroadcastReplyEnabled: true
            };
        case types.DISABLE_BROADCAST_REPLY:
            return {
                ...state,
                isBroadcastReplyEnabled: false
            };
        case types.SET_CONFIRM_DELETE_MESSAGE:
            return {
                ...state,
                confirmDeleteMessage: true
            };
        case types.DISMISS_CONFIRM_DELETE_MESSAGE:
            return {
                ...state,
                confirmDeleteMessage: false
            };

        case types.SET_CALL_STATUS:
            return {
                ...state,
                callStatus: action.payload
            };

        case types.SET_PTT_CONNECTION:
            return {
                ...state,
                pttConnection: action.payload
            };

        case types.SHOW_CALL_SCREEN:
            return {
                ...state,
                isCallScreenVisible: true
            };

        case types.HIDE_CALL_SCREEN:
            return {
                ...state,
                isCallScreenVisible: false
            };
        case types.SET_ACTIVE_CHAT_LIST_PRESENT:
            return {
                ...state,
                noActiveChatListPresent: action.payload
            }
        case types.SET_PLAYING_ID:
            return {
                ...state,
                playingId: action.payload
            }

        // case types.INCREAMENT_COUNTER:
        //     return {
        //         ...state,
        //         counter: state.counter + 1
        //     }

        // case types.INCREMENT_PRODUCT_QUANTITY:
        //     return {
        //         ...state,
        //         cartItemsCount: state.cartItemsCount + 1,
        //         totalPrice: state.totalPrice + action.price,
        //         products: state.products.map(product => {
        //             const newProduct = {...product}
        //             if (newProduct.id === action.id) {
        //                 newProduct.quantity++;
        //                 return newProduct;
        //             }
        //             return product;
        //         })
        //     }

        // case types.DECREMENT_PRODUCT_QUANTITY:
        //     return {
        //         ...state,
        //         cartItemsCount: state.cartItemsCount - 1,
        //         totalPrice: state.totalPrice - action.price,
        //         products: state.products.map(product => {
        //             if (product.id === action.id) {
        //                 product.quantity--; 
        //             }
        //             return product;
        //         })
        //     }

        // case types.GET_PRODUCT_DETAILS:
        //     return {
        //         ...state,
        //         detailProduct: state.products.find(product => product.id === action.id)
        //     }

        // case types.INCREMENT_PRODUCT_QUANTITY_FROM_DETAIL:
        //     return {
        //         ...state,
        //         detailProduct: { ...state.detailProduct, quantity: state.detailProduct.quantity + 1 }
        //     }

        // case types.DECREMENT_PRODUCT_QUANTITY_FROM_DETAIL:
        //     return {
        //         ...state,
        //         detailProduct: { ...state.detailProduct, quantity: state.detailProduct.quantity - 1 }
        //     }

        default:
            return state;
    }
}

export default reducer;