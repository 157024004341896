const fetchSettings = (axiosInstance) => {
	try {
		let response = axiosInstance.get('/ot/api/v1/organization_settings')
		return response;
	} catch (error) {
		return error;
	}
};

const updateSettings = (axiosInstance, settings) => {
	try {
		let response = axiosInstance.put(`/ot/api/v1/organization_settings`, settings)
		return response;
	} catch (error) {
		return error;
	}
};

const getEntitlements = (axiosInstance, settings) => {
	try {
		let response = axiosInstance.get(`/api/v1/org-entitlements`)
		return response;
	} catch (error) {
		return error;
	}
};

const fetchLocationSettings = (axiosInstance) => {
    try {
        let response = axiosInstance.get('/ot/api/v1/location_settings.json')
        return response;
    } catch (error) {
        return error;
    }
};

const updateLocationSettings = (axiosInstance, settings) => {
    try {
        let response = axiosInstance.post(`/ot/api/v1/location_settings.json`, settings)
        return response;
    } catch (error) {
        return error;
    }
};

const fetchAllSettings = (axiosInstance) => {
	try {
		let response = axiosInstance.get('/ot/api/v1/settings.json')
		return response;
	} catch (error) {
		return error;
	}
};

export default {
	fetchSettings,
	updateSettings,
	getEntitlements,
	fetchLocationSettings,
	updateLocationSettings,
	fetchAllSettings
}
