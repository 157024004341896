/**
 * Proto Command Builder object, this contains all the information that is
 * needed for the proto buff.
 */
class ProtoCommandBuilder {
    constructor() {
        this.protoBufferRoot = null;
        this.basePacket = null;
    }

    init(protoUrl, callback) {
        var _this = this;
        window.protobuf.load(protoUrl, function (err, root) {
            console.log("proto loaded: err: ", err);
            //FIXME: app dies with here
            if (root) {
                _this.protoBufferRoot = root;
                _this.basePacket = root.lookup("eva.BasePacket");
                if (typeof callback === "function") {
                    callback();
                }
            }
        });
    }

    getPendingMessageRequest(lastMessageId) {
        var pendingMessagesRequestProto = this.protoBufferRoot.lookup("eva.PendingMessagesRequest");
        var pendingMessagesRequestCommand = this.basePacket.create({
            pendingMessagesRequest: pendingMessagesRequestProto.create({
                sinceMessageId: lastMessageId,
            }),
        });
        return this.basePacket.encode(pendingMessagesRequestCommand).finish();
    }

    getEvaUserRequest(idToken) {
        var evaUserRequestProto = this.protoBufferRoot.lookup("eva.EvaUserRequest");
        var evaUserRequestCommand = this.basePacket.create({
            evaUserRequest: evaUserRequestProto.create({ idToken: idToken }),
        });
        return this.basePacket.encode(evaUserRequestCommand).finish();
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    getCallUuid() {
        var uuid = this.guid();
        return uuid;
    }

    getInitCommand(chatMetaData) {
        if (this.protoBufferRoot != null) {
            var initProto = this.protoBufferRoot.lookup("eva.SocketInitRequest");
            var initCommand = this.basePacket.create({
                socketInitRequest: initProto.create({
                    voipEnabled: true,
                    pubKey: chatMetaData.encryption.getPublicKeyB64(),
                    instanceId: "dashboard_" + this.getCallUuid(),
                    broadcastSupported: true,
                }),
            });
            return this.basePacket.encode(initCommand).finish();
        } else {
            console.log("protobuffer root is NULL!!!", this.protoBufferRoot);
        }
    }

    getTokenAuthRequestCommand(chatMetaData) {
        if (this.protoBufferRoot != null) {
            var tokenAuthProto = this.protoBufferRoot.lookup("eva.TokenAuthRequest");
            var tokenAuthCommand = this.basePacket.create({
                tokenAuthRequest: tokenAuthProto.create({
                    accessToken: chatMetaData.accessToken,
                }),
            });
            return this.basePacket.encode(tokenAuthCommand).finish();
        } else {
            console.log("protobuffer root is NULL!!!", this.protoBufferRoot);
        }
    }

    getChatHistoryCommand(withUUID, sinceMessageId) {
        var chatHistoryProto = this.protoBufferRoot.lookup("eva.ChatHistoryRequest");
        var chatHistoryCommand = this.basePacket.create({
            chatHistoryRequest: chatHistoryProto.create({
                withUuid: withUUID,
                sinceMessageId: sinceMessageId,
            }),
        });
        return this.basePacket.encode(chatHistoryCommand).finish();
    }

    getChatMessageCommand(encryptedMessage, encryptedParentMessage, messageObj, iv, parentMessageIv) {
        var chatMessageProto = this.protoBufferRoot.lookup("eva.ChatMessage");
        var msgObj = {
            message: encryptedMessage,
            toUuid: messageObj.toUuid,
            messageUuid: messageObj.messageUuid,
            iv: iv,
            isBroadcast: messageObj.isBroadcast,
            replyAllowed: messageObj.replyAllowed,
        };

        if (messageObj.isReply) {
            msgObj.isReply = messageObj.isReply;
            msgObj.parentMessageId = messageObj.parentMessageId;
            msgObj.parentMessageType = messageObj.parentMessageType;
            msgObj.parentMessage = encryptedParentMessage;
            msgObj.parentSenderUuid = messageObj.parentSenderUuid;
            msgObj.parentSentAt = messageObj.parentSentAt;
            msgObj.parentMessageIv = parentMessageIv;
        }
        var chatMessageCommand = this.basePacket.create({
            chatMessage: chatMessageProto.create(msgObj),
        });

        return this.basePacket.encode(chatMessageCommand).finish();
    }


    getSOSTextMessageCommand(encryptedMessage, encryptedParentMessage, messageObj, iv, parentMessageIv) {
        var chatMessageProto = this.protoBufferRoot.lookup("eva.ChatMessage");
        var msgObj = {
            message: encryptedMessage,
            toUuid: messageObj.toUuid,
            messageUuid: messageObj.messageUuid,
            iv: iv,
            isBroadcast: messageObj.isBroadcast,
            replyAllowed: messageObj.replyAllowed,
            groupWithMessageUuid: messageObj.groupWithMessageUuid
        };

        if (messageObj.isReply) {
            msgObj.isReply = messageObj.isReply;
            msgObj.parentMessageId = messageObj.parentMessageId;
            msgObj.parentMessageType = messageObj.parentMessageType;
            msgObj.parentMessage = encryptedParentMessage;
            msgObj.parentSenderUuid = messageObj.parentSenderUuid;
            msgObj.parentSentAt = messageObj.parentSentAt;
            msgObj.parentMessageIv = parentMessageIv;
        }
        var chatMessageCommand = this.basePacket.create({
            chatMessage: chatMessageProto.create(msgObj),
        });

        console.log('getChatMessageCommand msgObj', msgObj)
        return this.basePacket.encode(chatMessageCommand).finish();
    }


    getSOSChatMessageCommand(encryptedMessage, encryptedParentMessage, messageObj, iv, parentMessageIv) {
        var chatMessageProto = this.protoBufferRoot.lookup("eva.ChatMessage");
        var msgObj = {
            toUuid: messageObj.toUuid,
            messageUuid: messageObj.messageUuid,
            messageType: messageObj.messageType
        }


        if (messageObj.isReply) {
            msgObj.isReply = messageObj.isReply;
            msgObj.parentMessageId = messageObj.parentMessageId;
            msgObj.parentMessageType = messageObj.parentMessageType;
            msgObj.parentSenderUuid = messageObj.parentSenderUuid;
            msgObj.parentSentAt = messageObj.parentSentAt;
            msgObj.parentMessageIv = parentMessageIv;
        }
        console.log('getSOSChatMessageCommand msgObj', msgObj)
        var chatMessageCommand = this.basePacket.create({
            chatMessage: chatMessageProto.create(msgObj),
        });

        return this.basePacket.encode(chatMessageCommand).finish();
    }

    getFileUploadMessageCommand(fileObj) {
        var fileUploadMessageProto = this.protoBufferRoot.lookup("eva.FileUpload");
        var formattedFileObj = {
            fileUuid: fileObj.messageUuid,
            fileName: fileObj.encryptedFile.name,
            size: fileObj.size,
            conversationId: fileObj.conversationId,
            status: fileObj.status,
            type: fileObj.messageType,
            fileId: fileObj.fileId,
            iv: fileObj.iv,
            toUuid: fileObj.toUuid,
            contentType: fileObj.file.type,
            caption: fileObj.message,
            originalFileName: fileObj.originalFileName,
            isBroadcast: fileObj.isBroadcast,
            replyAllowed: fileObj.replyAllowed,
        };

        if (fileObj.isReply) {
            formattedFileObj.isReply = fileObj.isReply;
            formattedFileObj.parentMessageId = fileObj.parentMessageId;
            formattedFileObj.parentMessageType = fileObj.parentMessageType;
            formattedFileObj.parentMessage = fileObj.parentMessage;
            formattedFileObj.parentSenderUuid = fileObj.parentSenderUuid;
            formattedFileObj.parentMessageIv = fileObj.parentMessageIv;
            formattedFileObj.parentSentAt = fileObj.parentSentAt;
        }

        var fileUploadCommand = this.basePacket.create({
            fileUpload: fileUploadMessageProto.create(formattedFileObj),
        });
        return this.basePacket.encode(fileUploadCommand).finish();
    }

    getChatMessageStatusCommand(conversationId, messageId, delivered, read, open) {
        var chatMessageStatusProto = this.protoBufferRoot.lookup("eva.MessageStatus");
        var chatMessageStatusCommand = this.basePacket.create({
            messageStatus: chatMessageStatusProto.create({
                conversationId: conversationId,
                messageId: messageId,
                delivered: delivered,
                read: read,
                open: open,
            }),
        });
        return this.basePacket.encode(chatMessageStatusCommand).finish();
    }

    getChatMessageDeleteCommand(conversationId, messageId, toUuid, msgFileId) {
        var deleteMessageProto = this.protoBufferRoot.lookup("eva.DeleteMessageRequest");
        var deleteMessageCommand = this.basePacket.create({
            deleteMessageRequest: deleteMessageProto.create({
                conversationId: conversationId,
                messageId: messageId,
                toUuid: toUuid,
                fileId: msgFileId,
            }),
        });
        return this.basePacket.encode(deleteMessageCommand).finish();
    }

    getChatMessageEditCommand(conversationId, messageId, toUuid, msg, iv) {
        var editMessageProto = this.protoBufferRoot.lookup("eva.EditMessageRequest");
        var editMessageCommand = this.basePacket.create({
            editMessageRequest: editMessageProto.create({
                conversationId: conversationId,
                messageId: messageId,
                toUuid: toUuid,
                message: msg,
                iv: iv,
            }),
        });
        return this.basePacket.encode(editMessageCommand).finish();
    }

    getConversationsCommand(token) {
        var getConversationsProto = this.protoBufferRoot.lookup("eva.GetConversationsRequest");
        var getConversationsCommand = this.basePacket.create({
            conversationsRequest: getConversationsProto.create({
                token: token,
            }),
        });

        return this.basePacket.encode(getConversationsCommand).finish();
    }

    getContactListRequestCommand(page, q) {
        var getContactListRequestProto = this.protoBufferRoot.lookup("eva.ContactListRequest");
        var contactListRequestCommand = this.basePacket.create({
            contactListRequest: getContactListRequestProto.create({
                page: page,
                q: q,
            }),
        });

        return this.basePacket.encode(contactListRequestCommand).finish();
    }

    getGroupListRequestCommand(page, q) {
        var getGroupListRequestProto = this.protoBufferRoot.lookup("eva.GroupListRequest");
        var groupListRequestCommand = this.basePacket.create({
            groupListRequest: getGroupListRequestProto.create({
                page: page,
                q: q,
            }),
        });

        return this.basePacket.encode(groupListRequestCommand).finish();
    }

    getGroupInfoRequestCommand(uuid) {
        var getGroupInfoRequestProto = this.protoBufferRoot.lookup("eva.GroupInfoRequest");
        var groupInfoRequestCommand = this.basePacket.create({
            groupInfoRequest: getGroupInfoRequestProto.create({ uuid: uuid }),
        });

        return this.basePacket.encode(groupInfoRequestCommand).finish();
    }

    getChatMessageInfoRequestCommand(messageUuid) {
        var getChatMessageInfoRequestProto = this.protoBufferRoot.lookup("eva.ChatMessageInfoRequest");
        var chatMessageInfoRequestCommand = this.basePacket.create({
            chatMessageInfoRequest: getChatMessageInfoRequestProto.create({ messageUuid: messageUuid }),
        });

        return this.basePacket.encode(chatMessageInfoRequestCommand).finish();
    }

    getGroupSubscriptionCommand(groupUuid, action) {
        var subscriptionGroupProto = this.protoBufferRoot.lookup("eva.GroupSubscriptionRequest");
        var subscriptionGroupProtoCommand = this.basePacket.create({
            groupSubscriptionRequest: subscriptionGroupProto.create({
                groupUuid: groupUuid,
                action: action,
            }),
        });
        return this.basePacket.encode(subscriptionGroupProtoCommand).finish();
    }

    getDeviceDetailsRequest(withUuid) {
        var actionMessageProto = this.protoBufferRoot.lookup("eva.ActionMessage");
        var type = actionMessageProto.lookup("Type");
        var action = actionMessageProto.lookup("Action");

        var deviceDetailsRequestCommand = this.basePacket.create({
            actionMessage: actionMessageProto.create({
                type: type.values.REQUEST,
                action: action.values.DEVICE_INFO,
                withUuid: withUuid,
            }),
        });

        return this.basePacket.encode(deviceDetailsRequestCommand).finish();
    }

    getMarkConversationReadRequest(conversationId) {
        var markConversationReadRequestProto = this.protoBufferRoot.lookup("eva.MarkConversationReadRequest");

        var markConversationReadRequestCommand = this.basePacket.create({
            markConversationReadRequest: markConversationReadRequestProto.create({ conversationId: conversationId }),
        });

        return this.basePacket.encode(markConversationReadRequestCommand).finish();
    }

    decodeProto(binaryData, callback) {
        var _this = this;
        var fileReader = new FileReader();
        fileReader.onload = function (progressEvent) {
            var uint8ArrayNew = new Uint8Array(this.result);
            var decodedMessage = _this.basePacket.decode(uint8ArrayNew);
            callback(decodedMessage);
        };
        fileReader.readAsArrayBuffer(binaryData);
    }

    getCallInitiateCommand(toUuid, callId, callType) {
        var callInfoIncomingProto = this.protoBufferRoot.lookup("eva.CallIncoming");
        var callInitiateCommand = this.basePacket.create({
            callInfoIncoming: callInfoIncomingProto.create({
                toUuid: toUuid,
                callId: callId,
                type: callType
            }),
        });
        return this.basePacket.encode(callInitiateCommand).finish();
    }

    getUpdateCallStatusCommand(callId, callStatus) {
        var callUpdateRequestProto = this.protoBufferRoot.lookup("eva.CallUpdateRequest");
        var callUpdateRequestCommand = this.basePacket.create({
            callInfoUpdated: callUpdateRequestProto.create({
                callId: callId,
                callStatus: callStatus,
            }),
        });
        return this.basePacket.encode(callUpdateRequestCommand).finish();
    }

    getDataCallOfferCommand(callId, webRtcOffer) {
        var dataCallParametersProto = this.protoBufferRoot.lookup("eva.DataCallParameters");
        var webRtcOfferProto = this.protoBufferRoot.lookup("eva.WebRtcOffer");
        var dataCallParametersCommand = this.basePacket.create({
            dataCallParameters: dataCallParametersProto.create({
                offer: webRtcOfferProto.create({
                    type: webRtcOffer.type,
                    sdp: webRtcOffer.sdp,
                }),
                callId: callId,
            }),
        });

        return this.basePacket.encode(dataCallParametersCommand).finish();
    }

    getDataCallAnswerCommand(callId, webRtcAnswer) {
        var dataCallParametersProto = this.protoBufferRoot.lookup("eva.DataCallParameters");
        var webRtcAnswerProto = this.protoBufferRoot.lookup("eva.WebRtcAnswer");
        var dataCallParametersCommand = this.basePacket.create({
            dataCallParameters: dataCallParametersProto.create({
                answer: webRtcAnswerProto.create({
                    type: webRtcAnswer.type,
                    sdp: webRtcAnswer.sdp,
                }),
                callId: callId,
            }),
        });

        return this.basePacket.encode(dataCallParametersCommand).finish();
    }

    getCandidateCommand(callId, candidate) {
        console.log("webRtcCandidate", candidate);
        var dataCallParametersProto = this.protoBufferRoot.lookup("eva.DataCallParameters");
        var webRtcCandidateProto = this.protoBufferRoot.lookup("eva.WebRtcCandidate");
        var candidateCommand = this.basePacket.create({
            dataCallParameters: dataCallParametersProto.create({
                callId: callId,
                candidate: webRtcCandidateProto.create({
                    sdpMLineIndex: candidate.sdpMLineIndex,
                    sdpMid: candidate.sdpMid,
                    candidate: candidate.candidate,
                }),
            }),
        });
        return this.basePacket.encode(candidateCommand).finish();
    }

    getUpdateCallFeedbackCommand(callId, callStatus, rating) {
        var callUpdateRequestProto = this.protoBufferRoot.lookup("eva.CallUpdateRequest");
        var callUpdateRequestCommand = this.basePacket.create({
            callInfoUpdated: callUpdateRequestProto.create({
                callId: callId,
                callStatus: callStatus,
                rating: rating,
            }),
        });
        return this.basePacket.encode(callUpdateRequestCommand).finish();
    }

    getDeleteMessageCommand(messageId) { }

    getNewConversationRequestCommand(toUuid) {
        var newConversationRequestProto = this.protoBufferRoot.lookup("eva.NewConversationRequest");
        var newConversationRequestCommand = this.basePacket.create({
            newConversationRequest: newConversationRequestProto.create({
                toUuid: toUuid,
            }),
        });
        return this.basePacket.encode(newConversationRequestCommand).finish();
    }

    getConversationInfo(conversationId) {
        var conversationInfoRequestProto = this.protoBufferRoot.lookup("eva.GetConversationInfoRequest");
        var conversationInfoRequestCommand = this.basePacket.create({
            conversationInfoRequest: conversationInfoRequestProto.create({
                conversationId: conversationId,
            }),
        });
        return this.basePacket.encode(conversationInfoRequestCommand).finish();
    }

    getScheduleMessageCommand(toUuid, uuid, message, iv, startAt, endAt, frequency, replyAllowed) {
        var scheduleMessageProto = this.protoBufferRoot.lookup("eva.ScheduleMessage");
        var newScheduleMessageRequestProto = this.protoBufferRoot.lookup("eva.NewScheduleMessageRequest");
        var newScheduleMessageRequestCommand = this.basePacket.create({
            newScheduleMessageRequest: newScheduleMessageRequestProto.create({
                scheduleMessage: scheduleMessageProto.create({
                    uuid: uuid,
                    toUuid: toUuid,
                    message: message,
                    iv: iv,
                    scheduleStartAt: startAt,
                    scheduleEndAt: endAt,
                    frequency: frequency,
                    messageType: 1,
                    replyAllowed: replyAllowed,
                }),
            }),
        });
        return this.basePacket.encode(newScheduleMessageRequestCommand).finish();
    }

    getEditScheduleMessageCommand(messageUuid, message, iv, startAt, endAt, frequency) {
        var editScheduleMessageProto = this.protoBufferRoot.lookup("eva.EditScheduleMessageRequest");
        var editScheduleMessageRequestCommand = this.basePacket.create({
            editScheduleMessageRequest: editScheduleMessageProto.create({
                uuid: messageUuid,
                message: message,
                iv: iv,
                scheduleStartAt: startAt,
                scheduleEndAt: endAt,
                frequency: frequency,
                messageType: 1,
            }),
        });
        return this.basePacket.encode(editScheduleMessageRequestCommand).finish();
    }

    getCancelScheduleMessageCommand(messageUuid) {
        var cancelScheduleMessageProto = this.protoBufferRoot.lookup("eva.CancelScheduleMessageRequest");
        var cancelScheduleMessageRequestCommand = this.basePacket.create({
            cancelScheduleMessageRequest: cancelScheduleMessageProto.create({
                uuid: messageUuid,
            }),
        });
        return this.basePacket.encode(cancelScheduleMessageRequestCommand).finish();
    }

    getConversationScheduledMessagesCommand(toUuid, page) {
        var conversationScheduledMessagesProto = this.protoBufferRoot.lookup("eva.ConversationScheduledMessagesRequest");
        var conversationScheduledMessagesCommand = this.basePacket.create({
            conversationScheduledMessagesRequest: conversationScheduledMessagesProto.create({
                toUuid: toUuid,
                includeActive: true,
                includeCancelled: false,
                includeEnded: false,
                perPage: 20,
                page: page,
            }),
        });
        return this.basePacket.encode(conversationScheduledMessagesCommand).finish();
    }
}

export default ProtoCommandBuilder;
