import React, { useEffect } from 'react'
import PageContainer from '../PageContainer';
import SectionHeader from '../SectionHeader';
import { Box, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import LoadingProgress from '../Misc/LoadingProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import loadable from '@loadable/component'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import useShareToken from '../../Hooks/useShareToken';
import usePermissions from '../../Hooks/usePermissions';
import clsx from "clsx";

const Users = loadable(() => import('../Users/Users'), {
    fallback: <LoadingProgress />,
});
const Teams = loadable(() => import('../Teams/Teams'), {
    fallback: <LoadingProgress />,
});
const ContactsListManagement = loadable(() => import('../ContactsListManagement/ContactsListManagement'), {
    fallback: <LoadingProgress />,
});
const Feeds = loadable(() => import('../Feeds/FeedsPosts'), {
    fallback: <LoadingProgress />,
});

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100% - 54px)',
        marginTop: 20
    },

    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    ml20: {
        marginLeft: 20
    },

    container: {
        marginTop: 3,
        height: "100%"
    },

    activeTab: {
        background: `${theme.palette.primary.main} !important`,
        borderRadius: "4px",
        "& .MuiTab-wrapper": {
            color: `#ffffff !important`,
        }
    },

    tab: {
        "& .MuiTab-wrapper": {
            fontSize: "16px",
            fontWeight: 600,
            lineheight: "21px !important",
            color: "#161616",
            textTransform: "none",
        }
    },

    tabs: {
        overflow: "visible",
        "& .MuiTabs-fixed": {
            overflow: "visible !important",
        },
        "& .MuiTabs-flexContainer": {
            background: "#FFFFFF",
            boxShadow: " 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)",
            borderRadius: "2px",
            width: "min-content"
        }
    },

}))

const Directory = () => {
    const classes = useStyles()
    const { oidcUser, events } = useReactOidc();
    useShareToken({ oidcUser: oidcUser, events: events })

    const permissions = usePermissions()

    const [tabIndex, setTabIndex] = useState(0);

    const [isWaiting, setIsWaiting] = useState(true)

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsWaiting(false)
        }, 200);
    }, [])

    //for auto select if some tabs are no permission
    useEffect(()=>{
        if(permissions.showUsers) setTabIndex(0)
        else if(permissions.showTeams) setTabIndex(1)
        else if(permissions.showFeeds) setTabIndex(2)
        else if(permissions.showContacts) setTabIndex(3)
    },[permissions])


    return (
        <PageContainer className={classes.container}>
            {permissions.showDirectory && <Box className={classes.spaceBetween}>
                {!isWaiting ? <>
                    <SectionHeader
                        heading="Users & Teams"
                        subHeading="All utilities that you need to build your user/contact list and build teams"
                    />
                </>
                    : <Box className={classes.ml20}>
                        <Skeleton animation="wave" height={32} width={146} />
                        <Skeleton animation="wave" height={20} width={250} />
                    </Box>}
            </Box>}
            <Box className={classes.root}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                        style: {
                            display: "none",
                        },
                    }}
                    className={classes.tabs}
                    textColor="primary"
                    aria-label="icon tabs example">
                    {permissions.showUsers && <Tab label="Users" value={0} className={tabIndex === 0 ? clsx(classes.activeTab, classes.tab) : classes.tab} />}
                    {permissions.showTeams && <Tab label="Teams" value={1} className={tabIndex === 1 ? clsx(classes.activeTab, classes.tab) : classes.tab} />}
                    {permissions.showFeeds && <Tab label="Newsfeed" value={2} className={tabIndex === 2 ? clsx(classes.activeTab, classes.tab) : classes.tab} />}
                    {permissions.showContacts && <Tab label="Contact List" value={3} className={tabIndex === 3 ? clsx(classes.activeTab, classes.tab) : classes.tab} />}
                </Tabs>
                {tabIndex === 0 && <>
                    {permissions.showUsers && <Users />}
                </>}
                {tabIndex === 1 && <>
                    {permissions.showTeams && <Teams />}
                </>}
                {tabIndex === 2 && <>
                    {permissions.showFeeds && <Feeds />}
                </>}
                {tabIndex === 3 && <>
                    {permissions.showContacts && <ContactsListManagement text="This feature will come soon" />}
                </>}
            </Box>
        </PageContainer>
    )
};

export default Directory;
