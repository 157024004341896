import React, { useContext, useEffect, useState } from "react";
// import { Router } from '@reach/router'
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import oidcConfiguration from "../configuration";
import {
    AuthenticationProvider,
    oidcLog
} from "@axa-fr/react-oidc-context";
import SessionRedirect from "../components/Authentication/SessionRedirect";
import CustomCallback from "../Pages/CustomCallback";
import AppDrawer from "../components/AppDrawer";
import SwitchPortal from "../components/SwitchPortal";
// Context
import { AuthZProvider } from "../Context/AuthZ";
import RouteRedirect from "./RouteRedirect";

import { BrowserRouter as Router } from "react-router-dom";
import AccessTokenError from "../components/Authentication/AccessTokenError";
import AuthenticatingProgress from "../components/Authentication/AuthenticatingProgress";

import LocalWebStorageStore from "../components/Authentication/LocalWebStorageStore";

import { createBrowserHistory } from "history";
import { ChatManagerProvider } from "../components/Messanger/Store/ChatManagerStore";
import MainContent from "./MainContent";
const drawerWidth = 102; // sidebar width

const useStyles = makeStyles((theme) => ({
    content: {
        position: "relative",
        flexGrow: 1,
        // padding: '25px 24px 0px',
        "-webkit-box-ordinal-group": 3,
        "-ms-flex-order": 2,
        order: 2,
        '-webkit-box-flex': 1,
        '-ms-flex': 1,
        height: '100%',
        '& div[tabindex="-1"]': {
            height: "100%",
        },
        marginLeft: drawerWidth,
    },

    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    rootRouter: {
        flexGrow: 1,
    },
}));


const AppRouter = (props) => {
    
    const [accessTokenRenewError, setAccessTokenRenewError] = useState(false);
    const AccessTokenRenewError = function(data) {
        const closeEvaButton = document.getElementById('close-eva-chat');
        if(closeEvaButton) {
            closeEvaButton.click();
        }
        setAccessTokenRenewError(true);
    }

    useEffect(()=> { 
        document.addEventListener("AccessTokenRenewError", data => AccessTokenRenewError(data))

        return function cleanup() {
            document.removeEventListener('AccessTokenRenewError', AccessTokenRenewError);
        }
    }, []);

    const classes = useStyles();
    const customHistory = createBrowserHistory();

    return (
        <Router>
            <MainContent>
                <AuthenticationProvider
                    notAuthenticated={SessionRedirect}
                    notAuthorized={SessionRedirect}
                    sessionLostComponent={SessionRedirect}
                    authenticating={AuthenticatingProgress}
                    configuration={oidcConfiguration}
                    loggerLevel={oidcLog.DEBUG}
                    isEnabled={true}
                    callbackComponentOverride={CustomCallback}
                    UserStore={LocalWebStorageStore}>
                        {/* FIXME: how we can use secured component inside React's context api and start using this global context*/}
                        {/* As we want to access auth api ie logout, we need to wrap navbar inside auth component */}
                        {/* <ChatManager /> */}
                        {/* <ChatManagerProvider>
                            <AppDrawer />
                            <RouteRedirect />
                        </ChatManagerProvider> */}
                        <SwitchPortal />
                </AuthenticationProvider>
                { accessTokenRenewError && <AccessTokenError /> } 
            </MainContent>
        </Router>
    );
};


export default AppRouter;
