import React, { useRef, useEffect } from 'react'
import { toast } from 'react-toastify';
import { ChatRounded, InsertDriveFileRounded, VoicemailRounded, WallpaperRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import { Icon, Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx'
import notifymp3 from '../../assets/audio/notify.mp3'
import notifyWav from '../../assets/audio/notify.wav'
import notifyOgg from '../../assets/audio/notify.ogg'
import messageTypes from './MessegeTypes/messageTypes.json'
const useStyles = makeStyles((theme) => ({
    message: {
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400,
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        width: 241,
        lineHeight: '18px'
    },

    name: {
        fontWeight: 500
    },

    container: {
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white
        }
    },
    icon: {
        marginRight: 8
    }

}));

// const ChatToast = (props) => {
//     const notify = () => toast("Wow so easy !");
//     console.log('props', props)
//     // const { toast } = props.state
//     const { dispatch } = props
//     const classes = useStyles()

//     const showToast = (params) => {
//         notify()
//     }
    
//     const SlideTransition = (props) => <Slide {...props} direction="left" />

 

//     const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

//     const closeToast = () => {
//         dispatch({
//             type: types.SHOW_NOTIFICATION,
//             payload: {
//                 isVisible: false,
//                 message: '',
//                 variant: "info",
//                 type: 1
//             },
//         });
//     }

//     const preventCapture = (e) => {
//         e.preventDefault()
//         closeToast()
//     }

//     return { showToast }
// }

// export default ChatToast


// const messageType = {
//     TEXT: 1,
//     EDIT: 50,
//     DELETE: 51,
//     VOICE_MESSAGE: 3,
//     MEDIA_IMAGE: 4,
//     MEDIA_VIDEO: 11,
//     CALL: 40,
//     FILE: 5,

//     ADD_REMOVE_BANER_61: 61,
//     ADD_REMOVE_BANER_62: 62,
//     ADD_REMOVE_BANER_63: 63,
//     ADD_REMOVE_BANER_64: 64,
//     ADD_REMOVE_BANER_65: 65,
// }


const Msg = ({ closeToast, message, icon, name }) => {
    const classes = useStyles()
    const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
    const chatBellRef = useRef(null)
    useEffect(() => {
        chatBellRef.current.play().then(function() {
            console.log('Audio started unlocked!')
           }).catch(function(){
            console.log('Audio started locked')
           })
          
    }, [message])

    const Chatbell = () => {
        return (<>
            <audio ref={chatBellRef} >
                <source src={notifyOgg} type="audio/ogg" />
                <source src={notifymp3} type="audio/mpeg" />
                <source src={notifyWav} type="audio/wav" />
            </audio>
        </>)
    }

    return (
        <Button to="/messenger" component={AdapterLink} className={classes.container}>
            <Icon className={classes.icon}>{icon}</Icon>
            <div>
                <div className={clsx(classes.message, classes.name)}>{name}</div>
                <div className={classes.message}>{message}</div>
            </div>
            <Chatbell />
        </Button>
    )
}

const notify = (name, message, contact, messageType) => {
    const icon = getNotificationIcon(messageType)
    showToast(<Msg icon={icon} name={name} message={message} />)
}

const getNotificationIcon = (type) => {
    switch (type) {
        case messageTypes['MEDIA_IMAGE']:
        return 'wallpaper_rounded'
        case messageTypes['FILE']:
            return 'insert_drive_File_rounded'
        case messageTypes['VOICE_MESSAGE']:
            return 'Voicemail_Rounded'
        case messageTypes['ADD_REMOVE_BANER_61']:
        case messageTypes['ADD_REMOVE_BANER_62']:
        case messageTypes['ADD_REMOVE_BANER_63']:
        case messageTypes['ADD_REMOVE_BANER_64']:
        case messageTypes['ADD_REMOVE_BANER_65']:
            return 'chat_rounded'
        default:
            return 'chat_rounded'
    }
}

const showToast = (message) => toast(message);

export default {
    notify
}
