import ReactGA from "react-ga";
// Google analytics tracking id
const TRACKING_ID = "UA-55034152-2";

function init() {
    // Initialize analytics only in prod env
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    // env variables return boolean value as string
    if (process.env.REACT_APP_IS_PRODUCTION === "true") ReactGA.initialize(TRACKING_ID);
}

function sendEvent(payload) {
    ReactGA.event(payload);
}

function sendPageview(path) {
    if (process.env.REACT_APP_IS_PRODUCTION === "false") return
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
}

export default {
    init,
    sendEvent,
    sendPageview,
};
