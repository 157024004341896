import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import ChatManagerStore from "../Messanger/Store/ChatManagerStore";
import { connect } from 'react-redux';
import { setPttSettings } from "../Messanger/containers/Chat/actions"
import { isEmptyObject } from "jquery";

const PTTRecording = (props) => {
    const {
        selectedChatOwnerInfo,
        currentPttTarget,
        isPttOn
    } = props
    const { chatUiMgrRef, chatManagerRef } = useContext(ChatManagerStore);
    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef({});
    const recordingStreamRef = useRef(null);
    const recordedBlobsRef = useRef(null);
    const recordIntervalRef = useRef(null);
    const [time, setTime] = useState(false);
    const OpusMediaRecorder = window.OpusMediaRecorder;


    var recSeconds = 0;
    var recMinutes = 0;
    var recHours = 0;

    var workerOptions = {
        OggOpusEncoderWasmPath: "https://cdn.jsdelivr.net/npm/opus-media-recorder@0.8.0/OggOpusEncoder.wasm",
        WebMOpusEncoderWasmPath: "https://cdn.jsdelivr.net/npm/opus-media-recorder@0.8.0/WebMOpusEncoder.wasm",
    };

    useEffect(() => {
        if (isPttOn) recordAudio()
        else stopAudioRecording()
    }, [isPttOn])


    const guid = () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    };

    const ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
            recordedBlobsRef.current.push(event.data);
        }
        var blob = new Blob(recordedBlobsRef.current, { type: "audio/ogg" });
        var file = new File([blob], guid() + ".opus");
        var chatObj = chatUiMgrRef.current.getActiveChatObj();
        chatObj.sendFileUpload(file, "PTT_MESSAGE", "", "", false, null);
        releaseRecordMediaStream();
    };

    const releaseRecordMediaStream = () => {
        if (recordingStreamRef.current != null) {
            recordingStreamRef.current.getTracks().forEach(function (track) {
                track.stop();
            });
        }
        mediaRecorderRef.current = null;
        clearInterval(recordIntervalRef.current);
        recordIntervalRef.current = null;
        setTime(null);
    };

    const startRecordingTimer = () => {
        if (recordIntervalRef.current == null) {
            recordIntervalRef.current = setInterval(function () {
                addRecordTime();
            }, 1000);
        }
    };

    const stopAudioRecording = () => {
        if (!isEmptyObject(mediaRecorderRef.current)) {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    const addRecordTime = () => {
        recSeconds++;
        if (recSeconds >= 60) {
            recSeconds = 0;
            recMinutes++;
            if (recMinutes >= 60) {
                recMinutes = 0;
                recHours++;
            }
        }
        var time = (recMinutes ? (recMinutes > 9 ? recMinutes : "0" + recMinutes) : "00") + ":" + (recSeconds > 9 ? recSeconds : "0" + recSeconds);
        if (recHours > 0) {
            time = recHours + ":" + time;
        }
        setTime(time);
    };



    const recordMediaSuccess = (stream) => {
        window.MediaRecorder = OpusMediaRecorder;
        recordingStreamRef.current = stream;
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: "audio/ogg;codecs=opus" }, workerOptions);
        setRecording(true);
        mediaRecorderRef.current.ondataavailable = ondataavailable;
        recordedBlobsRef.current = [];
        mediaRecorderRef.current.start();
        recSeconds = 0;
        recMinutes = 0;
        recHours = 0;
        startRecordingTimer();
    };

    const recordMediaError = (stream) => {
        if (recording) stopAudioRecording()
    };


    const recordAudio = () => {
        var toUUID = selectedChatOwnerInfo.toUUID ? selectedChatOwnerInfo.toUUID : currentPttTarget.connected.toUUID;
        var contactChatObj = chatManagerRef.current.getChat(toUUID);
        if (!contactChatObj.readOnly) {
            var audioRecordStreamConstraints = { audio: true };
            navigator.mediaDevices.getUserMedia(audioRecordStreamConstraints).then(recordMediaSuccess).catch(recordMediaError);
        } else {
            chatUiMgrRef.current.errorMessage("Voice messages cannot be sent for a view-only chat.", "danger");
        }
    };

    return null
};

const mapStateToProps = function (state) {
    return {
        selectedChatOwnerInfo: state.reducerChat.selectedChatOwnerInfo,
        currentPttTarget: state.reducerChat.currentPttTarget,
        groupList: state.reducerChat.groupList,
        pttSettings: state.reducerChat.pttSettings,
    }
}

const mapDispatchToProps = {
    setPttSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(PTTRecording);
