export default class LocalWebStorageStore {
    constructor() {
            this._storage = window.localStorage;
    }

    getItem(key) {
        return this._storage.getItem(key);
    }

    setItem(key, value) {
        this._storage.setItem(key, value);
    }

    removeItem(key) {
        this._storage.removeItem(key);
    }

    get length() {
        return this._storage.length();
    }

    key(index) {
        return this._storage.key(index);
    }
}