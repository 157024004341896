import React, { createContext, useReducer, useEffect } from 'react';
import UserLogsDialog from '../components/Users/UserLogsDialog'
import { merge } from 'lodash'

const UPDATE_LOGS='UPDATE_LOGS'
const TOGGLE_DIALOG='TOGGLE_DIALOG'

/* Define a context and a reducer for updating the context */
const UserLogsContext = createContext();

const initialState = {
    successfullyCreatedUsers: [],
    existingUsers: [],
    failedUsers: {},
    isDialogVisible: false,
    stateUpdated: false
};

const userLogsReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_LOGS:
            const newState = updateLogs(state, action.payload) 
            return {
                ...state,
                ...newState
            }
        case TOGGLE_DIALOG:
            return {
                ...state,
                isDialogVisible: action.payload
            };
        default:
            return state;
    }
};


function updateLogs(state, payload){
    return {
        successfullyCreatedUsers: state.successfullyCreatedUsers.concat(payload.successfullyCreatedUsers),
        existingUsers: state.existingUsers.concat(payload.existingUsers),
        failedUsers: merge(state.failedUsers, payload.failedUsers),
        stateUpdated: true
    }
}
/* Export a component to provide the context to its children. This is used in our _app.js file */

export const UserLogsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        userLogsReducer,
        initialState
    );

    function close(){
        dispatch({
            type: 'TOGGLE_DIALOG',
            payload: false
        })
    }
    return (
        <UserLogsContext.Provider value={[state, dispatch]}>
            {children}
            {/* This component is used for displaying add user logs */}
            <UserLogsDialog close={close} />
        </UserLogsContext.Provider>
    );
};

export default UserLogsContext