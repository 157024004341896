import * as types from './types';

const setCurrentConversationOwnerDetails = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_CURRENT_CONVERSATION_OWNER_DETAILS, payload: data });
    };
};

const setGroupList = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_GROUPS_LIST, payload: data });
    };
};

const setCurrentPttTarget = target => {
    return (dispatch) => {
        dispatch({ type: types.SET_CURRENT_PTT_TARGET, payload: target });
    };
}

const setReceivingPttTarget = target => {
    return (dispatch) => {
        dispatch({ type: types.SET_RECEIVING_PTT_TARGET, payload: target });
    };
}

const setPttSettings = target => {
    return (dispatch) => {
        dispatch({ type: types.SET_PTT_SETTINGS, payload: target });
    };
}

const updateGroupsList = (group) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_GROUPS_LIST, group: group });
    };
}

const updateRemovedGroupsList = (group) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_REMOVED_GROUP_LIST, group: group });
    };
}

const setSubscribersList = (subscriber) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SUBSCRIBER_LIST, payload: subscriber });
    };
}

const resetGroupUnreadMessageCount = uuid => {
    return (dispatch) => {
        dispatch({ type: types.RESET_GROUP_UNREAD_MESSAGE_COUNT, payload: uuid });
    };
};

const setGroupUnreadMessageCount = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_GROUP_UNREAD_MESSAGE_COUNT, payload: data });
    };
};

const setContactUnreadCount = (message, identifier) => {
    return (dispatch) => {
        dispatch({ type: types.SET_CONTACT_UNREAD_COUNT, payload: { message: message, identifier: identifier} });
    };
};

const resetContactUnreadCount = uuid => {
    return (dispatch) => {
        dispatch({ type: types.RESET_CONTACT_UNREAD_COUNT, payload: uuid });
    };
};

const resetActiveContactUnreadCount = uuid => {
    return (dispatch) => {
        dispatch({ type: types.RESET_ACTIVE_CONTACT_UNREAD_COUNT });
    };
};

const setContactList = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_CONTACT_LIST, payload: data });
    };
};

const removeContact = data => {
    return (dispatch) => {
        dispatch({ type: types.REMOVE_CONTACT, payload: data });
    };
};

const updateContactList = data => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_CONTACT_LIST, payload: data });
    };
};

const setContactsSearchResult = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_CONTACTS_SEARCH_RESULT, payload: data });
    };
};

const setGroupsSearchResult = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_GROUPS_SEARCH_RESULT, payload: data });
    };
};

const setBroadcastsSearchResult = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_BROADCASTS_SEARCH_RESULT, payload: data });
    };
};

const setActiveChatList = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_ACTIVE_CHAT_LIST, payload: data });
    };
};

const setConversationList = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_CONVERSATION_LIST, payload: data });
    };
};

const setOneOOneChatMessages = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_ONE_O_ONE_CHAT_MESSAGES, payload: data });
    };
};

const setReceivedMessage = (message, identifier) => {
    return (dispatch) => {
        dispatch({ type: types.MESSAGE_RECEIVED, payload: { message: message, identifier: identifier} });
    };
};

const updateMessageFromHistory = (data, identifier) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_MESSAGE_HISTORY, payload: {data: data, identifier: identifier} });
    };
};

const setNewOneOOneChatMessages = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_NEW_SENT_MESSAGE, payload: data });
    };
};

const updateNewChatMessages = data => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_NEW_SENT_MESSAGE, payload: data });
        // dispatch({ type: types.UPDATE_MESSAGE_STATUS, payload: data });
    };
};

const setIncommingOneOOneChatMessages = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_INCOMMING_ONE_O_ONE_CHAT_MESSAGES, payload: data });
    };
};



const setGroupChatMessages = data => {
    return (dispatch) => {
        dispatch({ type: types.SET_GROUP_CHAT_MESSAGES, payload: data });
    };
};

const setImageUrl = source => {
    return (dispatch) => {
        dispatch({ type: types.SET_IMAGE_SOURCE, payload: source });
    };
};

const setAudioUrl = source => {
    return (dispatch) => {
        dispatch({ type: types.SET_AUDIO_SOURCE, payload: source });
    };
};

const selectMessageToEdit = message => {
    return (dispatch) => {
        dispatch({ type: types.SELECT_MESSAGE_TO_EDIT, payload: message });
    };
};

const updateEditedMessage = (messageId, editedMessage, messageParams, identifier) => {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_EDITED_MESSAGE, payload: { message: editedMessage, messageId: messageId, messageParams: messageParams, identifier: identifier} });
        dispatch({ type: types.CLEAR_MESSAGE_TO_EDIT });
    };
};

const _removeGroup = (group) => {
    return (dispatch) => {
        dispatch({ type: types.REMOVE_GROUP, group: group });
    };
}

const setChatMessageUuidList = (uuids) => {
    return (dispatch) => {
        dispatch({ type: types.SET_MASSAGE_UUID_LIST, uuids: uuids });
    };
}

const setBroadcastList = (broadcastList) => {
    return (dispatch) => {
        dispatch({ type: types.SET_BROADCAST_LIST, broadcastList: broadcastList });
    };
}

const toggleCopyEnabled = (isEnabled) => {
    return (dispatch) => {
        dispatch({ type: types.TOGGLE_COPY_ENABLED, isEnabled: isEnabled});
    };
}

const toggleEditEnabled = (isEnabled) => {
    return (dispatch) => {
        dispatch({ type: types.TOGGLE_EDIT_ENABLED, isEnabled: isEnabled});
    };
}

const toggleReplyEnabled = (isEnabled) => {
    return (dispatch) => {
        dispatch({ type: types.TOGGLE_REPLY_ENABLED, isEnabled: isEnabled});
    };
}

const setMaxFileSize = (size) => {
    return (dispatch) => {
        dispatch({ type: types.SET_MAX_FILE_SIZE, size: size});
    };
}

const toggleDeleteEnabled = (isEnabled) => {
    return (dispatch) => {
        dispatch({ type: types.TOGGLE_DELETE_ENABLED, isEnabled: isEnabled});
    };
}

const setSelectedMessage = (message) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SELECTED_MESSAGE, message: message});
    };
}

const setDraftMessage = (message) => {
    return (dispatch) => {
        dispatch({ type: types.SET_DRAFT_MESSAGE, message: message});
    };
}

const clearDraftMessage = (message) => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_DRAFT_MESSAGE, message: message});
    };
}

const setPendingFileUploadList = (file) => {
    return (dispatch) => {
        dispatch({ type: types.SET_PENDING_FILE_UPLOAD_LIST, file: Array.isArray(file) ? file : [file]});
    };
}

const clearPendingFileUploadList = (messageUuid) => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_PENDING_FILE_UPLOAD_LIST, messageUuid: messageUuid});
    };
}

const setImageForUpload = (image) => {
    return (dispatch) => {
        dispatch({ type: types.SET_IMAGE_FOR_UPLOAD, image: image});
        dispatch({ type: types.CLEAR_DRAFT_MESSAGE}); // clear draft message once in image upload mode
    };

}
const clearUploadedImage = (image) => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_UPLOADED_IMAGE });
    };
}

const setFileForUpload = (image) => {
    return (dispatch) => {
        dispatch({ type: types.SET_FILE_FOR_UPLOAD, image: image});
    };
}

const clearFileForUpload = () => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_FILE_FOR_UPLOAD });
    };
}

const setImageForPreview = (image) => {
    return (dispatch) => {
        dispatch({ type: types.SET_IMAGE_FOR_PREVIEW, image: image});
    };
}

const clearImageForPreview = (image) => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_IMAGE_FOR_PREVIEW});
    };
}

const setSelectedSchedulerData = (data) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SELECTED_SCHEDULER_DATA, payload: data});
    };
}

const setScheduledMessages = (message) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SCHEDULED_MESSAGE, message: message});
    };
}

const clearScheduledMessages = (message) => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_SCHEDULED_MESSAGE});
    };
}

const setScheduleData = (data) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SCHEDULE_DATA, payload: data });
    };
};

const setSearchQuery = (searchString) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SEARCH_QUERY, searchString: searchString});
    };
}

const handleDeleteMessage = (data, identifier) => {
    return (dispatch) => {
        dispatch({ type: types.HANDLE_DELETE_MESSAGE, payload: { data: data, identifier: identifier}});
    };
}

const clearConversationList = () => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_CONVERSATION_LIST });
    };
}

const setPendingMessage = (payload) => {
    return (dispatch) => {
        dispatch({ type: types.SET_PENDING_MESSAGE, payload: payload });
    };
}

const clearPendingMessage = (messageUuid) => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_PENDING_MESSAGE, messageUuid: messageUuid });
    };
}

const cancelEditMessage = () => {
    return (dispatch) => {
        dispatch({ type: types.CANCEL_EDIT_MESSAGE });
        // dispatch({ type: types.CLEAR_DRAFT_MESSAGE});
    };
}

const handleRemovedActiveChat = () => {
    return (dispatch) => {
        dispatch({ type: types.HANDLE_REMOVED_ACTIVE_CHAT });
    };
}

const setActiveUnreadCount = (identifier) => {
    return (dispatch) => {
        dispatch({ type: types.SET_ACTIVE_UNREAD_COUNT, identifier: identifier});
    };
}

const clearActiveUnreadCount = (identifier) => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_ACTIVE_UNREAD_COUNT});
    };
}

const markMessageFailed = (messageId) => {
    return (dispatch) => {
        dispatch({ type: types.MARK_MESSAGE_FAILED, payload: messageId});
    };
}

const setContactsNextPage = (pageNumber) => {
    return (dispatch) => {
        dispatch({ type: types.SET_CONTACTS_NEXT_PAGE, payload: pageNumber});
    };
}

const setGroupsNextPage = (pageNumber) => {
    return (dispatch) => {
        dispatch({ type: types.SET_GROUPS_NEXT_PAGE, payload: pageNumber});
    };
}


export {
    setCurrentConversationOwnerDetails,
    setGroupList,
    resetGroupUnreadMessageCount,
    setGroupUnreadMessageCount,
    setContactUnreadCount,
    resetContactUnreadCount,
    resetActiveContactUnreadCount,
    setContactList,
    removeContact,
    updateContactList,
    setContactsSearchResult,
    setGroupsSearchResult,
    setBroadcastsSearchResult,
    setOneOOneChatMessages,
    setIncommingOneOOneChatMessages,
    setGroupChatMessages,
    setImageUrl,
    selectMessageToEdit,
    updateEditedMessage,
    _removeGroup,
    updateGroupsList,
    updateRemovedGroupsList,
    setSubscribersList,
    setChatMessageUuidList,
    setBroadcastList,
    toggleCopyEnabled,
    toggleEditEnabled,
    toggleReplyEnabled,
    setMaxFileSize,
    toggleDeleteEnabled,
    setSelectedMessage,
    setDraftMessage,
    clearDraftMessage,
    setPendingFileUploadList,
    setImageForUpload,
    clearUploadedImage,
    setFileForUpload,
    clearFileForUpload,
    setImageForPreview,
    clearImageForPreview,
    setSelectedSchedulerData,
    setScheduledMessages,
    clearScheduledMessages,
    setSearchQuery,
    setNewOneOOneChatMessages,
    updateNewChatMessages,
    clearConversationList,
    setPendingMessage,
    clearPendingMessage,
    setConversationList,
    setActiveChatList,
    setScheduleData,
    cancelEditMessage,
    updateMessageFromHistory,
    setReceivedMessage,
    handleDeleteMessage,
    clearPendingFileUploadList,
    handleRemovedActiveChat,
    setActiveUnreadCount,
    clearActiveUnreadCount,
    markMessageFailed,
    setAudioUrl,
    setContactsNextPage,
    setGroupsNextPage,
    setCurrentPttTarget,
    setReceivingPttTarget,
    setPttSettings
}