export const startPtt = (value) => {
    const data = {
        action: "start_ptt",
        data: value
    }
    sendAction(data)
}

export const stopPtt = (value) => {
    const data = {
        action: "stop_ptt",
        data: value
    }
    sendAction(data)
}

export const listenChannels = (chanelIds) => {
    const data = {
        action: "listen_channels",
        chanelIds: chanelIds
    }
    sendAction(data)
}

export const connectPtt = (value) => {
    const data = {
        ...value,
        action: "connect",
    }
    sendAction(data)
}

export const shoutChannel = (pttSessionId) => {
    const data = {
        action: "shout_channels",
        pttSessionId: pttSessionId
    }
    sendAction(data)
}

export const joinUser = (uuid) => {
    const data = {
        action: "join_user",
        uuid: uuid
    }
    sendAction(data)
}

export const joinChannel = (channelId) => {
    const data = {
        action: "join_channel",
        channel: channelId
    }
    sendAction(data)
}

export const setTargetId = (id) => {
    const data = {
        action: "change_target",
        id: id
    }
    sendAction(data)
}

export const clearTragetAndShout = () => {
    const data = {
        action: "clear_targets_and_shout",
    }
    sendAction(data)
}

export const sendAction = (data) => {
    var receiver = document.getElementById('receiver')
    if (receiver) {
        receiver = receiver.contentWindow;
        receiver.postMessage(data, process.env.REACT_APP_WEB_PTT_URI);
    }
}

export default {
    startPtt,
    stopPtt,
    listenChannels,
    shoutChannel,
    connectPtt,
    joinUser,
    sendAction,
    joinChannel,
    setTargetId,
    clearTragetAndShout
}