import React, {useEffect, useState} from "react";
import PageContainer from '../PageContainer';
import { Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
    },

    subTitle: {
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '0.5px',
        marginBottom: 42,
        marginTop: 55,
        color: '#000'
    },

}));

const reloadPage = () => {
    window.location.reload();
}

const AccessTokenError = () => {
    useEffect(() => {
        window.addEventListener('online', () => setOnlineState(true));
        window.addEventListener('offline', () => setOnlineState(false));

        return function cleanup() {
            window.removeEventListener('online', setOnlineState);
            window.removeEventListener('offline', setOnlineState);
        }
    }, []);

    const [isOnline, setIsOnline] = useState(true);
    const setOnlineState = (state) => {   
        setIsOnline(state);
    }

    const classes = useStyles();

    if(isOnline) {
        reloadPage();
        return <div></div>
    } else {
        return  (
            <PageContainer>
                <Box className={classes.root}>
                    <Typography className={classes.subTitle}>
                    We detected a network loss. If you're still online, click the button to attempt a reconnection.
                    </Typography>
                    <Button onClick={reloadPage} 
                        variant="contained"
                        color="primary">
                        Click to Connect
                    </Button>
                </Box>
            </PageContainer>
        );
    }
    
}


export default AccessTokenError;