/* Action Types for Messenger store*/

export const SET_GROUPS_LIST = 'SET_GROUPS_LIST'
export const SET_MASSAGE_UUIDS_LIST = 'SET_MASSAGE_UUIDS_LIST'
export const SET_BROADCAST_GROUPS_LIST = 'SET_BROADCAST_GROUPS_LIST'
export const SET_CONTACT_LIST = 'SET_CONTACT_LIST'
export const SET_ONE_O_ONE_CHAT = 'SET_ONE_O_ONE_CHAT'
export const SET_TAB_INDEX = 'SET_TAB_INDEX'
export const ADD_NEW_ONE_O_ONE_CHAT = 'ADD_NEW_ONE_O_ONE_CHAT'
export const SET_GROUP_CHAT = 'SET_GROUP_CHAT'
export const SET_CONTACT_UNREAD_COUNT = 'SET_CONTACT_UNREAD_COUNT'
export const RESET_CONTACT_UNREAD_COUNT = 'RESET_CONTACT_UNREAD_COUNT'
export const SET_GROUP_UNREAD_COUNT = 'SET_GROUP_UNREAD_COUNT'
export const RESET_GROUP_UNREAD_COUNT = 'RESET_GROUP_UNREAD_COUNT'
export const SET_SUBSCRIBER_LIST = 'SET_SUBSCRIBER_LIST'
export const SET_AUDIO_SROUCE = 'SET_AUDIO_SROUCE'
export const SET_CHAT_OWNER_INFO = 'SET_CHAT_OWNER_INFO'
export const SET_SELECTED_CHAT_LIST = 'SET_SELECTED_CHAT_LIST'
export const SET_NEW_SELECTED_CHAT_LIST = 'SET_NEW_SELECTED_CHAT_LIST'
export const SET_PENDING_FILE_UPLOAD_LIST = 'SET_PENDING_FILE_UPLOAD_LIST'
export const message_RECEIVED = 'message_RECEIVED'
export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS'
export const SET_SELECTED_message = 'SET_SELECTED_message'
export const TOGGLE_MESSAGE_INFO = 'TOGGLE_MESSAGE_INFO'
export const TOGGLE_QUOTE_MESSAGE = 'TOGGLE_QUOTE_MESSAGE'
export const CLEAR_SELECTED_CHAT_LIST = 'CLEAR_SELECTED_CHAT_LIST'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const SET_HISTORY_CHAT_SELECTED_LIST = 'SET_HISTORY_CHAT_SELECTED_LIST'
export const TOGGLE_GROUP_INFO_SEELCTED = 'TOGGLE_GROUP_INFO_SEELCTED'
export const SET_DRAFT_MESSAGE = 'SET_DRAFT_MESSAGE'
export const SET_IMAGE_FOR_UPLOAD = 'SET_IMAGE_FOR_UPLOAD'
export const SET_IMAGE_FOR_PREVIEW = 'SET_IMAGE_FOR_PREVIEW'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const SET_IMAGE_URL = 'SET_IMAGE_URL'
export const SET_SELECTED_SCHEDULER_DATA = 'SET_SELECTED_SCHEDULER_DATA'
export const TOGGLE_SCHEDULE_VIEWER = 'TOGGLE_SCHEDULE_VIEWER'
export const SET_SCHEDULED_MESSAGE = 'SET_SCHEDULED_MESSAGE'
export const SET_SCHEDULE_DATA = 'SET_SCHEDULE_DATA'
export const TOGGLE_COPY_ENABLED = 'TOGGLE_COPY_ENABLED'
export const TOGGLE_EDIT_ENABLED = 'TOGGLE_EDIT_ENABLED'
export const TOGGLE_REPLY_ENABLED = 'TOGGLE_REPLY_ENABLED'
export const TOGGLE_DELETE_ENABLED = 'TOGGLE_DELETE_ENABLED'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const VIEW_MESSAGE_PREVIEW = 'VIEW_MESSAGE_PREVIEW'
export const TOGGLE_ALLOW_REPLY = 'TOGGLE_ALLOW_REPLY'
export const CHAT_HISTORY_LOADING = 'CHAT_HISTORY_LOADING'

// Experimental
export const _SET_ONE_O_ONE_CHAT = '_SET_ONE_O_ONE_CHAT'
export const _ADD_ONE_O_ONE_MESSAGE = '_ADD_ONE_O_ONE_MESSAGE'
