import React, { useState, useEffect, useContext } from 'react'
import GlobalStateContext from '../Context/GlobalStateContext'
/**
 * @returns {Boolean} - weather permission is allow or not
 */
const dataObject = {
    //readonly
    "ptt_n_chat_readonly": "ChatReadonly",
    "user_management_readonly": "UsersReadonly",
    "team_management_readonly": "TeamsReadonly",
    "newsfeed_readonly": "FeedsReadonly",
    "contact_management_readonly": "ContactsReadonly",
    "license_management_readonly": "LicensesReadonly",
    "deep_dive_readonly": "DeepDiveReadonly",
    "integration_readonly": "IntegrationReadonly",
    "device_location_readonly": "LocationsReadonly",
    "app_config_readonly": "AppConfigSettingsReadonly",
    "ptt_settings_readonly": "PttSettingsReadonly",
    "location_settings_readonly": "LocationSettingsReadonly",
    "roles_n_permissions_readonly": "RolesReadonly",

    "reseller": "isReseller"
}

const usePermissions = () => {
    const [state, setState] = useContext(GlobalStateContext)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        if (state.networkSettings.permissions) {
            let obj = {}
            state.networkSettings.permissions.map((permision) => {
                obj[dataObject[permision]] = true
            })
            permissionFunctions.map((permissionFunction) => {
                const permision = permissionFunction(state.networkSettings.permissions)
                obj[permision[0]] = permision[1]
            })
            setPermissions(obj)
        }
    }, [state.networkSettings])

    return permissions

}


function showChat(permissions) {
    if (!permissions) return ["showChat", false]
    return ["showChat", (
        permissions.includes("ptt_n_chat") || permissions.includes("ptt_n_chat_readonly")
    )]
}

function showUsers(permissions) {
    if (!permissions) return ["showUsers", false]
    return ["showUsers", (
        permissions.includes("user_management") || permissions.includes("user_management_readonly")
    )]
}

function showTeams(permissions) {
    if (!permissions) return ["showTeams", false]
    return ["showTeams", (
        permissions.includes("team_management") || permissions.includes("team_management_readonly")
    )]
}

function showFeeds(permissions) {
    if (!permissions) return ["showFeeds", false]
    return ["showFeeds", (
        permissions.includes("newsfeed") || permissions.includes("newsfeed_readonly")
    )]
}

function showContacts(permissions) {
    if (!permissions) return ["showContacts", false]
    return ["showContacts", (
        permissions.includes("contact_management") || permissions.includes("contact_management_readonly")
    )]
}

function showLicenses(permissions) {
    if (!permissions) return ["showLicenses", false]
    return ["showLicenses", (
        permissions.includes("license_management") || permissions.includes("license_management_readonly")
    )]
}

function showDeepDive(permissions) {
    if (!permissions) return ["showDeepDive", false]
    return ["showDeepDive", (
        permissions.includes("deep_dive") || permissions.includes("deep_dive_readonly")
    )]
}

function showIntegration(permissions) {
    if (!permissions) return ["showIntegration", false]
    return ["showIntegration", (
        permissions.includes("integration") || permissions.includes("integration_readonly")
    )]
}

function showLocations(permissions) {
    if (!permissions) return ["showLocations", false]
    return ["showLocations", (
        permissions.includes("device_location") || permissions.includes("device_location_readonly")
    )]
}

function showPttSettings(permissions) {
    if (!permissions) return ["showPttSettings", false]
    return ["showPttSettings", (
        permissions.includes("ptt_settings") || permissions.includes("ptt_settings_readonly")
    )]
}

function showLocationSettings(permissions) {
    if (!permissions) return ["showLocationSettings", false]
    return ["showLocationSettings", (
        permissions.includes("location_settings") || permissions.includes("location_settings_readonly")
    )]
}

function showRoles(permissions) {
    if (!permissions) return ["showRoles", false]
    return ["showRoles", (
        permissions.includes("roles_n_permissions") || permissions.includes("roles_n_permissions_readonly")
    )]
}

function showAppConfigSettings(permissions) {
    if (!permissions) return ["showAppConfigSettings", false]
    return ["showAppConfigSettings", (
        permissions.includes("app_config") || permissions.includes("app_config_readonly")
    )]
}

function showDirectory(permissions) {
    if (!permissions) return ["showDirectory", false]
    return ["showDirectory", (
        permissions.includes("user_management") || permissions.includes("team_management") || permissions.includes("contact_management") || permissions.includes("newsfeed") ||
        permissions.includes("user_management_readonly") || permissions.includes("team_management_readonly") || permissions.includes("contact_management_readonly") || permissions.includes("newsfeed_readonly")
    )]
}

function showChannels(permissions) {
    if (!permissions) return ["showChannels", false]
    return ["showChannels", (
        (permissions.includes("broadcast_channel_management") && permissions.includes("public_channel_management") && permissions.includes("closed_channel_management") && permissions.includes("sos_channel_management") && permissions.includes("limited_public_channel_management")) ||
        (permissions.includes("broadcast_channel_management_readonly") && permissions.includes("public_channel_management_readonly") && permissions.includes("closed_channel_management_readonly") && permissions.includes("sos_channel_management_readonly") && permissions.includes("limited_public_channel_management_readonly"))
    )]
}

function ChannelsReadonly(permissions) {
    if (!permissions) return ["ChannelsReadonly", false]
    return ["ChannelsReadonly", (
        permissions.includes("broadcast_channel_management_readonly") && permissions.includes("public_channel_management_readonly") && permissions.includes("closed_channel_management_readonly") && permissions.includes("sos_channel_management_readonly") && permissions.includes("limited_public_channel_management_readonly")
    )]
}

function showSettings(permissions) {
    if (!permissions) return ["showSettings", false]
    return ["showSettings", (
        permissions.includes("app_config") || permissions.includes("ptt_settings") || permissions.includes("location_settings") ||
        permissions.includes("app_config_readonly") || permissions.includes("ptt_settings_readonly") || permissions.includes("location_settings_readonly")
    )]
}

function writeSettings(permissions) {
    if (!permissions) return ["showSettings", false]
    return ["writeSettings", (
        permissions.includes("app_config") || permissions.includes("ptt_settings") || permissions.includes("location_settings")
    )]
}

const permissionFunctions = [
    showDirectory, showChannels, showSettings, writeSettings,
    showChat, showUsers, showTeams, showFeeds, showContacts, showDeepDive,
    showLicenses, showIntegration, showRoles, showLocations,
    showAppConfigSettings, showLocationSettings, showPttSettings, ChannelsReadonly
]


export default usePermissions;