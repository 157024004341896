import React, { createContext, useReducer, useState, useRef } from 'react';
import { useEffect, useContext } from 'react';
import { find, each, remove, debounce } from 'lodash'
import { useReactOidc, getUserManager } from "@axa-fr/react-oidc-context";
import AttachmentManager from '../AttachmentManager';
import ProtoCommandBuilder from '../ProtoCommandBuilder';
import ScheduleMessegeManager from '../ScheduleMessegeManager'
import * as types from './ChatStateMutations'
import { NavLink, useHistory } from 'react-router-dom'
import Notifications from '../Notifications'
import ChatToast from '../ChatToast'
import GlobalStateContext from '../../../Context/GlobalStateContext';
import { useAxios, useRORAxios } from '../../../core/useAxios';
import { toast } from 'react-toastify';
import ToastContainerWrapper from '../../Misc/ToastContainerWrapper';
import { connect } from 'react-redux';
import PttActions from "../../WebPTT/Actions"
import { getContacts, getChannels, getChannelDetails } from "../ChatApis"
import {
    startLoader,
    stopLoader,
    openScheduledMessageList,
    cancelMessageQuote,
    setGlobalUnreadMessageCount,
    disableBroadcastReply,
    setActiveChatListPresent,
    hideGroupInfoDrawer
} from '../containers/ChatUI/actions'
import {
    setOneOOneChatMessages,
    setGroupChatMessages,
    updateEditedMessage,
    setPendingFileUploadList,
    setScheduledMessages,
    updateMessageFromHistory,
    clearConversationList,
    clearScheduledMessages,
    clearPendingFileUploadList,
    handleRemovedActiveChat,
    updateContactList,
    updateRemovedGroupsList,
    removeContact,
    setActiveUnreadCount,
    markMessageFailed,
    setSelectedMessage
} from '../containers/Chat/actions'
import {
    setCurrentConversationOwnerDetails,
    setGroupList,
    setCurrentPttTarget,
    resetGroupUnreadMessageCount,
    setGroupUnreadMessageCount,
    setContactUnreadCount,
    resetContactUnreadCount,
    resetActiveContactUnreadCount,
    setContactList,
    setContactsSearchResult,
    setGroupsSearchResult,
    setBroadcastsSearchResult,
    setActiveChatList,
    _removeGroup,
    updateGroupsList,
    setSubscribersList,
    setChatMessageUuidList,
    toggleCopyEnabled,
    toggleEditEnabled,
    toggleReplyEnabled,
    setMaxFileSize,
    toggleDeleteEnabled,
    setDraftMessage,
    setIncommingOneOOneChatMessages,
    setNewOneOOneChatMessages,
    updateNewChatMessages,
    setPendingMessage,
    clearPendingMessage,
    setSelectedSchedulerData,
    setConversationList,
    handleDeleteMessage,
    setReceivedMessage,
    clearDraftMessage,
    setContactsNextPage,
    setGroupsNextPage
} from '../containers/Chat/actions'
import messageTypes from '../MessegeTypes/messageTypes.json'
import { useCallback } from 'react';
import DeleteMessageDialog from '../Dialogs/DeleteMessageDialog';
import CallScreen from '../Media/CallScreen';
const ZERO = 0

const makeSearchContactsRequest = (queryString, fetchContact) => {
    console.log("searchContacts :: sending getContactListRequestCommand ====" + queryString);
    fetchContact(queryString)
};

const makeSearchGroupsRequest = (queryString, fetchChannels) => {
    console.log("searchGroups :: sending getChannelListRequestCommand ====" + queryString);
    fetchChannels(queryString)
};

var delayedContactsSearchRequest = debounce((queryString, fetchContact) => makeSearchContactsRequest(queryString, fetchContact), 500, {
    leading: false,
    trailing: true,
});

var delayedGroupsSearchRequest = debounce((queryString, fetchChannels) => makeSearchGroupsRequest(queryString, fetchChannels), 500, {
    leading: false,
    trailing: true,
});

// import GlobalStateMutations from './GlobalStateMutations';

/* Define a context and a reducer for updating the context */
const ChatManagerStore = createContext();

const initialState = {
    // groupList: {},
    lastReceivedMessageId: 0,
    chats: {},
    group_chat: {},
    contactList: {},
    activeChatList: {},
    conversationList: [],
    groupListNextPage: 1,
    subscriberList: {},
    searchContactFn: null,
    searchGroupFn: null,
    contactListLoaded: false,
    messageUuids: {}, //Store a mapping of message-uuid -> Chat obj - Used when message sent in a chat object and acknowledged as received by server
    evaActivityContact: null,
    initialized: false,
    pendingMessages: [], //Will contain messages sent but whose sent ack is not received yet
    unprocessedChatMessages: [], // will contain messages recived from server but not processed yet
    pendingConversationInfoRequests: [], // will contain conversationIds whose requests is still pending

    pendingFileUpload: [],
    //New states
    selectedChatOwnerInfo: {
        name: '',
        email: '',
        toUUID: null
    },

    selectedChatList: 0,

    broadcastList: {},
    selectedTabIndex: 0,
    selectedmessage: null, // to get message info, etc
    messageToEdit: null, // message to edit
    // isMessageInfoOpen: false, // weather message info view is open or not
    // isChatInfoSelected: false, // weather chat info option is selected
    // isScheduleViererOpen: false, // weather schedule message view is open
    isQuotingmessage: false,
    toast: {
        isVisible: false,
        message: '',
        variant: "info",
        type: 1
    },

    draftMessegeList: {},
    fileToUpload: null, // image tp be uploaded
    imageForPreview: null, // holds chat object with image for preview
    // isMessagePreviewVisible: false,
    selectedSchedulerData: {
        isOpen: false,
        uuid: null
    },

    scheduledMessages: [],
    scheduleData: {},
    _one_o_one_chat: {},
    // Configuration settings
    copyEnabled: false,
    editEnabled: false,
    deleteEnabled: false,
    replyEnabled: false,
    isBroadcastReplyEnabled: false,

    searchQuery: '',

    isChatHistoryLoading: false,  // loader for fetching messages and history messages
    isImageAttachmentDownloading: false

};



/* Export a component to provide the context to its children. This is used in our app*/

const _ChatManagerProvider = (props) => {
    const {
        setOneOOneChatMessages,
        updateMessageFromHistory,
        setGroupChatMessages,
        setCurrentConversationOwnerDetails,
        groupList,
        setGroupList,
        setCurrentPttTarget,
        resetGroupUnreadMessageCount,
        setGroupUnreadMessageCount,
        setContactUnreadCount,
        resetContactUnreadCount,
        resetActiveContactUnreadCount,
        setContactList,
        setContactsSearchResult,
        setGroupsSearchResult,
        setBroadcastsSearchResult,
        activeChatList,
        conversationList,
        setActiveChatList,
        contactList,
        group_chat,
        one_o_one_chat,
        messageToEdit,
        updateEditedMessage,
        _removeGroup,
        updateGroupsList,
        setSubscribersList,
        subscriberList,
        setChatMessageUuidList,
        messageUuids,
        toggleCopyEnabled,
        toggleEditEnabled,
        toggleReplyEnabled,
        setMaxFileSize,
        toggleDeleteEnabled,
        setDraftMessage,
        draftMessegeList,
        selectedChatOwnerInfo,
        selectedSchedulerData,
        scheduleData,
        pendingFileUpload,
        setPendingFileUploadList,
        setScheduledMessages,
        clearConversationList,
        searchQuery,
        setIncommingOneOOneChatMessages,
        setNewOneOOneChatMessages,
        updateNewChatMessages,
        setPendingMessage,
        pendingMessages,
        clearPendingMessage,
        openScheduledMessageList,
        setSelectedSchedulerData,
        isQuotingmessage,
        isChatHistoryLoading,
        selectedmessage,
        cancelMessageQuote,
        setConversationList,
        handleDeleteMessage,
        setReceivedMessage,
        setGlobalUnreadMessageCount,
        clearDraftMessage,
        disableBroadcastReply,
        isBroadcastReplyEnabled,
        setActiveChatListPresent,
        clearScheduledMessages,
        clearPendingFileUploadList,
        handleRemovedActiveChat,
        updateContactList,
        updateRemovedGroupsList,
        removeContact,
        setActiveUnreadCount,
        markMessageFailed,
        setContactsNextPage,
        setGroupsNextPage,
        setSelectedMessage,
        isMessageInfoOpen,
        hideGroupInfoDrawer,
        pttConnection,
        currentPttTarget,
        receivingPttTarget,
        pttSettings
    } = props

    //helper functions
    const chatManagerStateReducer = (state, action) => {
        switch (action.type) {
            case types.SET_SELECTED_CHAT_LIST:
                return {
                    ...state,
                    selectedChatList: state.selectedChatList + 1
                };
            case types.SET_NEW_SELECTED_CHAT_LIST:
                return {
                    ...state,
                    selectedChatList: state.selectedChatList + 1
                };
            case types.CLEAR_SELECTED_CHAT_LIST:
                return {
                    ...state,
                    selectedChatList: state.selectedChatList + 1
                };
            case types.TOGGLE_ALLOW_REPLY:
                return {
                    ...state,
                    isBroadcastReplyEnabled: action.payload
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(
        chatManagerStateReducer,
        initialState
    );
    let history = useHistory()
    const { oidcUser, logout, events } = useReactOidc();
    const [contextState, contextDispatch] = useContext(GlobalStateContext);
    const [isComponentMounted, setIsComponentMounted] = useState(false)
    const [isConnected, setIsConnected] = useState(false) // Weather Socket connection eshtablished or not
    const chatContainerRef = useRef(null) // chat container element ref => for controlling message list scroll
    const webSocket = useRef(null);
    const protoBuffRef = useRef({});
    const chatManagerRef = useRef({});
    const chatUiMgrRef = useRef({});
    const chatMetaDataRef = useRef({
        chatToken: null,
        webSocketHost: null,
        chatHost: null,
        selfUUID: null,
        userDisplayName: null,
        encryption: null,
        licenseExpired: false
    });
    const attachmentUiMgrRef = useRef({});
    const notificationRef = useRef({});

    const scheduleMessageUiManagerRef = useRef({});
    const mediaRecorderRef = useRef({});
    const callObjRef = useRef({});
    const videoTrackDisabled = useRef({});

    const othercallObjRef = useRef({});
    const localStreamRef = useRef({});
    const callTimerRef = useRef(null);
    const startTimerRef = useRef(null);
    const draftMessageListRef = useRef({});
    const [progress, setUploadProgress] = useState(0)

    // Not using axios here, just for mentaining the refreshtoken
    // TODO: have a saperate hook for this

    const axiosRORInstance = useRORAxios(
        process.env.REACT_APP_IM_HTTP_API_ROR_URL,
        oidcUser.access_token
    );

    const fetchContact = (query) => {
        let params = query ? `directory_contacts=true&q=${encodeURIComponent(query)}` : "directory_contacts=true";
        getContacts(axiosRORInstance, params).then(({ data }) => {
            initialContactListLoaded.current = true;
            setContactsNextPage(data.next_page)
            var _contacts = {}
            if (data.contacts && data.contacts.length > 0) {
                data.contacts.map((contact) => {
                    _contacts = chatManagerRef.current.contactAdded(contact);
                    if (contact.visible) {
                    } else if (contact.role == "BOT") {
                        chatManagerRef.current.handleBot(contact);
                    }
                    _contacts[contact.uuid] = {
                        ...contact,
                        displayName: contact.name,
                        contactType: contact.contact_type
                    }
                })
            }
            delete _contacts[chatMetaDataRef.current.selfUUID]
            if (query) setContactsSearchResult(_contacts)
            else setContactList(_contacts)
        })
    }

    const fetchChannels = (query) => {
        let params = query ? `q=${query}` : "";
        getChannels(axiosRORInstance, params).then(({ data }) => {
            var _groupList = {}
            initialGroupListLoaded.current = true;
            var groups = data.channels;
            chatManagerRef.current.groupListNextPage = data.next_page;
            setGroupsNextPage(data.next_page)
            var _this = chatManagerRef.current;
            if (groups && groups.length > 0) {
                $.each(groups, function (i, group) {
                    let groupObj = {
                        ...group,
                        activeSubscriberUuids: group.active_subscriber_uuids,
                        canCopy: group.can_copy,
                        canDelete: group.can_delete,
                        canEdit: group.can_edit,
                        canForward: group.can_forward,
                        canJoin: group.can_join,
                        canLeave: group.can_leave,
                        canPost: group.can_post,
                        canReply: group.can_reply,
                        displayName: group.name,
                        pttChannelId: group.ptt_channel_id,
                        groupType: group.type_value,
                        allSubscribers: []
                    }
                    var existingGroup = chatManagerRef.current.getGroup(groupObj.uuid);
                    if (existingGroup && !query) {
                        chatManagerRef.current.HandleUpdateGroupInfo(groupObj);
                    } else {
                        if (!_this.groupList[groupObj.uuid]) {
                            _groupList[groupObj.uuid] = groupObj;
                        }
                        if (chatManagerRef.current.canShowGroupInList(groupObj.uuid)) {
                            var groupChatObj = _this.getGroupChat(groupObj.uuid);
                            chatUiMgrRef.current.ensureGroupConversationUiExist(groupChatObj);
                        }
                    }
                });
                if (query) setGroupsSearchResult(_groupList)
                else setGroupList(_groupList)
            }
            if (socketReconnectProcessing.current && chatManagerRef.current.getLastMessageReceivedAt() > 0) {
                var getPendingMessageRequestCommand = protoBuffRef.current.getPendingMessageRequest(chatManagerRef.current.getLastMessageReceivedAt());
                sendMessage(getPendingMessageRequestCommand);
            } else {
                socketReconnectProcessing.current = false;
            }
        })
    }

    useEffect(() => {
        if (axiosRORInstance) {
            fetchContact()
            fetchChannels()
        }
    }, [axiosRORInstance])



    const axiosInstance = useAxios(
        process.env.REACT_APP_KC_BROKER_URL,
        'oidcUser.access_token'
    ); // see https://github.com/panz3r/jwt-checker-server for a quick implementation
    // Hooks
    var twemoji = window.twemoji;
    var _ = window._;
    var $ = window.$;
    var swal = window.swal;
    var Offline = window.Offline;
    var EncryptionUtils = window.EncryptionUtils;
    var moment = window.moment;
    var DetectRTC = window.DetectRTC;
    var JSEncrypt = window.JSEncrypt;
    var CryptoJS = window.CryptoJS;
    var quill = window.Quill;
    const heartbeat_interval = useRef(null)
    const missed_heartbeats = useRef(0)
    const initialContactListLoaded = useRef(false)
    const initialGroupListLoaded = useRef(false)
    const socketReconnectProcessing = useRef(false)
    var heartbeat_msg = "-KA-";

    const videoCallConstraints = {
        video: {
            "width": {
                "min": "160",
                "ideal": "320",
                "max": "640"
            },
            "height": {
                "min": "120",
                "ideal": "240",
                "max": "480"
            },
            "frameRate": {
                "min": "10",
                "ideal": "15",
                "max": "30"
            }
        },
        audio: true
    };

    localStorage.setItem("eva-new-messages-available", "false");

    const mediaStreamConstraints = {
        video: false,
        audio: true,
    };

    localStreamRef.current = null;

    var recordingStream = null;
    var mediaRecorder = null;
    mediaRecorderRef.current = null
    var recordedBlobs = null;
    const iceServers = useRef([{ url: "stun:stun.l.google.com:19302" }])
    const dataChannel = useRef(null)
    const peerConnection = useRef(false)
    const peerConnectionClosed = useRef(false)
    const socketConnectionRetryTimeout = useRef(0)

    var seconds = 0;
    var minutes = 0;
    var hours = 0;
    var recSeconds = 0;
    var recMinutes = 0;
    var recHours = 0;
    const connectionTimer = useRef(false)
    const feedbackTimer = useRef(false)
    const feedBackScreen = useRef(false)
    var siteTitle = window.g__subdomain ? window.g__subdomain.site_title : "Oneteam";
    var supportMail = window.g__subdomain ? window.g__subdomain.support_email_id : "support@scalefusion.com";

    var defaultNotificationImage;
    var copyEnabled,
        editEnabled,
        deleteEnabled,
        replyEnabled = false;

    var isBrowserInFocus = true;
    window.onfocus = onFocus;
    window.onblur = onBlur;

    window.MobilockChatClient.chatReady = false;

    chatManagerRef.current = new chatManager();
    notificationRef.current = new Notifications()
    if (notificationRef.current) notificationRef.current.getBrowserNotificationPermission();
    // TODO: Get notification permissions
    attachmentUiMgrRef.current = new AttachmentManager(chatUiMgrRef.current, chatMetaDataRef.current);
    attachmentUiMgrRef.current.attachEvents();
    var replyLabel = 'Reply';
    var quoteLabel = 'Quote';

    useEffect(() => {
        if (callObjRef.current) {
            chatManagerRef.current.setEmptyCallObject();
        }
    }, [callObjRef])



    window.htmlEncode = htmlEncode;
    var broadCast = "BROADCAST";

    _.templateSettings = {
        interpolate: /\{(.+?)\}/g,
    };
    var evaActivityChatMessageTemplate = _.template($("#eva_activity_msg_template").html());
    var contactTemplate = _.template($("#contact-template").html());
    useEffect(() => {
        if (oidcUser) {
            chatMetaDataRef.current.accessToken = oidcUser.access_token; // Update token when refreshed
        }
        if (oidcUser && !isComponentMounted) {
            setIsComponentMounted(true)
        }
    }, [oidcUser]);

    useEffect(() => {
        if (!oidcUser) return
        let networkSettings = contextState.networkSettings
        if (networkSettings && networkSettings.endpoints) {
            startChatClient({
                host: networkSettings.endpoints.websocket_url || process.env.REACT_APP_EVA_SOCKET_URL,
                defaultNotificationImage: "%PUBLIC_URL%/assets/eva-activity-logo.jpg",
                oidcUser: oidcUser,
            });
            notificationRef.current.closeAllNotifications();
        }
    }, [oidcUser, contextState]);

    const isJoinedGroup = useCallback(() => {
        if (selectedChatOwnerInfo.toUUID && groupList[selectedChatOwnerInfo.toUUID]) return groupList[selectedChatOwnerInfo.toUUID].canJoin || false
    }, [selectedChatOwnerInfo])

    const startChatClient = (oidcProps) => {
        chatMetaDataRef.current.oidcUser = oidcProps.oidcUser;
        chatMetaDataRef.current.accessToken = oidcProps.oidcUser.access_token;
        chatMetaDataRef.current.idToken = oidcProps.oidcUser.id_token;
        if (webSocket.current != undefined && webSocket.current.readyState != 3) {
            console.log("socket status", webSocket.current.readyState);
            return;
        }

        var host = oidcProps.host;

        defaultNotificationImage = oidcProps.defaultNotificationImage;
        chatMetaDataRef.current.webSocketHost = host + "chat";
        chatMetaDataRef.current.webSocketHost = host + "chat";
        chatMetaDataRef.current.userDisplayName = oidcProps.oidcUser.profile.given_name;
        chatMetaDataRef.current.selfUUID = oidcProps.oidcUser.profile.sub;

        if (!("WebSocket" in window)) {
            return;
        }

        protoBuffRef.current = new ProtoCommandBuilder();
        // TODO: import this file from current dir
        protoBuffRef.current.init(window.MobilockChatClient.proto_url, function () {
            console.log("newProto.current initialized");
            if (webSocket.current == null) {
                chatMetaDataRef.current.encryption = new JSEncrypt();
                openWebSocket();
            }
        });


        scheduleMessageUiManagerRef.current = new ScheduleMessegeManager(chatUiMgrRef.current, protoBuffRef.current, sendMessage);
    };

    function htmlEncode(value) {
        if (value) {
            return $("<div/>").text(value).html();
        } else {
            return "";
        }
    }

    function onBlur() {
        isBrowserInFocus = false;
    }

    function onFocus() {
        isBrowserInFocus = true;
    }

    var notificationManager = null;

    function guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }

    function sendMessage(msgToSend) {
        var clientMsg = msgToSend;

        if (clientMsg == "") {
            return;
        }

        try {
            webSocket.current.send(clientMsg);
        } catch (exception) {
        }
    }

    function licenseExpiredNotification() {
        // FIXME: handle error 
        chatUiMgrRef.current.errorMessage("Your organization's license has expired.", 'danger');
        var el = $("#license-management-link")[0]
        if (el) el.click()
    }

    function formatMessageForUi(message, isBroadcast) {
        if (message == undefined) {
            return message;
        }
        var formattedMessage = message;

        if (!isBroadcast) {
            // STORE: comented
            formattedMessage = formattedMessage.linkify({
                validate: true,
            });
        }

        if (typeof twemoji === "object") {
            formattedMessage = twemoji.parse(formattedMessage);
        }

        //Replace \n with br
        formattedMessage = formattedMessage.replace(/\r?\n/g, "<br/>");
        return formattedMessage;
    }

    function formatBytes(bytes, decimals) {
        if (bytes == 0) return "0 Bytes";
        var k = 1024,
            dm = decimals + 1 || 3,
            sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    chatUiMgrRef.current = new chatUiManager();

    function deviceLocationSetOnMap(latitude, longitude) {
        // var mapElement = $('.right').find('.body-container .map-div')[0];
        // if (typeof google === 'object' && typeof google.maps === 'object') {
        //     var mapOptions = {
        //         zoom: 16,
        //         center: new google.maps.LatLng(latitude, longitude)
        //     }
        //     var map = new google.maps.Map(mapElement, mapOptions);
        //     var marker = new google.maps.Marker({
        //         position: {lat: latitude, lng: longitude},
        //         map: map
        //     });
        // }
    }

    var workerOptions = {
        OggOpusEncoderWasmPath: "https://cdn.jsdelivr.net/npm/opus-media-recorder@0.8.0/OggOpusEncoder.wasm",
        WebMOpusEncoderWasmPath: "https://cdn.jsdelivr.net/npm/opus-media-recorder@0.8.0/WebMOpusEncoder.wasm",
    };

    $.fn.observe = function (eventName, callback) {
        return this.each(function () {
            var el = this;
            $(document).on(eventName, function () {
                callback.apply(el, arguments);
            })
        });
    }

    function handleMediaSuccessIncomingCall(stream) {
        console.log("handleMediaSuccessIncomingCall", stream);
        var videoTrack = null;
        if (callObjRef.current.callStatus == 'RINGING-INCOMING') {
            localStreamRef.current = stream;

            if (!callObjRef.current.isVideoCall()) {
                videoTrack = callObjRef.current.getVideoTrack(localStreamRef.current);
                if (videoTrack) {
                    videoTrack.stop();
                    videoTrackDisabled.current = true;
                }
            } else {
                videoTrack = callObjRef.current.getVideoTrack(localStreamRef.current);

                var localVideo = $("#call_container #localVideo");
                attachMedia(localVideo[0], videoTrack);
            }

            chatManagerRef.current.stopCallRingtone();
            callObjRef.current.callStatus = "RECEIVED";
            chatManagerRef.current.displayCallActions();
            chatManagerRef.current.createPeerConnection();
            callObjRef.current.updateCallStatus();
            chatManagerRef.current.setCallStatus('Connecting ...');
            clearTimeout(callTimerRef.current);
            callTimerRef.current = null;
            callTimerRef.current = setTimeout(function () {
                console.log("executing timeout", callObjRef.current.callStatus)
                if (callObjRef.current.callStatus == "RECEIVED") {
                    callObjRef.current.callStatus = 'END';
                    callObjRef.current.updateCallStatus();
                    chatManagerRef.current.hangUpCall();
                }
            }, 15000);
        }
    }

    async function getLocalStreamDuringCall() {
        chatManagerRef.current.releaseMediaStream();
        try {
            localStreamRef.current = await navigator.mediaDevices.getUserMedia(videoCallConstraints);
            return localStreamRef.current;
        } catch (err) {
            return null;
        }
    }


    function handleMediaSuccessCaller(stream) {
        // console.log("handleMediaSuccessCaller");
        if (callObjRef.current.callStatus == "IDLE" && callObjRef.current.toUuid) {
            localStreamRef.current = stream;
            var contact = chatManagerRef.current.getContact(callObjRef.current.toUuid);

            callObjRef.current.initiateCall();
            // 45 second timeout if call is not picked up or reached destination device/dashboard then it will be considered as missed call
            callTimerRef.current = setTimeout(function () {
                // console.log("CANVAS ** going to end the call if not received on destination device within 45 seconds");
                if (callObjRef.current.callStatus == "RINGING-OUTGOING") {
                    callObjRef.current.callStatus = "END";
                    callObjRef.current.updateCallStatus();
                    chatManagerRef.current.stopCallRingtone();
                    chatManagerRef.current.hangUpCall();
                }
            }, 45 * 1000);

            chatManagerRef.current.playCallRingtone();
            callObjRef.current.callStatus = "RINGING-OUTGOING";
            chatManagerRef.current.setCallStatus("Ringing ...");
            // $(".start-call-btn").hide();
            chatManagerRef.current.initializeCallScreen(contact);
        }
    }

    function handleMediaError() {
        console.log("handleMediaError");
        chatUiMgrRef.current.errorMessage("To call, allow " + siteTitle + " to access your Microphone.", "danger");
    }

    function onIceCandidate(event) {
        if (!peerConnection.current || !event || !event.candidate) return;
        console.log("CANVAS ** Sending ice candidate to im-server...", event.candidate);
        callObjRef.current.sendCandidateParameters(event.candidate);
    }

    function onICEStateChange(event) {
        console.log("New Chat onICEStateChange :: event: ", event, peerConnection.current.iceConnectionState);
        switch (peerConnection.current.iceConnectionState) {
            case "checking":
                // connecting to peer
                console.log("CANVAS ** Ice checking");
                break;
            case "connected":
                console.log("CANVAS ** Ice connected");
                if (callObjRef.current.callStatus != "CONNECTED") {
                    chatManagerRef.current.setCallStatus("Connected");
                    callObjRef.current.callStatus = "CONNECTED";
                    callObjRef.current.updateCallStatus();
                }
                break;
            case "completed":
                console.log("CANVAS ** Ice complete");
                break;
            case "disconnected":
                console.log("disconnected");
                // TODO:: this state we can handle for reconnect webrtc call
                if (callObjRef.current.callStatus == "CONNECTED") {
                    callObjRef.current.callStatus = "CLEAR";
                    callObjRef.current.updateCallStatus();
                }
                chatManagerRef.current.hangUpCall();
                break;
            case "failed":
                console.log("CANVAS ** Ice connection failed...");
                chatManagerRef.current.notifyBusy("Connection failed due to poor network");
                break;
            case "closed":
                console.log("CANVAS ** Ice closed...");
                break;
        }
    }

    /**
     * PeerConnection's DataChannel Ready Callback
     */
    function onDataChannelReady(event) {
        console.log('data channel is ready', event.channel);
        if (!peerConnection || !event || !event.channel) return;
        dataChannel.current = event.channel;

        // attach channel events
        dataChannel.current.onopen = onDataChannelOpened;
        dataChannel.current.onmessage = onDataChannelMessage;
        dataChannel.current.onerror = onDataChannelError;
        console.log('data channel callback set');
    }


    /**
     * Data channel open callback, when the channel opens this method
     * gets executed.
     */
    function onDataChannelOpened() {
        console.log('DataChannel has been opened... %s', dataChannel.readyState);
    }

    /**
     *
     * @param  {Object} event Message event, the `data` property contains the JSON
     */
    function onDataChannelMessage(event) {
        if (!peerConnection || !event || !event.data) return;
        if (event.data) {
            console.log('DataChannel Message ', event.data);
            var message = JSON.parse(event.data);
            if (message.type == callObjRef.current.DATAMESSAGETYPE.streamUpdate) {
                switch (message["data"].streamUpdateType) {
                    case callObjRef.current.STREAMUPDATETYPE.audioOff:
                        toggleRemoteAudio(false)
                        $('#call_container .micro-phone-state').show();
                        break;
                    case callObjRef.current.STREAMUPDATETYPE.audioOn:
                        $('#call_container .micro-phone-state').hide();
                        toggleRemoteAudio(true)
                        break;
                    case callObjRef.current.STREAMUPDATETYPE.videoOff:
                        toggleRemoteVideo(false)
                        break;
                    case callObjRef.current.STREAMUPDATETYPE.videoOn:
                        toggleRemoteVideo(true);
                        break;
                }
            }
        }

    }

    function sendMessageOnDataChannel(message) {
        // FIXME: handle dataChannel state updates, received readyState is not open error here
        if (dataChannel.current) dataChannel.current.send(JSON.stringify(message));
    }

    async function replaceStreams() {
        var stream = await getLocalStreamDuringCall();
        if (!stream) {
            chatUiMgrRef.current.errorMessage('To switch to video, allow ' + siteTitle + ' to access your Camera.', 'danger');
            return;
        }

        localStreamRef.current.getTracks().forEach(
            function (track) {
                if (track.kind == "audio") {
                    track.enabled = callObjRef.current.voiceEnabled;
                }
            }
        );

        var videoTrack = callObjRef.current.getVideoTrack(localStreamRef.current);
        var audioTrack = callObjRef.current.getAudioTrack(localStreamRef.current);

        var localVideo = $("#call_container #localVideo");
        attachMedia(localVideo[0], videoTrack);
        peerConnection.current.getSenders()[0].replaceTrack(audioTrack);
        peerConnection.current.getSenders()[1].replaceTrack(videoTrack);
        videoTrackDisabled.current = false;
    }

    function toggleRemoteVideo(videoEnabled) {
        if (videoEnabled) {
            $("#call_container #remoteVideo").show();
        } else {
            $("#call_container #remoteVideo").hide();
        }
    }

    function toggleRemoteAudio(audioEnabled) {
        console.log("audio state changed", audioEnabled);
    }


    /**
     * Data channel error handler
     * @param  {Object} error Error object
     */
    function onDataChannelError(error) {
        console.log('DataChannel Error', error);
    }

    function onRemoteStreamReceived(rtcMedia) {
        console.log('onRemoteStreamReceived', rtcMedia);
        var remoteAudio = $("#call_container #remoteAudio");
        var remoteVideo = $("#call_container #remoteVideo");

        var stream = rtcMedia.streams[0];
        var audioTrack = callObjRef.current.getAudioTrack(stream);
        if (audioTrack) {
            attachMedia(remoteAudio[0], audioTrack);
        }
        var videoTrack = callObjRef.current.getVideoTrack(stream);
        attachMedia(remoteVideo[0], videoTrack);

        if (callObjRef.current.isVideoCall()) {
            remoteVideo.show();
        }
    }

    // Attach a media stream to an element.
    function attachMedia(element, track) {
        try {
            var stream = new MediaStream();
            stream.addTrack(track);
            if (typeof element.srcObject !== 'undefined') {
                element.srcObject = stream;
            } else if (typeof element.mozSrcObject !== 'undefined') {
                element.mozSrcObject = stream;
            } else if (typeof element.src !== 'undefined') {
                element.src = URL.createObjectURL(stream);
            } else {
                console.log('Error attaching stream to element.');
            }
            element.load();

            element.addEventListener('play', function () {
                var $this = this; //cache
                (function loop() {
                    if (!$this.paused && !$this.ended) {

                    }
                })();
            }, 0);
        } catch (error) {
            console.log('error in attachMedia', error.message)
        }
    }

    function chatUiManager() {
        return {
            hasNewMessages: false,
            lastTypedMessageForConversation: {},
            messageToEdit: null,
            messageToReply: null,
            attachEvents: function () {
                // NOTE: keeping calling functions until migrated
                $("#chat_container .right .start-call-btn").on("click", function () {
                    var toUUID = chatUiMgrRef.current.rootElement().find(".right #all_chats .active-chat").data("chat");
                    var contactChatObj = chatManagerRef.current.getChat(toUUID);
                    if (callObjRef.current.callStatus == "IDLE" && !contactChatObj.readOnly) {
                        callObjRef.current.toUuid = toUUID;
                        callObjRef.current.callId = chatManagerRef.current.getCallUuid();
                        navigator.mediaDevices.getUserMedia(mediaStreamConstraints).then(handleMediaSuccessCaller).catch(handleMediaError);
                    }
                });

                $("#chat_container .right .ringing-btn").on("click", function () {
                    $(this).hide();
                    $(".start-call-btn").show();
                    chatManagerRef.current.stopCallRingtone();
                });
            },

            formatReportTime: function (timestamp) {
                var msgTimeObj = moment(timestamp);
                return msgTimeObj.format('DD MMMM YYYY') + " " + this.formatMsgDate(timestamp);
            },

            getActiveChatObj: function () {
                // STORE: start
                var toUUID = selectedChatOwnerInfo.toUUID;
                if (chatManagerRef.current.isGroup(toUUID)) {
                    var chatObj = chatManagerRef.current.getGroupChat(toUUID);
                } else {
                    chatObj = chatManagerRef.current.getChat(toUUID);
                }
                return chatObj;
            },

            errorMessage: function (message, type) {
                $.notify(
                    {
                        message: message,
                    },
                    {
                        type: type,
                        z_index: 1202,
                        offset: {
                            x: 20,
                            y: 90,
                        },
                    }
                );
            },
            markConversationReadOnly: function (withUuid) {
                chatUiMgrRef.current.errorMessage("markConversationReadOnly", "danger");
            },

            notify: function (notification) {
                $(".notify-chat").show();
                setTimeout(function () {
                    $(".notify-chat").hide();
                }, notification.interval * 1000);
            },

            updateNoGroupAvailableMessage: function () {

            },

            resizeChatContainer: function () {

            },

            showHideScrollArrow: function (chatObj) {

            },

            openChat: function () {

            },

            nullifyNormalTextEditor: function () {

            },

            nullifyRichTextEditor: function () {
                quill.root.innerHTML = "";
            },

            formatBytes: function (bytes, decimals) {
                if (bytes == 0) return "0 Bytes";
                var k = 1024,
                    dm = decimals + 1 || 3,
                    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
            },

            closeRichTextReply: function (resetRichEditor) {

            },

            setRichEditorHeight: function () {

            },

            closeChat: function () {

            },

            updateGroupInfo: function (chatObj, group) {

            },

            updateContact: function (updatedContact) {
                if (chatMetaDataRef.current.selfUUID !== updatedContact.uuid) {
                    let contacts = {}
                    contacts = chatManagerRef.current.contactUpdated(updatedContact);
                    updateContactList(contacts)
                }
            },

            addContact: function (contact) {
                var chatObj = chatManagerRef.current.getChat(contact.uuid);

                this.ensureConversationUiExist(chatObj);
            },

            formatMessageForUi: formatMessageForUi,

            checkMessageExistOnUI: function (message) {

            },

            appendChatHistory: async function (withUuid, messages, callback) {
                var today = moment();
                if (chatManagerRef.current.isGroup(withUuid)) {
                    var chatObj = chatManagerRef.current.getGroupChat(withUuid);
                    var group = chatManagerRef.current.getGroup(withUuid);
                    if (!group.subscribed) {
                        return;
                    }
                    var group = chatManagerRef.current.getGroup(withUuid);
                } else {
                    var chatObj = chatManagerRef.current.getChat(withUuid);
                }

                this.makeChatVisible(withUuid);

                callback();
            },

            showFileMessage: function (messageObj, fromUuid, container, msgTimeUi, chatObj, sender, position) {
            },

            showCallMessage: function (message, container, msgTimeUi, chatObj, position) {
            },

            showSubscriberAddedRemovedMessage: function (messageObj, container, msgTimeUi, position) {
            },

            chatUiMgrRef: function () {
                var allGroupList = Object.values(chatManagerRef.current.groupList);
                var broadcastGroupList = allGroupList.filter(function (group) {
                    return group.groupType == broadCast && group.visible && (group.canJoin || group.canLeave || group.subscribed);
                });

                var nonBroadcastGroupList = allGroupList.filter(function (group) {
                    return group.groupType != broadCast && group.visible && (group.canJoin || group.canLeave || group.subscribed);
                });
            },

            handleCurrentChatScreen(uuid) {

            },

            updateChattability: function (chatObj, group) {
                /**
                 * // TODO:
                 * 1. If chatObj.isGroupChat() && group && group.groupType == broadCast 
                 *      - disable send button
                 *      - handle read only chat
                 *      - handle broadcast channel 
                 */
            },

            updateConversationMessageActions: function (uuid, readOnly) {
                setTimeout(function () {
                    chatUiMgrRef.current.toggleMessageActions("delete", uuid, readOnly || !deleteEnabled);
                    chatUiMgrRef.current.toggleMessageActions("edit", uuid, readOnly || !editEnabled);
                    if (chatUiMgrRef.current.messageToEdit) {
                        chatUiMgrRef.current.messageToEdit = null;
                        $("#user_chat_msg").val("");
                        $(document).trigger("action-btn-state-changed", "mike-btn");
                    }
                }, 500);
            },

            toggleMessageActions: function (messageAction, uuid, disabled) {

            },

            formatDuration: function (callDuration) {
                var callDurationStr = "";
                if (callDuration.hours() > 0) {
                    callDurationStr = callDurationStr + callDuration.hours() + "h ";
                }
                if (callDuration.minutes() > 0) {
                    callDurationStr = callDurationStr + callDuration.minutes() + "m ";
                }
                if (callDuration.seconds() > 0) {
                    callDurationStr = callDurationStr + callDuration.seconds() + "s ";
                }
                return callDurationStr;
            },

            incrementUnreadCount: function (withUuid) {

            },

            appendChatMessageNew: async function (withUuid, fromUuid, messageObj, msgTime, msgStatus, forceDownScroll) {
                var msgUuid = "";
                var msgId = "";
                msgUuid = messageObj.msgUuid || messageObj.messageUuid || "";
                msgId = messageObj.msgId || messageObj.messageId || "";
                forceDownScroll = forceDownScroll || false;
                chatManagerRef.current.setLastMessageReceivedAt(msgId);

                var allChatsContainer = $("#all_chats")[0];
                var isAtBottom = allChatsContainer.scrollTop + allChatsContainer.clientHeight == allChatsContainer.scrollHeight;

                if (chatManagerRef.current.isGroup(withUuid)) {
                    var chatObj = chatManagerRef.current.getGroupChat(withUuid);
                    var subscriberList = chatManagerRef.current.subscriberList;
                } else {
                    var chatObj = chatManagerRef.current.getChat(withUuid);
                }
                var chatContainer = $('#all_chats .chat[data-chat="' + withUuid + '"]');
                if (chatObj.isEvaActivityChat()) {
                    var msgTimeUi = moment(new Date(msgTime)).format("DD MMMM YYYY [at] LT");

                    var messageJson = JSON.parse(messageObj.message);
                    var messageToDisplay = this.formatBotMessage(messageJson);
                    var msgClass = "normal";

                    if (messageJson.type == 1) {
                        //Device locked
                        msgClass = "info";
                    } else if (messageJson.type == 2) {
                        //Device unlocked
                        msgClass = "alert";
                    } else if (messageJson.type <= 5) {
                        //Unlock failed, Sim Incident and Geo fence
                        msgClass = "warn";
                    } else if (messageJson.type == 6) {
                        msgClass = "lightgreen";
                    } else if (messageJson.type == 7) {
                        msgClass = "bluegray";
                    }
                    if (messageToDisplay) {

                    }
                } else {
                    var sender = chatManagerRef.current.getUserDisplayName(messageObj.fromUuid);
                    if (messageObj.isReply) {
                        var parentSender = chatManagerRef.current.getUserDisplayName(messageObj.parentSenderUuid);
                    }

                    var msgTimeUi = chatUiMgrRef.current.formatMsgDate(msgTime);

                    var today = moment();
                    var msgTimeObj = moment(msgTime);
                    var isDateToday = today.startOf("day").isSame(msgTimeObj.startOf("day"));
                    var chatStartDateStr = chatUiMgrRef.current.formatMsgDay(msgTime);
                    var chatStartDateFormatted = msgTimeObj.format("DD MMMM YYYY");

                    var chatStartDateElement = chatContainer.find('.conversation-start[data-chat-start-date="' + chatStartDateFormatted + '"]');

                    if (chatStartDateElement.length == 0) {
                        if (isDateToday) {
                            // chatContainer.append('<div class="conversation-start" data-chat-start-date="' + chatStartDateFormatted + '"><span>' + chatStartDateStr + "</span></div>");
                        } else {
                            // chatContainer.prepend('<div class="conversation-start" data-chat-start-date="' + chatStartDateFormatted + '"><span>' + chatStartDateStr + "</span></div>");
                        }
                    }

                    if (messageObj.messageType && messageObj.messageType == 61) {
                        //Contact added to the group
                        var msg = JSON.parse(messageObj.message);
                        chatManagerRef.current.addActiveSubscriber(chatObj.withUuid, msg.contact);
                        var group = chatManagerRef.current.getGroup(chatObj.withUuid);
                        if (group && group.groupType != broadCast) {
                        }

                        if (msg.contact && msg.contact.uuid == chatMetaDataRef.current.selfUUID) {
                            var group = chatManagerRef.current.getGroup(chatObj.withUuid);
                            group.subscribed = true;
                        }
                    } else if (messageObj.messageType && messageObj.messageType == 62) {
                        //Contact removed from the group
                        msg = JSON.parse(messageObj.message);
                        chatManagerRef.current.removeActiveSubscriber(chatObj.withUuid, msg.contact);
                        var group = chatManagerRef.current.getGroup(chatObj.withUuid);
                        if (group && group.groupType != broadCast) {
                        }

                        if (msg.contact && msg.contact.uuid == chatMetaDataRef.current.selfUUID) {
                            group.subscribed = false;
                            if ($('#all_chats .active-chat[data-chat="' + group.uuid + '"]').length > 0) {
                                chatUiMgrRef.current.updateChattability(chatObj, group);
                            }
                        }
                    } else if (messageObj.messageType && messageObj.messageType == 63) {
                        //Contact joined the group
                        msg = JSON.parse(messageObj.message);
                        chatManagerRef.current.addActiveSubscriber(chatObj.withUuid, msg.contact);
                        var group = chatManagerRef.current.getGroup(chatObj.withUuid);
                        if (group && group.groupType != broadCast) {
                        }
                    } else if (messageObj.messageType && messageObj.messageType == 64) {
                        //Contact left the group
                        msg = JSON.parse(messageObj.message);
                        chatManagerRef.current.removeActiveSubscriber(chatObj.withUuid, msg.contact);
                        var group = chatManagerRef.current.getGroup(chatObj.withUuid);
                        if (group && group.groupType != broadCast) {
                        }
                    } else if (messageObj.messageType && messageObj.messageType == 65) {
                        //Contact removed from the account
                        var msg = JSON.parse(messageObj.message);
                        chatManagerRef.current.removeActiveSubscriber(chatObj.withUuid, msg.contact);
                        var group = chatManagerRef.current.getGroup(chatObj.withUuid);
                        if (group && group.groupType != broadCast) {
                        }
                    } else if (messageObj.messageType && messageObj.messageType == chatObj.messageType["FILE"]) {
                        attachmentUiMgrRef.current.showFileMessage(messageObj, fromUuid, chatContainer, msgTimeUi, chatObj, sender, "append", deleteEnabled, replyEnabled);
                    } else {
                        var formattedMessage = formatMessageForUi(chatObj.decryptMessage(messageObj.message, messageObj.iv), messageObj.isBroadcast);
                        var canViewInfo = !(messageObj.isReply && messageObj.isBroadcast);
                        var canViewReplies = false;
                        if (messageObj.isReply) {
                            var parentMessage = formatMessageForUi(chatObj.decryptMessage(messageObj.parentMessage, messageObj.iv), messageObj.isBroadcast);
                            var parentSentAt = chatUiMgrRef.current.formatMsgFullDayTime(messageObj.parentSentAt);
                        } else {
                            group = chatManagerRef.current.getGroup(chatObj.withUuid);
                            if (group && group.canPost && messageObj.isBroadcast && messageObj.replyAllowed) {
                                canViewReplies = true;
                            }
                        }
                        // STORE: start
                        // dispatch({
                        //     type: types.SET_YOUR_CHAT,
                        //     payload: {
                        //         chat_msg: formattedMessage,
                        //         msg_time: msgTimeUi,
                        //     },
                        // });
                        // No if else please
                        // STORE: we want to clear unread count determine weather its a contact or group
                        if (messageObj.fromUuid === selectedChatOwnerInfo.toUUID || messageObj.toUuid === selectedChatOwnerInfo.toUUID) {
                            // Only add this msg if respective chat is open or show notification on contact
                            // This appends this message at top
                            // dispatch({
                            //     type: types.SET_SELECTED_CHAT_LIST,
                            //     payload: [newChatmessage],
                            // });
                        } else {
                            // STORE: check weather this chat is not active only then increment read count
                            // Update unread count state
                            // dispatch({
                            //     type: types.SET_CONTACT_UNREAD_COUNT,
                            //     payload: messageObj,
                            // });
                        }
                        // STORE: end

                        chatContainer.find(".dropdown-toggle").dropdown();
                    }

                    let chatList
                }

            },

            formatMsgDate: function (timestamp) {
                var msgDate = new Date(timestamp);
                return "" + msgDate.getHours() + ":" + ((msgDate.getMinutes() < 10 ? "0" : "") + msgDate.getMinutes());
            },

            formatMsgDay: function (timestamp) {
                var today = moment();
                var yesterday = moment().add(-1, "days");
                var msgTimeObj = moment(timestamp);
                var isDateToday = today.startOf("day").isSame(msgTimeObj.startOf("day"));
                var isDateYesterday = yesterday.startOf("day").isSame(msgTimeObj.startOf("day"));
                return isDateToday ? "Today" : isDateYesterday ? "Yesterday" : msgTimeObj.format("DD MMMM YYYY");
            },

            formatMsgFullDayTime: function (timestamp) {
                return this.formatMsgDay(timestamp) + " " + this.formatMsgDate(timestamp);
            },

            makeChatVisible: function (withUuid) {
                // FIXME: commented check later
                // if (chatManagerRef.current.isGroup(withUuid)) {
                //     var chatObj = chatManagerRef.current.getGroupChat(withUuid);
                //     this.ensureActiveChatExist(chatObj);
                // } else {
                //     var chatObj = chatManagerRef.current.getChat(withUuid);
                //     this.ensureActiveChatExist(chatObj);
                // }
                // var el = $('#active_chats_container .person[data-chat="' + withUuid + '"]')[0];
                // var elemTop = el.getBoundingClientRect().top;
                // var elemBottom = el.getBoundingClientRect().bottom;
                // var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;

                // //If the chat person is not visible in the list then bring him to top
                // if (!isVisible) {
                //     $(el).hide().insertAfter("#active_chats_container .person:first").fadeIn();
                // }
            },

            setFileId: function (withUuid, messageUuid, fileId) {
                // var chatContainer = $('#all_chats .chat[data-chat="' + withUuid + '"]');
                // var chatMessageContainer = $(chatContainer).find('.chat-msg-e[data-msg-uuid="' + messageUuid + '"]');
                // chatMessageContainer.attr("data-msg-file-id", fileId);
            },

            updateFileStatus: function (withUuid, messageUuid, status, messageType) {
                // var chatContainer = $('#all_chats .chat[data-chat="' + withUuid + '"]');
                // var chatMessageContainer = $(chatContainer).find('.chat-msg-e[data-msg-uuid="' + messageUuid + '"]');
                // if (messageType == 3) {
                //     this.updateAudioMessageStatus(chatMessageContainer, status);
                // } else if (messageType == 4) {
                //     this.updateImageMessageStatus(chatMessageContainer, status);
                // } else if (messageType == 5) {
                //     this.updateFileMessageStatus(chatMessageContainer, status);
                // }
            },

            updateImageMessageStatus: function (chatMessageContainer, status) {
                //TODO: handle statuses on UI
                // var actionBtn = chatMessageContainer.find(".action-btn");
                // if (status == 3) {
                //     var imgContainer = $(chatMessageContainer).find(".view-full-image");
                //     imgContainer.find(".placeholder").hide();
                //     imgContainer.addClass("placeholder-img");
                //     actionBtn.removeClass("sending");
                //     actionBtn.addClass("retry");
                //     actionBtn.show();
                //     var messageStatusContainer = $(chatMessageContainer).find(".message-status");
                //     messageStatusContainer.removeClass("sending").addClass("failed");
                // } else {
                //     actionBtn.hide();
                //     actionBtn.closest(".view-full-image").removeClass("placeholder-img");
                //     actionBtn.siblings(".placeholder").show();
                // }
            },

            updateFileMessageStatus: function (chatMessageContainer, status) {
                //TODO: handle statuses on UI
                // var actionBtn = chatMessageContainer.find(".message-action-btn");
                // actionBtn.removeClass("sending");
                // if (status == 3) {
                //     var messageStatusContainer = $(chatMessageContainer).find(".message-status");
                //     messageStatusContainer.removeClass("sending").addClass("failed");
                //     actionBtn.addClass("retry");
                // } else {
                //     actionBtn.addClass("download-file");
                // }
            },

            updateAudioMessageStatus: function (chatMessageContainer, status) {
                //TODO: handle statuses on UI
                // var actionBtn = chatMessageContainer.find(".message-action-btn");
                // actionBtn.removeClass("sending");
                // if (status == 3) {
                //     var messageStatusContainer = $(chatMessageContainer).find(".message-status");
                //     messageStatusContainer.removeClass("sending").addClass("failed");
                //     actionBtn.addClass("retry");
                // } else {
                //     actionBtn.addClass("play");
                // }
            },

            markMessageSent: function (withUuid, messageId, messageUuid) {
                let a = conversationList.map(message => {
                    if (message.msg_uuid == messageUuid) {
                        message.msg_status = "sent"
                        message.msg_id = messageId
                    }
                    return message;
                })
                setConversationList(a)
            },

            markMessageDelivered: function (withUuid, messageId, messageUuid) {
                console.log('markMessageDelivered chatUiManager', messageUuid)
                let list = conversationList.map(message => {
                    if (message.msg_id == messageId) {
                        message.msg_status = "delivered"
                    }
                    return message;
                })
                setConversationList(list)
            },

            markMessageRead: function (withUuid, messageId) {
                let list = conversationList.map(message => {
                    if (message.msg_id == messageId) {
                        message.msg_status = "read"
                    }
                    return message;
                })
                setConversationList(list)
            },

            markMessageOpen: function (withUuid, messageId) {
                let list = conversationList.map(message => {
                    if (message.msg_id == messageId) {
                        message.msg_status = "open"
                    }
                    return message;
                })
                setConversationList(list)
            },

            markMessageFailed: function (withUuid, messageUuid) {
                markMessageFailed(messageUuid)
                chatUiMgrRef.current.errorMessage("Retry sending message handling", "danger");
                //TODO: handle failed UI
                // var chatContainer = $('#all_chats .chat[data-chat="' + withUuid + '"]');
                // var chatMessageContainer = $(chatContainer).find('.chat-msg-e[data-msg-uuid="' + messageUuid + '"]');
                // var messageStatusContainer = $(chatMessageContainer).find(".message-status");
                // messageStatusContainer.addClass("failed").removeClass("sending");
                // $(chatMessageContainer).find(".message-action-btn").removeClass("sending").addClass("retry");
                // var imageContainer = $(chatMessageContainer).find(".view-full-image");
                // if (imageContainer) {
                //     console.log("image message");
                //     $(imageContainer).addClass("placeholder-img");
                //     $(imageContainer).find(".placeholder").hide();
                //     $(imageContainer).find(".action-btn").removeClass("sending").addClass("retry").show();
                // }
            },

            isChatPopupOpen: function () {
                // return $("#chat_container").is(":visible");
            },

            rootElement: function () {
                return $("#chat_container");
            },

            isConversationOpen: function (chatObj) {
                if (this.isChatPopupOpen) {
                    if (chatObj.chatContainer().is(":visible")) {
                        return true;
                    }
                }

                return false;
            },

            removeContact: function (contact) {
                // TODO: revisit
                // $('#contact_list .person[data-chat="' + contact.uuid + '"]').remove();
            },

            removeGroup: function (group) {
                // TODO: revisit
                // if (group.groupType == broadCast) {
                //     $('#broadcast_group_list .person[data-chat="' + group.uuid + '"]').remove();
                // } else {
                //     $('#group_list .person[data-chat="' + group.uuid + '"]').remove();
                // }

                // var activeChatObject = $('#active_chats_container .person[data-chat="' + group.uuid + '"]');
                // if (activeChatObject.length > 0) {
                //     setTimeout(function () {
                //         $("#active_chats_container li").first().trigger("click");
                //     }, 10);
                // }
                // activeChatObject.remove();
            },

            removeGroupConversation: function (group) {
                // TODO: revisit
                // var activeChatObject = $('#active_chats_container .person[data-chat="' + group.uuid + '"]');
                // var chatObject = $('#all_chats [data-chat="' + group.uuid + '"]');
                // activeChatObject.hide();
                // chatObject.hide();
            },

            setGlobalUnreadCount: function (count) {
                // TODO: revisit
                // var unreadMsgContainer = $("#eva-global-unread-count");
                // this.hasNewMessages = true;
                // localStorage.setItem("eva-new-messages-available", "true");
                // unreadMsgContainer.removeClass("hidden");
            },

            ensureConversationUiExist: function (chatObj) {
                if (chatObj.isOneToOne()) {
                    var withUuid = chatObj.withUuid;
                    var contact = chatManagerRef.current.getContact(withUuid);
                    var avatarThumbUrl = null;
                    var unreadCount = chatObj.getUnreadMessageCount();
                    if (unreadCount > 0) {
                        alert('getUnreadMessageCount')
                    }
                } else if (chatObj.isGroupChat()) {
                    this.ensureGroupConversationUiExist(chatObj);
                }
            },

            handleLeaveJoinButton: function (group, withUuid) {
                //TODO: revisit
                // if (group.canLeave) {
                //     $('#group_list .person[data-chat="' + withUuid + '"] .leave-button').show();
                //     $('#group_list .person[data-chat="' + withUuid + '"] .join-button').hide();
                // } else if (group.canJoin) {
                //     $('#group_list .person[data-chat="' + withUuid + '"] .leave-button').hide();
                //     $('#group_list .person[data-chat="' + withUuid + '"] .join-button').show();
                // } else {
                //     $('#group_list .person[data-chat="' + withUuid + '"] .leave-button').hide();
                //     $('#group_list .person[data-chat="' + withUuid + '"] .join-button').hide();
                // }
            },

            ensureGroupConversationUiExist: function (chatObj) {
                var withUuid = chatObj.withUuid;
                var group = chatManagerRef.current.getGroup(withUuid);
                var avatarThumbUrl = null;

                if (!chatManagerRef.current.canShowGroupInList(group.uuid)) {
                    chatManagerRef.current.groupRemoved(group);
                    console.log('remove group ensureGroupConversationUiExist')
                    return;
                }

                if (group.groupType == broadCast) {
                    // TODO: revisit
                    // NOTE: rendering broadcast list element
                    // if ($('#broadcast_group_list .person[data-chat="' + withUuid + '"]').length == 0) {
                    //     group.groupLabel = chatUiMgrRef.current.getGroupLabel(group.groupType);
                    //     var groupChatHtml = groupTemplate(group);
                    //     $("#broadcast_group_list").append(groupChatHtml);
                    // } else {
                    //     //    Update Group Info
                    //     var groupElement = $('#broadcast_group_list .person[data-chat="' + withUuid + '"]');
                    //     groupElement.find(".name").html(group.name);
                    //     groupElement.find(".user-avatar").html(group.avatar_image_html);
                    //     groupElement.find(".group-type").html(chatUiMgrRef.current.getGroupLabel(group.groupType));
                    // }
                } else {
                    // NOTE: rendering group or contact element
                    // if ($('#group_list .person[data-chat="' + withUuid + '"]').length == 0) {
                    //     group.groupLabel = chatUiMgrRef.current.getGroupLabel(group.groupType);
                    //     var groupChatHtml = groupTemplate(group);
                    //     $("#group_list").append(groupChatHtml);
                    // } else {
                    //     //    Update Group Info
                    //     var groupElement = $('#group_list .person[data-chat="' + withUuid + '"]');
                    //     groupElement.find(".name").html(group.name);
                    //     groupElement.find(".user-avatar").html(group.avatar_image_html);
                    //     groupElement.find(".group-type").html(chatUiMgrRef.current.getGroupLabel(group.groupType));
                    // }
                }
                // NOTE: weather group or contact is allowed to be shown in UI
                // NOTE: For readonly and visibility of groups, we just hide UI and keep the record 
                // if (chatManagerRef.current.canShowGroupConversationInList(group.uuid)) {
                //     if ($('#all_chats .group-chat[data-chat="' + withUuid + '"]').length == 0) {
                //         var contactChatHtml = contactChatTemplate(group);
                //         $("#all_chats").append(contactChatHtml);
                //     } else {
                //         // Update Group Info
                //         var groupElement = $('#all_chats .chat[data-chat="' + withUuid + '"]');
                //         groupElement.find(".name").text(group.name);
                //         groupElement.find(".user-avatar").text(group.avatar_image_html);
                //         groupElement.find(".group-type").text(chatUiMgrRef.current.getGroupLabel(group.groupType));
                //     }
                // }

                // var unreadCount = chatObj.getUnreadMessageCount();
                // this.handleLeaveJoinButton(group, withUuid);
                // if (unreadCount > 0) {
                //     if (group.groupType == broadCast) {
                //         //set unread message count for broadcast group
                //         $('#broadcast_group_list .person[data-chat="' + withUuid + '"] .unread-count')
                //             .data("count", unreadCount)
                //             .html(unreadCount)
                //             .show();
                //     } else {
                //         // Set unread message count for broadcast group chat
                //         $('#group_list .person[data-chat="' + withUuid + '"] .unread-count')
                //             .data("count", unreadCount)
                //             .html(unreadCount)
                //             .show();
                //     }

                //     $('#active_chats_container .person[data-chat="' + withUuid + '"] .unread-count')
                //         .data("count", unreadCount)
                //         .html(unreadCount)
                //         .show();
                // }
            },

            ensureActiveChatExist: function (chatObj) {
                if (chatObj.isOneToOne() || chatObj.isGroupChat()) {
                    var withUuid = chatObj.withUuid;
                    if (chatObj.isGroupChat()) {
                        var contact = chatManagerRef.current.getGroup(withUuid);
                    } else {
                        contact = chatManagerRef.current.getContact(withUuid);
                    }

                    if (!contact) {
                        return;
                    }
                    var displayName = contact.name;

                    var avatarThumbUrl = null;

                    if ($('#active_chats_container .person[data-chat="' + withUuid + '"]').length == 0) {
                        if (contact && chatObj.isGroupChat()) {
                            if (contact.subscribed) {

                            }
                        } else {
                            contact.device_name = displayName != contact.name ? " - " + contact.name : "";

                        }
                    }

                    var unreadCount = chatObj.getUnreadMessageCount();
                    if (unreadCount > 0) {
                    }
                }

            },

            //Returns true if there is any chat available in active chat screen
            activeChatsAvailable: function () {
                return this.rootElement().find("#active_chats_container li").length > 0;
            },

            formatBotMessage: function (messageJson) {
                var messageToDisplay = null;

                if (messageJson.msg) {
                    messageToDisplay = messageJson.msg;
                } else if (messageJson.type == 1) {
                    //Device locked
                    messageToDisplay = "Device locked - " + messageJson.name;

                    if (messageJson.imei_no) {
                        messageToDisplay += ", IMEI - " + messageJson.imei_no;
                    }

                    if (messageJson.group) {
                        messageToDisplay += ", Group - " + messageJson.group;
                    }
                } else if (messageJson.type == 2) {
                    //Device unlocked
                    messageToDisplay = "Device unlocked - " + messageJson.name;

                    if (messageJson.imei_no) {
                        messageToDisplay += ", IMEI - " + messageJson.imei_no;
                    }

                    if (messageJson.group) {
                        messageToDisplay += ", Group - " + messageJson.group;
                    }
                } else if (messageJson.type == 3) {
                    //Unlock failed
                    messageToDisplay = "Unlock Attempt Failed - Device - " + messageJson.name;

                    if (messageJson.imei_no) {
                        messageToDisplay += ", IMEI - " + messageJson.imei_no;
                    }

                    if (messageJson.group) {
                        messageToDisplay += ", Group - " + messageJson.group;
                    }
                }

                return messageToDisplay;
            },

            // STORE: using
            formatCallMessage: function (callDetail, messageFrom) {
                var from = chatManagerRef.current.getContact(callDetail.fromUuid);
                var to = chatManagerRef.current.getContact(callDetail.toUuid);
                var formattedMessage = "";

                if (chatMetaDataRef.current.selfUUID == messageFrom) {
                    if (callDetail.status == 7) {
                        formattedMessage = to.displayName + " missed a call from you";
                    } else if (callDetail.status == 4 || callDetail.status == 8) {
                        formattedMessage = "You tried to call " + to.displayName;
                    } else {
                        formattedMessage = "You called " + to.displayName;
                    }
                } else {
                    if (callDetail.status == 7 || callDetail.status == 8) {
                        formattedMessage = "You missed a call from " + from.displayName;
                    } else {
                        formattedMessage = "You received a call from " + from.displayName;
                    }
                }
                return formattedMessage;
            },

            formatSubscriberAddedRemovedMessage: function (msgObj) {
                var messageToDisplay = null;
                var messageJson = JSON.parse(msgObj.message);
                var subscriber = messageJson.contact ? (messageJson.contact.displayName ? messageJson.contact.displayName : messageJson.contact.name) : "Unknown contact";
                var action_by = chatManagerRef.current.getContact(messageJson.actionByUuid);
                if (msgObj.messageType == 61) {
                    //Contact added to the group
                    messageToDisplay = "New contact " + subscriber + " added to the channel";
                } else if (msgObj.messageType == 62) {
                    //Contact removed to the group
                    messageToDisplay = "Contact " + subscriber + " removed from the channel";
                } else if (msgObj.messageType == 63) {
                    //Contact joined to the group
                    messageToDisplay = "New contact " + subscriber + " joined the channel";
                } else if (msgObj.messageType == 64) {
                    //Contact left to the group
                    messageToDisplay = "Contact " + subscriber + " left the channel";
                } else if (msgObj.messageType == 65) {
                    //Contact left to the group
                    messageToDisplay = "Contact " + subscriber + " removed from the account";
                }

                return messageToDisplay;
            },

            handleActivityMessage: function (evaActivityBotUuid, activityMessage, fromHistory) {
                var activityMessageContainer = this.rootElement().find('.right .chat[data-chat="' + evaActivityBotUuid + '"]');
                var msgTimeUi = moment(new Date(activityMessage.sentAt)).format("DD MMMM YYYY [at] LT");

                var messageJson = JSON.parse(activityMessage.message);
                var messageToDisplay = this.formatBotMessage(messageJson);
                var msgClass = "normal";

                // console.log("handleActivityMessage :: messageJson: ", messageJson);

                if (messageJson.type == 1) {
                    //Device locked
                    msgClass = "info";
                } else if (messageJson.type == 2) {
                    //Device unlocked
                    msgClass = "alert";
                } else if (messageJson.type <= 5) {
                    //Unlock failed, Sim Incident and Geo fence
                    msgClass = "warn";
                } else if (messageJson.type == 6) {
                    msgClass = "lightgreen";
                } else if (messageJson.type == 7) {
                    msgClass = "bluegray";
                }

                // console.log("handleActivityMessage :: messageToDisplay: ", messageToDisplay);
                // console.log("handleActivityMessage :: evaActivityBotUuid: ", evaActivityBotUuid);
                if (messageToDisplay) {
                    if (fromHistory) {
                        activityMessageContainer.prepend(
                            evaActivityChatMessageTemplate({
                                chat_msg: messageToDisplay,
                                msg_time: msgTimeUi,
                                msg_class: msgClass,
                            })
                        );
                    } else {
                        activityMessageContainer.append(
                            evaActivityChatMessageTemplate({
                                chat_msg: messageToDisplay,
                                msg_time: msgTimeUi,
                                msg_class: msgClass,
                            })
                        );
                    }
                }

                this.showHideScrollArrow();
            },

            getDisplayableContact: function (contact) {
                var displayName = contact.displayName;
                var avatarThumbUrl = null;
                contact.device_name = displayName != contact.name ? " - " + contact.name : "";
                contact.displayName = displayName;
                contact.role = contact.role == undefined ? "User" : contact.role;
                return contact;
            },

            backFromInfoModal: function (uuid) {
            },

            showDeviceDetails: function (deviceDetailsInfo, chatObj) {

            },

            capitalizeWords: function (string) {
                string = string.replace(/_/, " ");
                return string.replace(/\w\S*/g, function (text) {
                    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
                });
            },

            getGroupLabel: function (groupType) {
                return "Channel (" + chatUiMgrRef.current.capitalizeWords(groupType.toLowerCase()) + ")";
            },


        };
    }
    function chatManager() {
        return {
            lastReceivedMessageId: 0,
            chats: { one_o_one: {}, group: {} },
            contactList: {},
            groupList: {},
            currentPttTarget: [],
            activeChatList: {},
            groupListNextPage: 1,
            subscriberList: {},
            searchContactFn: null,
            searchGroupFn: null,
            conversationList: [],
            contactListLoaded: false,
            messageUuids: {}, //Store a mapping of message-uuid -> Chat obj - Used when message sent in a chat object and acknowledged as received by server
            evaActivityContact: null,
            initialized: false,
            pendingMessages: [], //Will contain messages sent but whose sent ack is not received yet
            unprocessedChatMessages: [], // will contain messages recived from server but not processed yet
            pendingConversationInfoRequests: [], // will contain conversationIds whose requests is still pending

            initSuccessful: function () {
                if (!this.initialized || socketReconnectProcessing.current) {
                    this.initialized = true;
                    //Get conversations only if it's a case of refresh, no need to do it on re-connect
                    var getConversationsCommand = protoBuffRef.current.getConversationsCommand("1");
                    sendMessage(getConversationsCommand);
                }

                if (this.pendingMessages.length > 0) {
                    console.log("pending Messages ", this.pendingMessages);
                    //Resend pending messages
                    _.each(this.pendingMessages, function (pendingMessage) {
                        sendMessage(pendingMessage.msg);
                    });
                }
            },

            handleContactList: function (contactListResponse) {
                if (socketReconnectProcessing.current) {
                    // TODO: handle contact list controlls
                    // $("#contact_list").empty();
                }
                initialContactListLoaded.current = true;
                var totalCount = contactListResponse.totalCount;
                var contacts = contactListResponse.contacts;
                setContactsNextPage(contactListResponse.nextPage)
                var _this = this;
                var contactsAvailable = false;

                var _contacts = {}
                if (contacts && contacts.length > 0) {
                    $.each(contacts, function (i, contact) {
                        _contacts = _this.contactAdded(contact);

                        if (contact.visible) {
                            contactsAvailable = true;
                        } else if (contact.role == "BOT") {
                            _this.handleBot(contact);
                        }
                    });
                    setContactList(_contacts)
                }

                var getGroupListRequestCommand = protoBuffRef.current.getGroupListRequestCommand(1, "");
                sendMessage(getGroupListRequestCommand);
            },

            handleContactListRefresh: function (refreshedContactList) {
                var contacts = refreshedContactList.contacts;
                var _this = this;
                var _subscribers = {}
                if (contacts && contacts.length > 0) {
                    $.each(contacts, function (i, contact) {
                        var updatedContact = contact;
                        _subscribers = chatManagerRef.current.contactUpdated(updatedContact);
                        chatManagerRef.current.updateContact(updatedContact);
                    });
                    setSubscribersList(_subscribers)

                    //If no active chat is available then trigger click on first contact
                    if (!chatManagerRef.current.activeChatsAvailable()) {
                    }
                }

                var getGroupListRequestCommand = protoBuffRef.current.getGroupListRequestCommand(1, "");
                sendMessage(getGroupListRequestCommand);
            },

            handleGroupListRefresh: function (refreshedGroupList) {
                var groups = refreshedGroupList.groups;

                if (groups && groups.length > 0) {

                }
            },

            handleGroupList: function (groupListResponse) {
                if (socketReconnectProcessing.current) {
                    // $("#group_list").empty();
                }
                var _groupList = {}
                initialGroupListLoaded.current = true;
                var totalCount = groupListResponse.totalCount;
                var groups = groupListResponse.groups;
                this.groupListNextPage = groupListResponse.nextPage;
                if (groupListResponse.nextPage) setGroupsNextPage(groupListResponse.nextPage)
                var _this = this;
                var groupsAvailable = false;
                if (groups && groups.length > 0) {
                    // STORE: comment
                    if (groups && groups.length > 0) {
                        $.each(groups, function (i, group) {
                            var existingGroup = chatManagerRef.current.getGroup(group.uuid);
                            if (existingGroup) {
                                chatManagerRef.current.HandleUpdateGroupInfo(group);
                                groupsAvailable = true;
                            } else {
                                // Which causes it not to be found in list and therefore canShowGroupInList will be false 
                                // NOTE: Only store groups which are not already fetched from conversation list
                                if (!_this.groupList[group.uuid]) {
                                    _groupList[group.uuid] = group;
                                }
                                //
                                // FIXME: handle visibility of groups on UI
                                if (chatManagerRef.current.canShowGroupInList(group.uuid)) {
                                    var groupChatObj = _this.getGroupChat(group.uuid);
                                    // console.log('canShowGroupInList', groupChatObj)
                                    chatUiMgrRef.current.ensureGroupConversationUiExist(groupChatObj);
                                    groupsAvailable = true;
                                } else {
                                    // console.log('can not ShowGroupInList', group)
                                }
                            }
                        });
                        setGroupList(_groupList)
                    }
                }

                if (socketReconnectProcessing.current && chatManagerRef.current.getLastMessageReceivedAt() > 0) {
                    var getPendingMessageRequestCommand = protoBuffRef.current.getPendingMessageRequest(chatManagerRef.current.getLastMessageReceivedAt());
                    sendMessage(getPendingMessageRequestCommand);
                } else {
                    socketReconnectProcessing.current = false;
                }
            },

            loadMoreGroups: function (groupListNextPage) {
                var getGroupListRequestCommand = protoBuffRef.current.getGroupListRequestCommand(groupListNextPage, "");
                sendMessage(getGroupListRequestCommand);
            },

            loadMoreContacts: function (contactListNextPage = 1) {
                var getContactListRequestCommand = protoBuffRef.current.getContactListRequestCommand(contactListNextPage, "");
                sendMessage(getContactListRequestCommand);
            },

            handleGroupSubscriptionResponse: function (groupSubscriptionResponse) {
                var success = groupSubscriptionResponse.success;
                if (!success) {
                    console.log("Handle error: " + groupSubscriptionResponse.action);
                }
                var group = groupSubscriptionResponse.group;
                let _groupList = groupSubscriptionResponse.group;
                let _subList = {};
                if (group) {
                    _groupList = this.groupAdded(group);
                    _subList = this.groupSubscriberAdded(group)

                    // this.groupUpdated(group);
                    if (chatManagerRef.current.canShowGroupInList(group.uuid)) {
                        var groupChatObj = this.getGroupChat(group.uuid);
                        chatUiMgrRef.current.ensureGroupConversationUiExist(groupChatObj);
                        var chatObj = this.getGroupChat(group.uuid);
                        chatUiMgrRef.current.updateChattability(chatObj, group);
                        if (!group.subscribed) {
                            this.groupConversationRemoved(group);
                        } else {
                            // $('#group_list .person[data-chat="' + group.uuid + '"]').click();
                            this.showGroupConversation(group);
                        }
                    } else {
                        this.groupRemoved(group);
                        console.log('remove group handleGroupSubscriptionResponse')
                    }
                    updateGroupsList(_groupList)
                    setSubscribersList(_subList)
                }
            },

            handleGroupActivated: function (jsonResponse) {
                var group_uuid = jsonResponse.uuid;
                // var group = this.getGroup(group_uuid);
                // Fetch Group from server and handle it on UI. and if the group is subscribed by this user then
                var getGroupInfoRequestCommand = protoBuffRef.current.getGroupInfoRequestCommand(group_uuid);
                sendMessage(getGroupInfoRequestCommand);
            },

            handleGroupRemoved: function (jsonResponse) {
                var group_uuid = jsonResponse.uuid;
                var group = this.getGroup(group_uuid);
                let removedGroups = {}
                if (group) {
                    removedGroups = this.groupRemoved(group);
                    console.log('remove group handleGroupRemoved', removedGroups)
                    // STORE: handle de-activated group
                    if (group.uuid === selectedChatOwnerInfo.toUUID) {
                        // TODO: see if we can handle group deactivated here
                        handleRemovedActiveChat()
                    }
                }

                updateRemovedGroupsList(removedGroups)
            },

            handleGroupInfoResponse: function (groupInfoResponse) {
                var group = groupInfoResponse.group;
                let _groupList = {}
                let _subList = {}
                if (group) {
                    _groupList = this.groupAdded(group);
                    _subList = this.groupSubscriberAdded(group)
                    setSubscribersList(_subList)
                    updateGroupsList(_groupList)

                    this.updateGroupInGroupListView(group);
                    this.HandleUpdateGroupInfo(group);
                    if (groupInfoResponse.conversationId && (group.canJoin || group.canLeave || group.subscribed)) {
                        //If conversation Id exists that means the conversation is applicable for this user
                        var groupChatObj = this.getGroupChat(group.uuid);
                        groupChatObj.conversationId = groupInfoResponse.conversationId;
                        groupChatObj.encryptionKey = chatMetaDataRef.current.encryption.decrypt(groupInfoResponse.convKey);
                        chatUiMgrRef.current.ensureActiveChatExist(groupChatObj);
                    }
                    chatUiMgrRef.current.updateNoGroupAvailableMessage();
                }
            },

            updateGroupInGroupListView: function (group) {
                // if group is removed and active
                if (!group.visible && group.uuid === selectedChatOwnerInfo.toUUID) {
                    // TODO: see if we can handle group deactivated here
                    handleRemovedActiveChat()
                }
                let _groupList = {}
                let _subList = {}
                if (group) {
                    //If group is visible and active, show the group in group list
                    _groupList = this.groupAdded(group)
                    _subList = this.groupSubscriberAdded(group)
                    setSubscribersList(_subList)
                    updateGroupsList(_groupList)
                    // this.groupAdded(group);
                    // this.groupUpdated(group);
                    if (chatManagerRef.current.canShowGroupInList(group.uuid)) {
                        var groupChatObj = this.getGroupChat(group.uuid);
                        chatUiMgrRef.current.ensureGroupConversationUiExist(groupChatObj);
                    } else {
                        // this.groupRemoved(group);
                        // FIXME: commented because of unnecessary calls
                        console.log('remove group updateGroupInGroupListView')
                    }
                }
                chatUiMgrRef.current.updateNoGroupAvailableMessage();
            },

            HandleUpdateGroupInfo: function (jsoonResponse) {
                let _groupList = {}
                let _subList = {}
                //If group exists, then update the following properties
                var existingGroup = this.getGroup(jsoonResponse.uuid);
                if (existingGroup) {
                    existingGroup.name = jsoonResponse.name;
                    existingGroup.description = jsoonResponse.description;
                    existingGroup.groupType = jsoonResponse.groupType;
                    existingGroup.visible = jsoonResponse.visible;
                    existingGroup.readOnly = jsoonResponse.readOnly;
                    existingGroup.avatarUrl = jsoonResponse.avatarUrl;
                    existingGroup.avatarThumbUrl = jsoonResponse.avatarThumbUrl;
                    existingGroup.displayName = jsoonResponse.displayName;
                    _groupList = this.groupAdded(existingGroup);
                    _subList = this.groupSubscriberAdded(existingGroup)
                    setSubscribersList(_subList)
                    updateGroupsList(_groupList)
                    // this.groupUpdated(existingGroup);
                    if (existingGroup.visible) {
                        var groupChatObj = this.getGroupChat(existingGroup.uuid);
                        // newChatUIMgr.current.ensureGroupConversationUiExist(groupChatObj);
                        // newChatUIMgr.current.updateGroupInfo(groupChatObj, existingGroup);
                    } else {
                        this.groupRemoved(existingGroup);
                        console.log('remove group HandleUpdateGroupInfo')
                    }
                }
                // newChatUIMgr.current.updateNoGroupAvailableMessage();
            },

            handleBot: function (contact) {
                console.log("handleBot :: contact: ", contact);

                if (contact.role === "BOT" && contact.name === "Eva Notifications") {
                    this.evaActivityContact = contact;
                }

                if (!this.chats.one_o_one[contact.uuid]) {
                    //Create a conversation for BOT
                    var evaChatObj = new conversationObjConstructor({
                        withUuid: contact.uuid,
                        conversationId: null,
                        conversationType: 1,
                        readyToUse: true,
                        readOnly: true,
                    });
                    console.log("evaChatObj", evaChatObj);
                    // TODO: add to store
                    this.chats.one_o_one[contact.uuid] = evaChatObj;
                } else {
                    console.log("handleBot :: one o one already exist!!!", this.chats.one_o_one);
                }
            },

            userConversationsReceived: function (conversations) {
                var _this = this;
                if (socketReconnectProcessing.current) {
                }
                if (!conversations || conversations.length == 0) {
                    // There's no active conversation for this user, let's select
                    // the all contacts tab to show available contacts
                    setActiveChatListPresent(false)
                } else {
                    // console.log("userConversationsReceived :: conversation count: " + conversations.length);
                    var globalUnreadMessageCount = 0;
                    let _activeChatList = {} // active chat list data for redux store
                    let _activeContactList = {}
                    let _groupList = {}
                    let _subList = {}
                    _.each(conversations, function (conversation) {
                        if (conversation.type == 1) {
                            if (conversation.members.length == 2) {
                                var otherMember = null;

                                _.each(conversation.members, function (member) {
                                    if (!_this.isMe(member.uuid)) {
                                        _activeContactList = _this.contactAdded(member, conversation.id, conversation);
                                        _activeChatList = _this.activeChatListAdded(member, conversation);
                                        otherMember = member;
                                    }
                                });


                                if (otherMember.visible) {
                                    //FIXME: redux state update is inside a loop
                                    var chatObj = _this.getChat(otherMember.uuid, conversation.id, true, conversation.readOnly, conversation);

                                    if (conversation.unreadMessageCount) {
                                        globalUnreadMessageCount += conversation.unreadMessageCount;
                                        chatObj.setUnreadMessageCount(conversation.unreadMessageCount);
                                    }
                                    chatObj.encryptionKey = chatMetaDataRef.current.encryption.decrypt(conversation.convKey);
                                } else if (otherMember.role == "BOT") {
                                    _this.handleBot(otherMember);
                                }
                            } else {
                                //The other member of chat has been deleted, let's not show the conversation on UI
                            }
                        } else {
                            _groupList = _this.groupAdded(conversation.group, conversation)
                            _subList = _this.groupSubscriberAdded(conversation.group)
                            _activeChatList = _this.activeChatListAdded(conversation.group, conversation);
                            var chatObj = _this.getGroupChat(conversation.group.uuid, conversation.id, true, conversation.readOnly, conversation);
                            if (conversation.unreadMessageCount) {
                                globalUnreadMessageCount += conversation.unreadMessageCount;
                                chatObj.setUnreadMessageCount(conversation.unreadMessageCount);
                            }
                            chatObj.encryptionKey = chatMetaDataRef.current.encryption.decrypt(conversation.convKey);
                            if (_this.canShowGroupInList(conversation.group.uuid)) {
                            }
                            // Handle group conversation here
                        }
                    });
                    setGroupList(_groupList)

                    setSubscribersList(_subList)
                    setActiveChatList(_activeChatList) // Set active conversations
                    setActiveChatListPresent(true)
                    setContactList(_activeContactList) // Also set contact list of o-o conversations
                    if (globalUnreadMessageCount > 0 && !isMessengerOpen()) {
                        // Only show global unread count when messenger is not open
                        setGlobalUnreadMessageCount(globalUnreadMessageCount)
                        // newChatUIMgr.current.setGlobalUnreadCount(globalUnreadMessageCount);
                    }
                }
                var getContactListRequestCommand = protoBuffRef.current.getContactListRequestCommand(1, "");
                sendMessage(getContactListRequestCommand);
            },

            addActiveSubscriber: function (groupUuid, subscriber) {
                var group = this.getGroup(groupUuid);
                if (group) {
                    var displayName = subscriber.display_name || subscriber.displayName;
                    var deviceName = subscriber.device_name || subscriber.deviceName;
                    delete subscriber.display_name;
                    delete subscriber.device_name;
                    subscriber.displayName = displayName;
                    subscriber.deviceName = deviceName;
                    if (group.activeSubscriberUuids == undefined) {
                        group.activeSubscriberUuids = [];
                    }
                    if (!group.activeSubscriberUuids.includes(subscriber.uuid)) {
                        group.activeSubscriberUuids.push(subscriber.uuid);
                    }
                    if (subscriber) {
                        var _subscribers = {}
                        _subscribers = this.subscriberAdded(subscriber);
                        setSubscribersList(_subscribers)
                    }
                }
            },

            removeActiveSubscriber: function (groupUuid, subscriber) {
                var group = this.getGroup(groupUuid);
                if (group) {
                    if (group.activeSubscriberUuids != undefined && group.activeSubscriberUuids.includes(subscriber.uuid)) {
                        var currentListOfActiveSubscribers = group.activeSubscriberUuids;
                        group.activeSubscriberUuids = _.remove(currentListOfActiveSubscribers, function (uuid) {
                            return uuid != subscriber.uuid;
                        });
                    }
                }
            },

            canShowGroupInList: function (uuid) {
                var group = this.getGroup(uuid);
                if (group && group.visible && (group.canJoin || group.canLeave || group.subscribed)) {
                    return true;
                }
                return false;
            },

            canShowGroupConversationInList: function (uuid) {
                var group = this.getGroup(uuid);
                if (group && group.visible && group.subscribed) {
                    return true;
                }
                return false;
            },

            getUserDisplayName: function (uuid) {
                if (chatMetaDataRef.current.selfUUID == uuid) {
                    var userName = "You";
                } else {
                    var user = this.getUser(uuid);
                    userName = user ? user.displayName : "Unknown User";
                }
                return userName;
            },

            getUser: function (uuid) {
                return activeChatList[uuid] || contactList[uuid] || groupList[uuid] || subscriberList[uuid];
            },

            getContact: function (uuid) {
                return contactList[uuid] || activeChatList[uuid] || groupList[uuid] || subscriberList[uuid];
            },

            getSubscriber: function (uuid) {
                // FIXME: revisit why irs not working
                return subscriberList[uuid];
            },

            getGroup: function (uuid) {
                return groupList[uuid];
            },

            groupAdded: function (group, conversation) {
                if (!this.groupList[group.uuid]) {
                    if (conversation && conversation.unreadMessageCount) {
                        group.unreadMessageCount = conversation.unreadMessageCount // Redux
                    }
                    this.groupList[group.uuid] = group;
                    // setgroupList(this.groupList)
                }
                return this.groupList
            },
            groupSubscriberAdded: function (group) {
                if (!group) return
                let _this = this
                let _subscribers = {}
                var subscribers = group.allSubscribers || []
                window.$.each(subscribers, function (i, contact) {
                    _subscribers = _this.subscriberAdded(contact);
                });

                return _subscribers

            },

            groupUpdated: function (group) {

                this.groupList[group.uuid] = group;
                var _this = this;
                var subscribers = group.allSubscribers || [];
                var _subscribers = {}
                $.each(subscribers, function (i, contact) {
                    _subscribers = _this.subscriberAdded(contact);
                });
                // FIXME: commented sub update here
                // setSubscribersList(_subscribers)
            },

            groupRemoved: function (group) {
                chatUiMgrRef.current.removeGroup(group);
                // var group_uuid = group.uuid;
                let _groupList = groupList
                // FIXME: handle me
                // _removeGroup(group)
                delete _groupList[group.uuid];
                // delete this.chats.group[group_uuid];
                // RODO: remove chat object as well
                return _groupList
            },

            groupConversationRemoved: function (group) {
                chatUiMgrRef.current.removeGroupConversation(group);
                // delete this.chats.group[group.uuid];
            },

            showGroupConversation: function (group) {
            },

            contactAdded: function (contact, conversationId, conversation) {
                if (!this.contactList[contact.uuid]) {
                    if (conversation && conversation.unreadMessageCount) {
                        contact.unreadMessageCount = conversation.unreadMessageCount // Redux
                    }
                    this.contactList[contact.uuid] = contact;
                    // setContactList(this.contactList)
                }
                return this.contactList
            },

            getContactAdded: function (contacts, conversationId) {
                let _contactList = {}
                $.each(contacts, function (i, contact) {
                    if (!this.contactList[contact.uuid]) {
                        _contactList[contact.uuid] = contact;
                    }
                    return _contactList
                });
                return _contactList
            },

            activeChatListAdded: function (contact, conversation) {
                if (!this.activeChatList[contact.uuid]) {
                    if (conversation && conversation.unreadMessageCount) {
                        contact.unreadMessageCount = conversation.unreadMessageCount // Redux
                    }
                    this.activeChatList[contact.uuid] = contact;
                    // setActiveChatList(this.activeChatList)
                }
                return this.activeChatList
            },

            activeChatUpdated: function (contact, conversationId) {
                if (!this.activeChatList[contact.uuid]) {
                    this.activeChatList[contact.uuid] = contact;
                    setActiveChatList(this.activeChatList)
                }
            },

            subscriberAdded: function (contact) {
                this.subscriberList[contact.uuid] = contact;
                return this.subscriberList
            },

            contactUpdated: function (contact) {
                this.contactList[contact.uuid] = contact;
                // setContactList(this.contactList)
                this.subscriberList[contact.uuid] = contact;
                return this.subscriberList
            },

            contactRemoved: function (contact) {
                //Mark 1-0-1 conversation with this user as readonly
                var chatObj = this.getChat(contact.uuid);

                if (chatObj) {
                    chatObj.readOnly = true;
                }
                removeContact(contact)
                if (contact.uuid === selectedChatOwnerInfo.toUUID) {
                    handleRemovedActiveChat()
                }
                // TODO: HANDLE ACTIVE CONTACT REMOVE
            },

            isGroup: function (uuid) {
                if (groupList[uuid]) {
                    return true;
                } else {
                    return false;
                }
            },

            getChat: function (withUuid, conversationId, forceLoad, readOnly, conversation) {
                // console.log("get chat :: withUuid: " + withUuid + " :: conversation id: " + conversationId);
                if (typeof forceLoad === "undefined") {
                    forceLoad = true;
                }

                if (typeof readOnly === "undefined") {
                    readOnly = false;
                }
                if (!Object.keys(one_o_one_chat).includes(withUuid)) {
                    if (!forceLoad) {
                        return;
                    }
                    if (withUuid == chatMetaDataRef.current.selfUUID) {
                        //Should not come here!!!
                    } else {

                        var contact = this.getContact(withUuid);

                        if (contact) {
                            this.chats.one_o_one[withUuid] = new conversationObjConstructor({
                                withUuid: withUuid,
                                conversationId: conversationId,
                                conversationType: 1,
                                readyToUse: true,
                                readOnly: readOnly,
                            });
                            //STORE:start
                            if (conversation) {
                                // this.chats.one_o_one[withUuid].encryptionKey = chatMetaDataRef.current.encryption.decrypt(conversation.convKey);
                            }
                            setOneOOneChatMessages(this.chats.one_o_one)
                            //STORE:end

                            // Here chatObj.encryptionKey = chatMetaDataRef.current.encryption.decrypt(
                            // conversation.convKey
                            // );
                        } else {
                            //Contact not available, lets fetch conversation info from server
                            this.chats.one_o_one[withUuid] = new conversationObjConstructor({
                                withUuid: withUuid,
                                conversationId: conversationId,
                                conversationType: 1,
                                readyToUse: false,
                                readOnly: readOnly,
                            });

                            //STORE:start
                            if (conversation) {
                                // this.chats.one_o_one[withUuid].encryptionKey = chatMetaDataRef.current.encryption.decrypt(conversation.convKey);
                                setOneOOneChatMessages(this.chats.one_o_one)
                            }
                            //STORE:end

                            if (conversationId) {
                                var _this = this;
                            }
                        }
                    }
                }
                var chatObjToReturn = one_o_one_chat[withUuid] ? one_o_one_chat[withUuid] : this.chats.one_o_one[withUuid];
                // console.log("getChat :: chatObjToReturn for: " + withUuid, chatObjToReturn);
                return chatObjToReturn;
            },

            getGroupChat: function (withUuid, conversationId, forceLoad, readOnly, conversation) {
                // console.log("get group chat :: with group: " + withUuid + " :: conversation id: " + conversationId);
                if (typeof forceLoad === "undefined") {
                    forceLoad = true;
                }

                if (typeof readOnly === "undefined") {
                    readOnly = false;
                }
                var chatObjToReturn = group_chat[withUuid];

                // if (!this.chats.group[withUuid]) {
                if (!Object.keys(group_chat).includes(withUuid)) {
                    if (!forceLoad) {
                        return;
                    }
                    if (withUuid != chatMetaDataRef.current.selfUUID) {
                        var group = this.getGroup(withUuid);
                        if (group) {
                            this.chats.group[withUuid] = new conversationObjConstructor({
                                withUuid: withUuid,
                                conversationId: conversationId,
                                conversationType: 0,
                                readyToUse: true,
                                readOnly: readOnly,
                            });
                            setGroupChatMessages(this.chats.group)
                            chatObjToReturn = this.chats.group[withUuid];
                        } else {
                            //group not available, lets fetch conversation info from server
                            var chatObjToReturn = new conversationObjConstructor({
                                withUuid: withUuid,
                                conversationId: conversationId,
                                conversationType: 0,
                                readyToUse: false,
                                readOnly: readOnly,
                            });
                            if (withUuid) {
                                this.chats.group[withUuid] = chatObjToReturn;
                                // TODO: why was it build outside first ?
                                if (conversation) {
                                    // this.chats.group[withUuid].encryptionKey = chatMetaDataRef.current.encryption.decrypt(conversation.convKey);
                                    setGroupChatMessages(this.chats.group)
                                }
                            }
                            if (conversationId) {
                                if (!this.pendingConversationInfoRequests.includes(conversationId)) {
                                    this.pendingConversationInfoRequests.push(conversationId);
                                    // FIXME: commented for react repeated calls revisit if fails any test case
                                    console.log('cancelled conversationInfoRequest ***', conversationId)
                                    chatObjToReturn.conversationInfoRequest(conversationId);
                                }
                            }
                        }
                    }
                }

                // console.log("getGroupChat :: chatObjToReturn for: " + withUuid, chatObjToReturn);
                return group_chat[withUuid] ? group_chat[withUuid] : chatObjToReturn;
            },

            isMe: function (otherUuid) {
                return chatMetaDataRef.current.selfUUID === otherUuid;
            },

            messageReceived: function (msg) {
                msg.deliveryAckSent = false;
                msg.readAckSent = false;
                this.lastReceivedMessageId = msg.messageId;
                var chatObj = null;
                var fromuuid = msg.fromUuid;
                let _identifier = ""
                if (!chatManagerRef.current.isGroup(msg.toUuid)) {
                    if (msg.fromUuid == chatMetaDataRef.current.selfUUID) {
                        _identifier = msg.toUuid;
                    } else {
                        _identifier = msg.fromUuid;
                    }
                } else {
                    _identifier = msg.toUuid
                }
                if (msg.conversationType == 1) {
                    //one-o-one chat message received
                    var contact = chatManagerRef.current.getContact(fromuuid);
                    if (chatMetaDataRef.current.selfUUID == msg.fromUuid) {
                        chatObj = this.getChat(msg.toUuid, msg.conversationId);
                    } else {
                        chatObj = this.getChat(msg.fromUuid, msg.conversationId);
                        chatObj.markMessageDelivered(msg.conversationId, msg.messageId, msg.fromUuid);
                    }



                    setContactUnreadCount(contact, _identifier)
                    setActiveUnreadCount(_identifier)
                    if (!chatObj.conversationId) {
                        // console.log("loading conversation");
                        chatObj.conversationId = msg.conversationId;
                        console.log('conversationInfoRequest', msg.conversationId)
                        chatObj.conversationInfoRequest(msg.conversationId);
                        this.unprocessedChatMessages.push(msg);
                        return;
                    }


                    if (msg.fromUuid) {
                        // newChatUIMgr.current.ensureActiveChatExist(chatObj);
                        // console.log('times compiled messageReceived **')
                        chatObj.messageReceived(msg);
                        // if (chatObj.initialHistoryLoaded) 
                        setReceivedMessage(msg, _identifier)
                    } else {
                        console.log("ERROR: handle message received: ", msg);
                        return;
                    }
                } else {
                    //group chat message received
                    var contact = chatManagerRef.current.getContact(fromuuid);
                    var group = chatManagerRef.current.getGroup(msg.toUuid);
                    chatObj = this.getGroupChat(msg.toUuid, msg.conversationId);
                    let _identifier = ""
                    if (!chatManagerRef.current.isGroup(msg.toUuid)) {
                        if (msg.fromUuid == chatMetaDataRef.current.selfUUID) {
                            _identifier = msg.toUuid;
                        } else {
                            _identifier = msg.fromUuid;
                        }
                    } else {
                        _identifier = msg.toUuid
                    }
                    // if (chatObj.initialHistoryLoaded) 
                    setReceivedMessage(msg, _identifier)
                    setGroupUnreadMessageCount(group)
                    setActiveUnreadCount(_identifier)


                    if (chatMetaDataRef.current.selfUUID != msg.fromUuid) {
                        chatObj.markMessageDelivered(msg.conversationId, msg.messageId, msg.fromUuid);
                    }
                    // chatObj.conversationInfoRequest(msg.conversationId); // overriding request for added members
                    if (!chatObj.conversationId || !chatObj.encryptionKey || !group) {
                        // console.log("loading group conversation::msg " + msg);
                        chatObj.conversationId = msg.conversationId;
                        if (!this.pendingConversationInfoRequests.includes(msg.conversationId)) {
                            this.pendingConversationInfoRequests.push(msg.conversationId);
                            console.log('conversationInfoRequest**', msg.conversationId)
                            // FIXME: revisit commented line
                            chatObj.conversationInfoRequest(msg.conversationId);
                        }
                        this.unprocessedChatMessages.push(msg);
                        return;
                    }

                    if (msg.fromUuid) {
                        if (msg.messageUuid) {
                            var pendingMessageIndex = _.findIndex(this.pendingMessages, function (pendingMessage) {
                                return pendingMessage.uuid === msg.messageUuid;
                            });
                        }
                        if (pendingMessageIndex != -1) {
                            if (pendingMessageIndex != -1) {
                                this.pendingMessages.splice(pendingMessageIndex, 1);
                            }

                            chatObj = this.getChatObjForMessageUuid(msg.messageUuid);
                            //if message already exists in conversation chat then update the object.
                            var msgExists = false;
                            for (var i = chatObj.messages.length - 1; i >= 0; i--) {
                                if (chatObj.messages[i].messageUuid == msg.messageUuid) {
                                    chatObj.messages[i] = msg;
                                    msgExists = true;
                                    break;
                                }
                            }
                            if (!msgExists) {
                                //Inserting at the top of the array
                                chatObj.messages.splice(0, 0, msg);
                            }

                            msg.received = true;
                            chatObj.messageStatusReceived(msg);
                        } else {
                            // newChatUIMgr.current.ensureActiveChatExist(chatObj);
                            // console.log('times compiled messageReceived ***')
                            chatObj.messageReceived(msg);
                            // setReceivedMessage(msg)
                        }
                        // STORE: TODO: update chat object here
                    } else {
                        // console.log("ERROR: handle message received: ", msg);
                        return;
                    }
                }

                // TODO: handle unread count when chat is not visible
                // if (!$("#chat_container").is(":visible")) {
                //     var unreadMsgContainer = $("#eva-global-unread-count");
                //     // newChatUIMgr.current.hasNewMessages = true;
                //     localStorage.setItem("eva-new-messages-available", "true");
                //     if (msg.messageType != 40) {
                //         $(unreadMsgContainer).removeClass("hidden");
                //     }
                // }

                //If chat widget is not open then show un-read msg count in chat bubble
                // if (!isBrowserInFocus || !$("#chat_container").is(":visible")) {
                if (group && group.canJoin) return
                if (msg.messageType && msg.messageType == 99) {
                    var messageJson = JSON.parse(msg.message);
                    var messageToDisplay = chatUiMgrRef.current.formatBotMessage(messageJson);
                    if (messageToDisplay) {
                        notificationRef.current.notifyNewChatMessage(chatObj.name(), messageToDisplay, contact, defaultNotificationImage, goToMessenger);
                        ChatToast.notify(chatObj.name(), messageToDisplay, contact, msg.messageType)
                    }
                } else if (msg.messageType && msg.messageType == messageTypes['CALL']) {
                    var messageJson = JSON.parse(msg.message);
                    var messageToDisplay = chatUiMgrRef.current.formatCallMessage(messageJson, msg.fromUuid);
                    var divEle = document.createElement("div");
                    divEle.innerHTML = messageToDisplay
                    if (messageToDisplay && messageJson.status == callObjRef.current.callStatusMapping.MISSED) {
                        notificationRef.current.notifyNewChatMessage(chatObj.name(), divEle.innerText, contact, defaultNotificationImage, goToMessenger);
                        ChatToast.notify(chatObj.name(), messageToDisplay, contact, msg.messageType)
                    }
                } else if (msg.messageType && msg.messageType == messageTypes['VOICE_MESSAGE']) {
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), "New Voice Message", contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), "New Voice Message", contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == messageTypes['PTT_MESSAGE']) {
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), "New PTT Message", contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), "New PTT Message", contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == messageTypes['SOS_MESSAGE']) {
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), "New SOS Message", contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), "New SOS Message", contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == messageTypes['LOCATION']) {
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), "Shared their location", contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), "Shared their location", contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == messageTypes['MEDIA_IMAGE']) {
                    var formatedMsg = chatUiMgrRef.current.formatMessageForUi(chatObj.decryptMessage(msg.message, msg.iv));
                    var notifyMessage = "New Image";
                    if (msg.message) {
                        notifyMessage = notifyMessage + " - " + formatedMsg;
                    }
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), notifyMessage, contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), notifyMessage, contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == 61) {
                    if (group.type === 5) return // Do not show contact added for SOS channel as it will be all users
                    var formatedMsg = chatUiMgrRef.current.formatSubscriberAddedRemovedMessage(msg);
                    var notifyMessage = "Contact Added ";
                    notifyMessage = notifyMessage + " - " + formatedMsg;
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), notifyMessage, contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), notifyMessage, contact, msg.messageType)
                } else if (msg.messageType && (msg.messageType == 62 || msg.messageType == 65)) {
                    if (group.type === 5) return
                    var formatedMsg = chatUiMgrRef.current.formatSubscriberAddedRemovedMessage(msg);
                    var notifyMessage = "Contact Removed ";
                    notifyMessage = notifyMessage + " - " + formatedMsg;
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), notifyMessage, contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), messageToDisplay, contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == 63) {
                    if (group.type === 5) return
                    var formatedMsg = chatUiMgrRef.current.formatSubscriberAddedRemovedMessage(msg);
                    var notifyMessage = "Contact Joined ";
                    notifyMessage = notifyMessage + " - " + formatedMsg;
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), notifyMessage, contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), notifyMessage, contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == 64) {
                    if (group.type === 5) return
                    var formatedMsg = chatUiMgrRef.current.formatSubscriberAddedRemovedMessage(msg);
                    var notifyMessage = "Contact Left ";
                    notifyMessage = notifyMessage + " - " + formatedMsg;
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), notifyMessage, contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), notifyMessage, contact, msg.messageType)
                } else if (msg.messageType && msg.messageType == messageTypes['FILE']) {
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), "New File Message", contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), "New File Message", contact, msg.messageType)
                } else {
                    var formatedMsg = chatUiMgrRef.current.formatMessageForUi(chatObj.decryptMessage(msg.message, msg.iv));
                    if (group && group.groupType == broadCast) {
                        formatedMsg = $.parseHTML(formatedMsg)[0].data;
                        var regX = /(<([^>]+)>)/ig;
                        formatedMsg = formatedMsg.replace(regX, "")
                    }
                    notificationRef.current.notifyNewChatMessage(chatObj.name(), formatedMsg, contact, defaultNotificationImage, goToMessenger);
                    ChatToast.notify(chatObj.name(), formatedMsg, contact, msg.messageType)
                }
                // }
            },

            handleSearchContactResponse: function (contactListResponse) {
                if (contactListResponse.contacts && contactListResponse.contacts.length > 0) setContactsNextPage(contactListResponse.nextPage)
                let _contacts = {}
                $.each(contactListResponse.contacts, function (i, contact) {
                    _contacts = chatManagerRef.current.contactAdded(contact);
                });
                setContactsSearchResult(_contacts)
            },

            handleBroadcastGroupSearch: function (groups) {
                console.log('handleBroadcastGroupSearch', groups)
                var q = searchQuery
                if ($.trim(q).length == 0) {

                }

                // $("#broadcast_group_search_list").html("");
                var isAnyGroupVisible = false;
                if (groups) {
                    // chatUiMgrRef.current.rootElement().find(".search-no-broadcast-group-result-found").hide();
                    let _groupList = {}
                    $.each(groups, function (i, group) {
                        // _groupList = chatManagerRef.current.groupAdded(group);
                        // var withUuid = group.uuid;
                        // var avatarThumbUrl = null;
                        _groupList[group.uuid] = group;

                    });
                    setBroadcastsSearchResult(_groupList)
                }
                if (!isAnyGroupVisible) {
                    //show no group found message
                    // chatUiMgrRef.current.rootElement().find(".search-no-broadcast-group-result-found").show();
                    //If no group element is shown then hide it
                }
            },

            handleSearchGroupResponse: function (groupListResponse) {
                // FIXME: server needs to send nextPage = 0 when pagination ends (Just like contacts api) 
                if (groupListResponse.groups && groupListResponse.groups.length > 0 && groupListResponse.nextPage) setGroupsNextPage(groupListResponse.nextPage)
                let _groups = {}
                $.each(groupListResponse.groups, function (i, group) {
                    _groups = chatManagerRef.current.groupAdded(group);
                });
                setGroupsSearchResult(_groups)
            },

            searchContacts: function (queryString) {
                // if (!queryString) setContactsSearchResult({})
                delayedContactsSearchRequest(queryString, fetchContact)

            },

            searchGroups: function (queryString) {
                delayedGroupsSearchRequest(queryString, fetchChannels)

            },

            searchBroadcast: function (queryString) {
                var _this = this;
                var q = queryString
                if ($.trim(q).length == 0) {
                    setBroadcastsSearchResult({}) // clear search result if query is erased or empty
                    // _this.resetGroupSearch();
                    // _this.resetContactSearch();
                    // _this.resetBroadcastSearch();
                } else {
                    var searchedGroups = [];
                    //Filtering broadcast already existing broadcast channel( doing so because all accessible broadcast channels are already on UI.)
                    for (var key in groupList) {
                        var group = groupList[key];
                        if (group.groupType == broadCast && group.name.toLowerCase().includes(q.toLowerCase())) {
                            searchedGroups.push(group);
                        }
                    }
                    this.handleBroadcastGroupSearch(searchedGroups);
                }
            },

            resetContactSearch: function () {

            },

            resetGroupSearch: function () {

            },

            resetBroadcastSearch: function () {

            },

            getMessageUuid: function (chatObj) {
                var uuid = guid();
                this.messageUuids[uuid] = chatObj;
                // dispatch({
                //     type: types.SET_MASSAGE_UUIDS_LIST,
                //     payload: this.messageUuids,
                // });
                setChatMessageUuidList(this.messageUuids)
                return uuid;
            },

            getCallUuid: function () {
                var uuid = guid();
                return uuid;
            },

            getUuid: function () {
                return guid();
            },

            getChatObjForMessageUuid: function (msgUuid) {
                return messageUuids[msgUuid];
            },

            messageStatusReceived: function (jsonMessage) {
                // console.log('pendingMessages', pendingMessages)
                chatManagerRef.current.setLastMessageReceivedAt(jsonMessage.messageId);
                var chatObj;
                var message;
                if (jsonMessage.messageUuid && !jsonMessage.memberUuid) {
                    //handling sent status
                    var pendingMessageIndex = _.findIndex(pendingMessages, function (pendingMessage) {
                        return pendingMessage.uuid === jsonMessage.messageUuid;
                    });

                    if (jsonMessage.received) {
                        message = pendingMessages[pendingMessageIndex];
                        if (message) {
                            message = message.msg;
                            message.messageId = jsonMessage.messageId;
                            message.sent = true;
                        }
                    }

                    if (pendingMessageIndex != -1) {
                        // FIXME: handle pending message list
                        this.pendingMessages.splice(pendingMessageIndex, 1);
                        clearPendingMessage(jsonMessage.messageUuid)
                        clearPendingFileUploadList(jsonMessage.messageUuid) // clear files after upload
                    }
                    chatObj = this.getChatObjForMessageUuid(jsonMessage.messageUuid);
                } else if (jsonMessage.memberUuid) {
                    //handling delivered/read/open status
                    chatObj = _.find(this.chats.group, function (conversation) {
                        return conversation.conversationId == jsonMessage.conversationId;
                    });

                    //Handling one-one chat message status
                    if (!chatObj) {
                        chatObj = this.getChat(jsonMessage.memberUuid, jsonMessage.conversationId, false);
                    }
                    if (chatObj) {
                        //STORE: comented, revisit
                        message = chatObj.updateMemberStatus(jsonMessage, { ...one_o_one_chat, ...group_chat });
                        //var message = _updateMemberStatus(this, jsonMessage)
                        if (message) {
                            // _updateMessageStatus(message)
                            chatObj.updateMessageStatus(message);
                            return;
                        }
                    }
                }

                if (!chatObj) {
                    //Handling one-one chat message status
                    chatObj = this.getChat(jsonMessage.memberUuid, jsonMessage.conversationId, false);
                }
                
                if(chatObj) {
                    chatObj.messageStatusReceived(jsonMessage, message);
                }
            },

            addToPendingList: function (uuid, message) {
                setPendingMessage({ uuid: uuid, msg: message })
                this.pendingMessages.push({ uuid: uuid, msg: message });
            },

            getLastMessageReceivedAt: function () {
                return this.lastReceivedMessageId;
            },

            setLastMessageReceivedAt: function (messageId) {
                if (this.lastReceivedMessageId < messageId) {
                    // console.log("LastMessageId to update-- ", this.lastReceivedMessageId);
                    this.lastReceivedMessageId = messageId;
                }
                // console.log("LastMessageId -- ", this.lastReceivedMessageId);
            },

            handleActionMessage: function (actionMessage) {
                var contactUuid = actionMessage.withUuid;
                var chatObj = this.getChat(contactUuid);

                if (actionMessage.type == 1) {
                    if (chatObj) {
                        chatObj.handleActionMessageResponse(actionMessage);
                    }
                } else {
                    //
                }
            },

            handleRequestContactListRequest: function () {
                //Best to refresh the UI
                window.localStorage["handleRequestContactListRequest"] = parseInt(window.localStorage["handleRequestContactListRequest"] || 1) + 1;
                //window.location.reload();
            },

            // Handle the incoming call
            handleCallInfoIncoming: function (callInfoIncoming) {
                if (DetectRTC.isWebRTCSupported) {
                    if (callObjRef.current.callStatus == "IDLE") {
                        callObjRef.current.fromUuid = callInfoIncoming.fromUuid;
                        callObjRef.current.toUuid = callInfoIncoming.toUuid;
                        callObjRef.current.callId = callInfoIncoming.callId;

                        var from = chatManagerRef.current.getContact(callObjRef.current.fromUuid);
                        callObjRef.current.callStatus = "RINGING-INCOMING";
                        iceServers.current = _.map(callInfoIncoming.iceServers, function (iceServer) {
                            return {
                                username: iceServer.username,
                                credential: iceServer.password,
                                url: iceServer.url,
                            };
                        });
                        if (chatMetaDataRef.current.selfUUID == callObjRef.current.fromUuid) {
                            // Not valid Usecase
                        } else {
                            notificationRef.current.notifyNewCallMessage(from, callObjRef.current.toUuid, defaultNotificationImage, callObjRef.current.callId);
                            chatManagerRef.current.initializeCallScreen(from);
                        }
                        // $(".start-call-btn").hide();
                        chatManagerRef.current.setCallStatus("Incoming Call");
                        feedBackScreen.current = false;
                        // 45 second timout if call not picked then it will consider as missed call
                        callTimerRef.current = setTimeout(function () {
                            // console.log("executing timeout");
                            if (callObjRef.current.callStatus == "RINGING-INCOMING") {
                                callObjRef.current.callStatus = "MISSED";
                                callObjRef.current.updateCallStatus();
                                chatManagerRef.current.stopCallRingtone();
                                chatManagerRef.current.hangUpCall();
                            }
                        }, 45 * 1000);
                        // newChatMgr.current.initiateCallHeartBeatInterval();
                    } else {
                        othercallObjRef.current = new callObjMgr({
                            toUuid: callInfoIncoming.toUuid,
                            fromUuid: callInfoIncoming.fromUuid,
                            callId: callInfoIncoming.callId,
                            voiceEnabled: true,
                            callDuration: null,
                            callStatus: "BUSY",
                        });

                        othercallObjRef.current.updateCallStatus(othercallObjRef.current);
                    }
                }
            },

            startConnectionTimer: function () {
                if (connectionTimer.current == null) {
                    connectionTimer.current = setInterval(function () {
                        // console.log("executing connectionTimer", Offline.state);
                        if (Offline.state == "down") {
                            callObjRef.current.callDuration = null;
                            chatManagerRef.current.hangUpCall();
                        }
                    }, 2000);
                }
            },

            initializePTTInComingScreen: function (contact) {
                var callRootElement = $("#ptt_container");
                let avatarThumbUrl = null;
                avatarThumbUrl = null;
                var avatarImageHtml = window.MobilockChatClient.avatar(avatarThumbUrl, contact.user_name)[0].outerHTML;
                callRootElement.find(".call-user-avatar").html(avatarImageHtml);
                callRootElement.find(".call-user-name").html(contact.user_name);
                callRootElement.find("#ptt-status").show();
                callRootElement.css({ right: "30px", top: "75px" });
                callRootElement.show();
                callRootElement.draggable({
                    handle: ".popup",
                    containment: "window",
                });
            },

            // Handle the call screens
            initializeCallScreen: function (contact) {
                var callRootElement = $("#call_container");
                let avatarThumbUrl = null;
                avatarThumbUrl = null;
                // if (contact.avatar != undefined && contact.avatar.thumb.url !== "") {
                //     avatarThumbUrl = contact.avatar.thumb.url;
                // } else if (contact.avatarThumbUrl !== "") {
                //     avatarThumbUrl = contact.avatarThumbUrl;
                // }

                var avatarImageHtml = window.MobilockChatClient.avatar(avatarThumbUrl, contact.displayName)[0].outerHTML;

                var contact_name = contact.displayName.length > 20 ? contact.displayName.substring(0, 17) + "..." : contact.displayName;
                callRootElement.find(".call-user-avatar").html(avatarImageHtml);
                callRootElement.find(".call-user-name").html(contact.displayName);
                callRootElement.find("#call-status").show();
                callRootElement.find(".call-actions").show();
                callRootElement.css({ right: "30px", top: "75px" });
                callRootElement.find(".call-feedback-form").hide();
                callRootElement.show();
                callRootElement.draggable({
                    handle: ".popup",
                    containment: "window",
                });

                chatManagerRef.current.displayCallActions();
                chatManagerRef.current.initiateCallActions();
                chatManagerRef.current.startConnectionTimer();
            },

            // Actions to be show on call Screen depended on call status
            displayCallActions: function () {
                var actions = $('#call_container .call-actions');
                actions.find('#end-call-btn').show();
                switch (callObjRef.current.callStatus) {
                    case 'RINGING-OUTGOING':
                        actions.find('#answer-call-btn').hide();
                        actions.find('#answer-vid-call-btn').hide();
                        actions.find('#end-call-btn').show();
                        actions.find('#mute-call-btn').hide();
                        actions.find('#mute-video-btn').hide();
                        break;
                    case 'RINGING-INCOMING':
                        if (callObjRef.current.isVideoCall()) {
                            actions.find('#answer-vid-call-btn').show();
                            actions.find('#answer-call-btn').hide();
                        } else {
                            actions.find('#answer-call-btn').show();
                            actions.find('#answer-vid-call-btn').hide();
                        }
                        actions.find('#end-call-btn').show();
                        actions.find('#mute-call-btn').hide();
                        actions.find('#mute-video-btn').hide();
                        break;
                    case 'RECEIVED':
                        actions.find('#answer-call-btn').hide();
                        actions.find('#answer-vid-call-btn').hide();
                        actions.find('#end-call-btn').show();
                        var muteBtn = actions.find('#mute-call-btn')
                        if (muteBtn.hasClass('zmdi-mic-off')) {
                            muteBtn.removeClass('zmdi-mic-off');
                            muteBtn.addClass('zmdi-mic');
                        }

                        var muteVideoBtn = actions.find('#mute-video-btn');
                        if (callObjRef.current.isVideoCall()) {
                            muteVideoBtn.removeClass('zmdi-videocam-off');
                            muteVideoBtn.addClass('zmdi-videocam');
                        } else {
                            muteVideoBtn.removeClass('zmdi-videocam');
                            muteVideoBtn.addClass('zmdi-videocam-off');
                        }

                        muteVideoBtn.show();
                        muteBtn.show();

                        var maximizeVidBtn = $("#call_container #maximize-video-btn");
                        if (maximizeVidBtn.hasClass('zmdi-fullscreen-exit')) {
                            maximizeVidBtn.removeClass('zmdi-fullscreen-exit');
                            maximizeVidBtn.addClass('zmdi-fullscreen')
                        }
                        maximizeVidBtn.show();
                        break;
                    case 'BUSY':
                    case 'FAILED':
                        actions.find('#mute-call-btn').hide();
                        actions.find('#end-call-btn').hide();
                        break;
                }
            },

            // Play the call ringtone
            playCallRingtone: function () {
                var audio = $("#call-ringtone")[0];
                audio.loop = true;
                audio.play().then(function () {
                    console.log('Audio started unlocked!')
                }).catch(function () {
                    console.log('Audio started locked')
                })
            },

            // stop the call ringtone
            stopCallRingtone: function () {
                var audio = $("#call-ringtone")[0];
                audio.pause();
                audio.currentTime = 0;
            },

            // Add event listners on call screen actions
            initiateCallActions: function () {
                // Handle mute and unmute
                $('.call-actions #answer-call-btn').unbind().on('click', function () {
                    if (callObjRef.current.callStatus == 'RINGING-INCOMING') {
                        navigator.mediaDevices.getUserMedia(videoCallConstraints)
                            .then(handleMediaSuccessIncomingCall)
                            .catch(handleMediaError);
                    }
                    // notificationManager.closeAllNotifications();
                });

                $('.call-actions #answer-vid-call-btn').unbind().on('click', function () {
                    if (callObjRef.current.callStatus == 'RINGING-INCOMING') {
                        navigator.mediaDevices.getUserMedia(videoCallConstraints)
                            .then(handleMediaSuccessIncomingCall)
                            .catch(handleMediaError);
                    }
                    // notificationManager.closeAllNotifications();
                });

                // Handle reject or end
                $('.call-actions #end-call-btn').unbind().on('click', function () {
                    if (!['IDLE', 'BUSY'].includes(callObjRef.current.callStatus)) {
                        chatManagerRef.current.stopCallRingtone();
                        var oldStatus = callObjRef.current.callStatus;
                        if (callObjRef.current.callStatus == 'RINGING-INCOMING') {
                            callObjRef.current.callStatus = 'REJECT';
                        } else {
                            callObjRef.current.callStatus = 'END';
                        }
                        chatManagerRef.current.endCall();
                        // if (callObjRef.current.callStatus == 'REJECT' || oldStatus == 'RINGING-OUTGOING') {
                        //     chatManagerObj.hangUpCall();
                        // }
                    }
                    // notificationManager.closeAllNotifications();
                });

                // Handle mute and unmute
                $('.call-actions #mute-call-btn').unbind().on('click', function () {
                    // navigator.mediaDevices.getUserMedia({video: true})
                    //    .then(videoCallSuccess)

                    var message = {
                        type: callObjRef.current.DATAMESSAGETYPE.streamUpdate,
                        data: {
                            contactUuid: chatMetaDataRef.current.selfUUID
                        }
                    }

                    if (!['RINGING-INCOMING', 'RINGING-OUTGOING', 'IDLE'].includes(callObjRef.current.callStatus)) {
                        callObjRef.current.voiceEnabled = !callObjRef.current.voiceEnabled;
                        if (callObjRef.current.voiceEnabled) {
                            $(this).addClass('zmdi-mic');
                            $(this).removeClass('zmdi-mic-off');
                            message["data"].streamUpdateType = callObjRef.current.STREAMUPDATETYPE.audioOn;
                        } else {
                            $(this).addClass('zmdi-mic-off');
                            $(this).removeClass('zmdi-mic');
                            message["data"].streamUpdateType = callObjRef.current.STREAMUPDATETYPE.audioOff;
                        }
                        if (localStreamRef.current != null) {
                            localStreamRef.current.getTracks().forEach(
                                function (track) {
                                    if (track.kind == "audio") {
                                        track.enabled = callObjRef.current.voiceEnabled;
                                    }
                                }
                            );
                        }
                        sendMessageOnDataChannel(message);
                    }
                });

                $('#call_container #maximize-video-btn').unbind().on('click', function () {
                    var callContainer = $("#call_container");
                    if (callContainer.hasClass("max_call_container")) {
                        callContainer.removeClass("max_call_container");
                        $(this).removeClass("zmdi-fullscreen-exit");
                        $(this).addClass("zmdi-fullscreen");
                        $("#call_container #localVideo").hide();
                    } else {
                        callContainer.addClass("max_call_container");
                        $(this).addClass("zmdi-fullscreen-exit");
                        $(this).removeClass("zmdi-fullscreen");
                        $("#call_container #localVideo").show();
                    }

                });

                $('.call-actions #mute-video-btn').unbind().on('click', function () {
                    if (videoTrackDisabled) {
                        replaceStreams();
                    }
                    var message = {
                        type: callObjRef.current.DATAMESSAGETYPE.streamUpdate,
                        data: {
                            contactUuid: chatMetaDataRef.current.selfUUID
                        }
                    }

                    if (!['RINGING-INCOMING', 'RINGING-OUTGOING', 'IDLE'].includes(callObjRef.current.callStatus)) {
                        callObjRef.current.videoEnabled = !callObjRef.current.videoEnabled;
                        console.log("toggling video", callObjRef.current.videoEnabled);
                        if (callObjRef.current.videoEnabled) {
                            $(this).addClass('zmdi-videocam');
                            $(this).removeClass('zmdi-videocam-off');
                            message["data"].streamUpdateType = callObjRef.current.STREAMUPDATETYPE.videoOn;
                        } else {
                            $(this).addClass('zmdi-videocam-off');
                            $(this).removeClass('zmdi-videocam');
                            message["data"].streamUpdateType = callObjRef.current.STREAMUPDATETYPE.videoOff;
                        }
                        if (localStreamRef.current != null) {
                            localStreamRef.current.getTracks().forEach(
                                function (track) {
                                    if (track.kind == "video") {
                                        track.enabled = callObjRef.current.videoEnabled;
                                    }
                                }
                            );
                        }
                        sendMessageOnDataChannel(message);
                    }
                });

                window.addEventListener("beforeunload", function (event) {
                    if (callObjRef.current.callStatus == "RECEIVED") {
                        event.returnValue = "Call In Progress";
                    }
                });
            },

            handleCallUpdateRequest: function (callUpdateData) {
                if (callObjRef.current.callId == callUpdateData.callId) {
                    chatManagerRef.current.stopCallRingtone();
                    switch (callUpdateData.callStatus) {
                        case callObjRef.current.callStatusMapping.RECEIVED:
                            callObjRef.current.callStatus = "RECEIVED";
                            chatManagerRef.current.createOffer(callUpdateData);
                            chatManagerRef.current.stopCallRingtone();
                            chatManagerRef.current.setCallStatus("Connecting ...");
                            chatManagerRef.current.displayCallActions();
                            break;
                        case callObjRef.current.callStatusMapping.ALREADY_INCALL:
                            chatManagerRef.current.notifyBusy("You are already in call");
                            // notificationManager.closeAllNotifications();
                            break;
                        case callObjRef.current.callStatusMapping.BUSY:
                            chatManagerRef.current.notifyBusy("User is busy");
                            // notificationManager.closeAllNotifications();
                            break;
                        case callObjRef.current.callStatusMapping.REJECT:
                            chatManagerRef.current.hangUpCall();
                            break;
                        case callObjRef.current.callStatusMapping.DISCONNECTED:
                            chatManagerRef.current.hangUpCall();
                            break;
                        case callObjRef.current.callStatusMapping.MISSED:
                            chatManagerRef.current.hangUpCall();
                            break;
                        case callObjRef.current.callStatusMapping.FAILED:
                            chatManagerRef.current.showCallFailed(callUpdateData.failedReason);
                            break;
                        case callObjRef.current.callStatusMapping.END:
                            callObjRef.current.callStatus = "END";
                            chatManagerRef.current.hangUpCall();
                            break;
                    }
                    // notificationManager.closeAllNotifications();
                }
            },

            handleDataCallParameters: function (dataCallParams) {
                if (callObjRef.current.callId == dataCallParams.callId) {
                    if (dataCallParams.offer) {
                        chatManagerRef.current.setOfferRemoteDescription(dataCallParams.offer);
                    } else if (dataCallParams.answer) {
                        chatManagerRef.current.setAnswerRemoteDescription(dataCallParams.answer);
                    } else if (dataCallParams.candidate) {
                        chatManagerRef.current.addCandidates(dataCallParams.candidate);
                    }
                }
            },

            handleSettingChanged: function (settings) {
                if (!settings.chatEnabled) {
                    webSocket.current.close();
                } else {
                    var config = settings.evaMessagingConfig;
                    toggleCopyEnabled(config.chatCopyEnabled || false)

                    toggleEditEnabled(config.chatEditEnabled || false)


                    toggleDeleteEnabled(config.chatDeleteEnabled || false)


                    toggleReplyEnabled(config.replyEnabled || false)

                    setMaxFileSize(config.maxFileSize || 0)

                    chatManagerRef.current.initSuccessful();
                    attachmentUiMgrRef.current.setMaxFileSize(settings.evaMessagingConfig.maxFileSize);
                }
            },


            handleDeleteMessageRequest: function (deleteMessageParams) {
                console.log('deleteMessageParams', deleteMessageParams)
                // handleDeleteMessage(deleteMessageParams)
                let _identifier = ""
                if (!chatManagerRef.current.isGroup(deleteMessageParams.toUuid)) {
                    if (deleteMessageParams.fromUuid == chatMetaDataRef.current.selfUUID) {
                        _identifier = deleteMessageParams.toUuid;
                    } else {
                        _identifier = deleteMessageParams.fromUuid;
                    }
                } else {
                    _identifier = deleteMessageParams.toUuid
                }
                handleDeleteMessage(deleteMessageParams, _identifier)
            },

            deletePendingMsg: function (message) {
                var deleteMessageParams = {};
                deleteMessageParams.fromUuid = message.chatMessage.fromUuid;
                deleteMessageParams.messageId = message.chatMessage.message;
                // TODO: handle check this delete 
                // chatManagerRef.current.handleDeleteMessageRequest(deleteMessageParams);
            },

            editPendingMessage: function (message) {
                var editMessageParams = {};
                var jsonMessage = JSON.parse(message.chatMessage.message);
                editMessageParams.messageId = jsonMessage.message_id;
                editMessageParams.message = jsonMessage.message;
                editMessageParams.fromUuid = message.chatMessage.fromUuid;
                editMessageParams.iv = jsonMessage.iv;
                chatManagerRef.current.handleEditMessageRequest(editMessageParams);
            },

            handleEditMessageRequest: function (editMessageParams) {
                var isBroadcastMsg = false;
                if (!chatManagerRef.current.isGroup(editMessageParams.toUuid)) {
                    if (editMessageParams.fromUuid == chatMetaDataRef.current.selfUUID) {
                        var withUuid = editMessageParams.toUuid;
                    } else {
                        withUuid = editMessageParams.fromUuid;
                    }
                    var chatObj = chatManagerRef.current.getChat(withUuid);
                    var conversation = chatManagerRef.current.chats.one_o_one[withUuid];
                } else {
                    chatObj = chatManagerRef.current.getGroupChat(editMessageParams.toUuid);
                    conversation = chatManagerRef.current.chats.group[editMessageParams.toUuid];
                    var group = chatManagerRef.current.getGroup(editMessageParams.toUuid);
                    if (group && group.groupType == broadCast) {
                        isBroadcastMsg = true;
                    }
                }

                var message;
                if (editMessageParams.iv) {
                    message = formatMessageForUi(chatObj.decryptMessage(editMessageParams.message, editMessageParams.iv), isBroadcastMsg);
                } else {
                    message = formatMessageForUi(editMessageParams.message, isBroadcastMsg);
                }

                if (conversation) {
                    message = _.find(conversation.messages, function (message) {
                        return message.messageId == editMessageParams.messageId;
                    });
                    if (message) {
                        message.edited = true;
                    }
                }
                //
                let _identifier = ""
                if (!chatManagerRef.current.isGroup(editMessageParams.toUuid)) {
                    if (editMessageParams.fromUuid == chatMetaDataRef.current.selfUUID) {
                        _identifier = editMessageParams.toUuid;
                    } else {
                        _identifier = editMessageParams.fromUuid;
                    }
                } else {
                    _identifier = editMessageParams.toUuid
                }
                //
                // let _identifier = editMessageParams.fromUuid == chatMetaDataRef.current.selfUUID ? editMessageParams.toUuid : editMessageParams.fromUuid
                updateEditedMessage(editMessageParams.messageId, message, editMessageParams, _identifier)
                // dispatch({
                //         type: 'UPDATE_EDITED_MESSAGE',
                //         payload: message
                // });
            },

            setOfferRemoteDescription: function (offerData) {
                peerConnection.current.setRemoteDescription(
                    new RTCSessionDescription(offerData),
                    function () {
                        peerConnection.current.createAnswer(callObjRef.current.offerOptions).then(
                            function (desc) {
                                callObjRef.current.webRtcAnswer.type = desc.type;
                                callObjRef.current.webRtcAnswer.sdp = desc.sdp;

                                peerConnection.current.setLocalDescription(
                                    desc,
                                    function () {
                                        callObjRef.current.sendAnswerParameters();
                                    },
                                    function () {
                                        console.log("setLocalDescription failed");
                                    }
                                );
                            },
                            function () {
                                console.log("Remote Desc success anser failed");
                            }
                        );
                    },
                    function () {
                        console.log("setRemoteDescription failed");
                    }
                );
            },

            setAnswerRemoteDescription: function (answerData) {
                peerConnection.current.setRemoteDescription(
                    new RTCSessionDescription(answerData),
                    function () {
                        // console.log("setAnswerRemoteDiscription success");
                    },
                    function () {
                        // console.log("setAnswerRemoteDiscription error");
                    }
                );
            },

            hangUpCall: function () {
                //hide vide view after video call end
                $("#call_container #remoteVideo").hide();
                
                chatManagerRef.current.stopTimer();
                chatManagerRef.current.releaseMediaStream();
                chatManagerRef.current.closePeerConnection();
                chatManagerRef.current.stopCallRingtone();
                if (chatManagerRef.current.feedbackNeed()) {
                    if (callObjRef.current.callId) {
                        // TODO: show feedback form
                        chatManagerRef.current.showFeedbackForm(callObjRef.current.callId);
                    }
                    clearTimeout(feedbackTimer.current);
                    feedbackTimer.current = setTimeout(function () {
                        if (callObjRef.current.callStatus == "IDLE") {
                            chatManagerRef.current.dismissCallScreen();
                        }
                    }, 10 * 1000);
                    chatManagerRef.current.setEmptyCallObject();
                } else {
                    chatManagerRef.current.setEmptyCallObject();
                    chatManagerRef.current.dismissCallScreen();
                }
                clearInterval(connectionTimer.current);
                connectionTimer.current = null;
            },

            endPtt: function () {
                var callRootElement = $("#ptt_container");
                if (callRootElement) callRootElement.hide()
            },



            endCall: function () {
                callObjRef.current.updateCallStatus();
                chatManagerRef.current.hangUpCall();
                // if (newChatMgr.current.feedbackNeed() && callObjRef.current.callId) {
                //     newChatMgr.current.showFeedbackForm(callObjRef.current.callId);
                //     callObjRef.current.callId = undefined;
                // }
            },

            showCallOption: function () {
                var activeTab = $(".contact-available-container .tab-nav .active").find("a").attr("class");
                // console.log("activeTab", activeTab);
                $(".contact-available-container .tab-content .tab-pane." + activeTab + " .active").click();
            },

            createOffer: function (callUpdateData) {
                iceServers.current = _.map(callUpdateData.iceServers, function (iceServer) {
                    return {
                        username: iceServer.username,
                        credential: iceServer.password,
                        url: iceServer.url,
                    };
                });

                chatManagerRef.current.createPeerConnection();

                peerConnection.current.createOffer(callObjRef.current.offerOptions).then(
                    function (desc) {
                        callObjRef.current.webRtcOffer.type = desc.type;
                        callObjRef.current.webRtcOffer.sdp = desc.sdp;

                        peerConnection.current.setLocalDescription(
                            desc,
                            function () {
                                callObjRef.current.sendOfferParameters();
                            },
                            function () {
                                // console.log("setLocalDescription failed");
                            }
                        );
                    },
                    function () {
                        // console.log("create offer failed");
                    }
                );
                //
                iceServers.current = _.map(callUpdateData.iceServers, function (iceServer) {
                    return { username: iceServer.username, credential: iceServer.password, url: iceServer.url };
                });

                chatManagerRef.current.createPeerConnection();

                dataChannel.current = peerConnection.current.createDataChannel("WebRTCData");
                dataChannel.current.onmessage = onDataChannelMessage;
                dataChannel.current.onopen = onDataChannelOpened;
                dataChannel.current.onerror = onDataChannelError;


                peerConnection.current.createOffer(callObjRef.current.offerOptions).then(function (desc) {

                    callObjRef.current.webRtcOffer.type = desc.type;
                    callObjRef.current.webRtcOffer.sdp = desc.sdp;

                    peerConnection.current.setLocalDescription(desc, function () {
                        callObjRef.current.sendOfferParameters();
                    }, function () {
                        console.log('setLocalDescription failed');
                    });

                }, function () {
                    console.log('create offer failed');
                });
            },

            setEmptyCallObject: function () {
                callObjRef.current = new callObjMgr({
                    toUuid: null,
                    fromUuid: null,
                    callId: null,
                    callStatus: 'IDLE',
                    voiceEnabled: true,
                    videoEnabled: false,
                    callDuration: null
                });
            },

            createPeerConnection: function () {
                peerConnection.current = new RTCPeerConnection({ iceServers: iceServers.current });

                peerConnectionClosed.current = false;
                // attach connection events
                peerConnection.current.onicecandidate = onIceCandidate;
                // monitor iceconnectionstatechange
                peerConnection.current.oniceconnectionstatechange = onICEStateChange;
                // when data channel is created the callback will be invoked with Channel information
                peerConnection.current.ondatachannel = onDataChannelReady;
                // play received track
                peerConnection.current.ontrack = onRemoteStreamReceived;

                if (localStreamRef.current) {
                    localStreamRef.current.getTracks().forEach(
                        function (track) {
                            if (!callObjRef.current.isVideoCall() && track.kind == "video") {
                                track.enabled = false;
                            }
                            peerConnection.current.addTrack(track, localStreamRef.current);
                        }
                    );
                }
            },

            addCandidates: function (candidate) {
                candidate["sdpMLineIndex"] = candidate.sdpMLineIndex || 0;
                // console.log("candidate", candidate);
                peerConnection.current.addIceCandidate(candidate).then(
                    function () {
                        // console.log("candidate added successfully");
                    },
                    function () {
                        // console.log("candidate added failed");
                    }
                );
            },

            releaseMediaStream: function () {
                if (localStreamRef.current != null) {
                    localStreamRef.current.getTracks().forEach(function (track) {
                        track.stop();
                    });
                }
            },

            setCallStatus: function (status) {
                $("#call_container #status").text(status);
                if (status == "Connected") {
                    seconds = 0;
                    minutes = 0;
                    hours = 0;
                    clearInterval(startTimerRef.current);
                    startTimerRef.current = setInterval(function () {
                        chatManagerRef.current.addTime();
                    }, 1000);
                }
            },

            setPttStatus: function (status) {
                $("#ptt_container #status").text(status);
                if (status == "Connected") {
                    seconds = 0;
                    minutes = 0;
                    hours = 0;
                    clearInterval(startTimerRef.current);
                    startTimerRef.current = setInterval(function () {
                        chatManagerRef.current.addTime();
                    }, 1000);
                }
            },

            addTime: function () {
                seconds++;
                if (seconds >= 60) {
                    seconds = 0;
                    minutes++;
                    if (minutes >= 60) {
                        minutes = 0;
                        hours++;
                    }
                }
                var time = (hours ? (hours > 9 ? hours : "0" + hours) : "00") + ":" + (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds);
                callObjRef.current.callDuration = time;
                $("#call_container #status").text(time);
            },

            closePeerConnection: function () {
                if (peerConnection.current && peerConnection.current.signalingState != "closed") {
                    peerConnection.current.close();
                    peerConnectionClosed.current = true;
                }
            },

            stopTimer: function () {
                seconds = 0;
                minutes = 0;
                hours = 0;
                clearInterval(startTimerRef.current);
                clearTimeout(callTimerRef.current);
            },

            notifyBusy: function (msg) {
                callObjRef.current.callStatus = "BUSY";
                chatManagerRef.current.displayCallActions();
                chatManagerRef.current.setCallStatus(msg);
                chatManagerRef.current.stopCallRingtone();
                if (msg == "User is busy") {
                    chatManagerRef.current.playBusySound();
                    setTimeout(function () {
                        chatManagerRef.current.hangUpCall();
                        chatManagerRef.current.stopBusyRingtone();
                    }, 5000);
                } else {
                    setTimeout(function () {
                        chatManagerRef.current.hangUpCall();
                    }, 5000);
                }
            },

            playBusySound: function () {
                var audio = $("#call-busy-ringtone")[0];
                audio.play().then(function () {
                    console.log('Audio started unlocked!')
                }).catch(function () {
                    console.log('Audio started locked')
                })
            },

            stopBusyRingtone: function () {
                var audio = $("#call-ringtone")[0];
                audio.pause();
                audio.currentTime = 0;
            },

            showCallFailed: function (failedReason) {
                callObjRef.current.callStatus = "FAILED";
                chatManagerRef.current.displayCallActions();
                var contact = chatManagerRef.current.getContact(callObjRef.current.toUuid);
                var status_msg = "";
                if (failedReason == 2) {
                    status_msg = contact.role == "Device" ? "The device is not available on a supported version of the app" : "The user is not available on a supported platform";
                    chatManagerRef.current.setCallStatus(status_msg);
                } else {
                    status_msg = contact.role == "Device" ? "The device is not available for call" : "The user is not available for call";
                    chatManagerRef.current.setCallStatus(status_msg);
                }
                setTimeout(function () {
                    chatManagerRef.current.hangUpCall();
                }, 3000);
            },

            showFeedbackForm: function (callId) {
                feedBackScreen.current = true;
                $("#call_container").show();
                $("#call_container #call-status").hide();
                $("#call_container .call-actions").hide();
                $("#call_container .call-feedback-form").show();
                $(".call-feedback-form input.call-feedback").val(5);
                chatManagerRef.current.initiateFeedBackFrom(callId);
            },

            initiateFeedBackFrom: function (callId) {
                var ratingField = $(".call-feedback-form input.call-feedback");
                var ratings = $(".call-feedback-form .zmdi");
                $(".call-feedback-form .zmdi-star").on("mouseover", function () {
                    ratingField.val($(this).data("feedback"));
                    ratings.each(function () {
                        if (parseInt(ratingField.val()) >= parseInt($(this).data("feedback"))) {
                            return $(this).css({ color: "#f5a623" });
                        } else {
                            return $(this).css({ color: "#5E6162" });
                        }
                    });
                });

                ratings.each(function () {
                    if (parseInt(ratingField.val()) >= parseInt($(this).data("feedback"))) {
                        return $(this).css({ color: "#f5a623" });
                    } else {
                        return $(this).css({ color: "#5E6162" });
                    }
                });

                $(".call-feedback-form #later-feedback-btn")
                    .unbind()
                    .on("click", function () {
                        chatManagerRef.current.dismissCallScreen();
                    });

                $(".call-feedback-form #submit-feedback-btn")
                    .unbind()
                    .on("click", function () {
                        callObjRef.current.updateCallFeedBack(callId, ratingField.val());
                        chatManagerRef.current.setEmptyCallObject();
                        chatManagerRef.current.dismissCallScreen();

                        chatUiMgrRef.current.errorMessage("Thank you for your feedback.", "success");
                    });
            },

            dismissCallScreen: function () {
                chatManagerRef.current.showCallOption();
                $("#call_container .call-feedback-form").hide();
                $("#call_container #call-status").show();
                $("#call_container .call-actions").show();
                $("#call_container").css({ left: "unset" }).hide();
                clearTimeout(feedbackTimer.current);
                feedBackScreen.current = false;
            },

            feedbackNeed: function () {
                if (["END", "CONNECTED", "DISCONNECTED", "CLEAR"].includes(callObjRef.current.callStatus) && callObjRef.current.callDuration != null) {
                    return true;
                } else if (feedBackScreen.current) {
                    return true;
                }
                return false;
            },

            handleConversationResponse: function (conversationResponseParams) {
                var chatObj;
                if (conversationResponseParams.fromUuid == chatMetaDataRef.current.selfUUID) {
                    if (conversationResponseParams.conversationType == 1) {
                        chatObj = chatManagerRef.current.getChat(conversationResponseParams.toUuid);
                    } else {
                        chatObj = chatManagerRef.current.getGroupChat(conversationResponseParams.toUuid);
                    }
                } else {
                    if (conversationResponseParams.conversationType == 1) {
                        chatObj = chatManagerRef.current.getChat(conversationResponseParams.fromUuid);
                    } else {
                        chatObj = chatManagerRef.current.getGroupChat(conversationResponseParams.fromUuid);
                    }
                }
                chatObj.conversationId = conversationResponseParams.conversationId;
                chatObj.encryptionKey = chatMetaDataRef.current.encryption.decrypt(conversationResponseParams.convKey);
                console.log("pending Messages ", chatObj.pendingMessages);
                if (pendingMessages.length > 0) {
                    chatObj.sendPendingMessages(pendingMessages);
                }
                console.log("pending files ", chatObj.pendingFileUpload);
                if (pendingFileUpload.length > 0) {
                    chatObj.sendPendingFiles();
                }

                var pendingMessageIndex = _.findIndex(this.pendingMessages, function (pendingMessage) {
                    return pendingMessage.uuid === conversationResponseParams.toUuid;
                });

                if (pendingMessageIndex != -1) {
                    this.pendingMessages.splice(pendingMessageIndex, 1);
                }
            },

            handleTokenAuthResponse: function (tokenAuthResponse) {
                if (tokenAuthResponse.success) {
                    document.dispatchEvent(new CustomEvent("socketAuthComplete"));
                    var initCommand = protoBuffRef.current.getInitCommand(chatMetaDataRef.current);
                    sendMessage(initCommand);
                } else {
                    if (tokenAuthResponse.failedReason == 2) {
                        var userRequestCommand = protoBuffRef.current.getEvaUserRequest(chatMetaDataRef.current.idToken);
                        sendMessage(userRequestCommand);
                    } else {
                        console.error("TokenAuthResponse :: ", tokenAuthResponse);
                    }
                }
            },

            handleSocketClosingActions: function (response) {
                if (response.reason == 4) {
                    chatMetaDataRef.current.licenseExpired = true
                    licenseExpiredNotification();
                } else {
                    localStorage.clear();
                    window.location.reload();
                }

            },

            handleEvaUserResponse: function (evaUserResponse) {
                if (evaUserResponse.success) {
                    var initCommand = protoBuffRef.current.getInitCommand(chatMetaDataRef.current);
                    sendMessage(initCommand);
                }
            },

            handleInitResponse: function (socketInitResponse) {
                // TODO: handle max session used
                if (!socketInitResponse.success) {
                    if (socketInitResponse.failedReason == 1) {
                        // $(".left").hide();
                        // $(".right").hide();
                        // $(".max-session-used").show();
                    }
                    console.log('handleInitResponse')
                    webSocket.current.close();
                } else {
                    // $(".left").show();
                    // $(".right").show();
                    // $(".max-session-used").hide();
                }
            },

            handleConversationInfoResponse: function (conversationInfoParams) {
                var chatObj;
                if (conversationInfoParams.conversation.type == 1) {
                    var otherMember = _.find(conversationInfoParams.conversation.members, function (member) {
                        if (member.uuid != chatMetaDataRef.current.selfUUID) {
                            return member;
                        }
                    });

                    if (otherMember) {
                        chatObj = chatManagerRef.current.getChat(otherMember.uuid);
                    }
                } else {
                    otherMember = conversationInfoParams.conversation.group;
                    if (otherMember) {
                        //connect to mublr fot listeningChannels
                        if (otherMember.pttEnabled && (otherMember.type == 2 || otherMember.type == 4 || otherMember.type == 5)) {
                            let channleIdlist = [...currentPttTarget.listeningChannels, otherMember.pttChannelId]
                            PttActions.listenChannels(channleIdlist)
                            setCurrentPttTarget({ ...currentPttTarget, listeningChannels: channleIdlist })
                        }
                        chatManagerRef.current.updateGroupInGroupListView(otherMember);
                        chatObj = chatManagerRef.current.getGroupChat(otherMember.uuid);
                        chatUiMgrRef.current.updateNoGroupAvailableMessage();
                    }
                }
                if (otherMember) {
                    chatObj.conversationId = conversationInfoParams.conversation.id;
                    //Remove conversationId from the pending Conversation Info Requests list
                    for (var i = 0; i < this.pendingConversationInfoRequests.length; i++) {
                        if (this.pendingConversationInfoRequests[i] === chatObj.conversationId) {
                            this.pendingConversationInfoRequests.splice(i, 1);
                        }
                    }
                    chatObj.encryptionKey = chatMetaDataRef.current.encryption.decrypt(conversationInfoParams.conversation.convKey);
                    if (chatManagerRef.current.unprocessedChatMessages.length > 0) {
                        var pendingChatMessageIndex = 0;
                        _.each(chatManagerRef.current.unprocessedChatMessages, function (message) {
                            if (!message) {
                                chatManagerRef.current.unprocessedChatMessages.splice(pendingChatMessageIndex, 1);
                            } else {
                                if (chatObj.conversationId == message.conversationId) {
                                    if (message.conversationType != 1 && !message.toUuid && message.conversationId) {
                                        message.toUuid = otherMember.uuid;
                                    }
                                    chatManagerRef.current.messageReceived(message);
                                    // console.log('times compiled handleConversationInfoResponse')
                                }
                            }
                            pendingChatMessageIndex += 1;
                        });
                        chatManagerRef.current.unprocessedChatMessages = [];
                    }
                }
            },

            handleFileUpload: function (fileUploadParams) {
                var chatObj;

                chatObj = this.getChatObjForMessageUuid(fileUploadParams.fileUuid);
                // chatObj = this.getChat(fileUploadParams.fileUuid);
                if (fileUploadParams.failed) {
                    var messageStatus = {
                        conversationId: chatObj.conversationId,
                        failed: true,
                        messageUuid: fileUploadParams.fileUuid,
                    };
                    chatObj.messageStatusReceived(messageStatus);
                    if (fileUploadParams.failedReason == 3) {
                        swal({
                            title: "Uplaod limit exhausted",
                            text: `Sorry, your File upload limit is exhausted. Contact <a href='mailto:${supportMail}'>${supportMail}</a> with your account details for further action.`,
                            html: true,
                            confirmButtonText: "Okay",
                            dangerMode: true,
                            closeOnConfirm: true,
                        });
                    }
                } else {
                    chatObj.uploadFile(fileUploadParams);
                }

                // Remove the file upload request from pendingMessages if chat_obj is one-o-one chat

                if (!chatObj.isGroupChat()) {
                    var pendingMessageIndex = _.findIndex(this.pendingMessages, function (pendingMessage) {
                        return pendingMessage.uuid === fileUploadParams.fileUuid;
                    });

                    if (pendingMessageIndex != -1) {
                        this.pendingMessages.splice(pendingMessageIndex, 1);
                    }
                }
            },

            handlePendingMessages: function (pendingMessages) {
                console.log('handle pending messages', pendingMessages)
                _.each(pendingMessages.list, function (message) {
                    if (message.chatMessage) {
                        if ([1, 3, 4, 5].includes(message.chatMessage.messageType)) {
                            // console.log('times compiled handlePendingMessages')
                            chatManagerRef.current.messageReceived(message.chatMessage);
                        } else if (message.chatMessage.messageType == 51) {
                            chatManagerRef.current.deletePendingMsg(message);
                        } else if (message.chatMessage.messageType == 50) {
                            chatManagerRef.current.editPendingMessage(message);
                        }
                    } else {
                        // console.log("unhandled pending message: ", message);
                    }
                });

                socketReconnectProcessing.current = false;

                //Check for pending messages to send
                if (this.pendingMessages.length > 0) {
                    // console.log("pending Messages ", this.pendingMessages);
                    //Resend pending messages
                    _.each(this.pendingMessages, function (pendingMessage) {
                        sendMessage(pendingMessage.msg);
                    });
                }
            },

            _joinGroup: function (groupUUID) {
                // let groupUUID = selectedChatOwnerInfo.toUUID;
                let groupSubscriptionCommand = null;

                groupSubscriptionCommand = protoBuffRef.current.getGroupSubscriptionCommand(groupUUID, "SUBSCRIBE");
                if (Offline.state == "down") {
                    chatUiMgrRef.current.errorMessage("Please connect to the network to join the channel", "danger");
                }
                sendMessage(groupSubscriptionCommand);
            },

            _leaveGroup: function (groupUUID) {
                // let groupUUID = selectedChatOwnerInfo.toUUID;
                let groupSubscriptionCommand = null;

                groupSubscriptionCommand = protoBuffRef.current.getGroupSubscriptionCommand(groupUUID, "UNSUBSCRIBE");
                sendMessage(groupSubscriptionCommand);
            },
        };
    }

    function openWebSocket() {
        var CLOSED = 3;
        try {
            // try to open socket only when it is not created or it is in closed state
            if (webSocket.current == undefined || webSocket.current.readyState == CLOSED) {
                webSocket.current = new WebSocket(chatMetaDataRef.current.webSocketHost);
            }

            webSocket.current.onopen = function () {
                socketConnectionRetryTimeout.current = 0;

                if (!oidcUser.expired) {
                    var tokenAuthRequest = protoBuffRef.current.getTokenAuthRequestCommand(chatMetaDataRef.current);
                    sendMessage(tokenAuthRequest);
                } else {
                    console.log("access token is expired token");
                    window.location.reload()
                }


                if (heartbeat_interval.current === null) {
                    missed_heartbeats.current = 0;
                    heartbeat_interval.current = setInterval(function () {
                        try {
                            missed_heartbeats.current++;
                            if (missed_heartbeats.current >= 5) {
                                throw new Error("Too many missed heartbeats.");
                            }

                            var msgObj = {
                                cmd: "ping",
                                msg: heartbeat_msg,
                            };
                            sendMessage(JSON.stringify(msgObj));
                        } catch (e) {
                            clearInterval(heartbeat_interval.current);
                            heartbeat_interval.current = null;
                            console.warn("Closing connection. Reason: " + e.message);
                            webSocket.current.close();
                        }
                    }, 30 * 1000);
                }
            };


            webSocket.current.onmessage = function (msg) {
                if (typeof msg.data === "object") {
                    protoBuffRef.current.decodeProto(msg.data, function (decodedMessage) {
                        setIsConnected(true)
                        var jsonData = decodedMessage.asJSON();
                        console.log("decoded message recieved", jsonData);
                        if (decodedMessage.getCommand() === "chatMessage") {
                            chatManagerRef.current.messageReceived(jsonData.chatMessage);
                        } else if (decodedMessage.getCommand() === "authResponse") {
                            if (jsonData.authResponse.success) {
                                chatMetaDataRef.current.selfUUID = jsonData.authResponse.uuid;
                                chatManagerRef.current.authenticationSuccessful();
                                attachmentUiMgrRef.current.setMaxFileSize(jsonData.authResponse.settings.evaMessagingConfig.maxFileSize);
                            } else {
                                if (jsonData.authResponse.failedReason == 1) {
                                    //Unauthorized - user deleted
                                    window.location.reload();
                                }
                            }
                        } else if (decodedMessage.getCommand() === "chatHistoryResponse") {
                            var fromUUID = jsonData.chatHistoryResponse.withUuid;
                            if (chatManagerRef.current.isGroup(fromUUID)) {
                                chatManagerRef.current.getGroupChat(fromUUID).messageHistoryReceived(jsonData.chatHistoryResponse.chatMessages);
                            } else {
                                chatManagerRef.current.getChat(fromUUID).messageHistoryReceived(jsonData.chatHistoryResponse.chatMessages);
                            }
                        } else if (decodedMessage.getCommand() === "messageStatus") {
                            console.log("message status recieved", jsonData.messageStatus);
                            chatManagerRef.current.messageStatusReceived(jsonData.messageStatus);
                        } else if (decodedMessage.getCommand() === "pendingMessages") {
                            chatManagerRef.current.handlePendingMessages(jsonData.pendingMessages);
                        } else if (decodedMessage.getCommand() === "contactUpdated") {
                            var updatedContact = jsonData.contactUpdated.contact;
                            chatUiMgrRef.current.updateContact(updatedContact);
                        } else if (decodedMessage.getCommand() === "contactRemoved") {
                            var removedContact = jsonData.contactRemoved.contact;
                            chatManagerRef.current.contactRemoved(removedContact);
                        } else if (decodedMessage.getCommand() === "contactAdded") {
                            var newContact = jsonData.contactAdded.contact;
                            let _newContactList = chatManagerRef.current.contactAdded(newContact);
                            updateContactList(_newContactList)
                            //If the 1-0-1 conversation was readonly then change it
                            var chatObj = chatManagerRef.current.getChat(newContact.uuid, null, false);

                            if (chatObj && !chatObj.isEvaActivityChat()) {
                                chatObj.readOnly = false;
                            }

                            // chatUiMgrRef.current.addContact(newContact);
                        } else if (decodedMessage.getCommand() === "conversationsResponse") {
                            //Got conversations of user
                            chatManagerRef.current.userConversationsReceived(jsonData.conversationsResponse.conversations);
                        } else if (decodedMessage.getCommand() === "contactListResponse") {
                            // if (!initialContactListLoaded.current || socketReconnectProcessing.current) {
                            //     chatManagerRef.current.handleContactList(jsonData.contactListResponse);
                            //     // chatMgrHook.handleContactList(jsonData.contactListResponse);
                            // } else {
                            //     chatManagerRef.current.handleSearchContactResponse(jsonData.contactListResponse);
                            // }
                        } else if (decodedMessage.getCommand() === "actionMessage") {
                            chatManagerRef.current.handleActionMessage(jsonData.actionMessage);
                        } else if (decodedMessage.getCommand() === "refreshContactListRequest") {
                            chatManagerRef.current.handleRequestContactListRequest(jsonData.actionMessage);
                        } else if (decodedMessage.getCommand() === "callInfoIncoming") {
                            chatManagerRef.current.handleCallInfoIncoming(jsonData.callInfoIncoming);
                        } else if (decodedMessage.getCommand() === "callInfoUpdated") {
                            chatManagerRef.current.handleCallUpdateRequest(jsonData.callInfoUpdated);
                        } else if (decodedMessage.getCommand() === "dataCallParameters") {
                            chatManagerRef.current.handleDataCallParameters(jsonData.dataCallParameters);
                        } else if (decodedMessage.getCommand() === 'settingChanged') {
                            chatManagerRef.current.handleSettingChanged(jsonData.settingChanged);
                        } else if (decodedMessage.getCommand() === 'deleteMessageRequest') {
                            chatManagerRef.current.handleDeleteMessageRequest(jsonData.deleteMessageRequest);
                            // } else if (decodedMessage.getCommand() === 'deleteMessageResponse') {
                            //     chatManagerRef.current.handleDeleteMessageRequest(jsonData.deleteMessageRequest);
                        } else if (decodedMessage.getCommand() === "editMessageRequest") {
                            chatManagerRef.current.handleEditMessageRequest(jsonData.editMessageRequest);
                        } else if (decodedMessage.getCommand() === "newConversationResponse") {
                            chatManagerRef.current.handleConversationResponse(jsonData.newConversationResponse);
                        } else if (decodedMessage.getCommand() === "conversationInfoResponse") {
                            chatManagerRef.current.handleConversationInfoResponse(jsonData.conversationInfoResponse);
                        } else if (decodedMessage.getCommand() === "groupListResponse") {
                            // if (!initialGroupListLoaded.current || socketReconnectProcessing.current) {
                            //     chatManagerRef.current.handleGroupList(jsonData.groupListResponse);
                            // } else {
                            //     chatManagerRef.current.handleSearchGroupResponse(jsonData.groupListResponse);
                            // }
                        } else if (decodedMessage.getCommand() === "groupSubscriptionResponse") {
                            chatManagerRef.current.handleGroupSubscriptionResponse(jsonData.groupSubscriptionResponse);
                        } else if (decodedMessage.getCommand() === "groupAdded") {
                            //handle new group added
                            chatManagerRef.current.updateGroupInGroupListView(jsonData.groupAdded.group);
                        } else if (decodedMessage.getCommand() === "groupDeactivated") {
                            //handle if group is deactivated
                            chatManagerRef.current.handleGroupRemoved(jsonData.groupDeactivated);
                        } else if (decodedMessage.getCommand() === "refreshGroup") {
                            //handle if group is to be refreshed
                            chatManagerRef.current.handleGroupActivated(jsonData.refreshGroup);
                        } else if (decodedMessage.getCommand() === "groupRemoved") {
                            //handle if group is removed
                            chatManagerRef.current.handleGroupRemoved(jsonData.groupRemoved);
                        } else if (decodedMessage.getCommand() === "groupInfoUpdated") {
                            //handle if group info updated
                            chatManagerRef.current.HandleUpdateGroupInfo(jsonData.groupInfoUpdated);
                        } else if (decodedMessage.getCommand() === "groupInfoResponse") {
                            chatManagerRef.current.handleGroupInfoResponse(jsonData.groupInfoResponse);
                        } else if (decodedMessage.getCommand() === "chatMessageInfoResponse") {
                            setConversationList(jsonData.chatMessageInfoResponse)
                        } else if (decodedMessage.getCommand() === "fileUpload") {
                            chatManagerRef.current.handleFileUpload(jsonData.fileUpload);
                        } else if (decodedMessage.getCommand() === "tokenAuthResponse") {
                            chatManagerRef.current.handleTokenAuthResponse(jsonData.tokenAuthResponse);
                        } else if (decodedMessage.getCommand() == "evaUserResponse") {
                            chatManagerRef.current.handleEvaUserResponse(jsonData.evaUserResponse);
                        } else if (decodedMessage.getCommand() == "socketInitResponse") {
                            chatManagerRef.current.handleInitResponse(jsonData.socketInitResponse);
                        } else if (decodedMessage.getCommand() == 'conversationScheduledMessagesResponse') {
                            _handleConversationScheduledMessages(jsonData.conversationScheduledMessagesResponse);
                        } else if (decodedMessage.getCommand() == 'socketClosing') {
                            chatManagerRef.current.handleSocketClosingActions(jsonData.socketClosing);
                        } else {
                            // console.log("openMyWebSocket unhandled command : ", decodedMessage.getCommand());
                        }
                    });
                } else {
                    if (msg.data === heartbeat_msg) {
                        // reset the counter for missed heartbeats
                        missed_heartbeats.current = 0;
                        return;
                    }
                    var jsonMessage = JSON.parse(msg.data);
                    console.log("received message: ", jsonMessage);

                    if (jsonMessage.cmd == "auth") {
                        chatMetaDataRef.current.selfUUID = jsonMessage.uuid;

                        if (msg.error) {
                            console.log("Authentication Failed!!!");
                        } else {
                            //Fetch contact list
                            chatManagerRef.current.authenticationSuccessful();
                        }
                    } else if (jsonMessage.cmd == "msg") {
                        // console.log('times compiled sonMessage.cmd == "msg"')
                        chatManagerRef.current.messageReceived(jsonMessage);
                    } else if (jsonMessage.cmd == "messages") {
                        var fromUUID = jsonMessage.withUuid;
                        chatManagerRef.current.getChat(fromUUID).messageHistoryReceived(jsonMessage);
                    } else if (jsonMessage.cmd == "msg_status") {
                        chatManagerRef.current.messageStatusReceived(jsonMessage);
                    }
                }
            };

            webSocket.current.onclose = function (event) {
                console.log("socket closed!!!");
                console.log("socket close event", event);
                console.log("socket error code", event.code);
                console.log("socket error ", event.reason);

                try {
                    if (callObjRef.current.callStatus != "IDLE") {
                        chatManagerRef.current.hangUpCall();
                    }
                    //Retry connection -
                    socketConnectionRetryTimeout.current += 5000;
                    setTimeout(function () {
                        if (!oidcUser.expired && !chatMetaDataRef.current.licenseExpired) {
                            socketReconnectProcessing.current = true;
                            console.log("retrying connection");
                            openWebSocket();
                        }
                    }, socketConnectionRetryTimeout.current);

                    if (heartbeat_interval.current) {
                        clearInterval(heartbeat_interval.current);
                        heartbeat_interval.current = null;
                    }

                } catch (error) {
                    console.log('RECONNEDT ERROR', error.message)
                }

            };
        } catch (exception) {
            alert(exception);
        }
    }

    function conversationObjConstructor(conversationProps) {
        return {
            props: conversationProps,
            conversationId: conversationProps.conversationId,
            conversationType: conversationProps.conversationType,
            readyToUse: conversationProps.readyToUse,
            readOnly: conversationProps.readOnly || false,
            withUuid: conversationProps.withUuid,
            messages: [],
            pendingMessages: [], //Messages which are pushed before chat got loaded
            pendingFileUpload: [], //files which are pushed before chat got loaded
            initialHistoryLoaded: false,
            chatHistoryLastMsgId: null,
            chatHistoryLoading: false,
            prevMessagesAvailable: true,
            unreadMessageCount: 0,
            encryptionKey: null,
            chatMembers: [],
            newConversation: true,
            fileUploadStatus: {
                STARTED: 0,
                SUCCESS: 1,
                CANCEL: 2,
                FAILED: 3,
                RETRY: 4,
            },

            messageType: {
                TEXT: 1,
                EDIT: 50,
                DELETE: 51,
                VOICE_MESSAGE: 3,
                LOCATION: 30,
                PTT_MESSAGE: 6,
                MEDIA_IMAGE: 4,
                MEDIA_VIDEO: 11,
                CALL: 40,
                FILE: 5,
            },

            ready: function () {
                return this.initialHistoryLoaded;
            },

            isOneToOne: function () {
                return this.conversationType == 1;
            },

            isGroupChat: function () {
                return this.conversationType == 0;
            },

            getGroup: function () {
                return groupList[this.withUuid];
            },

            name: function () {
                var contact = chatManagerRef.current.getContact(this.withUuid);

                if (contact) {
                    return contact.name;
                } else {
                    return "No Name";
                }
            },

            chatUiOpen: function () {
                // console.log("CANVAS ** chatUiOpen :: conversation id: " + this.conversationId + " :: unreadMessageCount: " + this.unreadMessageCount);

                if (this.conversationId && this.unreadMessageCount > 0) {
                    var markConversationReadRequest = protoBuffRef.current.getMarkConversationReadRequest(this.conversationId);

                    // console.log("CANVAS ** chatUiOpen :: sending mark conversation request: ", markConversationReadRequest);
                    this.sendMessage(markConversationReadRequest);
                }
            },

            setUnreadMessageCount: function (unreadMessageCount) {
                if (unreadMessageCount) {
                    this.unreadMessageCount = unreadMessageCount;
                }
            },

            incrementUnreadMessageCount: function () {
                this.unreadMessageCount += 1;
            },

            decrementUnreadMessageCount: function () {
                this.unreadMessageCount -= 1;
            },

            getUnreadMessageCount: function () {
                return this.unreadMessageCount;
            },

            isWithDevice: function () {
                var contact = chatManagerRef.current.getContact(this.withUuid);

                if (contact) {
                    return contact.role == "Device";
                } else {
                    return false;
                }
            },

            isEvaActivityChat: function () {
                if (chatManagerRef.current.evaActivityContact) {
                    return this.withUuid === chatManagerRef.current.evaActivityContact.uuid;
                } else {
                    return false;
                }
            },

            processPendingMessages: function () {
                console.log('processPendingMessages + chatLoaded')
                this.chatLoaded();
            },

            getDetails: function () {
                if (this.isOneToOne()) {
                    //Get details of other member of this chat
                    if (this.isWithDevice()) {
                        var getDeviceDetailsCommand = protoBuffRef.current.getDeviceDetailsRequest(this.withUuid);

                        // console.log("CANVAS ** getDetails :: sending get device details command for uuid: " + this.withUuid);

                        this.sendMessage(getDeviceDetailsCommand);
                    } else {
                        //Not implemented yet
                    }
                } else {
                    //Not implemented yet
                }
            },


            getChatMessageInfo: function (messageUuid) {
                var getMessageInfoCommand = protoBuffRef.current.getChatMessageInfoRequestCommand(messageUuid)
                this.sendMessage(getMessageInfoCommand);
            },

            sendChatMessage: function (msg, isBroadcast, replyAllowed, isQuotingmessage, selectedmessage) {
                const isSosMessage = selectedmessage && selectedmessage.messageType === 7


                var sentAt = new Date().getTime();
                var msgObj = {
                    cmd: isSosMessage ? "sosMessage" : "msg",
                    message: msg,
                    messageType: isSosMessage ? 7 : 1, //Sos type or Text otherwise
                    messageUuid: chatManagerRef.current.getMessageUuid(this),
                    toUuid: this.withUuid,
                    isBroadcast: isBroadcast,
                    sentAt: sentAt,
                    replyAllowed: replyAllowed,
                    fromUuid: chatMetaDataRef.current.selfUUID,
                };

                if (isQuotingmessage) {
                    cancelMessageQuote()
                    msgObj.isReply = true;
                    msgObj.parentMessageId = selectedmessage.messageObj.messageId;
                    msgObj.parentMessage = selectedmessage.chat_msg;
                    msgObj.parentMessageType = selectedmessage.messageObj.messageType;
                    msgObj.parentSenderUuid = selectedmessage.messageObj.fromUuid;
                    msgObj.parentSentAt = selectedmessage.messageObj.sentAt;
                }

                let sosTextChildMsgObj = {}
                if (isSosMessage) {
                    sosTextChildMsgObj = { ...sosTextChildMsgObj, ...msgObj }
                    const newUuid = chatManagerRef.current.getMessageUuid(this)
                    delete sosTextChildMsgObj['groupWithMessageUuid']
                    sosTextChildMsgObj.groupWithMessageUuid = msgObj.messageUuid
                    delete sosTextChildMsgObj['sentAt']
                    sosTextChildMsgObj.sentAt = new Date().getTime();
                    delete sosTextChildMsgObj['messageUuid']
                    sosTextChildMsgObj.messageUuid = newUuid
                    msgObj.children = [sosTextChildMsgObj]
                }

                console.log('CANVAS ** sending message =>>', msgObj)
                if (this.conversationId) {
                    setNewOneOOneChatMessages(msgObj)
                    if (selectedmessage !== null) setSelectedMessage(null) //clear seleted message after responding to SOS message
                    hideGroupInfoDrawer() // Close message info drawer is it is using prvious selected message
                    this.sendMessageWithEncryption(msgObj);
                } else {
                    setPendingMessage(msgObj)
                    this.pendingMessages.push(msgObj);
                    console.log('Send New conversation request')
                    this.sendNewConversationRequest();
                }
            },

            sendScheduledMessage: function (msg, isBroadcast, startAt, frequency, endAt, replyAllowed) {
                setSelectedSchedulerData({
                    isOpen: false,
                    uuid: null,
                })
                openScheduledMessageList()
                var msgObj = {
                    cmd: 'newScheduleMessage',
                    message: msg,
                    msgUuid: chatManagerRef.current.getMessageUuid(this),
                    to: this.withUuid,
                    isBroadcast: isBroadcast,
                    startAt: startAt,
                    endAt: endAt,
                    frequency: frequency,
                    replyAllowed: replyAllowed
                };
                if (this.conversationId) {
                    this.sendMessageWithEncryption(msgObj);

                } else {
                    this.sendNewConversationRequest();
                }
            },

            sendEditScheduledMessage: function (uuid, msg, isBroadcast, startAt, frequency, endAt, replyAllowed) {
                setSelectedSchedulerData({
                    isOpen: false,
                    uuid: null,
                })
                openScheduledMessageList()
                var iv = CryptoJS.lib.WordArray.random(128 / 16);
                var encryptedData = this.encryptMessage(msg, iv)
                var msgObj = {
                    startAt: startAt,
                    endAt: endAt,
                    isBroadcast: isBroadcast,
                    frequency: frequency,
                    replyAllowed: replyAllowed
                }
                var editScheduleMessageCommand = scheduleMessageUiManagerRef.current.getEditScheduleRequestCommand(uuid, encryptedData, iv, msgObj);
                this.sendMessage(editScheduleMessageCommand);
            },

            sendNewConversationRequest: function () {
                if (this.newConversation) {
                    this.newConversation = false;
                    var newConversationRequestCommand = protoBuffRef.current.getNewConversationRequestCommand(this.withUuid);
                    this.sendMessage(newConversationRequestCommand);
                    // console.log("CANVAS ** conversationResponseParams.toUuid", this.withUuid);
                    // FIXME: store these pending messages in state ?
                    chatManagerRef.current.addToPendingList(this.withUuid, newConversationRequestCommand);

                }
            },

            sendFileUpload: function (file, type, caption, originalFileName, msgToReply, parentMessage) {
                var iv = CryptoJS.lib.WordArray.random(128 / 16);
                var isBroadcastMessage = false;
                if (this.isGroupChat()) {
                    var group = chatManagerRef.current.getGroup(this.withUuid);
                }
                if (group && group.groupType == "BROADCAST") {
                    isBroadcastMessage = true;
                }

                var fileObj = {
                    cmd: "fileupload",
                    status: this.fileUploadStatus["STARTED"],
                    name: file.name,
                    size: file.size,
                    messageUuid: chatManagerRef.current.getMessageUuid(this),
                    toUuid: this.withUuid,
                    conversationId: this.conversationId,
                    file: file,
                    messageType: this.messageType[type],
                    iv: btoa(iv),
                    message: caption,
                    decryptedText: caption,
                    originalIv: iv,
                    originalFileName: originalFileName,
                    isBroadcast: isBroadcastMessage,
                    is_broadcast: isBroadcastMessage, // this is UI key, to handle full width of broadcast message
                    fromUuid: chatMetaDataRef.current.selfUUID
                };

                if (msgToReply) {
                    cancelMessageQuote()

                    // FIXME: did fileObj.isReply = true; from fileObj.isReply = true,
                    fileObj.isReply = true;
                    fileObj.parentMessage = parentMessage.chat_msg
                    fileObj.parentMessageId = parentMessage.msg_id
                    fileObj.parentMessageType = parentMessage.messageType
                    fileObj.parentSenderUuid = parentMessage.messageObj.fromUuid
                    fileObj.parentSentAt = parentMessage.sentAt
                }

                if (this.conversationId) {
                    if (caption) {
                        var encrypted = this.encryptMessage(fileObj.message, fileObj.originalIv);
                        fileObj.message = encrypted.message;
                    }

                    if (fileObj.isReply) {
                        var parentMessageIv = CryptoJS.lib.WordArray.random(128 / 16);
                        var encryptedParentMsgData = this.encryptMessage(fileObj.parentMessage, parentMessageIv);
                        var _parentMessage = encryptedParentMsgData.message;
                        fileObj.parentMessage = _parentMessage;
                        fileObj.parentMessageIv = encryptedParentMsgData.iv;
                    }

                    this.encryptAndSend(fileObj);
                } else {
                    this.sendNewConversationRequest();
                }

                // FIXME: this is only one on one, do the group too
                // dispatch({
                //     type: types.ADD_NEW_ONE_O_ONE_CHAT,
                //     payload: [fileObj],
                // });

                this.pendingFileUpload.push(fileObj);
                setPendingFileUploadList(fileObj)
                // chatUiMgrRef.current.appendChatMessageNew(this.withUuid, chatMetaDataRef.current.selfUUID, fileObj, new Date().getTime(), "sending", true);
                // TODO: revisit
                let data = { ...fileObj }
                data.sentAt = new Date().getTime();
                setNewOneOOneChatMessages(data)
            },

            encryptAndSend: function (fileObj) {
                var fileReader = new FileReader();
                var _this = this;
                // console.log("encryption start ", moment());
                fileReader.onload = async function (e) {
                    var encryptedData = await EncryptionUtils.encryptFile(e.target.result, _this.encryptionKey, fileObj.iv);
                    var encryptedFile = new File([encryptedData], fileObj.name + ".enc");
                    fileObj.size = encryptedFile.size;
                    fileObj.encryptedFile = encryptedFile;
                    _this.sendFileUploadMessage(fileObj);
                };
                fileReader.readAsArrayBuffer(fileObj.file);
            },

            sendEditMessageRequest: function (msg, originalMessage) {
                var iv = CryptoJS.lib.WordArray.random(128 / 16);
                var encryptedData = this.encryptMessage(msg, iv);

                var chatMessageEditCommand = protoBuffRef.current.getChatMessageEditCommand(this.conversationId, originalMessage.messageId, this.withUuid, encryptedData.message, encryptedData.iv);
                this.sendMessage(chatMessageEditCommand);
            },

            markMessageDelivered: function (conversationId, messageId, fromUuid) {
                console.log('markMessageDelivered server request', conversationId)
                if (chatManagerRef.current.isMe(fromUuid)) {
                    return;
                }

                var msgObj = {
                    cmd: "msg_status",
                    conversationId: conversationId,
                    messageId: messageId,
                    delivered: true,
                };

                var chatMessageStatusCommand = protoBuffRef.current.getChatMessageStatusCommand(conversationId, messageId, true);
                var message = _.find(this.messages, function (eachMessage) {
                    return eachMessage.messageId == messageId;
                });

                this.sendMessage(chatMessageStatusCommand);
                if (message) {
                    message.deliveryAckSent = true;
                    // console.log("CANVAS ** chatObj :: markMessageDelivered :: delivery ack sent for message id: " + messageId);
                }
            },

            deleteMsg: function (messageId, msgFileId) {
                var chatMessageDeleteCommand = protoBuffRef.current.getChatMessageDeleteCommand(this.conversationId, messageId, this.withUuid, msgFileId);

                this.sendMessage(chatMessageDeleteCommand);
            },

            markMessageRead: function (conversationId, messageId, fromUuid) {
                if (chatManagerRef.current.isMe(fromUuid)) {
                    return;
                }
                var msgObj = {
                    cmd: "msg_status",
                    conversationId: conversationId,
                    messageId: messageId,
                    read: true,
                };
                var chatMessageStatusCommand = protoBuffRef.current.getChatMessageStatusCommand(conversationId, messageId, false, true);
                var message = _.find(this.messages, function (eachMessage) {
                    return eachMessage.messageId == messageId;
                });
                this.sendMessage(chatMessageStatusCommand);
                if (message) {
                    message.readAckSent = true;
                    console.log("CANVAS ** chatObj :: markMessageRead :: read ack sent for message id: " + messageId);
                }
            },

            markMessageOpen: function (messageId) {
                var message = _.find(this.messages, function (eachMessage) {
                    return eachMessage.messageId == messageId;
                });

                if (message == undefined || chatManagerRef.current.isMe(message.fromUuid) || message.openAckSent) {
                    return;
                }

                var selfMsgStatus = _.find(message.statuses, function (msgStatus) {
                    return msgStatus.memberUuid == chatMetaDataRef.current.selfUUID;
                });

                if (selfMsgStatus && selfMsgStatus.open) {
                    return;
                }
                var msgObj = {
                    cmd: "msg_status",
                    conversationId: this.conversationId,
                    messageId: messageId,
                    open: true,
                };

                var chatMessageStatusCommand = protoBuffRef.current.getChatMessageStatusCommand(this.conversationId, message.messageId, false, false, true);
                this.sendMessage(chatMessageStatusCommand);
                message.openAckSent = true;
            },

            chatContainer: function () {
                return $('#all_chats .chat[data-chat="' + this.withUuid + '"]');
            },

            updateMessageStatus: function (jsonMessage) {
                if (!this.conversationId && jsonMessage.conversationId) {
                    this.conversationId = jsonMessage.conversationId;
                }

                var status = this.messageStatus(jsonMessage);
                console.log('message status', status)
                if (status == "delivered") {
                    chatUiMgrRef.current.markMessageDelivered(this.withUuid, jsonMessage.messageId);
                } else if (status == "read") {
                    chatUiMgrRef.current.markMessageRead(this.withUuid, jsonMessage.messageId);
                } else if (status == "open") {
                    chatUiMgrRef.current.markMessageOpen(this.withUuid, jsonMessage.messageId);
                }
            },

            messageStatusReceived: function (jsonMessage, message) {
                console.log('messageStatusReceived', jsonMessage)
                // console.log('messageStatus', one_o_one_chat)
                // message = _.find(this.messages, function (message) {
                //     return message.messageId == jsonMessage.messageId;
                // });

                if (!this.conversationId && jsonMessage.conversationId) {
                    this.conversationId = jsonMessage.conversationId;
                }

                let _conversationId = this.conversationId;

                // console.log("_this.conversationId", _conversationId);
                if (jsonMessage.received) {
                    var participants = [];
                    if (message && chatManagerRef.current.isGroup(this.withUuid)) {
                        var group = chatManagerRef.current.getGroup(this.withUuid);
                        message.statuses = [];
                        participants = group.activeSubscriberUuids;
                    } else {

                        var contact = chatManagerRef.current.getContact(this.withUuid);
                        //first status that message has been sent to server
                        //If message is not in pending message list, it must be a media message, creating temporary message object to hold the status of the same.

                        if (!message) {
                            message = { uuid: jsonMessage.messageUuid };
                        }
                        message.messageId = jsonMessage.messageId;
                        if (this.isOneToOne()) {
                            jsonMessage.memberUuid = this.withUuid;
                        }
                        if (!this.messages) {
                            this.messages = [];
                        }
                        if (!message.statuses) {
                            message.statuses = [];
                        }

                        if (contact) {
                            participants = [contact.uuid];
                        }
                    }
                    _.each(participants, function (chatMember) {
                        if (chatMember != chatMetaDataRef.current.selfUUID) {
                            message.statuses.push({
                                "messageId": message.messageId,
                                "conversationId": _conversationId,
                                "memberUuid": chatMember
                            });
                        }
                    });
                    // console.log('this.messages', this.messages)

                    if (!this.messages) {
                        this.messages = [];
                    }

                    if (chatContainerRef.current) chatContainerRef.current.scrollTop = 0
                    updateNewChatMessages(message)

                    chatUiMgrRef.current.markMessageSent(this.withUuid, jsonMessage.messageId, jsonMessage.messageUuid);
                } else if (jsonMessage.delivered) {
                    chatUiMgrRef.current.markMessageDelivered(this.withUuid, jsonMessage.messageId, jsonMessage.messageUuid);
                } else if (jsonMessage.read) {
                    chatUiMgrRef.current.markMessageRead(this.withUuid, jsonMessage.messageId);
                } else if (jsonMessage.open) {
                    chatUiMgrRef.current.markMessageOpen(this.withUuid, jsonMessage.messageId);
                } else if (jsonMessage.failed) {
                    chatUiMgrRef.current.markMessageFailed(this.withUuid, jsonMessage.messageUuid);

                    if (jsonMessage.failedReason == 2) {
                        //Message sent in read only conversation
                        this.readOnly = true;
                        chatUiMgrRef.current.markConversationReadOnly(this.withUuid);
                    }
                }
            },

            uploadFile: function (fileUploadParams) {
                console.log('upload file', fileUploadParams)
                var fileObj = _.find(this.pendingFileUpload, function (fileObj) {
                    return fileObj.messageUuid == fileUploadParams.fileUuid;
                });
                fileObj.uploadUrl = fileUploadParams.uploadUrl;
                fileObj.fileId = fileUploadParams.fileId;
                // //
                // // STORE: switched to axios from ajax here 
                let that = this

                var xhrObj = undefined;
                var xhrInterval = setInterval(function () {
                    console.log("xhrInterval called")
                    if (Offline.state == "down") {
                        xhrObj.abort();
                    }
                }, 1000)

                console.log("starting upload ---- ", moment())
                xhrObj = $.ajax({
                    url: fileObj.uploadUrl,
                    type: "PUT",
                    async: true,
                    cache: false,
                    data: fileObj.encryptedFile,
                    headers: { 'Content-Type': fileObj.file.type },
                    processData: false,
                    xhr: function () {
                        var xhr = $.ajaxSettings.xhr();
                        if (xhr.upload) {
                            xhr.upload.addEventListener("progress", function (e) {
                                setUploadProgress(Math.ceil(e.loaded / e.total * 100), fileObj);
                            }, false);
                        }
                        return xhr;
                    },
                    success: function (data, textStatus, jqXHR) {
                        clearInterval(xhrInterval);
                        xhrInterval = undefined;
                        console.log("uploadManager success", moment());
                        that.success(fileObj);
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        clearInterval(xhrInterval);
                        xhrInterval = undefined;
                        console.error("xhr error :: ", errorThrown);
                        that.error(fileObj);
                    }
                });
                //
                //
                // window.MobilockChatClient.upload(fileObj, this);
                chatUiMgrRef.current.setFileId(this.withUuid, fileObj.messageUuid, fileUploadParams.fileId);
            },

            progress: function (progress, fileObj) {
                // console.log("progress");
            },

            success: function (fileObj) {
                // console.log("success called ------", moment());
                fileObj.status = 1;
                var chatMessageCommand = protoBuffRef.current.getFileUploadMessageCommand(fileObj);
                this.sendMessage(chatMessageCommand);
                chatUiMgrRef.current.updateFileStatus(fileObj.toUuid, fileObj.messageUuid, fileObj.status, fileObj.messageType);
                this.pendingFileUpload = _.reject(this.pendingFileUpload, function (file) {
                    return file.fileId == fileObj.fileId;
                });
            },

            error: function (fileObj) {
                fileObj.status = 3;
                chatUiMgrRef.current.updateFileStatus(fileObj.toUuid, fileObj.messageUuid, fileObj.status, fileObj.messageType);
            },

            retryUpload: function (fileUuid) {
                var fileObj = _.find(this.pendingFileUpload, function (fileObj) {
                    return fileObj.messageUuid == fileUuid;
                });
                fileObj.status = this.fileUploadStatus["RETRY"];
                this.sendFileUploadMessage(fileObj);
            },

            updateMemberStatus: function (receivedStatus, chat) {
                // console.log('STATE', one_o_one_chat, conversationList)
                let chatObject = {};
                Object.keys(chat).map(key => {
                    if (chat[key].conversationId == receivedStatus.conversationId) {
                        chatObject = chat[key]
                        return;
                    }
                })
                var message = _.find(chatObject.messages, function (message) {
                    return message.messageId == receivedStatus.messageId;
                });
                var status;
                if (message) {
                    status = _.find(message.statuses || [], function (status) {
                        return status.memberUuid == receivedStatus.memberUuid;
                    });
                }

                if (!status && this.isOneToOne()) {
                    if (!message) message = {} // FIXME: This function is called with undefined chat object
                    message.statuses = [];
                    message.statuses = [...message.statuses, receivedStatus];
                    //message.statuses.push(receivedStatus);
                }
                if (status) {
                    if (receivedStatus.open) {
                        status.open = true;
                        status.openAt = receivedStatus.openAt;
                    } else if (receivedStatus.read) {
                        status.read = true;
                        status.readAt = receivedStatus.readAt;
                    } else if (receivedStatus.delivered) {
                        status.delivered = true;
                        status.deliveredAt = receivedStatus.deliveredAt;
                    }
                }

                return message;
            },

            messageStatus: function (message) {
                var messageStatus = "sending";
                if (message.statuses && message.statuses.length > 0) {
                    var notOpenByMembers = _.filter(message.statuses, function (status) {
                        return status.open != true;
                    });

                    var notReadByMembers = _.filter(message.statuses, function (status) {
                        return status.read != true;
                    });

                    var notDeliveredToMembers = _.filter(message.statuses, function (status) {
                        return status.delivered != true;
                    });

                    if (notOpenByMembers.length == 0 || (notOpenByMembers.length == 1 && notOpenByMembers[0].memberUuid == chatMetaDataRef.current.selfUUID)) {
                        messageStatus = "open";
                    } else if (notReadByMembers.length == 0 || (notReadByMembers.length == 1 && notReadByMembers[0].memberUuid == chatMetaDataRef.current.selfUUID)) {
                        messageStatus = "read";
                    } else if (notDeliveredToMembers.length == 0 || (notDeliveredToMembers.length == 1 && notDeliveredToMembers[0].memberUuid == chatMetaDataRef.current.selfUUID)) {
                        messageStatus = "delivered";
                    } else {
                        messageStatus = "sent";
                    }
                }
                if (messageStatus === "sending" && chatMetaDataRef.current.selfUUID == message.fromUuid && message.messageId) {
                    messageStatus = "sent";
                }

                // console.log("CANVAS ** message: ", message, "messageStatus: ", messageStatus);
                return messageStatus;
            },

            messageReceived: function (jsonMessage) {
                console.log('object nested messageReceived:');

                console.log('dispatch jsonMessage', jsonMessage)

                var messageStatus;
                if (chatMetaDataRef.current.selfUUID == jsonMessage.fromUuid) {
                    messageStatus = "sent";
                } else {
                    messageStatus = this.messageStatus(jsonMessage);
                }
                console.log(selectedChatOwnerInfo.toUUID)
                if (!chatUiMgrRef.current.isConversationOpen(this)) {
                    this.incrementUnreadMessageCount();
                }

                // chatUiMgrRef.current.appendChatMessageNew(this.withUuid, jsonMessage.fromUuid, jsonMessage, jsonMessage.sentAt, messageStatus);

                if (!chatManagerRef.current.isMe(jsonMessage.fromUuid)) {
                    //If the message is not sent by me then mark it as delivered
                    // NOTE: This is being taken care of inside cchat container'e effect
                    // this.markMessageDelivered(jsonMessage.conversationId, jsonMessage.messageId, jsonMessage.fromUuid);
                }

                //If this chat is open then need to send message read notification
                if (chatUiMgrRef.current.isConversationOpen(this)) {
                    if (!chatManagerRef.current.isMe(jsonMessage.fromUuid)) {
                        // console.log("CANVAS ** messageReceived : marking message as read: " + jsonMessage.messageId);
                        // NOTE:  This is being taken care of inside cchat container'e effect
                        // this.markMessageRead(jsonMessage.conversationId, jsonMessage.messageId, jsonMessage.fromUuid);
                    }
                } else {
                    // console.log("CANVAS ** messageReceived : not marking message as read: " + jsonMessage.messageId);
                }

                if (!this.initialHistoryLoaded) {
                    //Need to load chat history
                    // We are loading history once user selects the conversation and not when message is received 
                    this.getChatHistory(this.withUuid);
                }
            },

            messageHistoryReceived: function (messages) {
                // NOTE: all the updates here applies to cchcat object (initiated with constructor /ref )
                var _this = this;
                var chatMessages = messages || [];
                if (this.isEvaActivityChat()) {
                    // console.log("messageHistoryReceived :: eva activity chat");
                    if (chatMessages.length > 0) {
                        // _.each(chatMessages, function (activityMessage) {
                        //     chatManagerRef.current.handleActivityMessage(_this.withUuid, activityMessage, true);
                        // });

                        this.chatHistoryLastMsgId = chatMessages[chatMessages.length - 1].messageId;
                    } else {
                        this.prevMessagesAvailable = false;
                    }

                    this.initialHistoryLoaded = true; // Do not remove Using this variable in react cocntext
                    this.chatHistoryLoading = false; // ==//==
                    // props.stopLoader()
                    // dispatch({
                    //     type: types.CHAT_HISTORY_LOADING,
                    //     payload: false
                    // })


                    return;
                }

                //If there are any pending messages then check if they need to be added in the received message list
                if (this.pendingMessages.length > 0) {
                    var pendingMsg = null;
                    while ((pendingMsg = this.pendingMessages.pop()) != undefined) {
                        var exist = _.find(chatMessages, function (message) {
                            return message.messageId == pendingMsg.messageId;
                        });
                        if (!exist) {
                            chatMessages.unshift(pendingMsg);
                        }
                    }
                }

                var messageCount = chatMessages.length;
                if (messageCount > 0) {
                    this.chatHistoryLastMsgId = chatMessages[messageCount - 1].messageId;
                    // TODO: check null here
                    if (!this.encryptionKey) {
                        // console.log("conversation not available to process messages");
                    } else {

                        _this.initialHistoryLoaded = true;
                        _this.chatHistoryLoading = false;

                    }
                    //Check if there are chat messages for which this chat member is supposed to send delivery/read ack and haven't done so yet

                    _.each(chatMessages, function (chatMsg) {
                        if (chatMsg.from != chatMetaDataRef.current.selfUUID) {
                            if (chatMsg.statuses) {
                                var selfMsgStatus = _.find(chatMsg.statuses, function (msgStatus) {
                                    return msgStatus.memberUuid == chatMetaDataRef.current.selfUUID;
                                });

                                if (selfMsgStatus && !selfMsgStatus.delivered) {
                                    // console.log("CANVAS ** send message delivered for: ", chatMsg.messageId);
                                    _this.markMessageDelivered(chatMsg.conversationId, chatMsg.messageId, chatMsg.fromUuid);
                                    // chatMsg.deliveryAckSent = true;
                                }

                                if (selfMsgStatus && !selfMsgStatus.read) {
                                    //Send only if the conversation is open right now
                                    if (chatUiMgrRef.current.isConversationOpen(_this)) {
                                        // console.log("CANVAS ** send message read for: ", chatMsg.messageId);
                                        _this.markMessageRead(chatMsg.conversationId, chatMsg.messageId, chatMsg.fromUuid);
                                    } else {
                                        chatMsg.readAckSent = false;
                                    }
                                }
                            }
                        }

                    });
                    let _identifier = ""
                    if (!chatManagerRef.current.isGroup(chatMessages.toUuid)) {
                        if (chatMessages.fromUuid == chatMetaDataRef.current.selfUUID) {
                            _identifier = chatMessages.toUuid;
                        } else {
                            _identifier = chatMessages.fromUuid;
                        }
                    } else {
                        _identifier = chatMessages.toUuid
                    }
                    updateMessageFromHistory(chatMessages, _this.withUuid)
                    //FIXME: loading state is not consistant, remove timer after fixed
                    setTimeout(() => {
                        props.stopLoader()
                    }, 2000);
                } else {
                    this.prevMessagesAvailable = false;
                    this.initialHistoryLoaded = true;
                    this.chatHistoryLoading = false;
                    // clearConversationList()
                    setTimeout(() => {
                        props.stopLoader()
                    }, 2000);
                }
            },

            chatLoaded: function (state) {
                if (!isChatHistoryLoading && !this.initialHistoryLoaded && !this.chatHistoryLoading) {
                    this.getChatHistory(this.withUuid);
                } else {
                    // console.log("CANVAS ** NOT loading chat history for: ", this.withUuid);
                    this.unreadMessageCount = 0;
                    // TODO: update unread count here
                    let states = getState()
                    // console.log('Messages from state', state.one_o_one_chat[this.withUuid].messages)
                    //Send message read notification for un-read messages
                    if (this.messages.length > 0) {
                        var pendingAckMessages = _.filter(this.messages, function (eachMessage) {
                            return eachMessage.deliveryAckSent == false || eachMessage.readAckSent == false;
                        });

                        // console.log("CANVAS ** pendingAckMessages count: " + pendingAckMessages.length);

                        var _this = this;
                        _.each(pendingAckMessages, function (pendingAckMessage) {
                            console.log('pending messages markMessageDelivered', pendingAckMessage)
                            if (!pendingAckMessage.deliveryAckSent) {
                                _this.markMessageDelivered(pendingAckMessage.conversationId, pendingAckMessage.messageId, pendingAckMessage.fromUuid);
                            }

                            if (!pendingAckMessage.readAckSent) {
                                console.log('pending messages markMessageRead', pendingAckMessage)
                                _this.markMessageRead(pendingAckMessage.conversationId, pendingAckMessage.messageId, pendingAckMessage.fromUuid);
                            }
                        });
                    }
                }
            },

            loadHistory: function () {
                // TODO: handle lloading in state
                // if (!isChatHistoryLoading && this.prevMessagesAvailable) {
                if (!isChatHistoryLoading && !this.chatHistoryLoading && this.prevMessagesAvailable) {
                    this.getChatHistory(this.withUuid, this.chatHistoryLastMsgId);
                }
            },

            handleActionMessageResponse: function (actionMessage) {
                if (this.isOneToOne() && this.isWithDevice()) {
                    var deviceDetailsInfo = JSON.parse(actionMessage.message);
                    chatManagerRef.current.showDeviceDetails(deviceDetailsInfo, this);
                }
            },

            getChatHistory: function (withUUID, sinceMessageId) {
                // TODO: handle loading
                // if (!isChatHistoryLoading) {
                if (!isChatHistoryLoading && !this.chatHistoryLoading) {
                    this.chatHistoryLoading = true;
                    props.startLoader()
                    // dispatch({
                    //     type: types.CHAT_HISTORY_LOADING,
                    //     payload: true
                    // })
                    var chatHistoryCommand = protoBuffRef.current.getChatHistoryCommand(withUUID, sinceMessageId);

                    // console.log("CANVAS ** getChatHistory :: withUUID: " + withUUID + " :: sinceMessageId: " + sinceMessageId);
                    this.sendMessage(chatHistoryCommand);
                } else {
                    // console.log("CANVAS ** getChatHistory :: NOT loading as request already made :: withUUID: " + withUUID + " :: sinceMessageId: " + sinceMessageId);
                }
            },

            sendMessage: function (msgToSend) {
                sendMessage(msgToSend);
            },

            sendPendingMessages: function (pendingMessages) {
                console.log('sendPendingMessages pendingMessages', this.pendingMessages)
                var chatObj = this;
                _.each(this.pendingMessages, function (message) {
                    if (message.cmd) { // FIXME: receiving duplicate message in list
                        // work around for now to check message has cmd key present
                        chatObj.sendMessageWithEncryption(message);
                        setNewOneOOneChatMessages(message)
                    }
                });
            },

            sendPendingFiles: function () {
                var chatObj = this;
                _.each(this.pendingFileUpload, function (fileObj) {
                    if (fileObj.decryptedText) {
                        var encrypted = chatObj.encryptMessage(fileObj.message, fileObj.originalIv);
                        fileObj.message = encrypted.message;
                    }
                    fileObj.conversationId = chatObj.conversationId;
                    chatObj.encryptAndSend(fileObj);
                });
            },

            sendMessageWithEncryption: function (msgObj) {
                var msg, iv, parentMessage;
                if (this.encryptionKey) {
                    var iv = CryptoJS.lib.WordArray.random(128 / 16);
                    var encryptedData = this.encryptMessage(msgObj.message, iv);
                    msg = encryptedData.message;
                    if (msgObj.isReply) {
                        var parentMessageIv = CryptoJS.lib.WordArray.random(128 / 16);
                        var encryptedParentMsgData = this.encryptMessage(msgObj.parentMessage, parentMessageIv);
                        parentMessage = encryptedParentMsgData.message;
                        parentMessageIv = encryptedParentMsgData.iv;
                    }

                    iv = encryptedData.iv;
                } else {
                    msg = msgObj.msg;
                    parentMessage = msgObj.parentMessage;
                }

                var chatMessageCommand;
                if (msgObj.cmd == "newScheduleMessage") {
                    chatMessageCommand = scheduleMessageUiManagerRef.current.getNewScheduleRequestCommand(msgObj.to, msgObj.msgUuid, msg, iv, msgObj);
                } else if (msgObj.cmd == "sosMessage") {
                    chatMessageCommand = protoBuffRef.current.getSOSChatMessageCommand(msg, parentMessage, msgObj, iv, parentMessageIv);
                } else {
                    chatMessageCommand = protoBuffRef.current.getChatMessageCommand(msg, parentMessage, msgObj, iv, parentMessageIv);
                }
                this.sendMessage(chatMessageCommand);


                if (msgObj.cmd == "sosMessage") {
                    let sosTextMsgObj = msgObj.children[0]
                    chatMessageCommand = protoBuffRef.current.getSOSTextMessageCommand(msg, parentMessage, sosTextMsgObj, iv, parentMessageIv);
                    this.sendMessage(chatMessageCommand);
                }
                chatManagerRef.current.addToPendingList(msgObj.messageUuid, msgObj);

            },

            sendFileUploadMessage: function (fileObj) {
                var chatMessageCommand = protoBuffRef.current.getFileUploadMessageCommand(fileObj);
                this.sendMessage(chatMessageCommand);
                chatManagerRef.current.addToPendingList(fileObj.messageUuid, fileObj);
            },

            encryptMessage: function (messageText, iv) {
                var encryptionOptions = {
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                    iv: CryptoJS.enc.Utf8.parse(iv),
                };
                var cipherData = CryptoJS.AES.encrypt(messageText, CryptoJS.enc.Base64.parse(this.encryptionKey), encryptionOptions);
                // console.log("encrypted message : ", cipherData.toString());

                return { message: cipherData.toString(), iv: btoa(iv) };
            },

            decryptMessage: function (message, iv) {
                if (!iv || !message) {
                    return message;
                }
                var encryptionOptions = {
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                    iv: CryptoJS.enc.Base64.parse(iv),
                };
                var decryptedText = CryptoJS.AES.decrypt(message, CryptoJS.enc.Base64.parse(this.encryptionKey), encryptionOptions);
                return decryptedText.toString(CryptoJS.enc.Utf8);
            },

            conversationInfoRequest: function (conversationId) {
                var chatMessageCommand = protoBuffRef.current.getConversationInfo(conversationId);
                this.sendMessage(chatMessageCommand);
            },
        };
    }

    function callObjMgr(props) {
        return {
            props: props,
            toUuid: props.toUuid,
            fromUuid: props.fromUuid,
            callId: props.callId,
            callStatus: props.callStatus,
            voiceEnabled: true,
            callDuration: null,
            TYPES: {
                AUDIO: 0,
                VIDEO: 1
            },
            DATAMESSAGETYPE: {
                streamUpdate: 0
            },
            STREAMUPDATETYPE: {
                audioOff: 0,
                audioOn: 1,
                videoOff: 2,
                videoOn: 3
            },
            offerOptions: {
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 1
            },
            webRtcOffer: {
                type: null,
                sdp: null,
            },
            webRtcAnswer: {
                type: null,
                sdp: null,
            },
            webRtcCandidate: {
                sdpMLineIndex: null,
                sdpMid: null,
                candidate: null
            },

            callStatusMapping: {
                'RINGING-INCOMING': 0,
                'RINGING-OUTGOING': 0,
                'RECEIVED': 1,
                'END': 2,
                'BUSY': 3,
                'REJECT': 4,
                'CONNECTING': 5,
                'CONNECTED': 6,
                'MISSED': 7,
                'FAILED': 8,
                'ALREADY_INCALL': 9,
                'FEEDBACK': 10,
                'CLEAR': 11,
                'IDLE': 99
            },

            initiateCall: function () {
                var callInitiateCommand = protoBuffRef.current.getCallInitiateCommand(this.toUuid, this.callId, this.type);
                this.sendCommand(callInitiateCommand);
            },

            updateCallStatus: function (status) {
                var callUpdateCommand = protoBuffRef.current.getUpdateCallStatusCommand(this.callId, this.callStatusMapping[this.callStatus]);
                this.sendCommand(callUpdateCommand);
            },

            sendOfferParameters: function () {
                var callOfferCommand = protoBuffRef.current.getDataCallOfferCommand(this.callId, this.webRtcOffer);
                this.sendCommand(callOfferCommand);
            },

            sendAnswerParameters: function () {
                var callAnswerCommand = protoBuffRef.current.getDataCallAnswerCommand(this.callId, this.webRtcAnswer);
                this.sendCommand(callAnswerCommand);
            },

            sendCandidateParameters: function (candidate) {
                var candidateCommand = protoBuffRef.current.getCandidateCommand(this.callId, candidate);
                this.sendCommand(candidateCommand);
            },

            sendCommand: function (commandToSend) {
                sendMessage(commandToSend);
            },

            updateCallFeedBack: function (callId, rating) {
                var callUpdateCommand = protoBuffRef.current.getUpdateCallFeedbackCommand(callId, this.callStatusMapping.FEEDBACK, rating);
                this.sendCommand(callUpdateCommand);
            },

            getAudioTrack: function (stream) {
                return _.find(stream.getTracks(), function (track) { return track.kind.toLowerCase() == "audio"; });
            },

            getVideoTrack: function (stream) {
                return _.find(stream.getTracks(), function (track) { return track.kind.toLowerCase() == "video"; });
            },

            isVideoCall: function () {
                return this.type == this.TYPES["VIDEO"];
            }
        };
    }

    function _getUserDisplayName(uuid) {
        if (chatMetaDataRef.current.selfUUID == uuid) {
            var userName = "You";
        } else {
            var user = _getUser(uuid);
            userName = user ? user.displayName : "Unknown User";
        }
        return userName;
    }

    function _getUser(uuid) {
        var contact = contactList[uuid];
        if (contact) {
            return contact;
        } else {
            return subscriberList[uuid];
        }
    }

    function _getChatHistory(withUUID, sinceMessageId) {
        // if (!this.chatHistoryLoading) {
        var chatHistoryCommand = protoBuffRef.current.getChatHistoryCommand(withUUID, sinceMessageId);

        sendMessage(chatHistoryCommand);

    }

    function _openChat(toUUID, type) {
        // STORE: move this function to a module
        // Clear unread count
        let contact;
        switch (type) {
            case "isActiveChat":
                contact = activeChatList[toUUID]
                break;
            case "isContact":
                contact = contactList[toUUID]
                break;
            case "isChannel":
                contact = groupList[toUUID]
                break;
            default:
                contact = subscriberList[toUUID]
                break;
        }
        if (!contact) return // bail if no contact found.
        if (contact && contact.unreadMessageCount) {
            resetActiveContactUnreadCount()
            resetContactUnreadCount()
            resetGroupUnreadMessageCount()
            // type == "isChannel" ? resetGroupUnreadMessageCount(toUUID) : resetContactUnreadCount(toUUID)
        }
        if ($(this).hasClass(".active")) {
            // TODO: handle same conversation request/click
            return false;
        } else {
            // Reset chat message
            // var lastChatUserUuid = $("#all_chats .active-chat").data("chat");
            // if (toUUID != lastChatUserUuid) {
            //     chatUiMgrRef.current.backFromInfoModal(lastChatUserUuid);
            //     $("#close-eva-preview").trigger("click");
            // }
            var isBroadcastGroup = false;
            if (chatManagerRef.current.isGroup(toUUID)) {
                // var contact = chatManagerRef.current.getGroup(toUUID);
                var contactChatObj = chatManagerRef.current.getGroupChat(toUUID);
                var displayName = contact.displayName;
                var showGroupChatActions = true;
                // if (contact.groupType == broadCast) {
                //     isBroadcastGroup = true;
                //     $("#all_chats").css("height", "calc(69% - 50px)");
                //     $(".more-messages-arrow").css("bottom", "121px");
                // } else {
                //     $("#all_chats").css("height", "calc(100% - 136px)");
                //     $(".more-messages-arrow").css("bottom", "85px");
                // }
            } else {
                // contact = chatManagerRef.current.getContact(toUUID);
                contactChatObj = chatManagerRef.current.getChat(toUUID);
                displayName = contact.displayName;
                showGroupChatActions = false;
                // $("#all_chats").css("height", "calc(100% - 136px)");
                // $(".more-messages-arrow").css("bottom", "85px");
            }



            if (chatUiMgrRef.current.messageToEdit) {
                chatUiMgrRef.current.messageToEdit = null;
                // $(document).trigger("action-btn-state-changed", "mike-btn");
            } else {

            }
            // TODO: handle back to chat with draft message
            // $("#user_chat_msg").val("");
            // var lastTypedMessageForCurrent = chatUiMgrRef.current.lastTypedMessageForConversation[toUUID];
            // if (lastTypedMessageForCurrent && $.trim(lastTypedMessageForCurrent).length > 0) {
            //     if (isBroadcastGroup) {
            //         quill.root.innerHTML = $.trim(lastTypedMessageForCurrent);
            //     } else {
            //         $("#user_chat_msg").val(lastTypedMessageForCurrent);
            //     }
            //     $(document).trigger("action-btn-state-changed", "send-btn");
            // } else {
            //     if (isBroadcastGroup) {
            //         // STORE: not working
            //         // quill.root.innerText = "";
            //     }
            //     $(document).trigger("action-btn-state-changed", "mike-btn");
            // }

            var avatarThumb = null;
            if (!contactChatObj) return
            var showCallOption = !(contactChatObj?.isEvaActivityChat());
            if (contactChatObj.isGroupChat()) {
                showCallOption = false;
                var roleWithName = chatUiMgrRef.current.getGroupLabel(contact.groupType);

            } else {
                roleWithName = contact.name === displayName ? contact.role : contact.role + " - " + contact.name;
            }

            chatUiMgrRef.current.updateChattability(contactChatObj, contact);
            //Update the right side chat info
            // $(".right .top .name").html(displayName);
            // dispatch({
            //     type: types.SET_CHAT_OWNER_INFO,
            //     payload: {
            //         email: contact.email || contact.status || roleWithName,
            //         name: displayName,
            //         toUUID: contact.uuid,
            //     },
            // });
            setCurrentConversationOwnerDetails({
                email: contact.email || contact.status || roleWithName,
                name: displayName,
                toUUID: contact.uuid,
            })
            contactChatObj.chatLoaded(state);
            contactChatObj.chatUiOpen();
        }
    }


    const clearDraftMessageFromRef = (params) => {
        draftMessageListRef.current = { ...draftMessageListRef.current, [selectedChatOwnerInfo.toUUID]: "" }
    }


    const _sendMessage = () => {
        // Get the messege in draft
        let message = draftMessageListRef.current[selectedChatOwnerInfo.toUUID]
        // attachmentUiMgrRef.current.onFileInputChange(message)
        var chatObj = chatUiMgrRef.current.getActiveChatObj();
        let group = chatObj.isGroupChat() ? groupList[selectedChatOwnerInfo.toUUID] : null;
        var isBroadcastMessage = false;
        var replyAllowed = true;
        // if(scheduleMessageUiManagerObj.getIsBroadcastScheduleOpen()) {
        //     if(!scheduleMessageUiManagerObj.validateScheduleForm()) {
        //         return;
        //     }
        // }
        let msgText;
        let isEmptyMessage
        if (group && group.groupType == broadCast) {
            var regX = /(<([^>]+)>)/ig;
            isEmptyMessage = message.replace(regX, "").trim()
            if (isEmptyMessage.length == 0) return // bail out if rich text message is empty
            msgText = message;
            isBroadcastMessage = true;
            //can broadcast message be replied? will be determined from the state of the toggle button
            replyAllowed = isBroadcastReplyEnabled

        } else {
            msgText = message.trim(); // trim whitespace
        }
        if (msgText.length > 0) {
            if (messageToEdit) { // uuid will only be available while editing message
                if (Offline.state == "down") {
                    chatUiMgrRef.current.errorMessage("Please connect to the network to edit the message", "danger");
                    // chatUiMgrRef.current.messageToEdit = null;
                } else if (selectedSchedulerData.isOpen) {

                }
                else {
                    chatObj.sendEditMessageRequest(message, messageToEdit);

                }
            }
            else if (selectedSchedulerData.isOpen && selectedSchedulerData.uuid) {
                let scheduleStartAt = `${scheduleData.startDate} ${scheduleData.startTime}`
                let frequency = scheduleData.frequency
                let scheduleEndAt = `${scheduleData.endDate} 23:59`
                console.log('selectedSchedulerData', selectedSchedulerData)
                chatObj.sendEditScheduledMessage(selectedSchedulerData.uuid, message, true, scheduleStartAt, frequency, scheduleEndAt, replyAllowed);
            }
            else if (selectedSchedulerData.isOpen) {
                let scheduleStartAt = `${scheduleData.startDate} ${scheduleData.startTime}`
                console.log('++++++ scheduleStartAt', scheduleStartAt)
                let frequency = scheduleData.frequency
                console.log('++++++ frequency', frequency)
                let scheduleEndAt = `${scheduleData.endDate} 23:59`
                console.log('++++++ frequency', scheduleEndAt)
                console.log('++++++ create new scheduled message', message + '++++' + true + '++++' + scheduleStartAt + '++++' + frequency + '++++' + scheduleEndAt + '++++' + replyAllowed)
                chatObj.sendScheduledMessage(message, true, scheduleStartAt, frequency, scheduleEndAt, replyAllowed);
            }
            else {
                chatObj.sendChatMessage(message, isBroadcastMessage, replyAllowed, isQuotingmessage, selectedmessage);

            }
        } else if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        // Clear sent message here
        clearDraftMessageFromRef()

        if (isBroadcastReplyEnabled) disableBroadcastReply()
        if (selectedSchedulerData.isOpen) {
            scheduleMessageUiManagerRef.current.isBroadcastScheduleOpen = false;

        }


    }

    const goToMessenger = (e) => {
        // e.preventDefault()
        history.push({
            pathname: "/messenger"
        })
    }

    const getState = (params) => {
        return state
    }

    // Not sure if this code needs to be in react render cycle
    // TODO: move inside ref object like other data related options
    const _handleConversationScheduledMessages = (response) => {
        var chatManagerObj = chatManagerRef.current
        var chatUiManagerObj = chatUiMgrRef.current;
        var chatContainer = $('.scheduled-msgs-area');
        if (chatManagerObj.isGroup(response.withUuid)) {
            var chatObj = chatManagerObj.getGroupChat(response.withUuid);
            var group = chatManagerObj.getGroup(response.withUuid);
            if (!group.subscribed) {
                return;
            }
            var subscriberList = chatManagerObj.subscriberList;
            var group = chatManagerObj.getGroup(response.withUuid)
        } else {
            var chatObj = chatManagerObj.getChat(response.withUuid);
        }


        var previousTopElement = chatContainer.children('.chat-msg-e').first();

        if (!response.scheduleMessages) {
            clearScheduledMessages()
        } else {
        }
        if (!response.scheduleMessages) return
        const _scheduleMessages = response.scheduleMessages.map((scheduledMessage) => {
            scheduleMessageUiManagerRef.current.scheduleMessageList[scheduledMessage.uuid] = scheduledMessage;
            var nextScheduledAt = moment(scheduledMessage.nextScheduledAt).format("DD-MM-YYYY HH:mm");
            var endAt = moment(scheduledMessage.scheduleEndAt).format("DD-MM-YYYY");
            var contactName = "You";

            if (chatMetaDataRef.current.selfUUID != scheduledMessage.fromUuid) {
                contactName = chatManagerRef.current.getContact(scheduledMessage.fromUuid).name;
            }

            var formattedMessage = chatUiManagerObj.formatMessageForUi(chatObj.decryptMessage(scheduledMessage.message, scheduledMessage.iv), scheduledMessage.isBroadcast);
            let scheduledFrequencies = { 1: "Once", 2: "Every Hour", 3: "Daily", 4: "On Every Weekday", 5: "Weekly", 6: "Monthly" }
            return {
                formattedMessage: formattedMessage,
                nextScheduledAt: nextScheduledAt,
                end_at: scheduledFrequencies[scheduledMessage.frequency] == "Once" ? "NA" : endAt,
                scheduledMessageUuid: scheduledMessage.uuid,
                isBroadcast: scheduledMessage.isBroadcast,
                contactName: contactName,
                frequency: scheduledMessage.frequency
            }
        })


        setScheduledMessages(_scheduleMessages)
    }


    const childRef = useRef();


    const updateMessageOnUi = (messageId, message) => {
        // let editedMessage = messageToEdit.origanlMessage
        // let messageToEditOnUi = find(childRef.current.list, function (message) {
        // return message.messageId == payload.messageId;
        // });
        updateEditedMessage(messageId, message)
        // childRef.current.setList([])
    }

    function assignImageURL(messageObj, file, withUuid) {
        return URL.createObjectURL(file);
    }

    const isMessengerOpen = useCallback(() => {
        return history.location.pathname === "/messenger"
    }, [history])

    return (
        <ChatManagerStore.Provider value={{
            state,
            dispatch,
            _openChat,
            _sendMessage,
            chatManagerRef,
            chatUiMgrRef,
            mediaRecorderRef,
            attachmentUiMgrRef,
            callObjRef,
            scheduleMessageUiManagerRef,
            chatMetaDataRef,
            childRef,
            localStreamRef,
            callTimerRef,
            videoTrackDisabled,
            isConnected,
            draftMessageListRef,
            chatContainerRef
        }}>
            {state && props.children}
            <ToastContainerWrapper />
            <DeleteMessageDialog />
            <CallScreen />
        </ChatManagerStore.Provider>
    );
};

const mapStateToProps = function (state) {
    return {
        isChatHistoryLoading: state.reducerChatUI.isChatHistoryLoading,
        confirmDeleteMessage: state.reducerChatUI.confirmDeleteMessage,
        isBroadcastReplyEnabled: state.reducerChatUI.isBroadcastReplyEnabled,
        isQuotingmessage: state.reducerChatUI.isQuotingmessage,
        one_o_one_chat: state.reducerChat.one_o_one_chat,
        groupList: state.reducerChat.groupList,
        contactList: state.reducerChat.contactList,
        activeChatList: state.reducerChat.activeChatList,
        group_chat: state.reducerChat.group_chat,
        messageToEdit: state.reducerChat.messageToEdit,
        subscriberList: state.reducerChat.subscriberList,
        messageUuids: state.reducerChat.messageUuids,
        draftMessegeList: state.reducerChat.draftMessegeList,
        selectedSchedulerData: state.reducerChat.selectedSchedulerData,
        scheduleData: state.reducerChat.scheduleData,
        conversationList: state.reducerChat.conversationList,
        selectedChatOwnerInfo: state.reducerChat.selectedChatOwnerInfo,
        pendingFileUpload: state.reducerChat.pendingFileUpload,
        searchQuery: state.reducerChat.searchQuery,
        pendingMessages: state.reducerChat.pendingMessages,
        selectedmessage: state.reducerChat.selectedmessage,
        isMessageInfoOpen: state.reducerChatUI.isMessageInfoOpen,
        chtaMode: state.reducerChatUI.chtaMode,
        pttConnection: state.reducerChat.pttConnection,
        currentPttTarget: state.reducerChat.currentPttTarget,
        receivingPttTarget: state.reducerChat.receivingPttTarget,
        pttSettings: state.reducerChatUI.pttSettings
    }
}

const mapDispatchToProps = {
    startLoader,
    stopLoader,
    updateMessageFromHistory,
    clearConversationList,
    setReceivedMessage,
    setOneOOneChatMessages,
    setGroupChatMessages,
    setCurrentConversationOwnerDetails,
    setGroupList,
    setCurrentPttTarget,
    resetGroupUnreadMessageCount,
    setGroupUnreadMessageCount,
    setContactUnreadCount,
    resetContactUnreadCount,
    resetActiveContactUnreadCount,
    setContactList,
    setContactsSearchResult,
    setGroupsSearchResult,
    setBroadcastsSearchResult,
    setActiveChatList,
    updateEditedMessage,
    _removeGroup,
    updateGroupsList,
    setSubscribersList,
    setChatMessageUuidList,
    toggleCopyEnabled,
    toggleEditEnabled,
    toggleReplyEnabled,
    setMaxFileSize,
    toggleDeleteEnabled,
    setDraftMessage,
    setPendingFileUploadList,
    setScheduledMessages,
    clearScheduledMessages,
    setIncommingOneOOneChatMessages,
    setNewOneOOneChatMessages,
    updateNewChatMessages,
    setPendingMessage,
    clearPendingMessage,
    openScheduledMessageList,
    setSelectedSchedulerData,
    cancelMessageQuote,
    setConversationList,
    handleDeleteMessage,
    setGlobalUnreadMessageCount,
    clearDraftMessage,
    disableBroadcastReply,
    setActiveChatListPresent,
    clearPendingFileUploadList,
    handleRemovedActiveChat,
    updateContactList,
    updateRemovedGroupsList,
    removeContact,
    setActiveUnreadCount,
    markMessageFailed,
    setContactsNextPage,
    setGroupsNextPage,
    setSelectedMessage,
    hideGroupInfoDrawer
}

export const ChatManagerProvider = connect(mapStateToProps, mapDispatchToProps)(_ChatManagerProvider);

export default ChatManagerStore
