/* Action Types */
const SET_FEEDS_RESPONSE = 'SET_FEEDS_RESPONSE';
const SET_POST_RESPONSE = 'SET_POST_RESPONSE'
const UPDATE_POST_RESPONSE = 'UPDATE_POST_RESPONSE'
const SET_POST_LIST = 'SET_POST_LIST'
const UPDATE_POST_LIST = 'UPDATE_POST_LIST'
const UPDATE_POST = 'UPDATE_POST'
const EDIT_POST_TITLE = 'EDIT_POST_TITLE'
const EDIT_POST_BODY = 'EDIT_POST_BODY'
const SET_SELECTED_POST = 'SET_SELECTED_POST'
const SET_SELECTED_ATTACHMENT = 'SET_SELECTED_ATTACHMENT'
const SET_ATTACHMENTS_PENDING_FOR_UPLOAD = 'SET_ATTACHMENTS_PENDING_FOR_UPLOAD'
const CLEAR_PENDING_ATTACHMENTS = 'CLEAR_PENDING_ATTACHMENTS'
const SET_ATTACHMENTS_TO_DELETE = 'SET_ATTACHMENTS_TO_DELETE'
const CLEAR_ATTACHMENTS_TO_DELETE = 'CLEAR_ATTACHMENTS_TO_DELETE'
const SET_PENDING_ATTACHMENT_FILES = 'SET_PENDING_ATTACHMENT_FILES'
const REMOVE_FROM_PENDING_ATTACHMENT_FILES = 'REMOVE_FROM_PENDING_ATTACHMENT_FILES'
const CLEAR_PENDING_ATTACHMENT_FILES = 'CLEAR_PENDING_ATTACHMENT_FILES'
const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'
const SET_VIDEO_ATTACHMENT = 'SET_VIDEO_ATTACHMENT'

export default { 
    SET_FEEDS_RESPONSE,
    SET_POST_RESPONSE,
    UPDATE_POST_RESPONSE,
    SET_POST_LIST,
    UPDATE_POST_LIST,
    UPDATE_POST,
    EDIT_POST_TITLE,
    EDIT_POST_BODY,
    SET_SELECTED_POST,
    SET_SELECTED_ATTACHMENT,
    SET_ATTACHMENTS_PENDING_FOR_UPLOAD,
    CLEAR_PENDING_ATTACHMENTS,
    SET_ATTACHMENTS_TO_DELETE,
    CLEAR_ATTACHMENTS_TO_DELETE,
    SET_PENDING_ATTACHMENT_FILES,
    REMOVE_FROM_PENDING_ATTACHMENT_FILES,
    CLEAR_PENDING_ATTACHMENT_FILES,
    SET_UPLOAD_PROGRESS,
    SET_VIDEO_ATTACHMENT
}