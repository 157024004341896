import { combineReducers } from 'redux';
import reducerChatUI from '../components/Messanger/containers/ChatUI/reducerChatUI';
import reducerChat from '../components/Messanger/containers/Chat/reducerChat'
const rootReducer = {
    reducerChatUI,
    reducerChat,
};

const combinedReducer = combineReducers(rootReducer);

export default combinedReducer;
