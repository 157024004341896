import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import clsx from "clsx"
import GlobalStateContext from '../Context/GlobalStateContext'
/**
 * Section header component for every page inside main
 */
const useStyles = makeStyles(theme => ({

    root: {
        paddingTop: 16,
        paddingBottom: 16,
        // height: 'calc(100% - 69px)'
        height: "100%"
        // height: ({ showBanner }) => showBanner ? "90% !important" : "100%"
    },

    containerHeight: {
        height: ({ showBanner }) => showBanner ? "90% !important" : "100%"
    }

}))

const PageContainer = (props) => {
    const { width, ...others } = props
    const [state, dispatch] = useContext(GlobalStateContext)
    const classes = useStyles()

    return (
        <Box fixed className={classes.root} {...others} style={{ maxWidth: width || "100%", minWidth: width || "100%" }}>
            {props.children}
        </Box>
    )
}

PageContainer.defaultProps = {
}

PageContainer.propTypes = {
}

export default PageContainer