import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Breadcrumbs, Link, Box, Paper, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import BackIcon from '@material-ui/icons/ArrowBackIosRounded'
import Skeleton from '@material-ui/lab/Skeleton'
/**
 * Section header component for every page inside main
 */
const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        alignItems: 'center',
    },

    title: {
        color: "#010101",
        fontWeight: 600,
        fontSize: 15,
        lineHeight: '21.6px',
        marginBottom: 7
    },

    p: {
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '22.87px',
        marginTop: 7,
        color: '#161616',
    },

    paper: {
        padding: theme.spacing(1, 2),
    },

    backIcon: {
        fontSize: 23
    },

    subHeading: {
        whiteSpace: 'noWrap',
        [theme.breakpoints.down(1280)]: {
            whiteSpace: 'normal'
        }
    }

  }))

const SectionHeader = (props) => {
    
    const classes = useStyles()
    const { heading, subHeading, isWaiting } = props
    
    return (
        <>
            {isWaiting ?
            <>
                <Skeleton animation="wave" height={40} width={100} />
                <Skeleton animation="wave" height={20} width={250} />
            </>
            :
            <Box className={classes.root}>
                <Box>
                    <Typography component="h1" 
                        variant="h6" 
                        noWrap 
                        className={classes.title}>
                        {heading}
                    </Typography>
                    <Typography component="p" 
                        className={clsx(classes.p, classes.subHeading)}>
                        {subHeading}
                    </Typography>
                </Box>
            </Box>}
        </>
    )
}

SectionHeader.defaultProps = {
    isWaiting: false
}
  
SectionHeader.propTypes = {
    heading: PropTypes.any.isRequired, 
    subHeading: PropTypes.any.isRequired, 
    isWaiting: PropTypes.bool
}

export default SectionHeader