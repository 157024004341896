import React from "react";
import ReactDOM from "react-dom";
import "./layout.scss";
import "./index.css";
import "./tabs.scss";
import "./dropdown.scss";
import "./animate.css";
import "./waves.scss";
import "./alerts.scss";
import "./base.scss";
import "./_spacing_helpers.scss"
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store } from './Store/store'
import { Provider } from 'react-redux';
import "./fonts/SourceSans3VF-Roman.otf";

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root")
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (navigator.serviceWorker) {
    navigator.serviceWorker.register("worker.js").then(function (reg) {
        console.log("[Companion]", "Service worker registered!");
        console.log(reg);
    });
}
