import Axios from "axios";
/**
 * @returns {string} url of image from S3
 * @param {*} oidcUser 
 * @param {*} profile user profile
 */
export const getImageUrl = async (oidcUser, profile) => {
    var url = profile.profile_picture; // getAttachmentUrl(feeds.uuid, attachment.uuid);
    const config = {
        headers: {
            Authorization: `Bearer ${oidcUser.access_token}`,
            'X-Requested-With': 'XMLHttpRequest'
        }
    };

    return await Axios.get(url, config).then((response) => {
        return response.data;
    })
    .catch(function (e) {
        return e.response.request.responseURL
    });
};