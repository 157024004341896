import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
/**
 * This is an override component of oidc context api
 * 
 */

const SessionRedirect = () => {
    useEffect(() => {
        // We will redirect user to root
        // when session expires ie this component is rendered
        // And try for a silent sign in
        window.location.href = '/directory'
    }, [])

    return <div></div>
}

export default SessionRedirect