import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from 'lodash'
import usePermissions from "../Hooks/usePermissions";
import { withOidcSecure } from "@axa-fr/react-oidc-context";
import {
    BrowserRouter as Router,
    Route,
    Link as RouteLink,
    Redirect,
    Switch,
} from 'react-router-dom';
import useRedirect from "../Hooks/useRedirect";
import useGoogleAnalytics from "../Hooks/useGoogleAnalytics";

const RouteRedirect = (props) => {
    useGoogleAnalytics()
    const permissions = usePermissions()
    const { routes } = useRedirect()

    // Redirect all non allowed roots 
    const rootPath = !isEmpty(permissions) && permissions.showUsers ? '/directory' : "/messenger"
    return (
        <Switch>
            {routes.length > 0 && <>
                {routes.map((route) =>
                    <Route
                        exact={route.exact}
                        key={route.path}
                        path={route.path}
                        component={route.component}
                    />
                )}
                {/* Because router works with browser history, we need to wait for valid data before rendering redirect components */}
                <Route path="/">
                    <Redirect to={rootPath} />
                </Route>
                <Route path="/authentication/callback" exact>
                    <Redirect to={rootPath} />
                </Route>
            </>}
        </Switch>
    );
};


export default withOidcSecure(RouteRedirect);
